import { getTheme, IBasePickerStyleProps, IBasePickerStyles, IButtonProps, Icon, IconButton, IModalStyleProps, IModalStyles, ISearchBoxProps, IStyle, IStyleFunctionOrObject, ITagPickerProps, Modal, SearchBox, Stack, TagPicker, Text, values } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { t, use } from "i18next";
import { useImperativeHandle, useRef } from "react";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/Hooks";
import { getMainTheme, ThemeManager } from "../../../app/Themes";

interface SearchFieldProps {

    endButtons?: IButtonProps[],
    showIcon?: boolean,
    onClear?: (e?: any) => any,
    onSearch?: (e?: any) => any,

}
/*
 */

export const SearchField: React.FunctionComponent<SearchFieldProps & ITagPickerProps> = (props) => {
    const [isFocused, setFoucsed] = useState(false);
    const [hasText, setHasText] = useState(false);
    const [key, setKey] = useState("def");
    const stateTheme: { appTheme: string, isDarkModeEnabled: boolean } = useAppSelector((state) => {

        return { appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled }
    })
    const styles: any = props?.styles;
    const { endButtons, ...searchBoxProps } = props;
    return (
        <Stack horizontal tokens={{ padding: "0px 0px 0px 10px" }} styles={{
            root: {
                ...styles?.root,
                borderBottom: isFocused ? "2px solid " + getMainTheme(stateTheme.appTheme, stateTheme.isDarkModeEnabled).palette.themePrimary : "1px solid " + getMainTheme(stateTheme.appTheme, stateTheme.isDarkModeEnabled).semanticColors.focusBorder, borderRadius: "0px"
            }
        }} >
            <Stack.Item grow>
                <TagPicker
                    {...searchBoxProps}
                    inputProps={{
                        ...searchBoxProps.inputProps,
                        onBlur: (e) => {
                            setFoucsed(false);
                            if (props?.inputProps?.onBlur)
                                props.inputProps?.onBlur(e);
                        },
                        onFocus: (e) => {
                            setFoucsed(true);
                            if (props?.inputProps?.onFocus)
                                props.inputProps.onFocus(e);
                        },
                        onKeyPress: (e) => {
                            if (e.key === 'Enter' && props?.onSearch) {
                                props?.onSearch(e);
                            } else {
                                if (props.inputProps?.onKeyPress) {
                                    props.inputProps?.onKeyPress(e);
                                }
                            }
                        },

                    }}
                    key={key}

                    onInputChange={(e) => {
                        setHasText(e?.length > 0);
                        if (props?.onInputChange)
                            return props.onInputChange(e);
                        else
                            return e;
                    }}
                    styles={{

                        text: {
                            ...(styles)?.text,
                            border: "none",
                            ':after': {
                                border: "none",
                            }
                        },

                    }} />
            </Stack.Item>
            {
                props.showIcon ?
                    <IconButton
                        onClick={() => {
                            if (props.onClear)
                                props.onClear()

                            setHasText(false);
                            setKey(key == "new" ? "def" : "new") // this so fucking stupid, the only thing that worked to re-render the element

                        }}
                        iconProps={{ iconName: "clear" }}
                        styles={{
                            root: {
                                display: hasText ? "block" : "none",
                            },
                            icon: {
                                fontSize: 12,
                            }
                        }}
                    />
                    : null
            }
            {

                endButtons?.map((e, index) => {

                    return <IconButton key={index} {...e}
                    ></IconButton>
                })
            }

        </Stack >

    );

}