import { createTheme, Theme } from '@fluentui/react';

interface CustomTheme {
    mainTheme: Theme,
    headerTheme: Theme
}

export const defaultTheme = "exchange";
const themes: Map<string, CustomTheme> = new Map<string, CustomTheme>([
    [
        "exchange", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#0078d4',
                    themeLighterAlt: '#eff6fc',
                    themeLighter: '#deecf9',
                    themeLight: '#c7e0f4',
                    themeTertiary: '#71afe5',
                    themeSecondary: '#2b88d8',
                    themeDarkAlt: '#106ebe',
                    themeDark: '#005a9e',
                    themeDarker: '#004578',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#0075ce',
                    neutralLighter: '#0073cb',
                    neutralLight: '#006ec2',
                    neutralQuaternaryAlt: '#0067b5',
                    neutralQuaternary: '#0062ad',
                    neutralTertiaryAlt: '#005ea6',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#0078d4',
                }
            })
        }
    ],
    [
        "access", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#a4373a',
                    themeLighterAlt: '#fbf5f5',
                    themeLighter: '#f0d7d8',
                    themeLight: '#e3b6b8',
                    themeTertiary: '#c8797b',
                    themeSecondary: '#ae494c',
                    themeDarkAlt: '#933235',
                    themeDark: '#7c2a2d',
                    themeDarker: '#5b1f21',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#9f3639',
                    neutralLighter: '#9c3539',
                    neutralLight: '#963336',
                    neutralQuaternaryAlt: '#8c2f33',
                    neutralQuaternary: '#852d30',
                    neutralTertiaryAlt: '#802c2e',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#a4373a',
                }
            })
        }

    ],
    [
        "excel", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#217346',
                    themeLighterAlt: '#f2f9f5',
                    themeLighter: '#cee9da',
                    themeLight: '#a8d5bc',
                    themeTertiary: '#62ab83',
                    themeSecondary: '#318456',
                    themeDarkAlt: '#1e673f',
                    themeDark: '#195735',
                    themeDarker: '#134027',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#27794c',
                    neutralLighter: '#2c7e51',
                    neutralLight: '#35875a',
                    neutralQuaternaryAlt: '#3b8d60',
                    neutralQuaternary: '#409165',
                    neutralTertiaryAlt: '#58a47a',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#217346',
                }
            })
        }

    ],
    [
        "office", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#d83b01',
                    themeLighterAlt: '#fdf6f3',
                    themeLighter: '#f9dcd1',
                    themeLight: '#f4beaa',
                    themeTertiary: '#e8825d',
                    themeSecondary: '#dd4f1b',
                    themeDarkAlt: '#c33400',
                    themeDark: '#a52c00',
                    themeDarker: '#792000',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#d33800',
                    neutralLighter: '#cf3700',
                    neutralLight: '#c73500',
                    neutralQuaternaryAlt: '#b93100',
                    neutralQuaternary: '#b12f00',
                    neutralTertiaryAlt: '#aa2d00',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#d83b01',
                }
            })
        }

    ],
    [
        "oneNote", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#7719aa',
                    themeLighterAlt: '#f9f3fc',
                    themeLighter: '#e6d1f2',
                    themeLight: '#d1abe6',
                    themeTertiary: '#a864cd',
                    themeSecondary: '#862eb5',
                    themeDarkAlt: '#6c179a',
                    themeDark: '#5b1382',
                    themeDarker: '#430e60',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#7d21ae',
                    neutralLighter: '#8127b2',
                    neutralLight: '#8832b7',
                    neutralQuaternaryAlt: '#8d39ba',
                    neutralQuaternary: '#913fbd',
                    neutralTertiaryAlt: '#a159c8',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#7719aa',
                }
            })
        }
    ],
    [
        "planner", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#31752f',
                    themeLighterAlt: '#f4f9f4',
                    themeLighter: '#d3e9d3',
                    themeLight: '#b1d6af',
                    themeTertiary: '#70ac6e',
                    themeSecondary: '#42863f',
                    themeDarkAlt: '#2c6a2a',
                    themeDark: '#255924',
                    themeDarker: '#1c421a',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#377b34',
                    neutralLighter: '#3c813a',
                    neutralLight: '#458943',
                    neutralQuaternaryAlt: '#4b8f49',
                    neutralQuaternary: '#50934e',
                    neutralTertiaryAlt: '#67a565',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#31752f',
                }
            })
        }
    ],
    [
        "powerApps", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#742774',
                    themeLighterAlt: '#f9f3f9',
                    themeLighter: '#e9d0e9',
                    themeLight: '#d5abd5',
                    themeTertiary: '#ab67ab',
                    themeSecondary: '#843784',
                    themeDarkAlt: '#672367',
                    themeDark: '#571e57',
                    themeDarker: '#401640',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#792c79',
                    neutralLighter: '#7e327e',
                    neutralLight: '#873b87',
                    neutralQuaternaryAlt: '#8d418d',
                    neutralQuaternary: '#914691',
                    neutralTertiaryAlt: '#a45da4',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#742774',
                }
            })
        }
    ],
    [
        "powerPoint", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#b7472a',
                    themeLighterAlt: '#fcf6f4',
                    themeLighter: '#f4dcd6',
                    themeLight: '#eabeb4',
                    themeTertiary: '#d48672',
                    themeSecondary: '#c0583e',
                    themeDarkAlt: '#a53f26',
                    themeDark: '#8c3620',
                    themeDarker: '#672718',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#bb4d31',
                    neutralLighter: '#bd5238',
                    neutralLight: '#c25c42',
                    neutralQuaternaryAlt: '#c56249',
                    neutralQuaternary: '#c7674f',
                    neutralTertiaryAlt: '#d17d68',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#b7472a',
                }
            })
        }
    ],
    [
        "publisher", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#077568',
                    themeLighterAlt: '#f0f9f8',
                    themeLighter: '#c6e9e5',
                    themeLight: '#99d6cf',
                    themeTertiary: '#4baca1',
                    themeSecondary: '#178679',
                    themeDarkAlt: '#066a5e',
                    themeDark: '#05594f',
                    themeDarker: '#04423a',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#0c7b6e',
                    neutralLighter: '#128174',
                    neutralLight: '#1b897c',
                    neutralQuaternaryAlt: '#218f82',
                    neutralQuaternary: '#279386',
                    neutralTertiaryAlt: '#40a59a',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#077568',
                }
            })
        }
    ],
    [
        "sway", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#008272',
                    themeLighterAlt: '#f0faf9',
                    themeLighter: '#c5ebe7',
                    themeLight: '#98dad2',
                    themeTertiary: '#48b4a7',
                    themeSecondary: '#119182',
                    themeDarkAlt: '#007567',
                    themeDark: '#006357',
                    themeDarker: '#004940',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#068778',
                    neutralLighter: '#0c8c7d',
                    neutralLight: '#159485',
                    neutralQuaternaryAlt: '#1c998a',
                    neutralQuaternary: '#229d8f',
                    neutralTertiaryAlt: '#3daea0',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#008272',
                }
            })
        }
    ],
    [
        "teams", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#6264a7',
                    themeLighterAlt: '#f7f7fb',
                    themeLighter: '#e1e1f1',
                    themeLight: '#c8c9e4',
                    themeTertiary: '#989ac9',
                    themeSecondary: '#7173b0',
                    themeDarkAlt: '#585a95',
                    themeDark: '#4a4c7e',
                    themeDarker: '#37385d',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#6769aa',
                    neutralLighter: '#6c6ead',
                    neutralLight: '#7476b3',
                    neutralQuaternaryAlt: '#797bb6',
                    neutralQuaternary: '#7e80b9',
                    neutralTertiaryAlt: '#9092c5',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#6264a7',
                }
            })
        }
    ],
    [
        "visio", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#3955a3',
                    themeLighterAlt: '#f5f7fb',
                    themeLighter: '#d7def0',
                    themeLight: '#b7c3e3',
                    themeTertiary: '#7a8fc8',
                    themeSecondary: '#4b65ae',
                    themeDarkAlt: '#334d93',
                    themeDark: '#2b417c',
                    themeDarker: '#20305b',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#38539f',
                    neutralLighter: '#37529c',
                    neutralLight: '#344e96',
                    neutralQuaternaryAlt: '#31498c',
                    neutralQuaternary: '#2f4685',
                    neutralTertiaryAlt: '#2d4380',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#3955a3',
                }
            })
        }
    ],
    [
        "word", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#2b579a',
                    themeLighterAlt: '#f4f7fb',
                    themeLighter: '#d3deef',
                    themeLight: '#b0c3e0',
                    themeTertiary: '#6e90c2',
                    themeSecondary: '#3d66a5',
                    themeDarkAlt: '#274e8a',
                    themeDark: '#214274',
                    themeDarker: '#183156',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#315c9d',
                    neutralLighter: '#3761a1',
                    neutralLight: '#406aa8',
                    neutralQuaternaryAlt: '#476fac',
                    neutralQuaternary: '#4c74af',
                    neutralTertiaryAlt: '#6488bd',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#2b579a',
                }
            })
        }
    ],
    [
        "yammer", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#106ebe',
                    themeLighterAlt: '#f3f8fc',
                    themeLighter: '#d1e4f5',
                    themeLight: '#abcdec',
                    themeTertiary: '#61a1d9',
                    themeSecondary: '#267cc7',
                    themeDarkAlt: '#0e62ac',
                    themeDark: '#0c5391',
                    themeDarker: '#093d6b',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#1772c2',
                    neutralLighter: '#1f77c4',
                    neutralLight: '#2b7fc8',
                    neutralQuaternaryAlt: '#3384cb',
                    neutralQuaternary: '#3988cd',
                    neutralTertiaryAlt: '#569ad5',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#106ebe',
                }
            })
        }
    ],
    [
        "basic", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#595959',
                    themeLighterAlt: '#f8f8f8',
                    themeLighter: '#e4e4e4',
                    themeLight: '#cdcdcd',
                    themeTertiary: '#9c9c9c',
                    themeSecondary: '#6d6d6d',
                    themeDarkAlt: '#505050',
                    themeDark: '#444444',
                    themeDarker: '#323232',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#606060',
                    neutralLighter: '#676767',
                    neutralLight: '#717171',
                    neutralQuaternaryAlt: '#787878',
                    neutralQuaternary: '#7d7d7d',
                    neutralTertiaryAlt: '#939393',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#595959',
                }
            })
        }
    ],
    [
        "black", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#323130',
                    themeLighterAlt: '#e7e6e5',
                    themeLighter: '#d0cfce',
                    themeLight: '#bab8b7',
                    themeTertiary: '#a3a2a0',
                    themeSecondary: '#8d8b8a',
                    themeDarkAlt: '#767573',
                    themeDark: '#605e5d',
                    themeDarker: '#494847',
                    neutralLighterAlt: '#faf9f8',
                    neutralLighter: '#f3f2f1',
                    neutralLight: '#edebe9',
                    neutralQuaternaryAlt: '#e1dfdd',
                    neutralQuaternary: '#d0d0d0',
                    neutralTertiaryAlt: '#c8c6c4',
                    neutralTertiary: '#a19f9d',
                    neutralSecondary: '#605e5c',
                    neutralPrimaryAlt: '#3b3a39',
                    neutralPrimary: '#323130',
                    neutralDark: '#201f1e',
                    black: '#000000',
                    white: '#ffffff',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#3c3b39',
                    neutralLighter: '#444241',
                    neutralLight: '#514f4e',
                    neutralQuaternaryAlt: '#595756',
                    neutralQuaternary: '#5f5e5c',
                    neutralTertiaryAlt: '#7a7977',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#323130',
                }
            })
        }
    ],
    [
        "dark", {
            mainTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#3c3b39',
                    neutralLighter: '#444241',
                    neutralLight: '#514f4e',
                    neutralQuaternaryAlt: '#595756',
                    neutralQuaternary: '#5f5e5c',
                    neutralTertiaryAlt: '#7a7977',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#323130',
                }
            }),
            headerTheme: createTheme({
                palette: {
                    themePrimary: '#ffffff',
                    themeLighterAlt: '#767676',
                    themeLighter: '#a6a6a6',
                    themeLight: '#c8c8c8',
                    themeTertiary: '#d0d0d0',
                    themeSecondary: '#dadada',
                    themeDarkAlt: '#eaeaea',
                    themeDark: '#f4f4f4',
                    themeDarker: '#f8f8f8',
                    neutralLighterAlt: '#3c3b39',
                    neutralLighter: '#444241',
                    neutralLight: '#514f4e',
                    neutralQuaternaryAlt: '#595756',
                    neutralQuaternary: '#5f5e5c',
                    neutralTertiaryAlt: '#7a7977',
                    neutralTertiary: '#c8c8c8',
                    neutralSecondary: '#d0d0d0',
                    neutralPrimaryAlt: '#dadada',
                    neutralPrimary: '#ffffff',
                    neutralDark: '#f4f4f4',
                    black: '#f8f8f8',
                    white: '#323130',
                }
            })
        }
    ]
]);

export const getMainTheme = (themeId: string, isDarkModeEnabled: boolean) : Theme=> {
    return ThemeManager.getThemeManager().getTheme(themeId, isDarkModeEnabled).theme.mainTheme
}

export const getHeaderTheme = (themeId: string, isDarkModeEnabled: boolean) : Theme=> {
    return ThemeManager.getThemeManager().getTheme(themeId, isDarkModeEnabled).theme.headerTheme
}

export class AppTheme {

    private _themeId: string;
    private _isDarkModeEnabled: boolean;
    private _theme: CustomTheme;

    public constructor(themeId: string) {
        
        var t = themes.get(themeId);
        if (!t) {
            this._theme = themes.get(defaultTheme) ?? {} as CustomTheme
            this._themeId = defaultTheme;
        } else {
            this._theme = t
            this._themeId = themeId;
        }
        this._isDarkModeEnabled = false;
     
    }

    public get themeId(): string {
        return this._themeId;
    }
    public get theme(): CustomTheme {
        return this._theme;
    }
    public get isDarkModeEnabled(): boolean {
        return this._isDarkModeEnabled;
    }
    public set isDarkModeEnabled(value: boolean) {
        if (value == this._isDarkModeEnabled) {
            return;
        }
        this._isDarkModeEnabled = value;
        var r = this._theme;
        var dark = createTheme({
            palette: {
                themePrimary: r?.mainTheme.palette.themePrimary,
                themeLighterAlt: r?.mainTheme.palette.themeLighterAlt,
                themeLighter: r?.mainTheme.palette.themeLighter,
                themeLight: r?.mainTheme.palette.themeLight,
                themeTertiary: r?.mainTheme.palette.themeTertiary,
                themeSecondary: r?.mainTheme.palette.themeSecondary,
                themeDarkAlt: r?.mainTheme.palette.themeDarkAlt,
                themeDark: r?.mainTheme.palette.themeDark,
                themeDarker: r?.mainTheme.palette.themeDarker,
                neutralLighterAlt: '#3c3b39',
                neutralLighter: '#444241',
                neutralLight: '#514f4e',
                neutralQuaternaryAlt: '#595756',
                neutralQuaternary: '#5f5e5c',
                neutralTertiaryAlt: '#7a7977',
                neutralTertiary: '#c8c8c8',
                neutralSecondary: '#d0d0d0',
                neutralPrimaryAlt: '#dadada',
                neutralPrimary: '#ffffff',
                neutralDark: '#f4f4f4',
                black: '#f8f8f8',
                white: '#323130',
            }
        });
        if (dark) {
            this._theme = { mainTheme: dark, headerTheme: dark } as CustomTheme
        }
    }

}


export class ThemeManager {

    private static themeManager?: ThemeManager = undefined
    private currentTheme: AppTheme;

    private constructor() {
        var isDarkModeEnabled = localStorage.getItem('isDarkModeEnabled') === 'true' ?? false
        var themeId = localStorage.getItem('theme') ?? defaultTheme;
        this.currentTheme = new AppTheme(themeId);
        this.currentTheme.isDarkModeEnabled = isDarkModeEnabled
    }

    public static getThemeManager = (): ThemeManager => {
        if (ThemeManager.themeManager == undefined) {
            ThemeManager.themeManager = new ThemeManager();
            return ThemeManager.themeManager;
        } else {
            return ThemeManager.themeManager
        }
    }

    public getTheme(themeId?: string, isDarkModeEnabled?: boolean): AppTheme {
        if (!themeId) {
            return this.currentTheme
        } else {
            var t =  new AppTheme(themeId);
            t.isDarkModeEnabled = isDarkModeEnabled ?? false;
            return t;
        }
    }

    public applyTheme(appTheme: AppTheme): void {
        localStorage.setItem('theme', appTheme.themeId)
        localStorage.setItem('isDarkModeEnabled', appTheme.isDarkModeEnabled + "")
        this.currentTheme = appTheme;
    }
}

