// source: usertype.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('../common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.managers_user_type_endpoint.AddUserTypeReplay', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.AddUserTypeRequest', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.AddUserTypeResponse', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.AddUserTypeResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.DeleteUserTypeReplay', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.DeleteUserTypeRequest', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.DeleteUserTypeResponse', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.DeleteUserTypeResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.GetUserTypesReplay', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.GetUserTypesRequest', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.GetUserTypesResponse', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.GetUserTypesResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.UpdateUserTypeReplay', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.UpdateUserTypeRequest', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.UpdateUserTypeResponse', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.UpdateUserTypeResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_user_type_endpoint.UserTypeSummaryInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_user_type_endpoint.AddUserTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_user_type_endpoint.AddUserTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_user_type_endpoint.AddUserTypeRequest.displayName = 'proto.managers_user_type_endpoint.AddUserTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_user_type_endpoint.UpdateUserTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_user_type_endpoint.UpdateUserTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_user_type_endpoint.UpdateUserTypeRequest.displayName = 'proto.managers_user_type_endpoint.UpdateUserTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_user_type_endpoint.DeleteUserTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_user_type_endpoint.DeleteUserTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_user_type_endpoint.DeleteUserTypeRequest.displayName = 'proto.managers_user_type_endpoint.DeleteUserTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_user_type_endpoint.GetUserTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_user_type_endpoint.GetUserTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_user_type_endpoint.GetUserTypesRequest.displayName = 'proto.managers_user_type_endpoint.GetUserTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_user_type_endpoint.AddUserTypeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_user_type_endpoint.AddUserTypeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_user_type_endpoint.AddUserTypeReplay.displayName = 'proto.managers_user_type_endpoint.AddUserTypeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_user_type_endpoint.UpdateUserTypeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_user_type_endpoint.UpdateUserTypeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_user_type_endpoint.UpdateUserTypeReplay.displayName = 'proto.managers_user_type_endpoint.UpdateUserTypeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_user_type_endpoint.DeleteUserTypeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_user_type_endpoint.DeleteUserTypeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_user_type_endpoint.DeleteUserTypeReplay.displayName = 'proto.managers_user_type_endpoint.DeleteUserTypeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_user_type_endpoint.GetUserTypesReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.managers_user_type_endpoint.GetUserTypesReplay.repeatedFields_, null);
};
goog.inherits(proto.managers_user_type_endpoint.GetUserTypesReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_user_type_endpoint.GetUserTypesReplay.displayName = 'proto.managers_user_type_endpoint.GetUserTypesReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_user_type_endpoint.UserTypeSummaryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_user_type_endpoint.UserTypeSummaryInfo.displayName = 'proto.managers_user_type_endpoint.UserTypeSummaryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_user_type_endpoint.AddUserTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_user_type_endpoint.AddUserTypeResponse.oneofGroups_);
};
goog.inherits(proto.managers_user_type_endpoint.AddUserTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_user_type_endpoint.AddUserTypeResponse.displayName = 'proto.managers_user_type_endpoint.AddUserTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_user_type_endpoint.UpdateUserTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_user_type_endpoint.UpdateUserTypeResponse.oneofGroups_);
};
goog.inherits(proto.managers_user_type_endpoint.UpdateUserTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_user_type_endpoint.UpdateUserTypeResponse.displayName = 'proto.managers_user_type_endpoint.UpdateUserTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_user_type_endpoint.DeleteUserTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_user_type_endpoint.DeleteUserTypeResponse.oneofGroups_);
};
goog.inherits(proto.managers_user_type_endpoint.DeleteUserTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_user_type_endpoint.DeleteUserTypeResponse.displayName = 'proto.managers_user_type_endpoint.DeleteUserTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_user_type_endpoint.GetUserTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_user_type_endpoint.GetUserTypesResponse.oneofGroups_);
};
goog.inherits(proto.managers_user_type_endpoint.GetUserTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_user_type_endpoint.GetUserTypesResponse.displayName = 'proto.managers_user_type_endpoint.GetUserTypesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_user_type_endpoint.AddUserTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_user_type_endpoint.AddUserTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_user_type_endpoint.AddUserTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.AddUserTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_user_type_endpoint.AddUserTypeRequest}
 */
proto.managers_user_type_endpoint.AddUserTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_user_type_endpoint.AddUserTypeRequest;
  return proto.managers_user_type_endpoint.AddUserTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_user_type_endpoint.AddUserTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_user_type_endpoint.AddUserTypeRequest}
 */
proto.managers_user_type_endpoint.AddUserTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_user_type_endpoint.AddUserTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_user_type_endpoint.AddUserTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_user_type_endpoint.AddUserTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.AddUserTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.managers_user_type_endpoint.AddUserTypeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.managers_user_type_endpoint.AddUserTypeRequest} returns this
 */
proto.managers_user_type_endpoint.AddUserTypeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value organization = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_user_type_endpoint.AddUserTypeRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_user_type_endpoint.AddUserTypeRequest} returns this
*/
proto.managers_user_type_endpoint.AddUserTypeRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.AddUserTypeRequest} returns this
 */
proto.managers_user_type_endpoint.AddUserTypeRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.AddUserTypeRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_user_type_endpoint.UpdateUserTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_user_type_endpoint.UpdateUserTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_user_type_endpoint.UpdateUserTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.UpdateUserTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeRequest}
 */
proto.managers_user_type_endpoint.UpdateUserTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_user_type_endpoint.UpdateUserTypeRequest;
  return proto.managers_user_type_endpoint.UpdateUserTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_user_type_endpoint.UpdateUserTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeRequest}
 */
proto.managers_user_type_endpoint.UpdateUserTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_user_type_endpoint.UpdateUserTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_user_type_endpoint.UpdateUserTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_user_type_endpoint.UpdateUserTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.UpdateUserTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.managers_user_type_endpoint.UpdateUserTypeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeRequest} returns this
 */
proto.managers_user_type_endpoint.UpdateUserTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.managers_user_type_endpoint.UpdateUserTypeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeRequest} returns this
 */
proto.managers_user_type_endpoint.UpdateUserTypeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Int32Value organization = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_user_type_endpoint.UpdateUserTypeRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeRequest} returns this
*/
proto.managers_user_type_endpoint.UpdateUserTypeRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeRequest} returns this
 */
proto.managers_user_type_endpoint.UpdateUserTypeRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.UpdateUserTypeRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_user_type_endpoint.DeleteUserTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_user_type_endpoint.DeleteUserTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_user_type_endpoint.DeleteUserTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.DeleteUserTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeRequest}
 */
proto.managers_user_type_endpoint.DeleteUserTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_user_type_endpoint.DeleteUserTypeRequest;
  return proto.managers_user_type_endpoint.DeleteUserTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_user_type_endpoint.DeleteUserTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeRequest}
 */
proto.managers_user_type_endpoint.DeleteUserTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_user_type_endpoint.DeleteUserTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_user_type_endpoint.DeleteUserTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_user_type_endpoint.DeleteUserTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.DeleteUserTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.managers_user_type_endpoint.DeleteUserTypeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeRequest} returns this
 */
proto.managers_user_type_endpoint.DeleteUserTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value organization = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_user_type_endpoint.DeleteUserTypeRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeRequest} returns this
*/
proto.managers_user_type_endpoint.DeleteUserTypeRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeRequest} returns this
 */
proto.managers_user_type_endpoint.DeleteUserTypeRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.DeleteUserTypeRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_user_type_endpoint.GetUserTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_user_type_endpoint.GetUserTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    nextto: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numofresults: jspb.Message.getFieldWithDefault(msg, 4, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_user_type_endpoint.GetUserTypesRequest}
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_user_type_endpoint.GetUserTypesRequest;
  return proto.managers_user_type_endpoint.GetUserTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_user_type_endpoint.GetUserTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_user_type_endpoint.GetUserTypesRequest}
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextto(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_user_type_endpoint.GetUserTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_user_type_endpoint.GetUserTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getNextto();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue search = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_user_type_endpoint.GetUserTypesRequest} returns this
*/
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.GetUserTypesRequest} returns this
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool order = 2;
 * @return {boolean}
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.managers_user_type_endpoint.GetUserTypesRequest} returns this
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 nextTo = 3;
 * @return {number}
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.getNextto = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_user_type_endpoint.GetUserTypesRequest} returns this
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.setNextto = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 numOfResults = 4;
 * @return {number}
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_user_type_endpoint.GetUserTypesRequest} returns this
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.Int32Value organization = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_user_type_endpoint.GetUserTypesRequest} returns this
*/
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.GetUserTypesRequest} returns this
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.GetUserTypesRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_user_type_endpoint.AddUserTypeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_user_type_endpoint.AddUserTypeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_user_type_endpoint.AddUserTypeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.AddUserTypeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    usertypeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_user_type_endpoint.AddUserTypeReplay}
 */
proto.managers_user_type_endpoint.AddUserTypeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_user_type_endpoint.AddUserTypeReplay;
  return proto.managers_user_type_endpoint.AddUserTypeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_user_type_endpoint.AddUserTypeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_user_type_endpoint.AddUserTypeReplay}
 */
proto.managers_user_type_endpoint.AddUserTypeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsertypeid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_user_type_endpoint.AddUserTypeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_user_type_endpoint.AddUserTypeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_user_type_endpoint.AddUserTypeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.AddUserTypeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsertypeid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 userTypeId = 1;
 * @return {number}
 */
proto.managers_user_type_endpoint.AddUserTypeReplay.prototype.getUsertypeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_user_type_endpoint.AddUserTypeReplay} returns this
 */
proto.managers_user_type_endpoint.AddUserTypeReplay.prototype.setUsertypeid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_user_type_endpoint.AddUserTypeReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_user_type_endpoint.AddUserTypeReplay} returns this
*/
proto.managers_user_type_endpoint.AddUserTypeReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.AddUserTypeReplay} returns this
 */
proto.managers_user_type_endpoint.AddUserTypeReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.AddUserTypeReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.managers_user_type_endpoint.AddUserTypeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.managers_user_type_endpoint.AddUserTypeReplay} returns this
*/
proto.managers_user_type_endpoint.AddUserTypeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.AddUserTypeReplay} returns this
 */
proto.managers_user_type_endpoint.AddUserTypeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.AddUserTypeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_user_type_endpoint.UpdateUserTypeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_user_type_endpoint.UpdateUserTypeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_user_type_endpoint.UpdateUserTypeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.UpdateUserTypeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeReplay}
 */
proto.managers_user_type_endpoint.UpdateUserTypeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_user_type_endpoint.UpdateUserTypeReplay;
  return proto.managers_user_type_endpoint.UpdateUserTypeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_user_type_endpoint.UpdateUserTypeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeReplay}
 */
proto.managers_user_type_endpoint.UpdateUserTypeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_user_type_endpoint.UpdateUserTypeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_user_type_endpoint.UpdateUserTypeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_user_type_endpoint.UpdateUserTypeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.UpdateUserTypeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_user_type_endpoint.UpdateUserTypeReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeReplay} returns this
*/
proto.managers_user_type_endpoint.UpdateUserTypeReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeReplay} returns this
 */
proto.managers_user_type_endpoint.UpdateUserTypeReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.UpdateUserTypeReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.managers_user_type_endpoint.UpdateUserTypeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeReplay} returns this
*/
proto.managers_user_type_endpoint.UpdateUserTypeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeReplay} returns this
 */
proto.managers_user_type_endpoint.UpdateUserTypeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.UpdateUserTypeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_user_type_endpoint.DeleteUserTypeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_user_type_endpoint.DeleteUserTypeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_user_type_endpoint.DeleteUserTypeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.DeleteUserTypeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeReplay}
 */
proto.managers_user_type_endpoint.DeleteUserTypeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_user_type_endpoint.DeleteUserTypeReplay;
  return proto.managers_user_type_endpoint.DeleteUserTypeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_user_type_endpoint.DeleteUserTypeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeReplay}
 */
proto.managers_user_type_endpoint.DeleteUserTypeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_user_type_endpoint.DeleteUserTypeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_user_type_endpoint.DeleteUserTypeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_user_type_endpoint.DeleteUserTypeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.DeleteUserTypeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_user_type_endpoint.DeleteUserTypeReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeReplay} returns this
*/
proto.managers_user_type_endpoint.DeleteUserTypeReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeReplay} returns this
 */
proto.managers_user_type_endpoint.DeleteUserTypeReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.DeleteUserTypeReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.managers_user_type_endpoint.DeleteUserTypeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeReplay} returns this
*/
proto.managers_user_type_endpoint.DeleteUserTypeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeReplay} returns this
 */
proto.managers_user_type_endpoint.DeleteUserTypeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.DeleteUserTypeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.managers_user_type_endpoint.GetUserTypesReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_user_type_endpoint.GetUserTypesReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_user_type_endpoint.GetUserTypesReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_user_type_endpoint.GetUserTypesReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.GetUserTypesReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    usertypesList: jspb.Message.toObjectList(msg.getUsertypesList(),
    proto.managers_user_type_endpoint.UserTypeSummaryInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_user_type_endpoint.GetUserTypesReplay}
 */
proto.managers_user_type_endpoint.GetUserTypesReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_user_type_endpoint.GetUserTypesReplay;
  return proto.managers_user_type_endpoint.GetUserTypesReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_user_type_endpoint.GetUserTypesReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_user_type_endpoint.GetUserTypesReplay}
 */
proto.managers_user_type_endpoint.GetUserTypesReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_user_type_endpoint.UserTypeSummaryInfo;
      reader.readMessage(value,proto.managers_user_type_endpoint.UserTypeSummaryInfo.deserializeBinaryFromReader);
      msg.addUsertypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_user_type_endpoint.GetUserTypesReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_user_type_endpoint.GetUserTypesReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_user_type_endpoint.GetUserTypesReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.GetUserTypesReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsertypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.managers_user_type_endpoint.UserTypeSummaryInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserTypeSummaryInfo userTypes = 1;
 * @return {!Array<!proto.managers_user_type_endpoint.UserTypeSummaryInfo>}
 */
proto.managers_user_type_endpoint.GetUserTypesReplay.prototype.getUsertypesList = function() {
  return /** @type{!Array<!proto.managers_user_type_endpoint.UserTypeSummaryInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.managers_user_type_endpoint.UserTypeSummaryInfo, 1));
};


/**
 * @param {!Array<!proto.managers_user_type_endpoint.UserTypeSummaryInfo>} value
 * @return {!proto.managers_user_type_endpoint.GetUserTypesReplay} returns this
*/
proto.managers_user_type_endpoint.GetUserTypesReplay.prototype.setUsertypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.managers_user_type_endpoint.UserTypeSummaryInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.managers_user_type_endpoint.UserTypeSummaryInfo}
 */
proto.managers_user_type_endpoint.GetUserTypesReplay.prototype.addUsertypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.managers_user_type_endpoint.UserTypeSummaryInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.managers_user_type_endpoint.GetUserTypesReplay} returns this
 */
proto.managers_user_type_endpoint.GetUserTypesReplay.prototype.clearUsertypesList = function() {
  return this.setUsertypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_user_type_endpoint.UserTypeSummaryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_user_type_endpoint.UserTypeSummaryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    usertypecategory: (f = msg.getUsertypecategory()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_user_type_endpoint.UserTypeSummaryInfo}
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_user_type_endpoint.UserTypeSummaryInfo;
  return proto.managers_user_type_endpoint.UserTypeSummaryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_user_type_endpoint.UserTypeSummaryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_user_type_endpoint.UserTypeSummaryInfo}
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setUsertypecategory(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_user_type_endpoint.UserTypeSummaryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_user_type_endpoint.UserTypeSummaryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUsertypecategory();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_user_type_endpoint.UserTypeSummaryInfo} returns this
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.ProfileResponse addedBy = 2;
 * @return {?proto.common.ProfileResponse}
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 2));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.managers_user_type_endpoint.UserTypeSummaryInfo} returns this
*/
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.UserTypeSummaryInfo} returns this
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_user_type_endpoint.UserTypeSummaryInfo} returns this
*/
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.UserTypeSummaryInfo} returns this
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value userTypeCategory = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.getUsertypecategory = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_user_type_endpoint.UserTypeSummaryInfo} returns this
*/
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.setUsertypecategory = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.UserTypeSummaryInfo} returns this
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.clearUsertypecategory = function() {
  return this.setUsertypecategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.hasUsertypecategory = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_user_type_endpoint.UserTypeSummaryInfo} returns this
*/
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.UserTypeSummaryInfo} returns this
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.UserTypeSummaryInfo.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_user_type_endpoint.AddUserTypeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_user_type_endpoint.AddUserTypeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_user_type_endpoint.AddUserTypeResponse.ResultCase}
 */
proto.managers_user_type_endpoint.AddUserTypeResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_user_type_endpoint.AddUserTypeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_user_type_endpoint.AddUserTypeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_user_type_endpoint.AddUserTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_user_type_endpoint.AddUserTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_user_type_endpoint.AddUserTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.AddUserTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_user_type_endpoint.AddUserTypeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_user_type_endpoint.AddUserTypeResponse}
 */
proto.managers_user_type_endpoint.AddUserTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_user_type_endpoint.AddUserTypeResponse;
  return proto.managers_user_type_endpoint.AddUserTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_user_type_endpoint.AddUserTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_user_type_endpoint.AddUserTypeResponse}
 */
proto.managers_user_type_endpoint.AddUserTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_user_type_endpoint.AddUserTypeReplay;
      reader.readMessage(value,proto.managers_user_type_endpoint.AddUserTypeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_user_type_endpoint.AddUserTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_user_type_endpoint.AddUserTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_user_type_endpoint.AddUserTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.AddUserTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_user_type_endpoint.AddUserTypeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddUserTypeReplay success = 1;
 * @return {?proto.managers_user_type_endpoint.AddUserTypeReplay}
 */
proto.managers_user_type_endpoint.AddUserTypeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_user_type_endpoint.AddUserTypeReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_user_type_endpoint.AddUserTypeReplay, 1));
};


/**
 * @param {?proto.managers_user_type_endpoint.AddUserTypeReplay|undefined} value
 * @return {!proto.managers_user_type_endpoint.AddUserTypeResponse} returns this
*/
proto.managers_user_type_endpoint.AddUserTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_user_type_endpoint.AddUserTypeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.AddUserTypeResponse} returns this
 */
proto.managers_user_type_endpoint.AddUserTypeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.AddUserTypeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_user_type_endpoint.UpdateUserTypeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_user_type_endpoint.UpdateUserTypeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_user_type_endpoint.UpdateUserTypeResponse.ResultCase}
 */
proto.managers_user_type_endpoint.UpdateUserTypeResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_user_type_endpoint.UpdateUserTypeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_user_type_endpoint.UpdateUserTypeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_user_type_endpoint.UpdateUserTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_user_type_endpoint.UpdateUserTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_user_type_endpoint.UpdateUserTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.UpdateUserTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_user_type_endpoint.UpdateUserTypeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeResponse}
 */
proto.managers_user_type_endpoint.UpdateUserTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_user_type_endpoint.UpdateUserTypeResponse;
  return proto.managers_user_type_endpoint.UpdateUserTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_user_type_endpoint.UpdateUserTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeResponse}
 */
proto.managers_user_type_endpoint.UpdateUserTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_user_type_endpoint.UpdateUserTypeReplay;
      reader.readMessage(value,proto.managers_user_type_endpoint.UpdateUserTypeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_user_type_endpoint.UpdateUserTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_user_type_endpoint.UpdateUserTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_user_type_endpoint.UpdateUserTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.UpdateUserTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_user_type_endpoint.UpdateUserTypeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateUserTypeReplay success = 1;
 * @return {?proto.managers_user_type_endpoint.UpdateUserTypeReplay}
 */
proto.managers_user_type_endpoint.UpdateUserTypeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_user_type_endpoint.UpdateUserTypeReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_user_type_endpoint.UpdateUserTypeReplay, 1));
};


/**
 * @param {?proto.managers_user_type_endpoint.UpdateUserTypeReplay|undefined} value
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeResponse} returns this
*/
proto.managers_user_type_endpoint.UpdateUserTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_user_type_endpoint.UpdateUserTypeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.UpdateUserTypeResponse} returns this
 */
proto.managers_user_type_endpoint.UpdateUserTypeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.UpdateUserTypeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_user_type_endpoint.DeleteUserTypeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_user_type_endpoint.DeleteUserTypeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_user_type_endpoint.DeleteUserTypeResponse.ResultCase}
 */
proto.managers_user_type_endpoint.DeleteUserTypeResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_user_type_endpoint.DeleteUserTypeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_user_type_endpoint.DeleteUserTypeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_user_type_endpoint.DeleteUserTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_user_type_endpoint.DeleteUserTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_user_type_endpoint.DeleteUserTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.DeleteUserTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_user_type_endpoint.DeleteUserTypeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeResponse}
 */
proto.managers_user_type_endpoint.DeleteUserTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_user_type_endpoint.DeleteUserTypeResponse;
  return proto.managers_user_type_endpoint.DeleteUserTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_user_type_endpoint.DeleteUserTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeResponse}
 */
proto.managers_user_type_endpoint.DeleteUserTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_user_type_endpoint.DeleteUserTypeReplay;
      reader.readMessage(value,proto.managers_user_type_endpoint.DeleteUserTypeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_user_type_endpoint.DeleteUserTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_user_type_endpoint.DeleteUserTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_user_type_endpoint.DeleteUserTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.DeleteUserTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_user_type_endpoint.DeleteUserTypeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteUserTypeReplay success = 1;
 * @return {?proto.managers_user_type_endpoint.DeleteUserTypeReplay}
 */
proto.managers_user_type_endpoint.DeleteUserTypeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_user_type_endpoint.DeleteUserTypeReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_user_type_endpoint.DeleteUserTypeReplay, 1));
};


/**
 * @param {?proto.managers_user_type_endpoint.DeleteUserTypeReplay|undefined} value
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeResponse} returns this
*/
proto.managers_user_type_endpoint.DeleteUserTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_user_type_endpoint.DeleteUserTypeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.DeleteUserTypeResponse} returns this
 */
proto.managers_user_type_endpoint.DeleteUserTypeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.DeleteUserTypeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_user_type_endpoint.GetUserTypesResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_user_type_endpoint.GetUserTypesResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_user_type_endpoint.GetUserTypesResponse.ResultCase}
 */
proto.managers_user_type_endpoint.GetUserTypesResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_user_type_endpoint.GetUserTypesResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_user_type_endpoint.GetUserTypesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_user_type_endpoint.GetUserTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_user_type_endpoint.GetUserTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_user_type_endpoint.GetUserTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.GetUserTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_user_type_endpoint.GetUserTypesReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_user_type_endpoint.GetUserTypesResponse}
 */
proto.managers_user_type_endpoint.GetUserTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_user_type_endpoint.GetUserTypesResponse;
  return proto.managers_user_type_endpoint.GetUserTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_user_type_endpoint.GetUserTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_user_type_endpoint.GetUserTypesResponse}
 */
proto.managers_user_type_endpoint.GetUserTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_user_type_endpoint.GetUserTypesReplay;
      reader.readMessage(value,proto.managers_user_type_endpoint.GetUserTypesReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_user_type_endpoint.GetUserTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_user_type_endpoint.GetUserTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_user_type_endpoint.GetUserTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_user_type_endpoint.GetUserTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_user_type_endpoint.GetUserTypesReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetUserTypesReplay success = 1;
 * @return {?proto.managers_user_type_endpoint.GetUserTypesReplay}
 */
proto.managers_user_type_endpoint.GetUserTypesResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_user_type_endpoint.GetUserTypesReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_user_type_endpoint.GetUserTypesReplay, 1));
};


/**
 * @param {?proto.managers_user_type_endpoint.GetUserTypesReplay|undefined} value
 * @return {!proto.managers_user_type_endpoint.GetUserTypesResponse} returns this
*/
proto.managers_user_type_endpoint.GetUserTypesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_user_type_endpoint.GetUserTypesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_user_type_endpoint.GetUserTypesResponse} returns this
 */
proto.managers_user_type_endpoint.GetUserTypesResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_user_type_endpoint.GetUserTypesResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.managers_user_type_endpoint);
