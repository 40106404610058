/**
 * @fileoverview gRPC-Web generated client stub for managers_role_privilege_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as roleprivilege_pb from './roleprivilege_pb';


export class RolePrivilegeEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    roleprivilege_pb.AddRolePrivilegeRequest,
    roleprivilege_pb.AddRolePrivilegeResponse,
    (request: roleprivilege_pb.AddRolePrivilegeRequest) => {
      return request.serializeBinary();
    },
    roleprivilege_pb.AddRolePrivilegeResponse.deserializeBinary
  );

  add(
    request: roleprivilege_pb.AddRolePrivilegeRequest,
    metadata: grpcWeb.Metadata | null): Promise<roleprivilege_pb.AddRolePrivilegeResponse>;

  add(
    request: roleprivilege_pb.AddRolePrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: roleprivilege_pb.AddRolePrivilegeResponse) => void): grpcWeb.ClientReadableStream<roleprivilege_pb.AddRolePrivilegeResponse>;

  add(
    request: roleprivilege_pb.AddRolePrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: roleprivilege_pb.AddRolePrivilegeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorAddBatch = new grpcWeb.MethodDescriptor(
    '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/AddBatch',
    grpcWeb.MethodType.UNARY,
    roleprivilege_pb.AddBatchRolePrivilegeRequest,
    roleprivilege_pb.AddBatchRolePrivilegeResponse,
    (request: roleprivilege_pb.AddBatchRolePrivilegeRequest) => {
      return request.serializeBinary();
    },
    roleprivilege_pb.AddBatchRolePrivilegeResponse.deserializeBinary
  );

  addBatch(
    request: roleprivilege_pb.AddBatchRolePrivilegeRequest,
    metadata: grpcWeb.Metadata | null): Promise<roleprivilege_pb.AddBatchRolePrivilegeResponse>;

  addBatch(
    request: roleprivilege_pb.AddBatchRolePrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: roleprivilege_pb.AddBatchRolePrivilegeResponse) => void): grpcWeb.ClientReadableStream<roleprivilege_pb.AddBatchRolePrivilegeResponse>;

  addBatch(
    request: roleprivilege_pb.AddBatchRolePrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: roleprivilege_pb.AddBatchRolePrivilegeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/AddBatch',
        request,
        metadata || {},
        this.methodDescriptorAddBatch,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/AddBatch',
    request,
    metadata || {},
    this.methodDescriptorAddBatch);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    roleprivilege_pb.DeleteRolePrivilegeRequest,
    roleprivilege_pb.DeleteRolePrivilegeResponse,
    (request: roleprivilege_pb.DeleteRolePrivilegeRequest) => {
      return request.serializeBinary();
    },
    roleprivilege_pb.DeleteRolePrivilegeResponse.deserializeBinary
  );

  delete(
    request: roleprivilege_pb.DeleteRolePrivilegeRequest,
    metadata: grpcWeb.Metadata | null): Promise<roleprivilege_pb.DeleteRolePrivilegeResponse>;

  delete(
    request: roleprivilege_pb.DeleteRolePrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: roleprivilege_pb.DeleteRolePrivilegeResponse) => void): grpcWeb.ClientReadableStream<roleprivilege_pb.DeleteRolePrivilegeResponse>;

  delete(
    request: roleprivilege_pb.DeleteRolePrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: roleprivilege_pb.DeleteRolePrivilegeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetRolePrivileges = new grpcWeb.MethodDescriptor(
    '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/GetRolePrivileges',
    grpcWeb.MethodType.UNARY,
    roleprivilege_pb.GetRolePrivilegesRequest,
    roleprivilege_pb.GetRolePrivilegesResponse,
    (request: roleprivilege_pb.GetRolePrivilegesRequest) => {
      return request.serializeBinary();
    },
    roleprivilege_pb.GetRolePrivilegesResponse.deserializeBinary
  );

  getRolePrivileges(
    request: roleprivilege_pb.GetRolePrivilegesRequest,
    metadata: grpcWeb.Metadata | null): Promise<roleprivilege_pb.GetRolePrivilegesResponse>;

  getRolePrivileges(
    request: roleprivilege_pb.GetRolePrivilegesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: roleprivilege_pb.GetRolePrivilegesResponse) => void): grpcWeb.ClientReadableStream<roleprivilege_pb.GetRolePrivilegesResponse>;

  getRolePrivileges(
    request: roleprivilege_pb.GetRolePrivilegesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: roleprivilege_pb.GetRolePrivilegesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/GetRolePrivileges',
        request,
        metadata || {},
        this.methodDescriptorGetRolePrivileges,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/GetRolePrivileges',
    request,
    metadata || {},
    this.methodDescriptorGetRolePrivileges);
  }

  methodDescriptorGetRolePrivilege = new grpcWeb.MethodDescriptor(
    '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/GetRolePrivilege',
    grpcWeb.MethodType.UNARY,
    roleprivilege_pb.GetRolePrivilegeRequest,
    roleprivilege_pb.GetRolePrivilegeResponse,
    (request: roleprivilege_pb.GetRolePrivilegeRequest) => {
      return request.serializeBinary();
    },
    roleprivilege_pb.GetRolePrivilegeResponse.deserializeBinary
  );

  getRolePrivilege(
    request: roleprivilege_pb.GetRolePrivilegeRequest,
    metadata: grpcWeb.Metadata | null): Promise<roleprivilege_pb.GetRolePrivilegeResponse>;

  getRolePrivilege(
    request: roleprivilege_pb.GetRolePrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: roleprivilege_pb.GetRolePrivilegeResponse) => void): grpcWeb.ClientReadableStream<roleprivilege_pb.GetRolePrivilegeResponse>;

  getRolePrivilege(
    request: roleprivilege_pb.GetRolePrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: roleprivilege_pb.GetRolePrivilegeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/GetRolePrivilege',
        request,
        metadata || {},
        this.methodDescriptorGetRolePrivilege,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/managers_role_privilege_endpoint.RolePrivilegeEndpoint/GetRolePrivilege',
    request,
    metadata || {},
    this.methodDescriptorGetRolePrivilege);
  }

}

