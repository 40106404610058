import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, ProgressIndicator, IComboBoxOption, Separator } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/Hooks"
import { getPage } from "../../../app/Pages"
import { setPage } from "../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../common/NavHeader/NavHeaderSlice"
import { dismissMessage, reset, resetManager, updateUser, getProfile } from "./ProfilePageSlice"
import { ApiMessage, clearSession, getHeaders, getSession } from "../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../app/Themes"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../common/Popup/Popup"
import { UsersForm } from "../../forms/Management/Users/UsersForm"
import { FormType } from "../../forms/FormProps"
import { Message } from "../../common/Message/Message"
import { ShareBox } from "../../common/ShareBox/ShareBox"
import { DynamicField } from "../../common/DynamicFields/DynamicFields"
import { countriesAR, countriesEn } from "../../../app/Content"
import { GetProfileRequest } from "../../../repository/UserManagement/Managers/profile_pb"
import { AuthenticateReply } from "../../../repository/UserManagement/authentication_pb"
import { ProfileForm } from "../../forms/Profile/ProfileForm"
import { TextView } from "../../common/DataView/TextView"
import { LinkView } from "../../common/DataView/LinkView"
import { ProfileView } from "../../common/DataView/ProfileView"
import { useReactToPrint } from "react-to-print"
import { printComponent, normalizeKey } from "../../../app/Helpers"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetProfileRequest;

let org: number = -1;

let getManagersPromise: any;
let current: AuthenticateReply.AsObject;


const key = "profile"
export const ProfilePage: React.FunctionComponent = () => {


    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        isChangeStateLoading: boolean,
        item: any,
        isLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.profilePage.isChangeStateLoading,
            item: state.profilePage.item, message: state.profilePage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.profilePage.isLoading,
        }
    })

    const [countries, setCountries] = useState([] as IComboBoxOption[])

    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');

    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true))
        }

    });
    const getActionId = (action: number): string => {

        return ""

    }

    useEffect(() => {

        if (localStorage.getItem("lang") == "en") {
            setCountries(countriesEn)
        } else {
            setCountries(countriesAR)
        }
        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        } else {

            req = new GetProfileRequest();
            var l = searchParams.get('org');
            if (l) {
                org = Number(l);
                if (Number.isNaN(org)) {
                    org = -1;
                } else {
                    //  const wrapper = new Int32Value();
                    //  wrapper.setValue(org);
                    //  req.setOrganization(wrapper)
                }
            }
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));

            getManagersPromise = dispatch(getProfile({ body: req, headers: getHeaders() }))

        }
        return () => { //clean up
            getManagersPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t("back"),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t("refresh"),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetManager())
                getManagersPromise = dispatch(getProfile({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'edit',
            text: t("edit"),
            title: t("edit"),
            iconProps: { iconName: 'EditSolid12' },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(101)
                }
            }
        },

        {
            key: 'logs',
            text: t("logs"),
            iconProps: {  iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.internalId + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },

    ];






    return (
        <Stack>
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareProfile")} value={`https://${window.location.host}/management/users/${state.item?.internalId}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            <Popup isOpen={(currentAction == 101)} title={t("editProfile")} onDismiss={() => { setCurrenctAction(0) }} isBlocking={true}>
                <ProfileForm childrenGap={10} type={FormType.EDIT} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateUser(e))
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>


            {state.isLoading ? <ProgressIndicator /> :

                !state.item ? null : <div ref={ref}>
                    <CommandBar
                        items={_selectionItems}

                    />
                    <Stack tokens={{ childrenGap: 10, padding: "0px 36% 10px 3%" }}>

                        <Stack horizontal tokens={{ childrenGap: 10 }}>

                            <TextView label={t("id")} value={state.item?.internalId} />


                            <TextView label={t("createdDate")} value={state.item?.createdDate} />

                            {state.item?.addedBy ? <ProfileView label={t("addedBy")} value={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                url={"/management/users/" + state.item?.addedById + ((org == -1) ? "" : "?org=" + org)}
                                text={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                size={PersonaSize.size40} />

                                : undefined}

                        </Stack>


                        <Separator alignContent="start"><b>{t("contactInfo")}</b></Separator>

                        <Stack horizontal tokens={{ childrenGap: 10 }}>

                            <TextView label={t("firstname")} value={state.item?.firstname} />
                            <TextView label={t("lastname")} value={state.item?.lastname} />
                            <TextView label={t("identity")} iconName={!state.item?.isConfirmed ? "FavoriteStar" : 'FavoriteStarFill'} value={state.item?.isConfirmed ? t("confirmed") : t("unconfirmed")} />


                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <TextView label={t("addressCountry")} value={countries.find(e => e.key === state.item?.addressCountry.key)?.text ?? ""} />
                            <TextView label={t("addressCity")} value={state.item?.addressCity} />

                        </Stack>
                        <TextView label={t("addressFirstLine")} value={state.item?.addressFirstLine} />
                        <TextView label={t("addressSecondLine")} value={state.item?.addressSecondLine} />
                        <TextView label={t("addressPostalCode")} value={state.item?.addressPostalCode} />



                        <Stack horizontal tokens={{ childrenGap: 10 }}>

                            <Stack>
                                {state.item?.emailAddresses.map((e: DynamicField, index: number) => {
                                    return <TextView key={e.key} label={t("emailAddresses")} value={e?.value} />
                                })}
                            </Stack>

                            <Stack>
                                {state.item?.phoneNumbers.map((e: DynamicField, index: number) => {
                                    return <TextView key={e.key} label={t("phonenumbers")} value={e?.value} />

                                })}
                            </Stack>
                        </Stack>



                        <Separator alignContent="start"><b>{t("accountInfo")}</b></Separator>

                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <TextView label={t("username")} value={state.item?.username} />
                            <TextView label={t("status")} iconName={state.item?.isActive != undefined ? state.item?.isActive ? "Accept" : "Cancel" : undefined} value={state.item?.isActive != undefined ? state.item?.isActive ? t("active") : t("inactive") : ""} />
                        </Stack>

                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <LinkView label={t("role")} value={state.item?.role == "" ? state.item?.roleId : state.item?.role}
                                url={"/management/roles/" + state.item?.roleId + ((org == -1) ? "" : "?org=" + org)} />

                            <LinkView label={t("usertype")} value={state.item?.usertype == "" ? state.item?.usertypeId : state.item?.usertype}
                                url={"/management/user-types/" + state.item?.usertypeId + ((org == -1) ? "" : "?org=" + org)} />

                        </Stack>

                        <TextView label={t("lastLogin")} value={state.item?.lastlogin} />



                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <TextView label={t("email")} value={state.item?.primaryEmail} />
                            <TextView label={t("phonenumber")} value={state.item?.primaryPhoneNumber} />
                        </Stack>

                        <Separator alignContent="start"><b>{t("otherInfo")}</b></Separator>

                        {state.item?.extraFields.map((e: DynamicField, index: number) => {
                            return <Stack key={e.key}>
                                <TextView label={e.label ?? ""} value={e?.value} />

                            </Stack>
                        })}



                    </Stack>
                </div>


            }

        </Stack>

    );
}




