// source: datarestriction.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('../common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.managers_data_restriction_endpoint.AddDataRestrictionReplay', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.AddDataRestrictionRequest', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.AddDataRestrictionResponse', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.GetDataRestrictionReplay', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.GetDataRestrictionRequest', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.GetDataRestrictionResponse', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.GroupInfo', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.PrivilegeDataInfo', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse', null, global);
goog.exportSymbol('proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_data_restriction_endpoint.AddDataRestrictionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.displayName = 'proto.managers_data_restriction_endpoint.AddDataRestrictionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.displayName = 'proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.displayName = 'proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_data_restriction_endpoint.GetDataRestrictionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.displayName = 'proto.managers_data_restriction_endpoint.GetDataRestrictionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.displayName = 'proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_data_restriction_endpoint.AddDataRestrictionReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.displayName = 'proto.managers_data_restriction_endpoint.AddDataRestrictionReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.displayName = 'proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.displayName = 'proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_data_restriction_endpoint.GetDataRestrictionReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.displayName = 'proto.managers_data_restriction_endpoint.GetDataRestrictionReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.repeatedFields_, null);
};
goog.inherits(proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.displayName = 'proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_data_restriction_endpoint.PrivilegeDataInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.PrivilegeDataInfo.displayName = 'proto.managers_data_restriction_endpoint.PrivilegeDataInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.GroupInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_data_restriction_endpoint.GroupInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.GroupInfo.displayName = 'proto.managers_data_restriction_endpoint.GroupInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.displayName = 'proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.oneofGroups_);
};
goog.inherits(proto.managers_data_restriction_endpoint.AddDataRestrictionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.displayName = 'proto.managers_data_restriction_endpoint.AddDataRestrictionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.oneofGroups_);
};
goog.inherits(proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.displayName = 'proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.oneofGroups_);
};
goog.inherits(proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.displayName = 'proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.oneofGroups_);
};
goog.inherits(proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.displayName = 'proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.oneofGroups_);
};
goog.inherits(proto.managers_data_restriction_endpoint.GetDataRestrictionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.displayName = 'proto.managers_data_restriction_endpoint.GetDataRestrictionResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.AddDataRestrictionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    privilegedataid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    alloweddata: (f = msg.getAlloweddata()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionRequest}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.AddDataRestrictionRequest;
  return proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.AddDataRestrictionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionRequest}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrivilegedataid(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAlloweddata(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.AddDataRestrictionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPrivilegedataid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAlloweddata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 groupId = 1;
 * @return {number}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionRequest} returns this
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 privilegeDataId = 2;
 * @return {number}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.prototype.getPrivilegedataid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionRequest} returns this
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.prototype.setPrivilegedataid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue allowedData = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.prototype.getAlloweddata = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionRequest} returns this
*/
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.prototype.setAlloweddata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionRequest} returns this
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.prototype.clearAlloweddata = function() {
  return this.setAlloweddata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.prototype.hasAlloweddata = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value organization = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionRequest} returns this
*/
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionRequest} returns this
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    alloweddata: (f = msg.getAlloweddata()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest;
  return proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAlloweddata(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAlloweddata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest} returns this
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue allowedData = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.prototype.getAlloweddata = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest} returns this
*/
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.prototype.setAlloweddata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest} returns this
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.prototype.clearAlloweddata = function() {
  return this.setAlloweddata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.prototype.hasAlloweddata = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value organization = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest} returns this
*/
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest} returns this
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest;
  return proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest} returns this
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value organization = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest} returns this
*/
proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest} returns this
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionRequest}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.GetDataRestrictionRequest;
  return proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionRequest}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionRequest} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value organization = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionRequest} returns this
*/
proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionRequest} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    privilegedataid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    nextto: jspb.Message.getFieldWithDefault(msg, 5, 0),
    numofresults: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest;
  return proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrivilegedataid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextto(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrivilegedataid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getNextto();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 privilegeDataId = 1;
 * @return {number}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.getPrivilegedataid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.setPrivilegedataid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value organization = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest} returns this
*/
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue search = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest} returns this
*/
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool order = 4;
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 nextTo = 5;
 * @return {number}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.getNextto = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.setNextto = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 numOfResults = 6;
 * @return {number}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.AddDataRestrictionReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    datarestrictionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionReplay}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.AddDataRestrictionReplay;
  return proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.AddDataRestrictionReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionReplay}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDatarestrictionid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.AddDataRestrictionReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatarestrictionid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 dataRestrictionId = 1;
 * @return {number}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.prototype.getDatarestrictionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionReplay} returns this
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.prototype.setDatarestrictionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionReplay} returns this
*/
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionReplay} returns this
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionReplay} returns this
*/
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionReplay} returns this
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay;
  return proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay} returns this
*/
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay} returns this
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay} returns this
*/
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay} returns this
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteddate: (f = msg.getDeleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay;
  return proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleteddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp deletedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.prototype.getDeleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay} returns this
*/
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.prototype.setDeleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay} returns this
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.prototype.clearDeleteddate = function() {
  return this.setDeleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.prototype.hasDeleteddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay} returns this
*/
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay} returns this
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    group: (f = msg.getGroup()) && proto.managers_data_restriction_endpoint.GroupInfo.toObject(includeInstance, f),
    alloweddata: (f = msg.getAlloweddata()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    privilegedata: (f = msg.getPrivilegedata()) && proto.managers_data_restriction_endpoint.PrivilegeDataInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.GetDataRestrictionReplay;
  return proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.managers_data_restriction_endpoint.GroupInfo;
      reader.readMessage(value,proto.managers_data_restriction_endpoint.GroupInfo.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAlloweddata(value);
      break;
    case 4:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 6:
      var value = new proto.managers_data_restriction_endpoint.PrivilegeDataInfo;
      reader.readMessage(value,proto.managers_data_restriction_endpoint.PrivilegeDataInfo.deserializeBinaryFromReader);
      msg.setPrivilegedata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.managers_data_restriction_endpoint.GroupInfo.serializeBinaryToWriter
    );
  }
  f = message.getAlloweddata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPrivilegedata();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.managers_data_restriction_endpoint.PrivilegeDataInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional GroupInfo group = 2;
 * @return {?proto.managers_data_restriction_endpoint.GroupInfo}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.getGroup = function() {
  return /** @type{?proto.managers_data_restriction_endpoint.GroupInfo} */ (
    jspb.Message.getWrapperField(this, proto.managers_data_restriction_endpoint.GroupInfo, 2));
};


/**
 * @param {?proto.managers_data_restriction_endpoint.GroupInfo|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} returns this
*/
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue allowedData = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.getAlloweddata = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} returns this
*/
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.setAlloweddata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.clearAlloweddata = function() {
  return this.setAlloweddata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.hasAlloweddata = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.ProfileResponse addedBy = 4;
 * @return {?proto.common.ProfileResponse}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 4));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} returns this
*/
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} returns this
*/
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PrivilegeDataInfo privilegeData = 6;
 * @return {?proto.managers_data_restriction_endpoint.PrivilegeDataInfo}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.getPrivilegedata = function() {
  return /** @type{?proto.managers_data_restriction_endpoint.PrivilegeDataInfo} */ (
    jspb.Message.getWrapperField(this, proto.managers_data_restriction_endpoint.PrivilegeDataInfo, 6));
};


/**
 * @param {?proto.managers_data_restriction_endpoint.PrivilegeDataInfo|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} returns this
*/
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.setPrivilegedata = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.clearPrivilegedata = function() {
  return this.setPrivilegedata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.prototype.hasPrivilegedata = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    datarestrictionsList: jspb.Message.toObjectList(msg.getDatarestrictionsList(),
    proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay;
  return proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo;
      reader.readMessage(value,proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.deserializeBinaryFromReader);
      msg.addDatarestrictions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatarestrictionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DataRestrictionSummaryInfo dataRestrictions = 1;
 * @return {!Array<!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo>}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.prototype.getDatarestrictionsList = function() {
  return /** @type{!Array<!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo, 1));
};


/**
 * @param {!Array<!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo>} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay} returns this
*/
proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.prototype.setDatarestrictionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.prototype.addDatarestrictions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.prototype.clearDatarestrictionsList = function() {
  return this.setDatarestrictionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.PrivilegeDataInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.PrivilegeDataInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    datatype: (f = msg.getDatatype()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.PrivilegeDataInfo}
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.PrivilegeDataInfo;
  return proto.managers_data_restriction_endpoint.PrivilegeDataInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.PrivilegeDataInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.PrivilegeDataInfo}
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setDatatype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.PrivilegeDataInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.PrivilegeDataInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDatatype();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_data_restriction_endpoint.PrivilegeDataInfo} returns this
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.PrivilegeDataInfo} returns this
*/
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.PrivilegeDataInfo} returns this
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue dataType = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.prototype.getDatatype = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.PrivilegeDataInfo} returns this
*/
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.prototype.setDatatype = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.PrivilegeDataInfo} returns this
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.prototype.clearDatatype = function() {
  return this.setDatatype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.PrivilegeDataInfo.prototype.hasDatatype = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.GroupInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.GroupInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.GroupInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.GroupInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.GroupInfo}
 */
proto.managers_data_restriction_endpoint.GroupInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.GroupInfo;
  return proto.managers_data_restriction_endpoint.GroupInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.GroupInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.GroupInfo}
 */
proto.managers_data_restriction_endpoint.GroupInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.GroupInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.GroupInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.GroupInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.GroupInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.managers_data_restriction_endpoint.GroupInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_data_restriction_endpoint.GroupInfo} returns this
 */
proto.managers_data_restriction_endpoint.GroupInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_data_restriction_endpoint.GroupInfo.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.GroupInfo} returns this
*/
proto.managers_data_restriction_endpoint.GroupInfo.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.GroupInfo} returns this
 */
proto.managers_data_restriction_endpoint.GroupInfo.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.GroupInfo.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    group: (f = msg.getGroup()) && proto.managers_data_restriction_endpoint.GroupInfo.toObject(includeInstance, f),
    alloweddata: (f = msg.getAlloweddata()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo}
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo;
  return proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo}
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.managers_data_restriction_endpoint.GroupInfo;
      reader.readMessage(value,proto.managers_data_restriction_endpoint.GroupInfo.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAlloweddata(value);
      break;
    case 4:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.managers_data_restriction_endpoint.GroupInfo.serializeBinaryToWriter
    );
  }
  f = message.getAlloweddata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo} returns this
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional GroupInfo Group = 2;
 * @return {?proto.managers_data_restriction_endpoint.GroupInfo}
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.getGroup = function() {
  return /** @type{?proto.managers_data_restriction_endpoint.GroupInfo} */ (
    jspb.Message.getWrapperField(this, proto.managers_data_restriction_endpoint.GroupInfo, 2));
};


/**
 * @param {?proto.managers_data_restriction_endpoint.GroupInfo|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo} returns this
*/
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo} returns this
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue allowedData = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.getAlloweddata = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo} returns this
*/
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.setAlloweddata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo} returns this
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.clearAlloweddata = function() {
  return this.setAlloweddata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.hasAlloweddata = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.ProfileResponse addedBy = 4;
 * @return {?proto.common.ProfileResponse}
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 4));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo} returns this
*/
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo} returns this
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo} returns this
*/
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo} returns this
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.DataRestrictionSummaryInfo.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.ResultCase}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.AddDataRestrictionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionResponse}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.AddDataRestrictionResponse;
  return proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.AddDataRestrictionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionResponse}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_data_restriction_endpoint.AddDataRestrictionReplay;
      reader.readMessage(value,proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.AddDataRestrictionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_data_restriction_endpoint.AddDataRestrictionReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddDataRestrictionReplay success = 1;
 * @return {?proto.managers_data_restriction_endpoint.AddDataRestrictionReplay}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_data_restriction_endpoint.AddDataRestrictionReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_data_restriction_endpoint.AddDataRestrictionReplay, 1));
};


/**
 * @param {?proto.managers_data_restriction_endpoint.AddDataRestrictionReplay|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionResponse} returns this
*/
proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.AddDataRestrictionResponse} returns this
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.AddDataRestrictionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.ResultCase}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse;
  return proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay;
      reader.readMessage(value,proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateDataRestrictionReplay success = 1;
 * @return {?proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay, 1));
};


/**
 * @param {?proto.managers_data_restriction_endpoint.UpdateDataRestrictionReplay|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse} returns this
*/
proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse} returns this
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.UpdateDataRestrictionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.ResultCase}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse;
  return proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay;
      reader.readMessage(value,proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteDataRestrictionReplay success = 1;
 * @return {?proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay, 1));
};


/**
 * @param {?proto.managers_data_restriction_endpoint.DeleteDataRestrictionReplay|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse} returns this
*/
proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse} returns this
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.DeleteDataRestrictionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.ResultCase}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse;
  return proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay;
      reader.readMessage(value,proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetDataRestrictionsReplay success = 1;
 * @return {?proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay, 1));
};


/**
 * @param {?proto.managers_data_restriction_endpoint.GetDataRestrictionsReplay|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse} returns this
*/
proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.ResultCase}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionResponse}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_data_restriction_endpoint.GetDataRestrictionResponse;
  return proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionResponse}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_data_restriction_endpoint.GetDataRestrictionReplay;
      reader.readMessage(value,proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_data_restriction_endpoint.GetDataRestrictionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_data_restriction_endpoint.GetDataRestrictionReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetDataRestrictionReplay success = 1;
 * @return {?proto.managers_data_restriction_endpoint.GetDataRestrictionReplay}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_data_restriction_endpoint.GetDataRestrictionReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_data_restriction_endpoint.GetDataRestrictionReplay, 1));
};


/**
 * @param {?proto.managers_data_restriction_endpoint.GetDataRestrictionReplay|undefined} value
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionResponse} returns this
*/
proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_data_restriction_endpoint.GetDataRestrictionResponse} returns this
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_data_restriction_endpoint.GetDataRestrictionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.managers_data_restriction_endpoint);
