/**
 * @fileoverview gRPC-Web generated client stub for authentication_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as authentication_pb from './authentication_pb';


export class AuthenticationEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAuthenticate = new grpcWeb.MethodDescriptor(
    '/authentication_endpoint.AuthenticationEndpoint/Authenticate',
    grpcWeb.MethodType.UNARY,
    authentication_pb.AuthenticateRequest,
    authentication_pb.AuthenticateResponse,
    (request: authentication_pb.AuthenticateRequest) => {
      return request.serializeBinary();
    },
    authentication_pb.AuthenticateResponse.deserializeBinary
  );

  authenticate(
    request: authentication_pb.AuthenticateRequest,
    metadata: grpcWeb.Metadata | null): Promise<authentication_pb.AuthenticateResponse>;

  authenticate(
    request: authentication_pb.AuthenticateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authentication_pb.AuthenticateResponse) => void): grpcWeb.ClientReadableStream<authentication_pb.AuthenticateResponse>;

  authenticate(
    request: authentication_pb.AuthenticateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authentication_pb.AuthenticateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/authentication_endpoint.AuthenticationEndpoint/Authenticate',
        request,
        metadata || {},
        this.methodDescriptorAuthenticate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/authentication_endpoint.AuthenticationEndpoint/Authenticate',
    request,
    metadata || {},
    this.methodDescriptorAuthenticate);
  }

  methodDescriptorTwoFactorConfirmation = new grpcWeb.MethodDescriptor(
    '/authentication_endpoint.AuthenticationEndpoint/TwoFactorConfirmation',
    grpcWeb.MethodType.UNARY,
    authentication_pb.TwoFactorConfirmationRequest,
    authentication_pb.TwoFactorConfirmationResponse,
    (request: authentication_pb.TwoFactorConfirmationRequest) => {
      return request.serializeBinary();
    },
    authentication_pb.TwoFactorConfirmationResponse.deserializeBinary
  );

  twoFactorConfirmation(
    request: authentication_pb.TwoFactorConfirmationRequest,
    metadata: grpcWeb.Metadata | null): Promise<authentication_pb.TwoFactorConfirmationResponse>;

  twoFactorConfirmation(
    request: authentication_pb.TwoFactorConfirmationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authentication_pb.TwoFactorConfirmationResponse) => void): grpcWeb.ClientReadableStream<authentication_pb.TwoFactorConfirmationResponse>;

  twoFactorConfirmation(
    request: authentication_pb.TwoFactorConfirmationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authentication_pb.TwoFactorConfirmationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/authentication_endpoint.AuthenticationEndpoint/TwoFactorConfirmation',
        request,
        metadata || {},
        this.methodDescriptorTwoFactorConfirmation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/authentication_endpoint.AuthenticationEndpoint/TwoFactorConfirmation',
    request,
    metadata || {},
    this.methodDescriptorTwoFactorConfirmation);
  }

  methodDescriptorRefreshToken = new grpcWeb.MethodDescriptor(
    '/authentication_endpoint.AuthenticationEndpoint/RefreshToken',
    grpcWeb.MethodType.UNARY,
    authentication_pb.RefreshTokenRequest,
    authentication_pb.RefreshTokenResponse,
    (request: authentication_pb.RefreshTokenRequest) => {
      return request.serializeBinary();
    },
    authentication_pb.RefreshTokenResponse.deserializeBinary
  );

  refreshToken(
    request: authentication_pb.RefreshTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<authentication_pb.RefreshTokenResponse>;

  refreshToken(
    request: authentication_pb.RefreshTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authentication_pb.RefreshTokenResponse) => void): grpcWeb.ClientReadableStream<authentication_pb.RefreshTokenResponse>;

  refreshToken(
    request: authentication_pb.RefreshTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authentication_pb.RefreshTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/authentication_endpoint.AuthenticationEndpoint/RefreshToken',
        request,
        metadata || {},
        this.methodDescriptorRefreshToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/authentication_endpoint.AuthenticationEndpoint/RefreshToken',
    request,
    metadata || {},
    this.methodDescriptorRefreshToken);
  }

}

