/**
 * @fileoverview gRPC-Web generated client stub for privilege_data_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as privilegedata_pb from './privilegedata_pb';


export class PrivilegeDataEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetPrivilegeData = new grpcWeb.MethodDescriptor(
    '/privilege_data_endpoint.PrivilegeDataEndpoint/GetPrivilegeData',
    grpcWeb.MethodType.UNARY,
    privilegedata_pb.GetPrivilegeDataRequest,
    privilegedata_pb.GetPrivilegeDataResponse,
    (request: privilegedata_pb.GetPrivilegeDataRequest) => {
      return request.serializeBinary();
    },
    privilegedata_pb.GetPrivilegeDataResponse.deserializeBinary
  );

  getPrivilegeData(
    request: privilegedata_pb.GetPrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilegedata_pb.GetPrivilegeDataResponse>;

  getPrivilegeData(
    request: privilegedata_pb.GetPrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilegedata_pb.GetPrivilegeDataResponse) => void): grpcWeb.ClientReadableStream<privilegedata_pb.GetPrivilegeDataResponse>;

  getPrivilegeData(
    request: privilegedata_pb.GetPrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilegedata_pb.GetPrivilegeDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/privilege_data_endpoint.PrivilegeDataEndpoint/GetPrivilegeData',
        request,
        metadata || {},
        this.methodDescriptorGetPrivilegeData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/privilege_data_endpoint.PrivilegeDataEndpoint/GetPrivilegeData',
    request,
    metadata || {},
    this.methodDescriptorGetPrivilegeData);
  }

  methodDescriptorGetPrivilegeDataById = new grpcWeb.MethodDescriptor(
    '/privilege_data_endpoint.PrivilegeDataEndpoint/GetPrivilegeDataById',
    grpcWeb.MethodType.UNARY,
    privilegedata_pb.GetPrivilegeDataByIdRequest,
    privilegedata_pb.GetPrivilegeDataByIdResponse,
    (request: privilegedata_pb.GetPrivilegeDataByIdRequest) => {
      return request.serializeBinary();
    },
    privilegedata_pb.GetPrivilegeDataByIdResponse.deserializeBinary
  );

  getPrivilegeDataById(
    request: privilegedata_pb.GetPrivilegeDataByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilegedata_pb.GetPrivilegeDataByIdResponse>;

  getPrivilegeDataById(
    request: privilegedata_pb.GetPrivilegeDataByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilegedata_pb.GetPrivilegeDataByIdResponse) => void): grpcWeb.ClientReadableStream<privilegedata_pb.GetPrivilegeDataByIdResponse>;

  getPrivilegeDataById(
    request: privilegedata_pb.GetPrivilegeDataByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilegedata_pb.GetPrivilegeDataByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/privilege_data_endpoint.PrivilegeDataEndpoint/GetPrivilegeDataById',
        request,
        metadata || {},
        this.methodDescriptorGetPrivilegeDataById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/privilege_data_endpoint.PrivilegeDataEndpoint/GetPrivilegeDataById',
    request,
    metadata || {},
    this.methodDescriptorGetPrivilegeDataById);
  }

}

