import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../../app/Helpers';
import { GroupMembershipEndpointClient } from '../../../../../../../repository/UserManagement/Managers/GroupmembershipServiceClientPb';
import { GetGroupMembershipResponse, GetGroupMembershipRequest, DeleteGroupMembershipResponse, DeleteGroupMembershipRequest } from '../../../../../../../repository/UserManagement/Managers/groupmembership_pb';


export interface GroupMemberDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: GroupMemberDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const client = new GroupMembershipEndpointClient(API_URL, null, null);


export const getItem = createAsyncThunk<GetGroupMembershipResponse.AsObject, APIRequest<GetGroupMembershipRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/groupDetails/groupMembershipDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getGroupMembership(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const removeItem = createAsyncThunk<APIResponse<DeleteGroupMembershipResponse.AsObject>, APIRequest<DeleteGroupMembershipRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/groupDetails/groupMembershipDetails/removeMembership',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)
export const managersGroupMembershipDetailsPageSlice = createSlice({
    name: 'pages/management/groupDetails/groupMembershipDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(getItem.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                var r = {
                    id: payload?.success?.id,
                    groupName: payload?.success?.group?.name?.value ?? "",
                    groupId: payload?.success?.group?.id,
                    profileId: payload?.success?.profile?.id?.value,
                    name: (payload?.success?.profile?.firstname?.value ?? "") + " " + (payload?.success?.profile?.lastname?.value ?? ""),
                    firstname: (payload?.success?.profile?.firstname?.value ?? ""),
                    lastname: (payload?.success?.profile?.lastname?.value ?? ""),

                    createdDate: formatDate(timestampToDate(payload?.success?.createddate?.seconds, payload?.success?.createddate?.nanos)),
                    addedBy: (payload?.success?.addedby?.firstname?.value ?? "") + " " + (payload?.success?.addedby?.lastname?.value ?? ""),
                    addedById: payload?.success?.addedby?.id?.value,
                }
                state.item = r;
                //console.log(state.items)
            }

        })
        builder.addCase(getItem.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItem.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })



        builder.addCase(removeItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
            }
        })
        builder.addCase(removeItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(removeItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, resetItem, updateItem } = managersGroupMembershipDetailsPageSlice.actions

export default managersGroupMembershipDetailsPageSlice.reducer