import { DefaultButton, DialogFooter, Icon, IconButton, IPersonaSharedProps, IStackTokens, Link, MessageBar, MessageBarType, Modal, Persona, PersonaPresence, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, Text, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/Hooks";
import { add, dismissMessage, getOrganization, OrganizationState, reset, setCurrentOrganization, setIsLoading, update, uploadLogo } from "./OrganizationsFormSlice";
import * as Yup from 'yup';
import { InputField } from "../controls/Controls";
import { inputs } from "../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, API_URL, clearSession, getHeaders, getSession, ORGANIZATION_LOGO_URL, saveSession, STORAGE_URL } from "../../../app/Api";
import { Message } from "../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../FormProps";
import { Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../app/Helpers";
import { AuthenticateReply } from "../../../repository/UserManagement/authentication_pb";
import { AddOrganizationRequest, UpdateOrganizationRequest, AddOrganizationResponse, UpdateOrganizationResponse, GetOrganizationRequest, UploadLogoRequest, UploadLogoResponse } from "../../../repository/UserManagement/organization_pb";
import { setOrganization } from "../../layouts/Master/MasterLayoutSlice";


let req: AddOrganizationRequest;
let updateReq: UpdateOrganizationRequest;

let promise: any;

let current: AuthenticateReply.AsObject;

export const OrganizationsForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();


    const state: { isLoading: boolean, message: ApiMessage | undefined, organization?: OrganizationState, currentOrganization: any } = useAppSelector((state) => {

        return { currentOrganization: state.masterLayout.currentOrganization, isLoading: state.organizationForm.isLoading, message: state.organizationForm.message, organization: state.organizationForm.currentOrganization }
    })

    useEffect(() => {
        req = new AddOrganizationRequest();
        updateReq = new UpdateOrganizationRequest();

        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        } else {
            var l = searchParams.get('org');
            let getOrgReq = new GetOrganizationRequest()
            if (l) {
                let org = Number(l);
                if (!Number.isNaN(org) && org != current.organization?.id) {
                    if (props.type == FormType.ADD) {
                        getOrgReq.setId(org)
                        promise?.abort()
                        promise = dispatch(getOrganization({ body: getOrgReq, headers: getHeaders() }))
                        /*promise.unwrap().catch((e: any) => {
                            if ((current.organization?.domain == undefined || current.organization?.domain.value.length == 0) && current.organization?.id) {
                                getOrgReq.setId(current.organization?.id)
                                promise?.abort()
                                promise = dispatch(getOrganization({ body: getOrgReq, headers: getHeaders() }))
                                promise.unwrap().catch((e: any) => {
                                    dispatch(setCurrentOrganization({ id: current.organization?.id ?? -1, domain: current.organization?.domain?.value ?? "", name: current.organization?.name?.value ?? "" }));
                                })
                            } else {
                                dispatch(setCurrentOrganization({ id: current.organization?.id ?? -1, domain: current.organization?.domain?.value ?? "", name: current.organization?.name?.value ?? "" }));
                            }
                           
                        }) */
                    } else {
                        dispatch(setCurrentOrganization({ id: org, domain: "", name: "", address: "", email: "", logo: "", phonenumber: "" }));
                    }
                }
            } else {
                if (props.type == FormType.ADD) {
                    if ((current.organization?.domain == undefined || current.organization?.domain.value.length == 0) && current.organization?.id) {
                        getOrgReq.setId(current.organization?.id)
                        promise?.abort()
                        promise = dispatch(getOrganization({ body: getOrgReq, headers: getHeaders() }))
                        promise.unwrap().catch((e: any) => {
                            dispatch(setCurrentOrganization({
                                id: current.organization?.id ?? -1, domain: current.organization?.domain?.value ?? "", name: current.organization?.name?.value ?? "",
                                address: "",
                                email: "",
                                phonenumber: "",
                                logo: "",
                            }));
                        })
                    } else {
                        dispatch(setCurrentOrganization({
                            id: current.organization?.id ?? -1, domain: current.organization?.domain?.value ?? "", name: current.organization?.name?.value ?? "", address: "",
                            email: "",
                            phonenumber: "",
                            logo: ""
                        }));
                    }
                } else {
                    if (props.fetchData) {
                        getOrgReq.setId(props?.renderObject?.id);
                        promise?.abort()
                        promise = dispatch(getOrganization({ body: getOrgReq, headers: getHeaders() }))
                    } else {
                        dispatch(setCurrentOrganization({
                            id: current.organization?.id ?? -1, domain: "", name: "", address: "",
                            email: "",
                            phonenumber: "",
                            logo: ""
                        }));
                    }

                }

            }
        }


        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])


    useEffect(() => {
        if (state.organization) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.organization?.id);
            req.setParentorganization(wrapper)
            if (props?.renderObject?.logo?.Name == undefined)
                setImg(state.organization?.logo?.Name)
        }
    }, [state.organization])

    const [img, setImg] = useState(props?.renderObject?.logo?.Name ?
        props?.renderObject?.logo?.Name : state.organization?.logo?.Name ?
            state.organization?.logo?.Name : "");
    const inputFile = useRef<HTMLInputElement>(null)


    return (
        <>

            <Formik

                enableReinitialize={true}
                initialValues={{

                    name: (props.renderObject) ? props.renderObject.name : '',
                    domain: (props.renderObject) ? props.renderObject.domain : '',
                    address: (props.renderObject) ? props.renderObject.address ?? (state.organization?.address ?? '') : '',
                    phonenumber: (props.renderObject) ? props.renderObject.phonenumber ?? (state.organization?.phonenumber ?? '') : '',
                    email: (props.renderObject) ? props.renderObject.email ?? (state.organization?.email ?? '') : '',
                    logo: (props.renderObject) ? props.renderObject.logo?.Id ?? (state.organization?.logo?.Id ?? '') : '',
                    isActive: true,
                }}

                validationSchema={Yup.object({

                    name: inputs.organizationName,
                    address: inputs.organizationAddress,
                    phonenumber: inputs.primaryPhoneNumber,
                    email: inputs.primaryEmail,

                    //domain: inputs.organizationDomain,

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {
                        req.setName(values.name.trim());
                        if (values.domain.trim().at(values.domain.trim().length - 1) == ".") {
                            values.domain = values.domain.trim().substring(0, (values.domain.trim().length - 1))
                        }
                        req.setDomain(values.domain.trim())
                        req.setIsactive(values.isActive);
                        req.setAddress(values.address?.trim())
                        req.setEmail(values.email?.trim())
                        req.setPhonenumber(values.phonenumber?.trim())
                        req.setLogo(values.logo?.trim())



                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddOrganizationResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res.success?.organizationid,
                                            name: values.name.trim(),
                                            domain: values.domain.trim() + "." + state.organization?.domain.trim(),
                                            isActive: values.isActive,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                            logo: { Id: values.logo?.trim(), Name: img },
                                            phonenumber: values.phonenumber?.trim(),
                                            email: values.email?.trim(),
                                            address: values.address?.trim(),

                                        });
                                    }
                                }
                                actions.setSubmitting(false)


                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {
                        updateReq.setName(values.name.trim());
                        updateReq.setId(props.renderObject?.id);
                        updateReq.setAddress(values.address?.trim())
                        updateReq.setEmail(values.email?.trim())
                        updateReq.setPhonenumber(values.phonenumber?.trim())
                        updateReq.setLogo(values.logo?.trim())

                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateOrganizationResponse.AsObject) => {
                                if (res) {

                                    if (state.currentOrganization.id == props.renderObject?.id) {
                                        dispatch(setOrganization({ id: state.currentOrganization?.id, name: values.name.trim(), domain: state.currentOrganization?.domain, logo: { Id: values.logo?.trim(), Name: img } }))
                                    }

                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject, name: values.name.trim(),
                                            logo: { Id: values.logo?.trim(), Name: img },
                                            phonenumber: values.phonenumber?.trim(),
                                            email: values.email?.trim(),
                                            address: values.address?.trim(),
                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }
                            <Stack horizontalAlign="center">
                                <Persona
                                    size={PersonaSize.size100}
                                    onClick={(e) => {
                                        if (!state.isLoading)
                                            inputFile?.current?.click();
                                    }}
                                    hidePersonaDetails={true}
                                    imageUrl={ORGANIZATION_LOGO_URL + img}
                                    styles={{ root: { cursor: "pointer" } }}
                                />
                                <Link disabled={state.isLoading} onClick={(e) => {
                                    inputFile?.current?.click();
                                }}>{t("pickLogo")}</Link>
                                <input
                                    ref={inputFile}
                                    type="file"
                                    onChange={(e: any) => {
                                        const [file] = e.target.files;
                                        const reader = new FileReader();
                                        reader.readAsArrayBuffer(file);
                                        let r = new UploadLogoRequest();
                                        reader.onloadend = (evt) => {
                                          if (evt?.target?.readyState === FileReader.DONE) {
                                            const arrayBuffer = evt.target.result;
                                             let array = new Uint8Array(arrayBuffer as ArrayBuffer);
                                             if (state.organization?.id) {
                                                const wrapper = new Int32Value();
                                                wrapper.setValue(state.organization?.id);
                                                r.setId(wrapper)
                                            }
                                            console.log(file);
                                            r.setImage(array)

                                            promise = dispatch(uploadLogo({ body: r, headers: getHeaders() }))
                                            promise.unwrap().then((e: UploadLogoResponse.AsObject) => {
                                                formkikProps.setFieldValue('logo', e.success?.id);
    
                                                setImg(e.success?.id + ".jpg" ); //file.name.split('.').pop().toLowerCase()
                                            })
                                            promise.unwrap().catch((e: any) => {
                                                formkikProps.setFieldValue('logo', '');
                                                setImg("");
                                            })
                                            
                                          }
                                        }
                                       
                                      
                                    }}
                                    style={{ display: "none" }}
                                    accept="image/*"
                                />
                            </Stack>

                            <Field name="name" label={t("organizationName")} placeholder={t("organizationName")} component={InputField} disabled={state.isLoading} autoFocus maxLength={150} required />

                            {props.type == FormType.ADD ? <Stack.Item dir="ltr"><Field name="domain" label={t("organizationDomain")} placeholder={t("organizationDomain")}
                                validate={async (e: string) => {
                                    try {
                                        await inputs.organizationDomain.validate("x@" + e + "." + state.organization?.domain)
                                    } catch (e: any) {
                                        return (e?.message)
                                    }


                                }}
                                component={InputField} required disabled={state.isLoading} maxLength={(253 - (state.organization?.domain.length ?? 0))} suffix={state.organization?.domain ? "." + state.organization?.domain : ""} />    </Stack.Item> : null}

                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} >
                                <Stack.Item grow>
                                    <Field name="email" label={
                                        <div>
                                            {t("email") + ' '}
                                            <TooltipHost content={t("organizationEmailDescription")}>
                                                <Icon iconName="Info" aria-label={t("email")} />
                                            </TooltipHost>
                                        </div>
                                    } placeholder={"abc@gmail.com"} dir="ltr" component={InputField} disabled={state.isLoading} type="email"
                                    />
                                </Stack.Item>

                                <Stack.Item grow>
                                    <Field name="phonenumber" label={
                                        <div>
                                            {t("phonenumber") + ' '}
                                            <TooltipHost content={t("organizationPhoneNumberDescription")}>
                                                <Icon iconName="Info" aria-label={t("phonenumber")} />
                                            </TooltipHost>
                                        </div>
                                    } placeholder={"+218911111111"} dir="ltr" component={InputField} disabled={state.isLoading} />
                                </Stack.Item>
                            </Stack>

                            <Field name="address" label={<div>
                                {t("address") + ' '}
                                <TooltipHost content={t("organizationAddressDescription")}>
                                    <Icon iconName="Info" aria-label={t("address")} />
                                </TooltipHost>
                            </div>} placeholder={t("address")} component={InputField} rows={3} multiline resizable={false} disabled={state.isLoading} maxLength={500} />

                            {props.type == FormType.ADD ?

                                <Toggle
                                    defaultChecked
                                    onText={t("organizationActive")} offText={t("organizationDeactive")}
                                    label={t("organizationStatus")}
                                    disabled={state.isLoading}

                                    onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                        formkikProps.setFieldValue('isActive', checked);
                                    }
                                    }
                                />


                                : null}
                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>
                    </Form>
                )}
            </Formik>
        </>
    );

}

