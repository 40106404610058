import { Callout, ChoiceGroup, DefaultButton, IChoiceGroupOption, Icon, Label, Panel, Text, SwatchColorPicker, Toggle, TooltipHost, mergeStyleSets, FontWeights, getTheme } from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../app/Hooks";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { useState } from "react";
import { AppTheme, ThemeManager } from "../../../app/Themes";
import { isDarkModeEnabled, isVisible, setAppTheme, setLang } from "./SettingsSlice";
import i18n from "../../../app/i18n";
import { t } from "i18next";
import { setRTL } from '@fluentui/react/lib/Utilities';
import { useTranslation } from "react-i18next";


const colorCellsExample2 = [
    { id: 'exchange', label: 'Lochmara', color: '#0078d4' },
    { id: 'access', label: 'Stiletto', color: '#a4373a' },
    { id: 'excel', label: 'Green Pea', color: '#217346' },
    { id: 'office', label: 'Grenadier', color: '#d83b01' },
    { id: 'oneNote', label: 'Seance', color: '#7719aa' },
    { id: 'planner', label: 'Goblin', color: '#31752f' },
    { id: 'powerApps', label: 'Plum', color: '#742774' },
    { id: 'powerPoint', label: 'Tuscany', color: '#b7472a' },
    { id: 'publisher', label: 'Elf Green', color: '#077568' },
    { id: 'sway', label: 'Deep Sea', color: '#008272' },
    { id: 'teams', label: 'Scampi', color: '#6264a7' },
    { id: 'visio', label: 'Azure', color: '#3955a3' },
    { id: 'word', label: 'St Tropaz', color: '#2b579a' },
    { id: 'yammer', label: 'Denim', color: '#106EBE' },
    { id: 'basic', label: 'Silver', color: '#595959' },
    { id: 'black', label: 'Black', color: '#323130', },
];

const options: IChoiceGroupOption[] = [
    {
        key: 'ar',
        iconProps: { iconName: 'LocaleLanguage', },
        text: 'العربية',
    },
    {
        key: 'en',
        iconProps: { iconName: 'LocaleLanguage' },
        text: 'English',
    },
];


export const Settings: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const colorBaseId = useId('color-picker');
    const langBaseId = useId('lang-picker');
    const labelId = useId('callout-label');
    const { t, i18n } = useTranslation()
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

    const state: { isVisible: boolean, appTheme: string, isDarkModeEnabled: boolean, currentLang: string } = useAppSelector((state) => {
        return { isVisible: state.settings.isVisible, appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled, currentLang: state.settings.currentLang };
    })

    const swatchColorPickerOnCellHovered = (id?: string | undefined, color?: string | undefined) => {
        if (id) {
            dispatch(setAppTheme(id))
        }
        else {
            dispatch(setAppTheme(ThemeManager.getThemeManager().getTheme().themeId))
        }
    };

    const swatchColorPickerOnCellClicked = (id?: string | undefined, color?: string | undefined) => {
        if (id) {
            changeTheme(ThemeManager.getThemeManager().getTheme(id, state.isDarkModeEnabled));
        }
    };
    const changeLang = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        if (option) {
            localStorage.setItem("lang", option.key);
            //dispatch(setLang(option.key))
           // i18n.changeLanguage(option.key)
           // setRTL(option.key == 'ar');
        window.location.reload();
        }
    };
    const changeTheme = (appTheme: AppTheme) => {
        dispatch(setAppTheme(appTheme.themeId))
        dispatch(isDarkModeEnabled(appTheme.isDarkModeEnabled))
        ThemeManager.getThemeManager().applyTheme(appTheme)
    }

    return (
        <Panel
            headerText={t("settings")}
            // this prop makes the panel non-modal
            isBlocking={false}
            isOpen={state.isVisible}
            onDismiss={() => dispatch(isVisible(false))}
            closeButtonAriaLabel={t("close")}

        >
            <ChoiceGroup id={langBaseId} label={t("langauge")} defaultSelectedKey={state.currentLang} options={options} onChange={changeLang} />

            <Label htmlFor={colorBaseId}>{t("theme")}</Label>
            <SwatchColorPicker
                // eslint-disable-next-line react/jsx-no-bind
                onCellHovered={swatchColorPickerOnCellHovered}
                // eslint-disable-next-line react/jsx-no-bind
                onCellFocused={swatchColorPickerOnCellClicked}
                columnCount={4}
                cellShape={'circle'}
                cellHeight={35}
                cellWidth={35}
                //defaultSelectedId={state.appTheme}
                selectedId={state.appTheme}
                cellBorderWidth={3}
                colorCells={colorCellsExample2}
                aria-labelledby={`${colorBaseId}-grid`}
            />

            <Toggle
                checked={state.isDarkModeEnabled}
                label={
                    <div>
                        {t("darkMode") + ' '}
                        <TooltipHost content={t("darkModeLabel")}>
                            <Icon iconName="Info" aria-label={t("darkModeLabel")} />
                        </TooltipHost>
                    </div>
                }
                onText={t("enabled")}
                offText={t("disabled")}
                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => { changeTheme(ThemeManager.getThemeManager().getTheme(state.appTheme, checked)) }}
            />
        </Panel>

    )
};
