import { IBreadcrumbItem } from '@fluentui/react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppTheme, defaultTheme, ThemeManager } from '../../../app/Themes';


export interface SettingsState {
  isVisible: boolean,
  appTheme: string,
  isDarkModeEnabled: boolean,
  currentLang: string
}

const initialState: SettingsState = {
  isVisible: false,
  appTheme: ThemeManager.getThemeManager().getTheme().themeId,
  isDarkModeEnabled: ThemeManager.getThemeManager().getTheme().isDarkModeEnabled,
  currentLang: (localStorage.getItem("lang") == "en") ? "en" : "ar"
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    isVisible: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
    setAppTheme: (state, action: PayloadAction<string>) => {
      state.appTheme = action.payload;
    },
    isDarkModeEnabled: (state, action: PayloadAction<boolean>) => {
      state.isDarkModeEnabled = action.payload;
    },
    setLang: (state, action: PayloadAction<string>) => {
      state.currentLang = action.payload;
    }
  }
})

export const { isVisible, setAppTheme, isDarkModeEnabled, setLang } = settingsSlice.actions

export default settingsSlice.reducer


