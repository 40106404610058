import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../../../../repository/UserManagement/Managers/UserServiceClientPb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../app/Helpers';

import { TableState } from '../../../../../common/Table/TableSate';
import { GetRolesRequest, GetRolesResponse } from '../../../../../../repository/UserManagement/Managers/role_pb';
import { RoleEndpointClient } from '../../../../../../repository/UserManagement/Managers/RoleServiceClientPb';
import { UserTypeEndpointClient } from '../../../../../../repository/UserManagement/Managers/UsertypeServiceClientPb';
import { GetUserTypesRequest, GetUserTypesResponse } from '../../../../../../repository/UserManagement/Managers/usertype_pb';
import { number } from 'yup';
import { DynamicField } from '../../../../../common/DynamicFields/DynamicFields';
import { GroupEndpointClient } from '../../../../../../repository/UserManagement/Managers/GroupServiceClientPb';
import { DeleteGroupMembershipReplay, DeleteGroupMembershipRequest, DeleteGroupMembershipResponse, GetGroupMembershipsRequest, GetGroupMembershipsResponse } from '../../../../../../repository/UserManagement/Managers/groupmembership_pb';
import { GroupMembershipEndpointClient } from '../../../../../../repository/UserManagement/Managers/GroupmembershipServiceClientPb';


export interface GroupMembersPageState {
    members: TableState
    userTypes: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],
}

const initialState: GroupMembersPageState = {
    members: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    userTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: [],
}

const client = new GroupMembershipEndpointClient (API_URL, null, null);
const clientuserType = new UserTypeEndpointClient(API_URL, null, null);

export const getMembers = createAsyncThunk<GetGroupMembershipsResponse.AsObject, APIRequest<GetGroupMembershipsRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/management/groupDetails/getMembers',
    async (req, thunkApi) => {

        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getGroupMemberships(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const getUserTypes = createAsyncThunk<GetUserTypesResponse.AsObject, APIRequest<GetUserTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/groupDetails/getUserTypes',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientuserType.getUserTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const removeProfile = createAsyncThunk<APIResponse<DeleteGroupMembershipResponse.AsObject>, APIRequest<DeleteGroupMembershipRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/groupDetails/removeMember',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

const getFilters = (state: GroupMembersPageState): any[] => {

    const selected = [];
    if (state.userTypes.selected.length > 0) {
        selected.push({ key: 2, name: state.userTypes.selected.at(0).name });
    }

    return selected;
}

export const managersGroupMembersPageSlice = createSlice({
    name: 'pages/management/groupDetails/members',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.members = initialState.members;
            state.message = initialState.message;
            state.userTypes = initialState.userTypes;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.filters = initialState.filters
        },
        resetMembers: (state) => {
            state.members.items = [];
            state.members.hasMore = true;
        },


        resetUserTypes: (state) => {
            state.userTypes.items = [];
            state.userTypes.hasMore = true;
        },
        setSort: (state, action: PayloadAction<boolean>) => {
            state.members.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.members.numberOfResults = action.payload;
        },
        setSelectedMembers: (state, action: PayloadAction<any[]>) => {
            state.members.selected = action.payload;
        },

        setSelectedUserType: (state, action: PayloadAction<any[]>) => {
            state.userTypes.selected = action.payload;
            state.filters = getFilters(state);

        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;

        },
        setIsFilteredMembersSet: (state, action: PayloadAction<boolean>) => {
            state.members.isFilteredSet = action.payload;
        },

        setIsFilteredUserTypesSet: (state, action: PayloadAction<boolean>) => {
            state.userTypes.isFilteredSet = action.payload;
        },

        addUser: (state, action: PayloadAction<any>) => {
            if (!state.members.isFilteredSet) {
                if (!state.members.hasMore && !state.members.isDescending) {

                    action.payload?.forEach((e: any) => {
                        state.members.items.push(e);
                    })
                    // state.privileges.items.push(action.payload);
                } else if (state.members.isDescending) {
                    action.payload?.forEach((e: any) => {
                        state.members.items.unshift(e);
                    })

                }
            }

        },
        updateUser: (state, action: PayloadAction<any>) => {

            let selected = state.members.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.members.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.members.items[u] = action?.payload
                }
            }
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getMembers.fulfilled, (state, { payload }) => {
            state.members.isFetching = false
            if (payload) {
                var r = (payload?.success?.groupmembershipsList.map(val => {
                    return {
                        id: val.id,
                        profileId: val.profile?.id?.value,
                        name: (val.profile?.firstname?.value ?? "") + " " + (val.profile?.lastname?.value ?? ""),
                        firstname: (val.profile?.firstname?.value ?? ""),
                        lastname: (val.profile?.lastname?.value ?? ""),

                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val?.createddate?.nanos)),
                        addedBy: (val?.addedby?.firstname?.value ?? "") + " " + (val?.addedby?.lastname?.value ?? ""),
                        addedById: val?.addedby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.members.items.length != 0) {
                    l = state.members.items.slice(0, state.members.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.members.numberOfResults) {
                    state.members.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.members.items = l
                //console.log(state.items)

            } else {
                state.members.hasMore = false;
            }
        })
        builder.addCase(getMembers.rejected, (state, action) => {
            state.members.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getMembers.pending, (state, action) => {
            state.members.isFetching = true;
            state.message = undefined;
        })


        builder.addCase(getUserTypes.fulfilled, (state, { payload }) => {
            state.userTypes.isFetching = false;
            if (payload) {
                var r = payload?.success?.usertypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        userTypeCategory: val.usertypecategory?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
                        addedById: val.addedby?.id?.value,
                    }
                }
                ) as any[]

                if (r.length < state.userTypes.numberOfResults) {
                    state.userTypes.hasMore = false;
                }
                state.userTypes.items = state.userTypes.items.concat(r);

            }
        })
        builder.addCase(getUserTypes.rejected, (state, action) => {
            state.userTypes.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getUserTypes.pending, (state, action) => {
            state.userTypes.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(removeProfile.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.isChangeStateLoading = false;
                if (payload) {
                    state.message = toApiMessage(payload?.response.success?.message);
                    let selected = state.members.selected;
                    if (payload?.metadata) {
                        if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                            state.members.selected = []
                        }
                        var u = state.members.items.findIndex(e => e.id == payload?.metadata.id)
                        if (u >= 0) {
                            state.members.items.splice(u, 1);
                        }
                    }
                }
            }
        })
        builder.addCase(removeProfile.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(removeProfile.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


    }
})

export const { setIsFilteredUserTypesSet, setIsFilteredMembersSet, reset, dismissMessage, setSort, setNumberOfResults, resetMembers, setSelectedMembers, resetUserTypes, setSelectedUserType, setSearchText, addUser, updateUser } = managersGroupMembersPageSlice.actions

export default managersGroupMembersPageSlice.reducer


