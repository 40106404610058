import { FontWeights, getTheme, IButtonStyles, mergeStyleSets } from "@fluentui/react";
import { ThemeManager } from "../../../app/Themes";

const theme = getTheme();
export const contentStyles = mergeStyleSets({
  header: [
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${getTheme().palette.themePrimary}`,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});

export const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
  },
};
