import { DefaultButton, DialogFooter, IconButton, IStackTokens, MessageBar, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, Text, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/Hooks";
import * as Yup from 'yup';
import { InputField } from "../controls/Controls";
import { inputs } from "../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../app/Api";
import { Message } from "../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../FormProps";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import { UpdatePasswordResponse, UpdateProfilePasswordRequest } from "../../../repository/UserManagement/Managers/profile_pb";
import { AuthenticateReply } from "../../../repository/UserManagement/authentication_pb";
import { dismissMessage, reset, update } from "./ChangePasswordFormSlice";
import { SessionExpiredDialog } from "../../common/SessionExpiredDialog/SessionExpiredDialog";


let req: UpdateProfilePasswordRequest;
let promise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;



export const ChangePasswordForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();


    const state: { isLoading: boolean, message: ApiMessage | undefined } = useAppSelector((state) => {

        return { isLoading: state.changePasswordForm.isLoading, message: state.changePasswordForm.message }
    })

    useEffect(() => {
        req = new UpdateProfilePasswordRequest();
        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])

    return (
        <>

            <Formik


                initialValues={{

                    oldPassword: '',
                    newPassword: '',
                    rePassword: '',


                }}

                validationSchema={Yup.object({

                    oldPassword: inputs.password,
                    newPassword: inputs.newPassword,
                    rePassword: inputs.rePassword,


                })}

                onSubmit={(values, actions) => {
                    if (values.newPassword != values.rePassword) {
                        actions.setFieldValue("rePassword", "")
                        return;
                    }
                    req.setNewpassword(values.newPassword);
                    req.setOldpassword(values.oldPassword);
                    req.setRenewpassword(values.rePassword);
                    promise = dispatch(update({ body: req, headers: getHeaders() }))
                    promise.unwrap()
                        .then((res: UpdatePasswordResponse.AsObject) => {
                            actions.resetForm({ values: { newPassword: "", oldPassword: "", rePassword: "" } })
                            if (res) {
                                if (props?.onSuccess) {
                                    props?.onSuccess({
                                    });
                                }
                            }
                            actions.setSubmitting(false)

                        })
                        .catch((error: ApiMessage) => {
                            actions.setSubmitting(false)
                            actions.resetForm({ values: { newPassword: "", oldPassword: "", rePassword: "" } })
                        })
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }


                            <Field name="oldPassword" label={t("oldPassword")} placeholder={t("oldPassword")} component={InputField} disabled={state.isLoading} type="password"
                                canRevealPassword
                                revealPasswordAriaLabel={t("showPassword")} autoComplete="new-password" maxLength={50} required autoFocus />


                            <Field name="newPassword" label={t("newPassword")} placeholder={t("newPassword")} component={InputField} disabled={state.isLoading} type="password"
                                canRevealPassword
                                revealPasswordAriaLabel={t("showPassword")} autoComplete="new-password" maxLength={50} required />



                            <Field name="rePassword" label={t("rePassword")} placeholder={t("rePassword")} component={InputField} disabled={state.isLoading} type="password"
                                canRevealPassword
                                revealPasswordAriaLabel={t("showPassword")} autoComplete="new-password" maxLength={50} required />
                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : t("change")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>


                    </Form>
                )}
            </Formik>
        </>
    );

}

