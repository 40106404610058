// source: businessclient.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('../common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.business_client_endpoint.AddBusinessClientReplay', null, global);
goog.exportSymbol('proto.business_client_endpoint.AddBusinessClientRequest', null, global);
goog.exportSymbol('proto.business_client_endpoint.AddBusinessClientResponse', null, global);
goog.exportSymbol('proto.business_client_endpoint.AddBusinessClientResponse.ResultCase', null, global);
goog.exportSymbol('proto.business_client_endpoint.BusinessClientSummaryInfo', null, global);
goog.exportSymbol('proto.business_client_endpoint.BusinessReplay', null, global);
goog.exportSymbol('proto.business_client_endpoint.DeleteBusinessClientReplay', null, global);
goog.exportSymbol('proto.business_client_endpoint.DeleteBusinessClientRequest', null, global);
goog.exportSymbol('proto.business_client_endpoint.DeleteBusinessClientResponse', null, global);
goog.exportSymbol('proto.business_client_endpoint.DeleteBusinessClientResponse.ResultCase', null, global);
goog.exportSymbol('proto.business_client_endpoint.GetBusinessClientReplay', null, global);
goog.exportSymbol('proto.business_client_endpoint.GetBusinessClientRequest', null, global);
goog.exportSymbol('proto.business_client_endpoint.GetBusinessClientResponse', null, global);
goog.exportSymbol('proto.business_client_endpoint.GetBusinessClientResponse.ResultCase', null, global);
goog.exportSymbol('proto.business_client_endpoint.GetBusinessClientsReplay', null, global);
goog.exportSymbol('proto.business_client_endpoint.GetBusinessClientsRequest', null, global);
goog.exportSymbol('proto.business_client_endpoint.GetBusinessClientsResponse', null, global);
goog.exportSymbol('proto.business_client_endpoint.GetBusinessClientsResponse.ResultCase', null, global);
goog.exportSymbol('proto.business_client_endpoint.ThirdPartySystemLinkReplay', null, global);
goog.exportSymbol('proto.business_client_endpoint.URL', null, global);
goog.exportSymbol('proto.business_client_endpoint.UpdateBusinessClientActivityReplay', null, global);
goog.exportSymbol('proto.business_client_endpoint.UpdateBusinessClientActivityResponse', null, global);
goog.exportSymbol('proto.business_client_endpoint.UpdateBusinessClientActivityResponse.ResultCase', null, global);
goog.exportSymbol('proto.business_client_endpoint.UpdateBusinessClientStateRequest', null, global);
goog.exportSymbol('proto.business_client_endpoint.UpdateThirdPartyConnectionReplay', null, global);
goog.exportSymbol('proto.business_client_endpoint.UpdateThirdPartyConnectionRequest', null, global);
goog.exportSymbol('proto.business_client_endpoint.UpdateThirdPartyConnectionResponse', null, global);
goog.exportSymbol('proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.URL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.URL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.URL.displayName = 'proto.business_client_endpoint.URL';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.AddBusinessClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.AddBusinessClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.AddBusinessClientRequest.displayName = 'proto.business_client_endpoint.AddBusinessClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.UpdateBusinessClientStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.UpdateBusinessClientStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.UpdateBusinessClientStateRequest.displayName = 'proto.business_client_endpoint.UpdateBusinessClientStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.DeleteBusinessClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.DeleteBusinessClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.DeleteBusinessClientRequest.displayName = 'proto.business_client_endpoint.DeleteBusinessClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.UpdateThirdPartyConnectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.UpdateThirdPartyConnectionRequest.displayName = 'proto.business_client_endpoint.UpdateThirdPartyConnectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.GetBusinessClientsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.GetBusinessClientsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.GetBusinessClientsRequest.displayName = 'proto.business_client_endpoint.GetBusinessClientsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.GetBusinessClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.GetBusinessClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.GetBusinessClientRequest.displayName = 'proto.business_client_endpoint.GetBusinessClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.AddBusinessClientReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.AddBusinessClientReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.AddBusinessClientReplay.displayName = 'proto.business_client_endpoint.AddBusinessClientReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.UpdateBusinessClientActivityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.UpdateBusinessClientActivityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.UpdateBusinessClientActivityReplay.displayName = 'proto.business_client_endpoint.UpdateBusinessClientActivityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.DeleteBusinessClientReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.DeleteBusinessClientReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.DeleteBusinessClientReplay.displayName = 'proto.business_client_endpoint.DeleteBusinessClientReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.UpdateThirdPartyConnectionReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.displayName = 'proto.business_client_endpoint.UpdateThirdPartyConnectionReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.GetBusinessClientsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.business_client_endpoint.GetBusinessClientsReplay.repeatedFields_, null);
};
goog.inherits(proto.business_client_endpoint.GetBusinessClientsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.GetBusinessClientsReplay.displayName = 'proto.business_client_endpoint.GetBusinessClientsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.BusinessReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.BusinessReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.BusinessReplay.displayName = 'proto.business_client_endpoint.BusinessReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.ThirdPartySystemLinkReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.ThirdPartySystemLinkReplay.displayName = 'proto.business_client_endpoint.ThirdPartySystemLinkReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.BusinessClientSummaryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.BusinessClientSummaryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.BusinessClientSummaryInfo.displayName = 'proto.business_client_endpoint.BusinessClientSummaryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.GetBusinessClientReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_client_endpoint.GetBusinessClientReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.GetBusinessClientReplay.displayName = 'proto.business_client_endpoint.GetBusinessClientReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.AddBusinessClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.business_client_endpoint.AddBusinessClientResponse.oneofGroups_);
};
goog.inherits(proto.business_client_endpoint.AddBusinessClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.AddBusinessClientResponse.displayName = 'proto.business_client_endpoint.AddBusinessClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.UpdateBusinessClientActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.business_client_endpoint.UpdateBusinessClientActivityResponse.oneofGroups_);
};
goog.inherits(proto.business_client_endpoint.UpdateBusinessClientActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.UpdateBusinessClientActivityResponse.displayName = 'proto.business_client_endpoint.UpdateBusinessClientActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.GetBusinessClientsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.business_client_endpoint.GetBusinessClientsResponse.oneofGroups_);
};
goog.inherits(proto.business_client_endpoint.GetBusinessClientsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.GetBusinessClientsResponse.displayName = 'proto.business_client_endpoint.GetBusinessClientsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.GetBusinessClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.business_client_endpoint.GetBusinessClientResponse.oneofGroups_);
};
goog.inherits(proto.business_client_endpoint.GetBusinessClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.GetBusinessClientResponse.displayName = 'proto.business_client_endpoint.GetBusinessClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.oneofGroups_);
};
goog.inherits(proto.business_client_endpoint.UpdateThirdPartyConnectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.displayName = 'proto.business_client_endpoint.UpdateThirdPartyConnectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_client_endpoint.DeleteBusinessClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.business_client_endpoint.DeleteBusinessClientResponse.oneofGroups_);
};
goog.inherits(proto.business_client_endpoint.DeleteBusinessClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_client_endpoint.DeleteBusinessClientResponse.displayName = 'proto.business_client_endpoint.DeleteBusinessClientResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.URL.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.URL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.URL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.URL.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.URL}
 */
proto.business_client_endpoint.URL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.URL;
  return proto.business_client_endpoint.URL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.URL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.URL}
 */
proto.business_client_endpoint.URL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.URL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.URL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.URL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.URL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.business_client_endpoint.URL.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.business_client_endpoint.URL} returns this
 */
proto.business_client_endpoint.URL.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.business_client_endpoint.URL.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_client_endpoint.URL} returns this
 */
proto.business_client_endpoint.URL.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.AddBusinessClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.AddBusinessClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.AddBusinessClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.AddBusinessClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    business: jspb.Message.getFieldWithDefault(msg, 1, ""),
    client: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    thirdpartyclientusername: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.AddBusinessClientRequest}
 */
proto.business_client_endpoint.AddBusinessClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.AddBusinessClientRequest;
  return proto.business_client_endpoint.AddBusinessClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.AddBusinessClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.AddBusinessClientRequest}
 */
proto.business_client_endpoint.AddBusinessClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusiness(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClient(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdpartyclientusername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.AddBusinessClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.AddBusinessClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.AddBusinessClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.AddBusinessClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusiness();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClient();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getThirdpartyclientusername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string business = 1;
 * @return {string}
 */
proto.business_client_endpoint.AddBusinessClientRequest.prototype.getBusiness = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_client_endpoint.AddBusinessClientRequest} returns this
 */
proto.business_client_endpoint.AddBusinessClientRequest.prototype.setBusiness = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client = 2;
 * @return {string}
 */
proto.business_client_endpoint.AddBusinessClientRequest.prototype.getClient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_client_endpoint.AddBusinessClientRequest} returns this
 */
proto.business_client_endpoint.AddBusinessClientRequest.prototype.setClient = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isActive = 3;
 * @return {boolean}
 */
proto.business_client_endpoint.AddBusinessClientRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.business_client_endpoint.AddBusinessClientRequest} returns this
 */
proto.business_client_endpoint.AddBusinessClientRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string thirdPartyClientUsername = 4;
 * @return {string}
 */
proto.business_client_endpoint.AddBusinessClientRequest.prototype.getThirdpartyclientusername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_client_endpoint.AddBusinessClientRequest} returns this
 */
proto.business_client_endpoint.AddBusinessClientRequest.prototype.setThirdpartyclientusername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.UpdateBusinessClientStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.UpdateBusinessClientStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.UpdateBusinessClientStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.UpdateBusinessClientStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.UpdateBusinessClientStateRequest}
 */
proto.business_client_endpoint.UpdateBusinessClientStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.UpdateBusinessClientStateRequest;
  return proto.business_client_endpoint.UpdateBusinessClientStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.UpdateBusinessClientStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.UpdateBusinessClientStateRequest}
 */
proto.business_client_endpoint.UpdateBusinessClientStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.UpdateBusinessClientStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.UpdateBusinessClientStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.UpdateBusinessClientStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.UpdateBusinessClientStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.business_client_endpoint.UpdateBusinessClientStateRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_client_endpoint.UpdateBusinessClientStateRequest} returns this
 */
proto.business_client_endpoint.UpdateBusinessClientStateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isActive = 2;
 * @return {boolean}
 */
proto.business_client_endpoint.UpdateBusinessClientStateRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.business_client_endpoint.UpdateBusinessClientStateRequest} returns this
 */
proto.business_client_endpoint.UpdateBusinessClientStateRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.DeleteBusinessClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.DeleteBusinessClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.DeleteBusinessClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.DeleteBusinessClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.DeleteBusinessClientRequest}
 */
proto.business_client_endpoint.DeleteBusinessClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.DeleteBusinessClientRequest;
  return proto.business_client_endpoint.DeleteBusinessClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.DeleteBusinessClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.DeleteBusinessClientRequest}
 */
proto.business_client_endpoint.DeleteBusinessClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.DeleteBusinessClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.DeleteBusinessClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.DeleteBusinessClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.DeleteBusinessClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.business_client_endpoint.DeleteBusinessClientRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_client_endpoint.DeleteBusinessClientRequest} returns this
 */
proto.business_client_endpoint.DeleteBusinessClientRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.UpdateThirdPartyConnectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.UpdateThirdPartyConnectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    thirdpartyclientusername: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionRequest}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.UpdateThirdPartyConnectionRequest;
  return proto.business_client_endpoint.UpdateThirdPartyConnectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.UpdateThirdPartyConnectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionRequest}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdpartyclientusername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.UpdateThirdPartyConnectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.UpdateThirdPartyConnectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getThirdpartyclientusername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionRequest} returns this
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string thirdPartyClientUsername = 2;
 * @return {string}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionRequest.prototype.getThirdpartyclientusername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionRequest} returns this
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionRequest.prototype.setThirdpartyclientusername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.GetBusinessClientsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.GetBusinessClientsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.GetBusinessClientsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 4, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    business: (f = msg.getBusiness()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    client: (f = msg.getClient()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    connectionmetadata: (f = msg.getConnectionmetadata()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.GetBusinessClientsRequest;
  return proto.business_client_endpoint.GetBusinessClientsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.GetBusinessClientsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBusiness(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setConnectionmetadata(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.GetBusinessClientsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.GetBusinessClientsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.GetBusinessClientsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getBusiness();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getConnectionmetadata();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue search = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
*/
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool order = 2;
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
*/
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 numOfResults = 4;
 * @return {number}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.Int32Value organization = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
*/
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue business = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.getBusiness = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
*/
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.setBusiness = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.clearBusiness = function() {
  return this.setBusiness(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.hasBusiness = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue client = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.getClient = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
*/
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.hasClient = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue connectionMetaData = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.getConnectionmetadata = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
*/
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.setConnectionmetadata = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.clearConnectionmetadata = function() {
  return this.setConnectionmetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.hasConnectionmetadata = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 9;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 9));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
*/
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientsRequest} returns this
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientsRequest.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.GetBusinessClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.GetBusinessClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.GetBusinessClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.GetBusinessClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.GetBusinessClientRequest}
 */
proto.business_client_endpoint.GetBusinessClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.GetBusinessClientRequest;
  return proto.business_client_endpoint.GetBusinessClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.GetBusinessClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.GetBusinessClientRequest}
 */
proto.business_client_endpoint.GetBusinessClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.GetBusinessClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.GetBusinessClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.GetBusinessClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.GetBusinessClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.business_client_endpoint.GetBusinessClientRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_client_endpoint.GetBusinessClientRequest} returns this
 */
proto.business_client_endpoint.GetBusinessClientRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.AddBusinessClientReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.AddBusinessClientReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.AddBusinessClientReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    thirdpartyid: (f = msg.getThirdpartyid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    thirdpartyorganization: (f = msg.getThirdpartyorganization()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.AddBusinessClientReplay}
 */
proto.business_client_endpoint.AddBusinessClientReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.AddBusinessClientReplay;
  return proto.business_client_endpoint.AddBusinessClientReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.AddBusinessClientReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.AddBusinessClientReplay}
 */
proto.business_client_endpoint.AddBusinessClientReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setThirdpartyid(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setThirdpartyorganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.AddBusinessClientReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.AddBusinessClientReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.AddBusinessClientReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getThirdpartyid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getThirdpartyorganization();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Id = 1;
 * @return {string}
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_client_endpoint.AddBusinessClientReplay} returns this
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.business_client_endpoint.AddBusinessClientReplay} returns this
*/
proto.business_client_endpoint.AddBusinessClientReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.AddBusinessClientReplay} returns this
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.business_client_endpoint.AddBusinessClientReplay} returns this
*/
proto.business_client_endpoint.AddBusinessClientReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.AddBusinessClientReplay} returns this
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue thirdPartyId = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.getThirdpartyid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_client_endpoint.AddBusinessClientReplay} returns this
*/
proto.business_client_endpoint.AddBusinessClientReplay.prototype.setThirdpartyid = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.AddBusinessClientReplay} returns this
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.clearThirdpartyid = function() {
  return this.setThirdpartyid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.hasThirdpartyid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue thirdPartyOrganization = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.getThirdpartyorganization = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_client_endpoint.AddBusinessClientReplay} returns this
*/
proto.business_client_endpoint.AddBusinessClientReplay.prototype.setThirdpartyorganization = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.AddBusinessClientReplay} returns this
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.clearThirdpartyorganization = function() {
  return this.setThirdpartyorganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.AddBusinessClientReplay.prototype.hasThirdpartyorganization = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.UpdateBusinessClientActivityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.UpdateBusinessClientActivityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.UpdateBusinessClientActivityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.UpdateBusinessClientActivityReplay}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.UpdateBusinessClientActivityReplay;
  return proto.business_client_endpoint.UpdateBusinessClientActivityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.UpdateBusinessClientActivityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.UpdateBusinessClientActivityReplay}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.UpdateBusinessClientActivityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.UpdateBusinessClientActivityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.UpdateBusinessClientActivityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.business_client_endpoint.UpdateBusinessClientActivityReplay} returns this
*/
proto.business_client_endpoint.UpdateBusinessClientActivityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.UpdateBusinessClientActivityReplay} returns this
 */
proto.business_client_endpoint.UpdateBusinessClientActivityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.business_client_endpoint.UpdateBusinessClientActivityReplay} returns this
*/
proto.business_client_endpoint.UpdateBusinessClientActivityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.UpdateBusinessClientActivityReplay} returns this
 */
proto.business_client_endpoint.UpdateBusinessClientActivityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.DeleteBusinessClientReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.DeleteBusinessClientReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.DeleteBusinessClientReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.DeleteBusinessClientReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteddate: (f = msg.getDeleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.DeleteBusinessClientReplay}
 */
proto.business_client_endpoint.DeleteBusinessClientReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.DeleteBusinessClientReplay;
  return proto.business_client_endpoint.DeleteBusinessClientReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.DeleteBusinessClientReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.DeleteBusinessClientReplay}
 */
proto.business_client_endpoint.DeleteBusinessClientReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleteddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.DeleteBusinessClientReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.DeleteBusinessClientReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.DeleteBusinessClientReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.DeleteBusinessClientReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp deletedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.business_client_endpoint.DeleteBusinessClientReplay.prototype.getDeleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.business_client_endpoint.DeleteBusinessClientReplay} returns this
*/
proto.business_client_endpoint.DeleteBusinessClientReplay.prototype.setDeleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.DeleteBusinessClientReplay} returns this
 */
proto.business_client_endpoint.DeleteBusinessClientReplay.prototype.clearDeleteddate = function() {
  return this.setDeleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.DeleteBusinessClientReplay.prototype.hasDeleteddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.business_client_endpoint.DeleteBusinessClientReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.business_client_endpoint.DeleteBusinessClientReplay} returns this
*/
proto.business_client_endpoint.DeleteBusinessClientReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.DeleteBusinessClientReplay} returns this
 */
proto.business_client_endpoint.DeleteBusinessClientReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.DeleteBusinessClientReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.UpdateThirdPartyConnectionReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    thirdpartyid: (f = msg.getThirdpartyid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    thirdpartyorganization: (f = msg.getThirdpartyorganization()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionReplay}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.UpdateThirdPartyConnectionReplay;
  return proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.UpdateThirdPartyConnectionReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionReplay}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setThirdpartyid(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setThirdpartyorganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.UpdateThirdPartyConnectionReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getThirdpartyid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getThirdpartyorganization();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionReplay} returns this
*/
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionReplay} returns this
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionReplay} returns this
*/
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionReplay} returns this
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue thirdPartyId = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.getThirdpartyid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionReplay} returns this
*/
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.setThirdpartyid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionReplay} returns this
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.clearThirdpartyid = function() {
  return this.setThirdpartyid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.hasThirdpartyid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue thirdPartyOrganization = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.getThirdpartyorganization = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionReplay} returns this
*/
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.setThirdpartyorganization = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionReplay} returns this
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.clearThirdpartyorganization = function() {
  return this.setThirdpartyorganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.prototype.hasThirdpartyorganization = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.business_client_endpoint.GetBusinessClientsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.GetBusinessClientsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.GetBusinessClientsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.GetBusinessClientsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.GetBusinessClientsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessclientsList: jspb.Message.toObjectList(msg.getBusinessclientsList(),
    proto.business_client_endpoint.BusinessClientSummaryInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.GetBusinessClientsReplay}
 */
proto.business_client_endpoint.GetBusinessClientsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.GetBusinessClientsReplay;
  return proto.business_client_endpoint.GetBusinessClientsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.GetBusinessClientsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.GetBusinessClientsReplay}
 */
proto.business_client_endpoint.GetBusinessClientsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_client_endpoint.BusinessClientSummaryInfo;
      reader.readMessage(value,proto.business_client_endpoint.BusinessClientSummaryInfo.deserializeBinaryFromReader);
      msg.addBusinessclients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.GetBusinessClientsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.GetBusinessClientsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.GetBusinessClientsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.GetBusinessClientsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessclientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.business_client_endpoint.BusinessClientSummaryInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BusinessClientSummaryInfo businessClients = 1;
 * @return {!Array<!proto.business_client_endpoint.BusinessClientSummaryInfo>}
 */
proto.business_client_endpoint.GetBusinessClientsReplay.prototype.getBusinessclientsList = function() {
  return /** @type{!Array<!proto.business_client_endpoint.BusinessClientSummaryInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.business_client_endpoint.BusinessClientSummaryInfo, 1));
};


/**
 * @param {!Array<!proto.business_client_endpoint.BusinessClientSummaryInfo>} value
 * @return {!proto.business_client_endpoint.GetBusinessClientsReplay} returns this
*/
proto.business_client_endpoint.GetBusinessClientsReplay.prototype.setBusinessclientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.business_client_endpoint.BusinessClientSummaryInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo}
 */
proto.business_client_endpoint.GetBusinessClientsReplay.prototype.addBusinessclients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.business_client_endpoint.BusinessClientSummaryInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_client_endpoint.GetBusinessClientsReplay} returns this
 */
proto.business_client_endpoint.GetBusinessClientsReplay.prototype.clearBusinessclientsList = function() {
  return this.setBusinessclientsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.BusinessReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.BusinessReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.BusinessReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.BusinessReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.BusinessReplay}
 */
proto.business_client_endpoint.BusinessReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.BusinessReplay;
  return proto.business_client_endpoint.BusinessReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.BusinessReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.BusinessReplay}
 */
proto.business_client_endpoint.BusinessReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.BusinessReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.BusinessReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.BusinessReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.BusinessReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_client_endpoint.BusinessReplay.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_client_endpoint.BusinessReplay} returns this
*/
proto.business_client_endpoint.BusinessReplay.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.BusinessReplay} returns this
 */
proto.business_client_endpoint.BusinessReplay.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.BusinessReplay.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_client_endpoint.BusinessReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_client_endpoint.BusinessReplay} returns this
*/
proto.business_client_endpoint.BusinessReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.BusinessReplay} returns this
 */
proto.business_client_endpoint.BusinessReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.BusinessReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.ThirdPartySystemLinkReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.ThirdPartySystemLinkReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    metadata: (f = msg.getMetadata()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.ThirdPartySystemLinkReplay}
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.ThirdPartySystemLinkReplay;
  return proto.business_client_endpoint.ThirdPartySystemLinkReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.ThirdPartySystemLinkReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.ThirdPartySystemLinkReplay}
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.ThirdPartySystemLinkReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.ThirdPartySystemLinkReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_client_endpoint.ThirdPartySystemLinkReplay} returns this
*/
proto.business_client_endpoint.ThirdPartySystemLinkReplay.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.ThirdPartySystemLinkReplay} returns this
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue metadata = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.prototype.getMetadata = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_client_endpoint.ThirdPartySystemLinkReplay} returns this
*/
proto.business_client_endpoint.ThirdPartySystemLinkReplay.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.ThirdPartySystemLinkReplay} returns this
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue organization = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_client_endpoint.ThirdPartySystemLinkReplay} returns this
*/
proto.business_client_endpoint.ThirdPartySystemLinkReplay.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.ThirdPartySystemLinkReplay} returns this
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.ThirdPartySystemLinkReplay.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.BusinessClientSummaryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.BusinessClientSummaryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    business: (f = msg.getBusiness()) && proto.business_client_endpoint.BusinessReplay.toObject(includeInstance, f),
    client: (f = msg.getClient()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    connection: (f = msg.getConnection()) && proto.business_client_endpoint.ThirdPartySystemLinkReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.BusinessClientSummaryInfo;
  return proto.business_client_endpoint.BusinessClientSummaryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.BusinessClientSummaryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 3:
      var value = new proto.business_client_endpoint.BusinessReplay;
      reader.readMessage(value,proto.business_client_endpoint.BusinessReplay.deserializeBinaryFromReader);
      msg.setBusiness(value);
      break;
    case 4:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = new proto.business_client_endpoint.ThirdPartySystemLinkReplay;
      reader.readMessage(value,proto.business_client_endpoint.ThirdPartySystemLinkReplay.deserializeBinaryFromReader);
      msg.setConnection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.BusinessClientSummaryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.BusinessClientSummaryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getBusiness();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.business_client_endpoint.BusinessReplay.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getConnection();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.business_client_endpoint.ThirdPartySystemLinkReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo} returns this
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.ProfileResponse createdBy = 2;
 * @return {?proto.common.ProfileResponse}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 2));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo} returns this
*/
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo} returns this
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BusinessReplay business = 3;
 * @return {?proto.business_client_endpoint.BusinessReplay}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.getBusiness = function() {
  return /** @type{?proto.business_client_endpoint.BusinessReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_client_endpoint.BusinessReplay, 3));
};


/**
 * @param {?proto.business_client_endpoint.BusinessReplay|undefined} value
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo} returns this
*/
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.setBusiness = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo} returns this
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.clearBusiness = function() {
  return this.setBusiness(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.hasBusiness = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.ProfileResponse client = 4;
 * @return {?proto.common.ProfileResponse}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.getClient = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 4));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo} returns this
*/
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo} returns this
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.hasClient = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo} returns this
*/
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo} returns this
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo} returns this
*/
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo} returns this
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ThirdPartySystemLinkReplay connection = 7;
 * @return {?proto.business_client_endpoint.ThirdPartySystemLinkReplay}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.getConnection = function() {
  return /** @type{?proto.business_client_endpoint.ThirdPartySystemLinkReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_client_endpoint.ThirdPartySystemLinkReplay, 7));
};


/**
 * @param {?proto.business_client_endpoint.ThirdPartySystemLinkReplay|undefined} value
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo} returns this
*/
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.setConnection = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.BusinessClientSummaryInfo} returns this
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.clearConnection = function() {
  return this.setConnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.BusinessClientSummaryInfo.prototype.hasConnection = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.GetBusinessClientReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.GetBusinessClientReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.GetBusinessClientReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    business: (f = msg.getBusiness()) && proto.business_client_endpoint.BusinessReplay.toObject(includeInstance, f),
    client: (f = msg.getClient()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    connection: (f = msg.getConnection()) && proto.business_client_endpoint.ThirdPartySystemLinkReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay}
 */
proto.business_client_endpoint.GetBusinessClientReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.GetBusinessClientReplay;
  return proto.business_client_endpoint.GetBusinessClientReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.GetBusinessClientReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay}
 */
proto.business_client_endpoint.GetBusinessClientReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 3:
      var value = new proto.business_client_endpoint.BusinessReplay;
      reader.readMessage(value,proto.business_client_endpoint.BusinessReplay.deserializeBinaryFromReader);
      msg.setBusiness(value);
      break;
    case 4:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = new proto.business_client_endpoint.ThirdPartySystemLinkReplay;
      reader.readMessage(value,proto.business_client_endpoint.ThirdPartySystemLinkReplay.deserializeBinaryFromReader);
      msg.setConnection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.GetBusinessClientReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.GetBusinessClientReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.GetBusinessClientReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getBusiness();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.business_client_endpoint.BusinessReplay.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getConnection();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.business_client_endpoint.ThirdPartySystemLinkReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay} returns this
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.ProfileResponse createdBy = 2;
 * @return {?proto.common.ProfileResponse}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 2));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay} returns this
*/
proto.business_client_endpoint.GetBusinessClientReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay} returns this
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BusinessReplay business = 3;
 * @return {?proto.business_client_endpoint.BusinessReplay}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.getBusiness = function() {
  return /** @type{?proto.business_client_endpoint.BusinessReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_client_endpoint.BusinessReplay, 3));
};


/**
 * @param {?proto.business_client_endpoint.BusinessReplay|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay} returns this
*/
proto.business_client_endpoint.GetBusinessClientReplay.prototype.setBusiness = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay} returns this
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.clearBusiness = function() {
  return this.setBusiness(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.hasBusiness = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.ProfileResponse client = 4;
 * @return {?proto.common.ProfileResponse}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.getClient = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 4));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay} returns this
*/
proto.business_client_endpoint.GetBusinessClientReplay.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay} returns this
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.hasClient = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay} returns this
*/
proto.business_client_endpoint.GetBusinessClientReplay.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay} returns this
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay} returns this
*/
proto.business_client_endpoint.GetBusinessClientReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay} returns this
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ThirdPartySystemLinkReplay connection = 7;
 * @return {?proto.business_client_endpoint.ThirdPartySystemLinkReplay}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.getConnection = function() {
  return /** @type{?proto.business_client_endpoint.ThirdPartySystemLinkReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_client_endpoint.ThirdPartySystemLinkReplay, 7));
};


/**
 * @param {?proto.business_client_endpoint.ThirdPartySystemLinkReplay|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay} returns this
*/
proto.business_client_endpoint.GetBusinessClientReplay.prototype.setConnection = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientReplay} returns this
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.clearConnection = function() {
  return this.setConnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientReplay.prototype.hasConnection = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.business_client_endpoint.AddBusinessClientResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.business_client_endpoint.AddBusinessClientResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.business_client_endpoint.AddBusinessClientResponse.ResultCase}
 */
proto.business_client_endpoint.AddBusinessClientResponse.prototype.getResultCase = function() {
  return /** @type {proto.business_client_endpoint.AddBusinessClientResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.business_client_endpoint.AddBusinessClientResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.AddBusinessClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.AddBusinessClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.AddBusinessClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.AddBusinessClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.business_client_endpoint.AddBusinessClientReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.AddBusinessClientResponse}
 */
proto.business_client_endpoint.AddBusinessClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.AddBusinessClientResponse;
  return proto.business_client_endpoint.AddBusinessClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.AddBusinessClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.AddBusinessClientResponse}
 */
proto.business_client_endpoint.AddBusinessClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_client_endpoint.AddBusinessClientReplay;
      reader.readMessage(value,proto.business_client_endpoint.AddBusinessClientReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.AddBusinessClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.AddBusinessClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.AddBusinessClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.AddBusinessClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business_client_endpoint.AddBusinessClientReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddBusinessClientReplay success = 1;
 * @return {?proto.business_client_endpoint.AddBusinessClientReplay}
 */
proto.business_client_endpoint.AddBusinessClientResponse.prototype.getSuccess = function() {
  return /** @type{?proto.business_client_endpoint.AddBusinessClientReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_client_endpoint.AddBusinessClientReplay, 1));
};


/**
 * @param {?proto.business_client_endpoint.AddBusinessClientReplay|undefined} value
 * @return {!proto.business_client_endpoint.AddBusinessClientResponse} returns this
*/
proto.business_client_endpoint.AddBusinessClientResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.business_client_endpoint.AddBusinessClientResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.AddBusinessClientResponse} returns this
 */
proto.business_client_endpoint.AddBusinessClientResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.AddBusinessClientResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.business_client_endpoint.UpdateBusinessClientActivityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.business_client_endpoint.UpdateBusinessClientActivityResponse.ResultCase}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityResponse.prototype.getResultCase = function() {
  return /** @type {proto.business_client_endpoint.UpdateBusinessClientActivityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.business_client_endpoint.UpdateBusinessClientActivityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.UpdateBusinessClientActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.UpdateBusinessClientActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.UpdateBusinessClientActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.business_client_endpoint.UpdateBusinessClientActivityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.UpdateBusinessClientActivityResponse}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.UpdateBusinessClientActivityResponse;
  return proto.business_client_endpoint.UpdateBusinessClientActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.UpdateBusinessClientActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.UpdateBusinessClientActivityResponse}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_client_endpoint.UpdateBusinessClientActivityReplay;
      reader.readMessage(value,proto.business_client_endpoint.UpdateBusinessClientActivityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.UpdateBusinessClientActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.UpdateBusinessClientActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.UpdateBusinessClientActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business_client_endpoint.UpdateBusinessClientActivityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateBusinessClientActivityReplay success = 1;
 * @return {?proto.business_client_endpoint.UpdateBusinessClientActivityReplay}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.business_client_endpoint.UpdateBusinessClientActivityReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_client_endpoint.UpdateBusinessClientActivityReplay, 1));
};


/**
 * @param {?proto.business_client_endpoint.UpdateBusinessClientActivityReplay|undefined} value
 * @return {!proto.business_client_endpoint.UpdateBusinessClientActivityResponse} returns this
*/
proto.business_client_endpoint.UpdateBusinessClientActivityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.business_client_endpoint.UpdateBusinessClientActivityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.UpdateBusinessClientActivityResponse} returns this
 */
proto.business_client_endpoint.UpdateBusinessClientActivityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.UpdateBusinessClientActivityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.business_client_endpoint.GetBusinessClientsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.business_client_endpoint.GetBusinessClientsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.business_client_endpoint.GetBusinessClientsResponse.ResultCase}
 */
proto.business_client_endpoint.GetBusinessClientsResponse.prototype.getResultCase = function() {
  return /** @type {proto.business_client_endpoint.GetBusinessClientsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.business_client_endpoint.GetBusinessClientsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.GetBusinessClientsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.GetBusinessClientsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.GetBusinessClientsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.GetBusinessClientsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.business_client_endpoint.GetBusinessClientsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.GetBusinessClientsResponse}
 */
proto.business_client_endpoint.GetBusinessClientsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.GetBusinessClientsResponse;
  return proto.business_client_endpoint.GetBusinessClientsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.GetBusinessClientsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.GetBusinessClientsResponse}
 */
proto.business_client_endpoint.GetBusinessClientsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_client_endpoint.GetBusinessClientsReplay;
      reader.readMessage(value,proto.business_client_endpoint.GetBusinessClientsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.GetBusinessClientsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.GetBusinessClientsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.GetBusinessClientsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.GetBusinessClientsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business_client_endpoint.GetBusinessClientsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetBusinessClientsReplay success = 1;
 * @return {?proto.business_client_endpoint.GetBusinessClientsReplay}
 */
proto.business_client_endpoint.GetBusinessClientsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.business_client_endpoint.GetBusinessClientsReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_client_endpoint.GetBusinessClientsReplay, 1));
};


/**
 * @param {?proto.business_client_endpoint.GetBusinessClientsReplay|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientsResponse} returns this
*/
proto.business_client_endpoint.GetBusinessClientsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.business_client_endpoint.GetBusinessClientsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientsResponse} returns this
 */
proto.business_client_endpoint.GetBusinessClientsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.business_client_endpoint.GetBusinessClientResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.business_client_endpoint.GetBusinessClientResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.business_client_endpoint.GetBusinessClientResponse.ResultCase}
 */
proto.business_client_endpoint.GetBusinessClientResponse.prototype.getResultCase = function() {
  return /** @type {proto.business_client_endpoint.GetBusinessClientResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.business_client_endpoint.GetBusinessClientResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.GetBusinessClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.GetBusinessClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.GetBusinessClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.GetBusinessClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.business_client_endpoint.GetBusinessClientReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.GetBusinessClientResponse}
 */
proto.business_client_endpoint.GetBusinessClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.GetBusinessClientResponse;
  return proto.business_client_endpoint.GetBusinessClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.GetBusinessClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.GetBusinessClientResponse}
 */
proto.business_client_endpoint.GetBusinessClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_client_endpoint.GetBusinessClientReplay;
      reader.readMessage(value,proto.business_client_endpoint.GetBusinessClientReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.GetBusinessClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.GetBusinessClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.GetBusinessClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.GetBusinessClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business_client_endpoint.GetBusinessClientReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetBusinessClientReplay success = 1;
 * @return {?proto.business_client_endpoint.GetBusinessClientReplay}
 */
proto.business_client_endpoint.GetBusinessClientResponse.prototype.getSuccess = function() {
  return /** @type{?proto.business_client_endpoint.GetBusinessClientReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_client_endpoint.GetBusinessClientReplay, 1));
};


/**
 * @param {?proto.business_client_endpoint.GetBusinessClientReplay|undefined} value
 * @return {!proto.business_client_endpoint.GetBusinessClientResponse} returns this
*/
proto.business_client_endpoint.GetBusinessClientResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.business_client_endpoint.GetBusinessClientResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.GetBusinessClientResponse} returns this
 */
proto.business_client_endpoint.GetBusinessClientResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.GetBusinessClientResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.ResultCase}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.prototype.getResultCase = function() {
  return /** @type {proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.UpdateThirdPartyConnectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionResponse}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.UpdateThirdPartyConnectionResponse;
  return proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.UpdateThirdPartyConnectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionResponse}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_client_endpoint.UpdateThirdPartyConnectionReplay;
      reader.readMessage(value,proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.UpdateThirdPartyConnectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business_client_endpoint.UpdateThirdPartyConnectionReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateThirdPartyConnectionReplay success = 1;
 * @return {?proto.business_client_endpoint.UpdateThirdPartyConnectionReplay}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.prototype.getSuccess = function() {
  return /** @type{?proto.business_client_endpoint.UpdateThirdPartyConnectionReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_client_endpoint.UpdateThirdPartyConnectionReplay, 1));
};


/**
 * @param {?proto.business_client_endpoint.UpdateThirdPartyConnectionReplay|undefined} value
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionResponse} returns this
*/
proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.UpdateThirdPartyConnectionResponse} returns this
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.UpdateThirdPartyConnectionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.business_client_endpoint.DeleteBusinessClientResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.business_client_endpoint.DeleteBusinessClientResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.business_client_endpoint.DeleteBusinessClientResponse.ResultCase}
 */
proto.business_client_endpoint.DeleteBusinessClientResponse.prototype.getResultCase = function() {
  return /** @type {proto.business_client_endpoint.DeleteBusinessClientResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.business_client_endpoint.DeleteBusinessClientResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_client_endpoint.DeleteBusinessClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.business_client_endpoint.DeleteBusinessClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_client_endpoint.DeleteBusinessClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.DeleteBusinessClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.business_client_endpoint.DeleteBusinessClientReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_client_endpoint.DeleteBusinessClientResponse}
 */
proto.business_client_endpoint.DeleteBusinessClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_client_endpoint.DeleteBusinessClientResponse;
  return proto.business_client_endpoint.DeleteBusinessClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_client_endpoint.DeleteBusinessClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_client_endpoint.DeleteBusinessClientResponse}
 */
proto.business_client_endpoint.DeleteBusinessClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_client_endpoint.DeleteBusinessClientReplay;
      reader.readMessage(value,proto.business_client_endpoint.DeleteBusinessClientReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_client_endpoint.DeleteBusinessClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_client_endpoint.DeleteBusinessClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_client_endpoint.DeleteBusinessClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_client_endpoint.DeleteBusinessClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business_client_endpoint.DeleteBusinessClientReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteBusinessClientReplay success = 1;
 * @return {?proto.business_client_endpoint.DeleteBusinessClientReplay}
 */
proto.business_client_endpoint.DeleteBusinessClientResponse.prototype.getSuccess = function() {
  return /** @type{?proto.business_client_endpoint.DeleteBusinessClientReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_client_endpoint.DeleteBusinessClientReplay, 1));
};


/**
 * @param {?proto.business_client_endpoint.DeleteBusinessClientReplay|undefined} value
 * @return {!proto.business_client_endpoint.DeleteBusinessClientResponse} returns this
*/
proto.business_client_endpoint.DeleteBusinessClientResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.business_client_endpoint.DeleteBusinessClientResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_client_endpoint.DeleteBusinessClientResponse} returns this
 */
proto.business_client_endpoint.DeleteBusinessClientResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_client_endpoint.DeleteBusinessClientResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.business_client_endpoint);
