/**
 * @fileoverview gRPC-Web generated client stub for managers_profile_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as profile_pb from './profile_pb';


export class ProfileEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetProfile = new grpcWeb.MethodDescriptor(
    '/managers_profile_endpoint.ProfileEndpoint/GetProfile',
    grpcWeb.MethodType.UNARY,
    profile_pb.GetProfileRequest,
    profile_pb.GetProfileResponse,
    (request: profile_pb.GetProfileRequest) => {
      return request.serializeBinary();
    },
    profile_pb.GetProfileResponse.deserializeBinary
  );

  getProfile(
    request: profile_pb.GetProfileRequest,
    metadata: grpcWeb.Metadata | null): Promise<profile_pb.GetProfileResponse>;

  getProfile(
    request: profile_pb.GetProfileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: profile_pb.GetProfileResponse) => void): grpcWeb.ClientReadableStream<profile_pb.GetProfileResponse>;

  getProfile(
    request: profile_pb.GetProfileRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: profile_pb.GetProfileResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/managers_profile_endpoint.ProfileEndpoint/GetProfile',
        request,
        metadata || {},
        this.methodDescriptorGetProfile,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/managers_profile_endpoint.ProfileEndpoint/GetProfile',
    request,
    metadata || {},
    this.methodDescriptorGetProfile);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/managers_profile_endpoint.ProfileEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    profile_pb.UpdateProfileRequest,
    profile_pb.UpdateProfileResponse,
    (request: profile_pb.UpdateProfileRequest) => {
      return request.serializeBinary();
    },
    profile_pb.UpdateProfileResponse.deserializeBinary
  );

  update(
    request: profile_pb.UpdateProfileRequest,
    metadata: grpcWeb.Metadata | null): Promise<profile_pb.UpdateProfileResponse>;

  update(
    request: profile_pb.UpdateProfileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: profile_pb.UpdateProfileResponse) => void): grpcWeb.ClientReadableStream<profile_pb.UpdateProfileResponse>;

  update(
    request: profile_pb.UpdateProfileRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: profile_pb.UpdateProfileResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/managers_profile_endpoint.ProfileEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/managers_profile_endpoint.ProfileEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdatePassword = new grpcWeb.MethodDescriptor(
    '/managers_profile_endpoint.ProfileEndpoint/UpdatePassword',
    grpcWeb.MethodType.UNARY,
    profile_pb.UpdateProfilePasswordRequest,
    profile_pb.UpdatePasswordResponse,
    (request: profile_pb.UpdateProfilePasswordRequest) => {
      return request.serializeBinary();
    },
    profile_pb.UpdatePasswordResponse.deserializeBinary
  );

  updatePassword(
    request: profile_pb.UpdateProfilePasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<profile_pb.UpdatePasswordResponse>;

  updatePassword(
    request: profile_pb.UpdateProfilePasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: profile_pb.UpdatePasswordResponse) => void): grpcWeb.ClientReadableStream<profile_pb.UpdatePasswordResponse>;

  updatePassword(
    request: profile_pb.UpdateProfilePasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: profile_pb.UpdatePasswordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/managers_profile_endpoint.ProfileEndpoint/UpdatePassword',
        request,
        metadata || {},
        this.methodDescriptorUpdatePassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/managers_profile_endpoint.ProfileEndpoint/UpdatePassword',
    request,
    metadata || {},
    this.methodDescriptorUpdatePassword);
  }

  methodDescriptorUpdateAccountSecurity = new grpcWeb.MethodDescriptor(
    '/managers_profile_endpoint.ProfileEndpoint/UpdateAccountSecurity',
    grpcWeb.MethodType.UNARY,
    profile_pb.UpdateAccountSecurityRequest,
    profile_pb.UpdateAccountSecurityResponse,
    (request: profile_pb.UpdateAccountSecurityRequest) => {
      return request.serializeBinary();
    },
    profile_pb.UpdateAccountSecurityResponse.deserializeBinary
  );

  updateAccountSecurity(
    request: profile_pb.UpdateAccountSecurityRequest,
    metadata: grpcWeb.Metadata | null): Promise<profile_pb.UpdateAccountSecurityResponse>;

  updateAccountSecurity(
    request: profile_pb.UpdateAccountSecurityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: profile_pb.UpdateAccountSecurityResponse) => void): grpcWeb.ClientReadableStream<profile_pb.UpdateAccountSecurityResponse>;

  updateAccountSecurity(
    request: profile_pb.UpdateAccountSecurityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: profile_pb.UpdateAccountSecurityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/managers_profile_endpoint.ProfileEndpoint/UpdateAccountSecurity',
        request,
        metadata || {},
        this.methodDescriptorUpdateAccountSecurity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/managers_profile_endpoint.ProfileEndpoint/UpdateAccountSecurity',
    request,
    metadata || {},
    this.methodDescriptorUpdateAccountSecurity);
  }

  methodDescriptorUpdateUsername = new grpcWeb.MethodDescriptor(
    '/managers_profile_endpoint.ProfileEndpoint/UpdateUsername',
    grpcWeb.MethodType.UNARY,
    profile_pb.UpdateUsernameRequest,
    profile_pb.UpdateUsernameResponse,
    (request: profile_pb.UpdateUsernameRequest) => {
      return request.serializeBinary();
    },
    profile_pb.UpdateUsernameResponse.deserializeBinary
  );

  updateUsername(
    request: profile_pb.UpdateUsernameRequest,
    metadata: grpcWeb.Metadata | null): Promise<profile_pb.UpdateUsernameResponse>;

  updateUsername(
    request: profile_pb.UpdateUsernameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: profile_pb.UpdateUsernameResponse) => void): grpcWeb.ClientReadableStream<profile_pb.UpdateUsernameResponse>;

  updateUsername(
    request: profile_pb.UpdateUsernameRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: profile_pb.UpdateUsernameResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/managers_profile_endpoint.ProfileEndpoint/UpdateUsername',
        request,
        metadata || {},
        this.methodDescriptorUpdateUsername,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/managers_profile_endpoint.ProfileEndpoint/UpdateUsername',
    request,
    metadata || {},
    this.methodDescriptorUpdateUsername);
  }

}

