import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, IStackTokens, CheckboxVisibility } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks"
import { getPage } from "../../../../../app/Pages"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../common/Table/Table"
import { ApiMessage, getHeaders } from "../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../../app/Themes"
import { SearchField } from "../../../../common/SearchField/SearchField"
import { formatDate, timestampToDate } from "../../../../../app/Helpers"
import { TableState } from "../../../../common/Table/TableSate"
import { Message } from "../../../../common/Message/Message"
import { ShareBox } from "../../../../common/ShareBox/ShareBox"
import { GetUserTypesRequest } from "../../../../../repository/UserManagement/Customers/usertype_pb"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool } from "yup"
import { dismissMessage, getPrivileges, reset, setSelectedPrivilege, setNumberOfResults, setSearchText, resetPrivileges, setSort, setIsFilteredSet, add } from "./RolePrivilegesFormSlice"
import { BaseFormProps, InputFormProps } from "../../../FormProps"
import { GetAvailablePrivilegesRequest } from "../../../../../repository/UserManagement/Customers/role_pb"
import { AddBatchRolePrivilegeRequest, AddBatchRolePrivilegeResponse } from "../../../../../repository/UserManagement/Customers/roleprivilege_pb"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

interface IRolePrivilegesFormProp {
    role: number
}

let req: GetAvailablePrivilegesRequest;
let addReq: AddBatchRolePrivilegeRequest;


let org: number = -1;

let getPrivilegesPromise: any;
let actionPromise: any;


export const RolePrivilegesForm: React.FunctionComponent<BaseFormProps & IStackTokens & IRolePrivilegesFormProp> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        message: ApiMessage | undefined, privileges: TableState,
        appTheme: string, isDarkModeEnabled: boolean, searchText: string | undefined,
        filters: any[],
        isLoading: boolean
    } = useAppSelector((state) => {
        return {
            message: state.customersRolePrivilegesForm.message, privileges: state.customersRolePrivilegesForm.privileges,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            searchText: state.customersRolePrivilegesForm.searchText,
            filters: state.customersRolePrivilegesForm.filters,
            isLoading: state.customersRolePrivilegesForm.isLoading
        }
    })
    const ref = useRef<HTMLDivElement>(null);

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedPrivilege(_selection.getSelection()));
            } else {
                dispatch(setSelectedPrivilege([]));
            }
        },

    }));


    const clearFilter = () => {

    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }
        req.setNextto(0)
        dispatch(resetPrivileges())
        dispatch(setIsFilteredSet(isFiltered))
        getPrivilegesPromise?.abort()
        getPrivilegesPromise = dispatch(getPrivileges({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {
        req = new GetAvailablePrivilegesRequest();
        addReq = new AddBatchRolePrivilegeRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                addReq.setOrganization(wrapper)
            }
        }
        req.setNextto(0)
        req.setNumofresults(state.privileges.numberOfResults)
        req.setOrder(state.privileges.isDescending)
        req.setId(props.role)

        getPrivilegesPromise = dispatch(getPrivileges({ body: req, headers: getHeaders() }))
        return () => { //clean up
            getPrivilegesPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])


    const c: IColumn[] = [
        {
            key: 'keyName',
            name: t("id"),
            minWidth: 220,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Text>{item?.keyName}</Text>;

                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'name',
            name: t("service"),
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {


                return <Link onClick={(e) => {
                    if (e) {
                        e.preventDefault();
                        navigate("/customers/privileges/" + item?.id + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/customers/privileges/" + item?.id + ((org == -1) ? "" : "?org=" + org)} > {item.name == "" ? item.id : item?.name}</Link >;

                //return <Link href="/" underline >{item.name}</Link>;
            },
        },


        {
            key: 'createdDate',
            name: t("createdDate"),
            minWidth: 120,
            maxWidth: 200,

            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {


                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },

    ];


    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t('sort'),
            iconProps: { iconName: 'sort' },
            disabled: state.privileges.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t('ascendingDate'),
                    canCheck: true,
                    checked: !state.privileges.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetPrivileges())
                        req.setOrder(false)
                        req.setNextto(0)
                        getPrivilegesPromise = dispatch(getPrivileges({ body: req, headers: getHeaders() }))

                    }

                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t('descendingDate'),
                    canCheck: true,
                    checked: state.privileges.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetPrivileges())
                        req.setOrder(true)
                        req.setNextto(0)
                        getPrivilegesPromise = dispatch(getPrivileges({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.privileges.numberOfResults,
            title: t('results') + ': ' + state.privileges.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.privileges.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.privileges.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.privileges.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                },
                {
                    key: '500',
                    text: '500',
                    title: '500',
                    canCheck: true,
                    checked: (state.privileges.numberOfResults == 500 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(500))
                        req.setNumofresults(500)
                    }

                }]
            }
        }
    ];




    return (
        <Stack >
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={[]}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}

            />

            <Text block><b>{t("addServicesDesc")}</b></Text>

            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {

                            });

                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.privileges.isFilteredSet) {
                                search(undefined);
                            }
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="privilegesAdd"
                items={state.privileges.items}
                columns={c}
                checkboxVisibility={ CheckboxVisibility.always }
                height={"60vh"}
                width={'80vw'}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.privileges.isFetching && state.privileges.hasMore) {
                        req.setNextto(state.privileges.items.at(state.privileges.items.length - 11)?.id)
                        getPrivilegesPromise?.abort();
                        getPrivilegesPromise = dispatch(getPrivileges({ body: req, headers: getHeaders() }))

                    }
                }}
                compRef={ref}
                selection={_selection}
                selectionMode={SelectionMode.multiple}
                disableSelectionZone={state.isLoading}
                enableShimmer={state.privileges.items.length == 0 && state.privileges.isFetching}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                isColumnSortingEnabled={state.privileges.selected.length == 0}
                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.privileges.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.privileges.items.length > 0 && !state.privileges.isFetching && state.privileges.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    req.setNextto(state.privileges.items.at(state.privileges.items.length - 11)?.id)
                                    getPrivilegesPromise?.abort();
                                    getPrivilegesPromise = dispatch(getPrivileges({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />



            <Stack horizontal reversed tokens={{ childrenGap: props.childrenGap }}>
                <PrimaryButton styles={{ root: { width: 120 } }} onClick={() => {
                    let ids: number[] = [];
                    state.privileges.selected.forEach(e => {
                        if (e?.id) {
                            ids.push(e?.id)
                        }
                       
                    })
                    addReq.setPrivilegeidsList(ids);
                    addReq.setRoleid(props.role)
                    actionPromise = dispatch(add({ body: addReq, headers: getHeaders() }))
                    actionPromise.unwrap()
                        .then((res: AddBatchRolePrivilegeResponse.AsObject) => {

                            if (res) {
                                if (props?.onSuccess) {
                                    const selected: any[] = []
                                    state.privileges.selected.forEach(e => {
                                        selected.push({ ...e, createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)) })
                                    })
                                    props?.onSuccess(selected);
                                }
                            }

                        })


                }} disabled={state.isLoading} text={state.isLoading ? undefined : t("add")} >

                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                </PrimaryButton>
                <DefaultButton styles={{ root: { width: 120 } }} disabled={state.isLoading} text={t("cancel")} onClick={() => {
                    if (props.onCancel) {
                        props.onCancel()
                    }
                }} />
            </Stack>
        </Stack>



    );
}




