import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, getSession, refreshSession } from '../../../app/Api';
import { formatDate, timestampToDate } from '../../../app/Helpers';

import { TableState } from '../../common/Table/TableSate';
import { number } from 'yup';
import { BusinessClientEndpointClient } from '../../../repository/UserManagement/Dimensions/BusinessclientServiceClientPb';
import { AddBusinessClientRequest, AddBusinessClientResponse, DeleteBusinessClientRequest, DeleteBusinessClientResponse, UpdateBusinessClientStateRequest, GetBusinessClientsRequest, GetBusinessClientsResponse, UpdateThirdPartyConnectionResponse, UpdateThirdPartyConnectionRequest, UpdateBusinessClientActivityResponse, } from '../../../repository/UserManagement/Dimensions/businessclient_pb';
import { GetUsersRequest, GetUsersResponse } from '../../../repository/UserManagement/Customers/user_pb';
import { BusinessEndpointClient } from '../../../repository/UserManagement/Dimensions/BusinessServiceClientPb';
import { UserEndpointClient } from '../../../repository/UserManagement/Customers/UserServiceClientPb';
import { GetBusinessesRequest, GetBusinessesResponse } from '../../../repository/UserManagement/Dimensions/business_pb';


export interface BusinessClientsPageState {
    items: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[]
    clients: TableState
    businesses: TableState
    isActiveFilterOption: { key: number, text: string },
    connectionMetaDataFilterOption: { value: string, text: string },

}

const initialState: BusinessClientsPageState = {
    items: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: [],
    isActiveFilterOption: { key: 0, text: "" },
    connectionMetaDataFilterOption: { text: "", value: "" },
    clients: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    businesses: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}

const businessClient = new BusinessClientEndpointClient(API_URL, null, null);
const clienCustomers = new UserEndpointClient(API_URL, null, null);
const clientBus = new BusinessEndpointClient(API_URL, null, null);

export const getItems = createAsyncThunk<GetBusinessClientsResponse.AsObject, APIRequest<GetBusinessClientsRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/businessClients/getBusinessClients',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await businessClient.getBusinessClients(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const updateActivity = createAsyncThunk<APIResponse<UpdateBusinessClientActivityResponse.AsObject>, APIRequest<UpdateBusinessClientStateRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/businessClients/updateActivity',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await businessClient.updateActivity(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), state: req.body.getIsactive() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const updateThirdPartyConnection = createAsyncThunk<APIResponse<UpdateThirdPartyConnectionResponse.AsObject>, APIRequest<UpdateThirdPartyConnectionRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/businessClients/updateThirdPartyConnection',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await businessClient.updateThirdPartyConnection(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), connectionId: req.body.getThirdpartyclientusername(), connectionMetadata: req.metadata?.connectionMetadata }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const deleteItem = createAsyncThunk<APIResponse<DeleteBusinessClientResponse.AsObject>, APIRequest<DeleteBusinessClientRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/businessClients/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await businessClient.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const getClinetUsers = createAsyncThunk<GetUsersResponse.AsObject, APIRequest<GetUsersRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/businessClients/clients/fetch',
    async (req, thunkApi) => {

        let callReq = async (): Promise<any> => {
            try {
                var r = await clienCustomers.getUsers(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getBusinesses = createAsyncThunk<GetBusinessesResponse.AsObject, APIRequest<GetBusinessesRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/businessClients/businesses/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientBus.getBusinesses(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)




const getFilters = (state: BusinessClientsPageState): any[] => {

    const selected = [];
    if (state.isActiveFilterOption.key != 0) {
        selected.push({ key: 1, name: state.isActiveFilterOption.text });
    }

    if (state.businesses.selected.length > 0) {
        selected.push({ key: 2, name: state.businesses.selected.at(0).name });
    }
    if (state.clients.selected.length > 0) {
        selected.push({ key: 3, name: state.clients.selected.at(0).name });
    }
    if (state.connectionMetaDataFilterOption.value && state.connectionMetaDataFilterOption.value != "") {
        selected.push({ key: 4, name: state.connectionMetaDataFilterOption.text });

    }

    return selected;

}
export const businessClientsPageSlice = createSlice({
    name: 'pages/businessClients',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.items = initialState.items;
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.isActiveFilterOption = initialState.isActiveFilterOption;
            state.businesses = initialState.businesses;
            state.connectionMetaDataFilterOption = initialState.connectionMetaDataFilterOption;
            state.clients = initialState.clients;

        },
        resetItems: (state) => {
            state.items.items = [];
            state.items.hasMore = true;
        },
        resetClients: (state) => {
            state.clients.items = [];
            state.clients.hasMore = true;
        },
        resetBusinesses: (state) => {
            state.businesses.items = [];
            state.businesses.hasMore = true;
        },
        setSort: (state, action: PayloadAction<boolean>) => {
            state.items.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.items.numberOfResults = action.payload;
        },
        setSelectedItems: (state, action: PayloadAction<any[]>) => {
            state.items.selected = action.payload;
        },
        setSelectedClientItems: (state, action: PayloadAction<any[]>) => {
            state.clients.selected = action.payload;
            state.filters = getFilters(state);

        },
        setSelectedBusinessItems: (state, action: PayloadAction<any[]>) => {
            state.businesses.selected = action.payload;
            state.filters = getFilters(state);
        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.items.isFilteredSet = action.payload;
        },
        setIsFilteredClientsSet: (state, action: PayloadAction<boolean>) => {
            state.clients.isFilteredSet = action.payload;
        },
        setIsFilteredBusinessesSet: (state, action: PayloadAction<boolean>) => {
            state.businesses.isFilteredSet = action.payload;
        },

        setActivityFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.isActiveFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setConnectionMetaDataFilterOption: (state, action: PayloadAction<{ value: string, text: string }>) => {
            state.connectionMetaDataFilterOption = action.payload;
            state.filters = getFilters(state);
        },

        addItem: (state, action: PayloadAction<any>) => {
            if (!state.items.isFilteredSet) {
                if (!state.items.hasMore && !state.items.isDescending) {
                    state.items.items.push(action.payload);
                } else if (state.items.isDescending) {
                    state.items.items.unshift(action.payload);
                }
            }
        },
        updateItem: (state, action: PayloadAction<any>) => {
            let selected = state.items.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.items.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.items.items[u] = action?.payload
                }
            }

        },
        updateConnection: (state, action: PayloadAction<any>) => {
            let selected = state.items.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.items.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.items.items[u].connectionId = action?.payload?.connectionId
                    state.items.items[u].connectionMetaData = action?.payload?.connectionMetaData

                }
            }

        }
    },
    extraReducers: (builder) => {
        builder.addCase(getClinetUsers.fulfilled, (state, { payload }) => {
            state.clients.isFetching = false
            if (payload) {
                var r = (payload?.success?.usersList.map(val => {
                    return {
                        internalId: val.profile?.internalid,
                        name: (val.profile?.firstname?.value ?? "") + " " + (val.profile?.lastname?.value ?? ""),
                        firstname: (val.profile?.firstname?.value ?? ""),
                        lastname: (val.profile?.lastname?.value ?? ""),
                        username: val.username?.value ?? "",
                        isActive: val.isactive,
                        isConfirmed: val.isconfirmed,
                        createdDate: formatDate(timestampToDate(val.profile?.createddate?.seconds, val.profile?.createddate?.nanos)),
                        addedBy: (val.profile?.addedby?.firstname?.value ?? "") + " " + (val.profile?.addedby?.lastname?.value ?? ""),
                        addedById: val.profile?.addedby?.id?.value,
                        role: val.profile?.role?.name?.value,
                        roleId: val.profile?.role?.id,
                        usertypeId: val.profile?.usertype?.id,
                        usertype: val.profile?.usertype?.name?.value,
                        lastlogin: formatDate(timestampToDate(val.lastlogin?.seconds, val.lastlogin?.nanos)),
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.clients.numberOfResults) {
                    state.clients.hasMore = false;
                }
                state.clients.items = state.clients.items.concat(r);
                //console.log(state.items)

            } else {
                state.clients.hasMore = false;
            }
        })
        builder.addCase(getClinetUsers.rejected, (state, action) => {
            state.clients.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getClinetUsers.pending, (state, action) => {
            state.clients.isFetching = true;
            // state.message = undefined;
        })

        builder.addCase(getBusinesses.fulfilled, (state, { payload }) => {
            state.businesses.isFetching = false
            if (payload) {
                var r = (payload?.success?.businessesList.map(val => {
                    return {
                        id: val.id,
                        name: (val.name?.value ?? ""),
                        connectionId: val.connection?.id?.value ?? "",
                        connectionMetaData: val.connection?.metadata?.value ?? "",
                        isActive: val.isactive?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        createdBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        createdById: val.createdby?.id?.value,
                        isAvailable: val.isavailable?.value,
                        location: val.locationList,
                        addressCity: val.address?.city,
                        addressCountry: val.address ? { key: val.address?.countryiso, text: '' } : { key: 'LY', text: 'Libya' },
                        logoId: val.logo?.id?.value,
                        logoName: val.logo?.name?.value,
                        logoPath: val.logo?.path?.value,
                        logoSource: val.logo?.source?.value,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.businesses.numberOfResults) {
                    state.businesses.hasMore = false;
                }
                state.businesses.items = state.businesses.items.concat(r);
                //console.log(state.items)

            } else {
                state.businesses.hasMore = false;
            }
        })
        builder.addCase(getBusinesses.rejected, (state, action) => {
            state.businesses.isFetching = false;
            if (action.payload) {
            }
        })
        builder.addCase(getBusinesses.pending, (state, action) => {
            state.businesses.isFetching = true;
        })



        builder.addCase(deleteItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.items.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        state.items.selected = []
                    }
                    var u = state.items.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.items.items.splice(u, 1);
                    }
                }
            }
        })
        builder.addCase(deleteItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            state.items.isFetching = false
            if (payload) {
                var r = (payload?.success?.businessclientsList.map(val => {
                    return {
                        id: val.id,
                        businessId: (val.business?.id?.value),
                        businessName: (val.business?.name?.value ?? ""),
                        connectionId: val.connection?.id?.value ?? "",
                        connectionOrganization: val.connection?.organization?.value ?? "",
                        connectionMetaData: val.connection?.metadata?.value ?? "",
                        isActive: val.isactive?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        createdBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        createdById: val.createdby?.id?.value,
                        clientId: val.client?.id?.value,
                        clientFirstName: val.client?.firstname?.value,
                        clientLastName: val.client?.lastname?.value,
                        clientMiddleName: val.client?.middlename?.value,
                        clientName: (val.client?.firstname?.value ?? "") + " " + (val.client?.lastname?.value ?? ""),




                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.items.items.length != 0) {
                    l = state.items.items.slice(0, state.items.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.items.numberOfResults) {
                    state.items.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.items.items = l
                //console.log(state.items)

            } else {
                state.items.hasMore = false;
            }
        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.items.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.items.isFetching = true;
            state.message = undefined;
        })


        builder.addCase(updateActivity.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.items.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        selected.at(0).isActive = payload?.metadata.state
                    }
                    var u = state.items.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.items.items.at(u).isActive = payload?.metadata.state
                    }

                }
            }
        })
        builder.addCase(updateActivity.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateActivity.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


        builder.addCase(updateThirdPartyConnection.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.items.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        selected.at(0).connectionId = payload?.metadata.connectionId
                        selected.at(0).connectionMetaData = payload?.metadata.connectionMetadata

                    }
                    var u = state.items.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.items.items.at(u).connectionId = payload?.metadata.connectionId
                        state.items.items.at(u).connectionMetaData = payload?.metadata.connectionMetadata

                    }

                }
            }
        })
        builder.addCase(updateThirdPartyConnection.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateThirdPartyConnection.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })




    }
})

export const { reset, dismissMessage, updateConnection, setSort, setNumberOfResults, resetItems, setSelectedItems, setSearchText, setIsFilteredSet, addItem, setActivityFilterOption, updateItem, resetBusinesses, resetClients, setConnectionMetaDataFilterOption, setIsFilteredBusinessesSet, setIsFilteredClientsSet, setSelectedBusinessItems, setSelectedClientItems } = businessClientsPageSlice.actions

export default businessClientsPageSlice.reducer


