

import { DocumentCard, DocumentCardType, FocusTrapZone, FontIcon, getTheme, IconButton, ImageIcon, IModalStyleProps, IModalStyles, IPersonaProps, IStyleFunctionOrObject, ITextField, ITextFieldProps, ITooltipHostStyles, Label, Link, mergeStyles, Modal, Persona, PrimaryButton, Stack, Text, TextField, TooltipHost } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { t, use } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../app/Hooks";
import { getMainTheme, ThemeManager } from "../../../app/Themes";
import { ItemView } from "./ItemView";

interface IProfileViewProps {
    label: string,
    value: string,
    url: string,
    iconName?: string,
    onLinkClick?: (e: any) => void
}

export const ProfileView: React.FunctionComponent<IProfileViewProps & IPersonaProps> = (props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    // t("copy") onClick={() => { navigator.clipboard.writeText(props.value) }} 
    if ((props.value == undefined || props.value == "")) {
        return (null)
    }
    return (
        <ItemView onClick={() => { navigator.clipboard.writeText(props.value) }} label={props.label} iconProps={{ iconName: "Copy" }}
            hintIconName={props.iconName} endButtonHint={t("copy")} title={props.label + ": " + props.value}>

            <Link href={props.url ?? "#"} target="_blank" onClick={(e) => {
                if (props.onLinkClick) {
                    props.onLinkClick(e);
                    window.history.replaceState(null, "", props.url)
                } else {
                    if (e) {
                        e.preventDefault();
                        navigate(props.url);
                        //   window.history.replaceState(null, "", props.url)
                    }
                }

            }}><Persona
                    {...props}

                />
            </Link>
        </ItemView >


    );




}

