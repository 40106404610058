/**
 * @fileoverview gRPC-Web generated client stub for organization_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as organization_pb from './organization_pb';


export class OrganizationEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    organization_pb.AddOrganizationRequest,
    organization_pb.AddOrganizationResponse,
    (request: organization_pb.AddOrganizationRequest) => {
      return request.serializeBinary();
    },
    organization_pb.AddOrganizationResponse.deserializeBinary
  );

  add(
    request: organization_pb.AddOrganizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.AddOrganizationResponse>;

  add(
    request: organization_pb.AddOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.AddOrganizationResponse) => void): grpcWeb.ClientReadableStream<organization_pb.AddOrganizationResponse>;

  add(
    request: organization_pb.AddOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.AddOrganizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    organization_pb.UpdateOrganizationRequest,
    organization_pb.UpdateOrganizationResponse,
    (request: organization_pb.UpdateOrganizationRequest) => {
      return request.serializeBinary();
    },
    organization_pb.UpdateOrganizationResponse.deserializeBinary
  );

  update(
    request: organization_pb.UpdateOrganizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.UpdateOrganizationResponse>;

  update(
    request: organization_pb.UpdateOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.UpdateOrganizationResponse) => void): grpcWeb.ClientReadableStream<organization_pb.UpdateOrganizationResponse>;

  update(
    request: organization_pb.UpdateOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.UpdateOrganizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdateActivity = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/UpdateActivity',
    grpcWeb.MethodType.UNARY,
    organization_pb.UpdateOrganizationStateRequest,
    organization_pb.UpdateOrganizationActivityResponse,
    (request: organization_pb.UpdateOrganizationStateRequest) => {
      return request.serializeBinary();
    },
    organization_pb.UpdateOrganizationActivityResponse.deserializeBinary
  );

  updateActivity(
    request: organization_pb.UpdateOrganizationStateRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.UpdateOrganizationActivityResponse>;

  updateActivity(
    request: organization_pb.UpdateOrganizationStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.UpdateOrganizationActivityResponse) => void): grpcWeb.ClientReadableStream<organization_pb.UpdateOrganizationActivityResponse>;

  updateActivity(
    request: organization_pb.UpdateOrganizationStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.UpdateOrganizationActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/UpdateActivity',
        request,
        metadata || {},
        this.methodDescriptorUpdateActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/UpdateActivity',
    request,
    metadata || {},
    this.methodDescriptorUpdateActivity);
  }

  methodDescriptorGetOrganizations = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/GetOrganizations',
    grpcWeb.MethodType.UNARY,
    organization_pb.GetOrganizationsRequest,
    organization_pb.GetOrganizationsResponse,
    (request: organization_pb.GetOrganizationsRequest) => {
      return request.serializeBinary();
    },
    organization_pb.GetOrganizationsResponse.deserializeBinary
  );

  getOrganizations(
    request: organization_pb.GetOrganizationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.GetOrganizationsResponse>;

  getOrganizations(
    request: organization_pb.GetOrganizationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.GetOrganizationsResponse) => void): grpcWeb.ClientReadableStream<organization_pb.GetOrganizationsResponse>;

  getOrganizations(
    request: organization_pb.GetOrganizationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.GetOrganizationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/GetOrganizations',
        request,
        metadata || {},
        this.methodDescriptorGetOrganizations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/GetOrganizations',
    request,
    metadata || {},
    this.methodDescriptorGetOrganizations);
  }

  methodDescriptorGetOrganization = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/GetOrganization',
    grpcWeb.MethodType.UNARY,
    organization_pb.GetOrganizationRequest,
    organization_pb.GetOrganizationResponse,
    (request: organization_pb.GetOrganizationRequest) => {
      return request.serializeBinary();
    },
    organization_pb.GetOrganizationResponse.deserializeBinary
  );

  getOrganization(
    request: organization_pb.GetOrganizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.GetOrganizationResponse>;

  getOrganization(
    request: organization_pb.GetOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.GetOrganizationResponse) => void): grpcWeb.ClientReadableStream<organization_pb.GetOrganizationResponse>;

  getOrganization(
    request: organization_pb.GetOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.GetOrganizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/GetOrganization',
        request,
        metadata || {},
        this.methodDescriptorGetOrganization,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/GetOrganization',
    request,
    metadata || {},
    this.methodDescriptorGetOrganization);
  }

  methodDescriptorUploadLogo = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/UploadLogo',
    grpcWeb.MethodType.UNARY,
    organization_pb.UploadLogoRequest,
    organization_pb.UploadLogoResponse,
    (request: organization_pb.UploadLogoRequest) => {
      return request.serializeBinary();
    },
    organization_pb.UploadLogoResponse.deserializeBinary
  );

  uploadLogo(
    request: organization_pb.UploadLogoRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.UploadLogoResponse>;

  uploadLogo(
    request: organization_pb.UploadLogoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.UploadLogoResponse) => void): grpcWeb.ClientReadableStream<organization_pb.UploadLogoResponse>;

  uploadLogo(
    request: organization_pb.UploadLogoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.UploadLogoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/UploadLogo',
        request,
        metadata || {},
        this.methodDescriptorUploadLogo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/UploadLogo',
    request,
    metadata || {},
    this.methodDescriptorUploadLogo);
  }

}

