import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession, APIResponse } from '../../../app/Api';
import { formatDate, timestampToDate } from '../../../app/Helpers';
import { ProfileEndpointClient } from '../../../repository/UserManagement/Managers/ProfileServiceClientPb';
import { UpdateProfileRequest, UpdateProfileResponse } from '../../../repository/UserManagement/Managers/profile_pb';
import { RoleEndpointClient } from '../../../repository/UserManagement/Managers/RoleServiceClientPb';
import { GetRolesRequest, GetRolesResponse } from '../../../repository/UserManagement/Managers/role_pb';
import { UserEndpointClient } from '../../../repository/UserManagement/Managers/UserServiceClientPb';
import { UserTypeEndpointClient } from '../../../repository/UserManagement/Managers/UsertypeServiceClientPb';
import { GetUserTypesRequest, GetUserTypesResponse } from '../../../repository/UserManagement/Managers/usertype_pb';
import { AddUserRequest, AddUserResponse, GetUserRequest, GetUserResponse, UpdateUserRequest, UpdateUserResponse } from '../../../repository/UserManagement/Managers/user_pb';
import { OrganizationEndpointClient } from '../../../repository/UserManagement/OrganizationServiceClientPb';
import { GetOrganizationRequest, GetOrganizationResponse } from '../../../repository/UserManagement/organization_pb';
import { DynamicField } from '../../common/DynamicFields/DynamicFields';
import { TableState } from '../../common/Table/TableSate';

export interface ProfileFormState {
  isLoading: boolean
  message?: ApiMessage
  stage1: any,
  stage2: any,

}

const initialState: ProfileFormState = {
  isLoading: false,
  message: undefined,
  stage1: undefined,
  stage2: undefined,
}

const client = new ProfileEndpointClient(API_URL, null, null);


export const update = createAsyncThunk<UpdateProfileResponse.AsObject, APIRequest<UpdateProfileRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/profile/update',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.update(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)


export const profileFormSlice = createSlice({
  name: 'forms/profile',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;

    },
    dismissMessage: (state) => {
      state.message = undefined;
    },
    reset: (state) => {
      state.isLoading = false;
      state.message = undefined;
      state.stage1 = initialState.stage1;
      state.stage2 = initialState.stage2;

    },
    setStage1: (state, action: PayloadAction<any>) => {
      let stage1 = {
        internalId: action.payload?.internalId ,
        firstname: action.payload?.firstname ? action.payload?.firstname.trim() : "",
        lastname: action.payload?.lastname ? action.payload?.lastname?.trim() : "",
        phoneNumbers: action.payload?.phoneNumbers,
        emailAddresses: action.payload?.emailAddresses,
        addressCountry: action.payload?.addressCountry,
        addressCity: action.payload?.addressCity,
        addressFirstLine: action.payload?.addressFirstLine,
        addressSecondLine: action.payload?.addressSecondLine,
        addressPostalCode: action.payload?.addressPostalCode,
        isConfirmed: action.payload?.isConfirmed,
        createdDate : action.payload?.createdDate,
        addedBy: action.payload?.addedBy,
        addedById: action.payload?.addedById,
        lastlogin: action.payload?.lastlogin,
      }
      state.stage1 = stage1;

    },
    setStage2: (state, action: PayloadAction<any>) => {
      let stage2 = {
        username: action.payload?.username,
        isActive: action.payload?.isActive,
        primaryEmail: action.payload?.primaryEmail,
        primaryPhoneNumber: action.payload?.primaryPhoneNumber,
        usertype: action.payload?.usertype,
        usertypeId: action.payload?.usertypeId,
        role: action.payload?.role,
        roleId: action.payload?.roleId,
        extraFields: action.payload?.extraFields,
      }
      state.stage2 = stage2
    },
  },
  extraReducers: (builder) => {


    builder.addCase(update.fulfilled, (state, { payload }) => {
      if (payload)
        state.message = toApiMessage(payload.success?.message);
      state.isLoading = false;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(update.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
  
  }
})

export const { setStage1, setStage2, reset, dismissMessage, setIsLoading } = profileFormSlice.actions

export default profileFormSlice.reducer


