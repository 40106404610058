import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession, APIResponse } from '../../../app/Api';
import { formatDate, timestampToDate } from '../../../app/Helpers';
import { DynamicField } from '../../common/DynamicFields/DynamicFields';
import { BusinessClientEndpointClient } from '../../../repository/UserManagement/Dimensions/BusinessclientServiceClientPb';
import { AddBusinessClientRequest, AddBusinessClientResponse, GetBusinessClientResponse, UpdateThirdPartyConnectionResponse, UpdateThirdPartyConnectionRequest, GetBusinessClientRequest } from '../../../repository/UserManagement/Dimensions/businessclient_pb';
import { GetUsersRequest, GetUsersResponse } from '../../../repository/UserManagement/Customers/user_pb';
import { BusinessEndpointClient } from '../../../repository/UserManagement/Dimensions/BusinessServiceClientPb';
import { UserEndpointClient } from '../../../repository/UserManagement/Customers/UserServiceClientPb';
import { GetBusinessesRequest, GetBusinessesResponse } from '../../../repository/UserManagement/Dimensions/business_pb';
import { TableState } from '../../common/Table/TableSate';

export interface BusinessClientsFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    clients: TableState
    businesses: TableState
}

const initialState: BusinessClientsFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    clients: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    businesses: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}

const businessClient = new BusinessClientEndpointClient(API_URL, null, null);
const clienCustomers = new UserEndpointClient(API_URL, null, null);
const clientBus = new BusinessEndpointClient(API_URL, null, null);


export const getClinetUsers = createAsyncThunk<GetUsersResponse.AsObject, APIRequest<GetUsersRequest>, {
    rejectValue: ApiMessage
}>(
    'forms/businessClients/clients/fetch',
    async (req, thunkApi) => {

        let callReq = async (): Promise<any> => {
            try {
                var r = await clienCustomers.getUsers(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getBusinesses = createAsyncThunk<GetBusinessesResponse.AsObject, APIRequest<GetBusinessesRequest>, {
    rejectValue: ApiMessage
}>(
    'forms/businessClients/businesses/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientBus.getBusinesses(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const updateThirdParty = createAsyncThunk<UpdateThirdPartyConnectionResponse.AsObject, APIRequest<UpdateThirdPartyConnectionRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/businessClients/updateThirdPartyConnection',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await businessClient.updateThirdPartyConnection(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const add = createAsyncThunk<AddBusinessClientResponse.AsObject, APIRequest<AddBusinessClientRequest>, {
    rejectValue: ApiMessage
}>(
    'forms/businessClients/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await businessClient.add(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const getBusinessClient = createAsyncThunk<APIResponse<GetBusinessClientResponse.AsObject>, APIRequest<GetBusinessClientRequest>, {
    rejectValue: ApiMessage
}>(
    'forms/businessClients/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await businessClient.getBusinessClient(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const businessClientFormSlice = createSlice({
    name: 'forms/businessClients',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.clients = initialState.clients;
            state.businesses = initialState.businesses;
        },
        resetClients: (state) => {
            state.clients.items = [];
            state.clients.hasMore = true;
        },
        resetBusinesses: (state) => {
            state.businesses.items = [];
            state.businesses.hasMore = true;
        },

        setSelectedClientItems: (state, action: PayloadAction<any[]>) => {
            state.clients.selected = action.payload;

        },
        setSelectedBusinessItems: (state, action: PayloadAction<any[]>) => {
            state.businesses.selected = action.payload;
        },


        setIsFilteredClientsSet: (state, action: PayloadAction<boolean>) => {
            state.clients.isFilteredSet = action.payload;
        },
        setIsFilteredBusinessesSet: (state, action: PayloadAction<boolean>) => {
            state.businesses.isFilteredSet = action.payload;
        },

        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
                id: action.payload?.id,
                businessId: action.payload?.businessId,
                businessName: action.payload?.businessName,
                createdBy: action.payload?.createdBy,
                createdById: action.payload?.createdById,
                isActive: action.payload?.isActive,
                connectionId: action.payload?.connectionId,
                connectionMetaData: action.payload?.connectionMetaData,
                clientId: action.payload?.clientId,
                clientFirstName: action.payload?.clientFirstName,
                clientLastName: action.payload?.clientLastName,
                clientMiddleName: action.payload?.clientMiddleName,
            }
            state.stage1 = stage1;
        },



    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
        builder.addCase(getClinetUsers.fulfilled, (state, { payload }) => {
            state.clients.isFetching = false
            if (payload) {
                var r = (payload?.success?.usersList.map(val => {
                    return {
                        internalId: val.profile?.internalid,
                        name: (val.profile?.firstname?.value ?? "") + " " + (val.profile?.lastname?.value ?? ""),
                        firstname: (val.profile?.firstname?.value ?? ""),
                        lastname: (val.profile?.lastname?.value ?? ""),
                        username: val.username?.value ?? "",
                        isActive: val.isactive,
                        isConfirmed: val.isconfirmed,
                        createdDate: formatDate(timestampToDate(val.profile?.createddate?.seconds, val.profile?.createddate?.nanos)),
                        addedBy: (val.profile?.addedby?.firstname?.value ?? "") + " " + (val.profile?.addedby?.lastname?.value ?? ""),
                        addedById: val.profile?.addedby?.id?.value,
                        role: val.profile?.role?.name?.value,
                        roleId: val.profile?.role?.id,
                        usertypeId: val.profile?.usertype?.id,
                        usertype: val.profile?.usertype?.name?.value,
                        lastlogin: formatDate(timestampToDate(val.lastlogin?.seconds, val.lastlogin?.nanos)),
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.clients.numberOfResults) {
                    state.clients.hasMore = false;
                }
                state.clients.items = state.clients.items.concat(r);
                //console.log(state.items)

            } else {
                state.clients.hasMore = false;
            }
        })
        builder.addCase(getClinetUsers.rejected, (state, action) => {
            state.clients.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getClinetUsers.pending, (state, action) => {
            state.clients.isFetching = true;
            // state.message = undefined;
        })

        builder.addCase(getBusinesses.fulfilled, (state, { payload }) => {
            state.businesses.isFetching = false
            if (payload) {
                var r = (payload?.success?.businessesList.map(val => {
                    return {
                        id: val.id,
                        name: (val.name?.value ?? ""),
                        connectionId: val.connection?.id?.value ?? "",
                        connectionMetaData: val.connection?.metadata?.value ?? "",
                        isActive: val.isactive?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        createdBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        createdById: val.createdby?.id?.value,
                        isAvailable: val.isavailable?.value,
                        location: val.locationList,
                        addressCity: val.address?.city,
                        addressCountry: val.address ? { key: val.address?.countryiso, text: '' } : { key: 'LY', text: 'Libya' },
                        logoId: val.logo?.id?.value,
                        logoName: val.logo?.name?.value,
                        logoPath: val.logo?.path?.value,
                        logoSource: val.logo?.source?.value,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));

                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.businesses.numberOfResults) {
                    state.businesses.hasMore = false;
                }
                state.businesses.items = state.businesses.items.concat(r);
                //console.log(state.items)

            } else {
                state.businesses.hasMore = false;
            }
        })
        builder.addCase(getBusinesses.rejected, (state, action) => {
            state.businesses.isFetching = false;
            if (action.payload) {
            }
        })
        builder.addCase(getBusinesses.pending, (state, action) => {
            state.businesses.isFetching = true;
        })


        builder.addCase(updateThirdParty.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(updateThirdParty.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })


        builder.addCase(updateThirdParty.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })


    }
})

export const { reset, dismissMessage, setStage1, setIsFilteredBusinessesSet, setIsFilteredClientsSet, setSelectedBusinessItems, resetBusinesses, resetClients, setSelectedClientItems } = businessClientFormSlice.actions

export default businessClientFormSlice.reducer


