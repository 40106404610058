import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../repository/UserManagement/Managers/UserServiceClientPb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../app/Api';
import { formatDate, phoneNumberFormatter, timestampToDate } from '../../../app/Helpers';

import { TableState } from '../../common/Table/TableSate';
import { DynamicField } from '../../common/DynamicFields/DynamicFields';
import { ProfileEndpointClient } from '../../../repository/UserManagement/Managers/ProfileServiceClientPb';
import { GetProfileResponse, GetProfileRequest } from '../../../repository/UserManagement/Managers/profile_pb';


export interface ProfilePageState {
    item: any
    isChangeStateLoading: boolean,
    isLoading: boolean
    message?: ApiMessage,
}

const initialState: ProfilePageState = {
    isChangeStateLoading: false,
    message: undefined,
    item: undefined,
    isLoading: false
}

const client = new ProfileEndpointClient(API_URL, null, null);

export const getProfile = createAsyncThunk<GetProfileResponse.AsObject, APIRequest<GetProfileRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/profile/getProfile',
    async (req, thunkApi) => {

        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getProfile(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const profilePageSlice = createSlice({
    name: 'pages/profile',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.item = initialState.item;
            state.message = initialState.message;
            state.isLoading = initialState.isLoading;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },
        resetManager: (state) => {
            state.item = initialState.item;
        },

        updateUser: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }

    },
    extraReducers: (builder) => {
        builder.addCase(getProfile.fulfilled, (state, { payload }) => {
          
            if (payload) {
                let stage1 = {
                    internalId: payload?.success?.profile?.internalid ,
                    firstname: payload?.success?.profile?.firstname ? payload?.success?.profile?.firstname?.value?.trim() : "",
                    lastname: payload?.success?.profile?.lastname ? payload?.success?.profile?.lastname?.value?.trim() : "",
                    phoneNumbers: [] as DynamicField[],
                    emailAddresses: [] as DynamicField[],
                    addressCountry: payload?.success?.profile?.address ? { key: payload?.success?.profile?.address?.countryiso?.value, text: '' } : { key: 'LY', text: 'Libya' },
                    addressCity: payload?.success?.profile?.address ? payload?.success?.profile?.address?.city?.value : "",
                    addressFirstLine: payload?.success?.profile?.address ? payload?.success?.profile?.address?.firstline?.value : "",
                    addressSecondLine: payload?.success?.profile?.address ? payload?.success?.profile?.address?.secondline?.value : "",
                    addressPostalCode: payload?.success?.profile?.address ? payload?.success?.profile?.address?.postalcode?.value : "",
                    isConfirmed: payload?.success?.isconfirmed?.value,
                    createdDate : payload?.success?.profile?.createddate? formatDate(timestampToDate(payload?.success?.profile?.createddate?.seconds, payload?.success?.profile?.createddate?.nanos))  : "",
                    addedBy: payload?.success?.profile?.addedby ? (payload?.success?.profile?.addedby?.firstname?.value ?? "") + " " + (payload?.success?.profile?.addedby?.lastname?.value ?? "") : "",
                    addedById: payload?.success?.profile?.addedby?.id?.value,
                    lastlogin: payload?.success?.lastlogin ? formatDate(timestampToDate(payload?.success?.lastlogin?.seconds, payload?.success?.lastlogin?.nanos)) : "",
                    username: payload?.success?.username ? payload?.success?.username?.value?.trim() : "",
                    usertype: payload?.success?.profile?.usertype ? payload?.success?.profile?.usertype.name?.value : "",
                    usertypeId: payload?.success?.profile?.usertype?.id,
                    role: payload?.success?.profile?.role ? payload?.success?.profile?.role.name?.value : "",
                    roleId: payload?.success?.profile?.role?.id,
                    isActive: payload?.success?.isactive?.value,
                    primaryEmail: (payload?.success?.accountemail) ? payload?.success?.accountemail?.value : '',
                    primaryPhoneNumber: (payload.success?.accountphonenumber) ? "+"+phoneNumberFormatter(payload.success?.accountphonenumber?.value) : '',

                    extraFields: [] as DynamicField[]
                }
          
                  if (payload?.success?.profile?.phonenumbersList) {
                    let count = 0
                    payload?.success?.profile?.phonenumbersList?.forEach(element => {
                      stage1.phoneNumbers.push({ key: count + "", label: undefined, type: 1, value: element.value })
                      count++
                    });
                  }
          
                  if (payload?.success?.profile?.emailaddressesList) {
                    let count = 0
                    payload?.success?.profile?.emailaddressesList?.forEach(element => {
                      stage1.emailAddresses.push({ key: count + "", label: undefined, type: 1, value: element.value })
                      count++
                    });
                  }
                
          
                  
                  if (payload?.success?.profile?.extrafieldsList) {
                    let count = 0
                    payload?.success?.profile?.extrafieldsList?.forEach(element => {
                      stage1.extraFields.push({ key: element.key , label: element.label, type: element.type, value: element.value })
                      count++
                    });
                  }
               
                  state.item = stage1
                //console.log(state.items)
            }
            state.isLoading = false
        })
        builder.addCase(getProfile.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getProfile.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })


 
    }
})

export const { reset, dismissMessage, resetManager, updateUser } = profilePageSlice.actions

export default profilePageSlice.reducer


