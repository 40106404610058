import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession, } from '../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { BusinessEndpointClient } from '../../../../repository/UserManagement/Dimensions/BusinessServiceClientPb';
import { UpdateBusinessActivityRequest, GetBusinessesRequest, GetBusinessesResponse, UpdateBusinessActivityResponse, UpdateThirdPartyConnectionResponse, UpdateThirdPartyConnectionRequest, GetBusinessResponse, GetBusinessRequest } from '../../../../repository/UserManagement/Dimensions/business_pb';
import { DynamicField } from '../../../common/DynamicFields/DynamicFields';


export interface BusinessDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: BusinessDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const businessClient = new BusinessEndpointClient(API_URL, null, null);

export const get = createAsyncThunk<GetBusinessResponse.AsObject, APIRequest<GetBusinessRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/businesses/businessDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await businessClient.getBusiness(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const updateActivity = createAsyncThunk<APIResponse<UpdateBusinessActivityResponse.AsObject>, APIRequest<UpdateBusinessActivityRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/businesses/businessDetails/updateActivity',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await businessClient.updateActivity(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), state: req.body.getIsactive() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const businessDetailsPageSlice = createSlice({
    name: 'pages/businesses/businessDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        },

        updateConnection: (state, action: PayloadAction<any>) => {
            state.item.connectionId = action?.payload?.connectionId
            state.item.connectionMetaData = action?.payload?.connectionMetaData

        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateActivity.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.isActive = payload.metadata?.state;
            }
        })
        builder.addCase(updateActivity.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateActivity.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload.success?.id,
                    name: (payload.success?.name?.value ?? ""),
                    connectionId: payload.success?.connection?.id?.value ?? "",
                    connectionMetaData: payload.success?.connection?.metadata?.value ?? "",
                    description: payload.success?.description?.value ?? "",
                    isActive: payload.success?.isactive?.value,
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    createdBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    createdById: payload.success?.createdby?.id?.value,
                    isAvailable: payload.success?.isavailable?.value,
                    location: payload.success?.locationList,
                    addressCity: payload.success?.address?.city?.value,
                    addressCountry: payload?.success?.address ? { key: payload?.success?.address?.countryiso?.value, text: '' } : { key: 'LY', text: 'Libya' },
                    logoId: payload.success?.logo?.id?.value,
                    logoName: payload.success?.logo?.name?.value,
                    logoPath: payload.success?.logo?.path?.value,
                    logoSource: payload.success?.logo?.source?.value,
                    extraFields: [] as DynamicField[],
                    phoneNumbers: [] as DynamicField[],
                    urls: [] as DynamicField[],
                    long: payload.success?.locationList?.length == 2 ? payload?.success?.locationList[0] : "",
                    lat: payload.success?.locationList?.length == 2 ? payload?.success?.locationList[1] : "",
                    addressFirstLine: payload.success?.address ? payload?.success?.address?.firstline?.value : "",
                    addressSecondLine: payload.success?.address ? payload?.success?.address?.secondline?.value : "",
                    addressPostalCode: payload.success?.address ? payload.success?.address?.postalcode?.value : "",


                }

                if (payload.success?.phonenumbersList) {
                    let count = 0
                    payload.success?.phonenumbersList?.forEach(element => {
                        r.phoneNumbers.push({ key: count + "", label: undefined, type: 1, value: element.value })
                        count++
                    });
                }

                if (payload.success?.urlsList) {
                    let count = 0
                    payload.success?.urlsList?.forEach(element => {
                        r.urls.push({ key: count + "", label: element.id + "", type: 2, value: element.url })
                        count++
                    });

                }

                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }

                state.item = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, resetItem, updateConnection, updateItem } = businessDetailsPageSlice.actions

export default businessDetailsPageSlice.reducer