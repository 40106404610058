import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator, Pivot, PivotItem, Separator, IComboBoxOption } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { ApiMessage, getHeaders } from "../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import { Message } from "../../../common/Message/Message"
import { ShareBox } from "../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { get, dismissMessage, resetItem, reset, updateActivity, updateItem, updateConnection } from "./BusinessDetailsPageSlice"
import { Popup } from "../../../common/Popup/Popup"
import { FormType } from "../../../forms/FormProps"
import { DynamicField } from "../../../common/DynamicFields/DynamicFields"
import { TextView } from "../../../common/DataView/TextView"
import { ProfileView } from "../../../common/DataView/ProfileView"
import { useReactToPrint } from "react-to-print"
import { printComponent, normalizeKey } from "../../../../app/Helpers"
import { GetBusinessRequest, UpdateBusinessActivityRequest } from "../../../../repository/UserManagement/Dimensions/business_pb"
import { BusinessesForm } from "../../../forms/Businesses/BusinessesForm"
import { BusinessThirdPartyForm } from "../../../forms/Businesses/BusinessThirdPartyForm"
import { countriesAR, countriesEn } from "../../../../app/Content"
import { LinkView } from "../../../common/DataView/LinkView"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetBusinessRequest;

let org: number = -1;

let getPromise: any;
let actionPromise: any;


const key = "businesses/details"
export const BusinessDetailsPage: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();
    const [countries, setCountries] = useState([] as IComboBoxOption[])

    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        item: any
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.businessDetailsPage.isChangeStateLoading,
            message: state.businessDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.businessDetailsPage.isLoading,
            item: state.businessDetailsPage.item,

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true))
        }

    });
    const getActionId = (action: number): string => {
        if (action == 1)
            return activityActionConfirmationcalloutTargetId;
        else {
            return ""
        }
    }


    useEffect(() => {

        if (localStorage.getItem("lang") == "en") {
            setCountries(countriesEn)
        } else {
            setCountries(countriesAR)
        }
        req = new GetBusinessRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {

            }
        }
        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        if (id) {
            req.setId(id)
            getPromise = dispatch(get({ body: req, headers: getHeaders() }))
        } else {
            navigate(-1)
        }


        return () => { //clean up
            getPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t('back'),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetItem())
                getPromise = dispatch(get({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'edit',
            text: t("edit"),
            title: t("edit"),
            iconProps: { iconName: 'EditSolid12' },
            subMenuProps: {
                items: [{
                    key: 'editInfo',
                    text: t('editInfo'),
                    title: t("editInfo"),
                    iconProps: { iconName: 'EditContact', styles: { root: { fontWeight: "bold" } } },

                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            setCurrenctAction(101)
                        }

                    }
                },
                {
                    key: 'editThirdPartyConnection',
                    text: t("editThirdPartyConnection"),
                    title: t("editThirdPartyConnection"),

                    iconProps: { iconName: 'PlugDisconnected', styles: { root: { fontWeight: "bold" } } },
                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            setCurrenctAction(102)
                        }
                    }
                },

                ]
            },

        },
        {
            key: 'status',
            text: (state.item && state.item?.isActive) ? t("deactivate") : t("activate"),
            title: (state.item && state.item?.isActive) ? t("deactivate") : t("activate"),
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: (state.item && state.item?.isActive) ? "Cancel" : "Accept", styles: { root: { fontWeight: (state.item && state.item?.isActive) ? undefined : "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },

        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t('share'),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },
    ];



    return (
        <Stack >
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <Popup isOpen={(currentAction == 101)} title={t("editBusiness")} onDismiss={() => { setCurrenctAction(0) }} >

                <BusinessesForm childrenGap={10}  maxWidth={580} type={FormType.EDIT} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 102)} title={t("editThirdPartyConnection")} onDismiss={() => { setCurrenctAction(0) }} >

                <BusinessThirdPartyForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateConnection(e))
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareBusiness")} value={`https://${window.location.host}/businesses/${state.item?.id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}
            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? (state.item?.isActive) ? t("deactivateBusiness") : t("activateBusiness") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? (state.item?.isActive) ? t("deactivateBusinessDesc") : t("activateBusinessDesc") : "")}

                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.item) {
                                        if (currentAction == 1) {
                                            let up = new UpdateBusinessActivityRequest();
                                            up.setId(state.item?.id)
                                            up.setIsactive(!state.item?.isActive)
                                            dispatch(updateActivity({ body: up, headers: getHeaders() }))

                                        }
                                    }
                                }} text={(currentAction == 1 ? (state.item?.isActive) ? t("deactivate") : t("activate") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {state.isLoading ? <ProgressIndicator /> :

                !state.item ? null :
                    <div ref={ref}>
                        <CommandBar
                            items={_selectionItems}
                        />
                        <Stack tokens={{ childrenGap: 10, padding: "0px 36% 10px 3%" }}>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <TextView label={t("id")} value={state.item?.id} />


                                <TextView label={t("createdDate")} value={state.item?.createdDate} />

                                {state.item?.createdBy ? <ProfileView label={t("addedBy")} value={state.item?.createdBy == "" ? state.item?.createdById : state.item?.createdBy}
                                    url={"/management/users/" + state.item?.createdById + ((org == -1) ? "" : "?org=" + org)}
                                    text={state.item?.createdBy == "" ? state.item?.createdById : state.item?.createdBy}

                                    size={PersonaSize.size40} />

                                    : undefined}

                            </Stack>

                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <ProfileView label={t("businessName")} value={state.item?.name}
                                    url={"#"}
                                    text={state.item?.name}
                                    imageUrl={state.item?.logoSource + "" + state.item?.logoPath}
                                    size={PersonaSize.size40} />
                                <TextView label={t("status")} iconName={state.item?.isActive != undefined ? state.item?.isActive ? "Accept" : "Cancel" : undefined} value={state.item?.isActive != undefined ? state.item?.isActive ? t("active") : t("inactive") : ""} />

                                <TextView label={t("availability")} iconName={state.item?.isAvailable != undefined ? state.item?.isAvailable ? "Accept" : "Cancel" : undefined} value={state.item?.isAvailable != undefined ? state.item?.isAvailable ? t("available") : t("unavailable") : ""} />
                            </Stack>

                            <LinkView label={t("thirdPartyConnection")} value={state.item?.connectionMetaData == "" ? state.item?.connectionId : state.item?.connectionMetaData + " (" + state.item?.connectionId + ")"}
                                url={"https://cs.sarafa.ly/organizations/" + state.item?.item?.connectionId} onLinkClick={(i) => {
                                    if (i) {
                                        i.preventDefault();
                                        window.location.href = ("https://cs.sarafa.ly/organizations/" + state.item?.item?.connectionId)
                                    }


                                }} />



                            <TextView label={t("description")} value={state.item?.description} />

                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <TextView label={t("addressCountry")} value={countries.find(e => e.key === state.item?.addressCountry.key)?.text ?? ""} />
                                <TextView label={t("addressCity")} value={state.item?.addressCity} />

                            </Stack>
                            <TextView label={t("addressFirstLine")} value={state.item?.addressFirstLine} />
                            <TextView label={t("addressSecondLine")} value={state.item?.addressSecondLine} />
                            <TextView label={t("addressPostalCode")} value={state.item?.addressPostalCode} />
                            {state.item?.location && state.item?.location?.length == 2 ? <LinkView label={t("location")} onLinkClick={(i) => {
                                if (i) {
                                    i.preventDefault();
                                    window.location.href = ("https://www.google.com/maps/@" + state.item?.location[0] + "," + state.item?.location[1] + ",15.83z")
                                }



                            }} value={state.item?.location[0] + "," + state.item?.location[1]}
                                url={"https://www.google.com/maps/@" + state.item?.location[0] + "," + state.item?.location[1] + ",15.83z"} /> : undefined}


                            <Stack>
                                {state.item?.urls.map((e: DynamicField, index: number) => {
                                    return <TextView key={index} label={e.label == "1" ? t("fb") : e.label == "2" ? t("whatsapp") : e.label == "3" ? t("viber")
                                        : e.label == "4" ? t("telegram") : e.label == "5" ? t("web") : e.label == "6" ? t("email") : ""} value={e?.value} />
                                })}
                            </Stack>

                            <Stack>
                                {state.item?.phoneNumbers.map((e: DynamicField, index: number) => {
                                    return <TextView key={e.key} label={t("phonenumbers")} value={e?.value} />

                                })}
                            </Stack>




                            <Separator alignContent="start"><b>{t("otherInfo")}</b></Separator>

                            {state.item?.extraFields.map((e: DynamicField, index: number) => {
                                return <Stack key={e.key}>
                                    <TextView label={e.label ?? ""} value={e?.value} />

                                </Stack>
                            })}

                        </Stack>

                    </div >


            }


        </Stack >



    );
}




