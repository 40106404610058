// source: business.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('../common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.business_endpoint.AddBusinessReplay', null, global);
goog.exportSymbol('proto.business_endpoint.AddBusinessRequest', null, global);
goog.exportSymbol('proto.business_endpoint.AddBusinessResponse', null, global);
goog.exportSymbol('proto.business_endpoint.AddBusinessResponse.ResultCase', null, global);
goog.exportSymbol('proto.business_endpoint.AddressReplay', null, global);
goog.exportSymbol('proto.business_endpoint.BusinessReplay', null, global);
goog.exportSymbol('proto.business_endpoint.BusinessSummaryInfo', null, global);
goog.exportSymbol('proto.business_endpoint.GetBusinessReplay', null, global);
goog.exportSymbol('proto.business_endpoint.GetBusinessRequest', null, global);
goog.exportSymbol('proto.business_endpoint.GetBusinessResponse', null, global);
goog.exportSymbol('proto.business_endpoint.GetBusinessResponse.ResultCase', null, global);
goog.exportSymbol('proto.business_endpoint.GetBusinessesReplay', null, global);
goog.exportSymbol('proto.business_endpoint.GetBusinessesRequest', null, global);
goog.exportSymbol('proto.business_endpoint.GetBusinessesResponse', null, global);
goog.exportSymbol('proto.business_endpoint.GetBusinessesResponse.ResultCase', null, global);
goog.exportSymbol('proto.business_endpoint.ThirdPartySystemLinkReplay', null, global);
goog.exportSymbol('proto.business_endpoint.URL', null, global);
goog.exportSymbol('proto.business_endpoint.UpdateBusinessActivityReplay', null, global);
goog.exportSymbol('proto.business_endpoint.UpdateBusinessActivityRequest', null, global);
goog.exportSymbol('proto.business_endpoint.UpdateBusinessActivityResponse', null, global);
goog.exportSymbol('proto.business_endpoint.UpdateBusinessActivityResponse.ResultCase', null, global);
goog.exportSymbol('proto.business_endpoint.UpdateBusinessReplay', null, global);
goog.exportSymbol('proto.business_endpoint.UpdateBusinessRequest', null, global);
goog.exportSymbol('proto.business_endpoint.UpdateBusinessResponse', null, global);
goog.exportSymbol('proto.business_endpoint.UpdateBusinessResponse.ResultCase', null, global);
goog.exportSymbol('proto.business_endpoint.UpdateThirdPartyConnectionReplay', null, global);
goog.exportSymbol('proto.business_endpoint.UpdateThirdPartyConnectionRequest', null, global);
goog.exportSymbol('proto.business_endpoint.UpdateThirdPartyConnectionResponse', null, global);
goog.exportSymbol('proto.business_endpoint.UpdateThirdPartyConnectionResponse.ResultCase', null, global);
goog.exportSymbol('proto.business_endpoint.UploadLogoReplay', null, global);
goog.exportSymbol('proto.business_endpoint.UploadLogoRequest', null, global);
goog.exportSymbol('proto.business_endpoint.UploadLogoResponse', null, global);
goog.exportSymbol('proto.business_endpoint.UploadLogoResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.URL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_endpoint.URL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.URL.displayName = 'proto.business_endpoint.URL';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.AddBusinessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.business_endpoint.AddBusinessRequest.repeatedFields_, null);
};
goog.inherits(proto.business_endpoint.AddBusinessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.AddBusinessRequest.displayName = 'proto.business_endpoint.AddBusinessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.UpdateBusinessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.business_endpoint.UpdateBusinessRequest.repeatedFields_, null);
};
goog.inherits(proto.business_endpoint.UpdateBusinessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.UpdateBusinessRequest.displayName = 'proto.business_endpoint.UpdateBusinessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.UpdateBusinessActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_endpoint.UpdateBusinessActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.UpdateBusinessActivityRequest.displayName = 'proto.business_endpoint.UpdateBusinessActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.UpdateThirdPartyConnectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_endpoint.UpdateThirdPartyConnectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.UpdateThirdPartyConnectionRequest.displayName = 'proto.business_endpoint.UpdateThirdPartyConnectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.GetBusinessesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.business_endpoint.GetBusinessesRequest.repeatedFields_, null);
};
goog.inherits(proto.business_endpoint.GetBusinessesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.GetBusinessesRequest.displayName = 'proto.business_endpoint.GetBusinessesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.GetBusinessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_endpoint.GetBusinessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.GetBusinessRequest.displayName = 'proto.business_endpoint.GetBusinessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.UploadLogoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_endpoint.UploadLogoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.UploadLogoRequest.displayName = 'proto.business_endpoint.UploadLogoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.AddBusinessReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_endpoint.AddBusinessReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.AddBusinessReplay.displayName = 'proto.business_endpoint.AddBusinessReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.UpdateBusinessReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_endpoint.UpdateBusinessReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.UpdateBusinessReplay.displayName = 'proto.business_endpoint.UpdateBusinessReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.UpdateBusinessActivityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_endpoint.UpdateBusinessActivityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.UpdateBusinessActivityReplay.displayName = 'proto.business_endpoint.UpdateBusinessActivityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_endpoint.UpdateThirdPartyConnectionReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.UpdateThirdPartyConnectionReplay.displayName = 'proto.business_endpoint.UpdateThirdPartyConnectionReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.UploadLogoReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_endpoint.UploadLogoReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.UploadLogoReplay.displayName = 'proto.business_endpoint.UploadLogoReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.GetBusinessesReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.business_endpoint.GetBusinessesReplay.repeatedFields_, null);
};
goog.inherits(proto.business_endpoint.GetBusinessesReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.GetBusinessesReplay.displayName = 'proto.business_endpoint.GetBusinessesReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.BusinessReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_endpoint.BusinessReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.BusinessReplay.displayName = 'proto.business_endpoint.BusinessReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.ThirdPartySystemLinkReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_endpoint.ThirdPartySystemLinkReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.ThirdPartySystemLinkReplay.displayName = 'proto.business_endpoint.ThirdPartySystemLinkReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.BusinessSummaryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.business_endpoint.BusinessSummaryInfo.repeatedFields_, null);
};
goog.inherits(proto.business_endpoint.BusinessSummaryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.BusinessSummaryInfo.displayName = 'proto.business_endpoint.BusinessSummaryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.AddressReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business_endpoint.AddressReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.AddressReplay.displayName = 'proto.business_endpoint.AddressReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.GetBusinessReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.business_endpoint.GetBusinessReplay.repeatedFields_, null);
};
goog.inherits(proto.business_endpoint.GetBusinessReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.GetBusinessReplay.displayName = 'proto.business_endpoint.GetBusinessReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.AddBusinessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.business_endpoint.AddBusinessResponse.oneofGroups_);
};
goog.inherits(proto.business_endpoint.AddBusinessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.AddBusinessResponse.displayName = 'proto.business_endpoint.AddBusinessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.UpdateBusinessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.business_endpoint.UpdateBusinessResponse.oneofGroups_);
};
goog.inherits(proto.business_endpoint.UpdateBusinessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.UpdateBusinessResponse.displayName = 'proto.business_endpoint.UpdateBusinessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.UpdateBusinessActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.business_endpoint.UpdateBusinessActivityResponse.oneofGroups_);
};
goog.inherits(proto.business_endpoint.UpdateBusinessActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.UpdateBusinessActivityResponse.displayName = 'proto.business_endpoint.UpdateBusinessActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.GetBusinessesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.business_endpoint.GetBusinessesResponse.oneofGroups_);
};
goog.inherits(proto.business_endpoint.GetBusinessesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.GetBusinessesResponse.displayName = 'proto.business_endpoint.GetBusinessesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.GetBusinessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.business_endpoint.GetBusinessResponse.oneofGroups_);
};
goog.inherits(proto.business_endpoint.GetBusinessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.GetBusinessResponse.displayName = 'proto.business_endpoint.GetBusinessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.UpdateThirdPartyConnectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.business_endpoint.UpdateThirdPartyConnectionResponse.oneofGroups_);
};
goog.inherits(proto.business_endpoint.UpdateThirdPartyConnectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.UpdateThirdPartyConnectionResponse.displayName = 'proto.business_endpoint.UpdateThirdPartyConnectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business_endpoint.UploadLogoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.business_endpoint.UploadLogoResponse.oneofGroups_);
};
goog.inherits(proto.business_endpoint.UploadLogoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business_endpoint.UploadLogoResponse.displayName = 'proto.business_endpoint.UploadLogoResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.URL.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.URL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.URL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.URL.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.URL}
 */
proto.business_endpoint.URL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.URL;
  return proto.business_endpoint.URL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.URL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.URL}
 */
proto.business_endpoint.URL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.URL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.URL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.URL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.URL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.business_endpoint.URL.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.business_endpoint.URL} returns this
 */
proto.business_endpoint.URL.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.business_endpoint.URL.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.URL} returns this
 */
proto.business_endpoint.URL.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.business_endpoint.AddBusinessRequest.repeatedFields_ = [5,6,9,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.AddBusinessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.AddBusinessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.AddBusinessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.AddBusinessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: (f = msg.getAddress()) && Protos_common_pb.Address.toObject(includeInstance, f),
    logo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phonenumbersList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    urlsList: jspb.Message.toObjectList(msg.getUrlsList(),
    proto.business_endpoint.URL.toObject, includeInstance),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    locationList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 9)) == null ? undefined : f,
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.AddBusinessRequest}
 */
proto.business_endpoint.AddBusinessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.AddBusinessRequest;
  return proto.business_endpoint.AddBusinessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.AddBusinessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.AddBusinessRequest}
 */
proto.business_endpoint.AddBusinessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new Protos_common_pb.Address;
      reader.readMessage(value,Protos_common_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addPhonenumbers(value);
      break;
    case 6:
      var value = new proto.business_endpoint.URL;
      reader.readMessage(value,proto.business_endpoint.URL.deserializeBinaryFromReader);
      msg.addUrls(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLocation(values[i]);
      }
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 11:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.AddBusinessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.AddBusinessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.AddBusinessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.AddBusinessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhonenumbersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.business_endpoint.URL.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getLocationList();
  if (f.length > 0) {
    writer.writePackedDouble(
      9,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.business_endpoint.AddBusinessRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.business_endpoint.AddBusinessRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.Address address = 3;
 * @return {?proto.common.Address}
 */
proto.business_endpoint.AddBusinessRequest.prototype.getAddress = function() {
  return /** @type{?proto.common.Address} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.Address, 3));
};


/**
 * @param {?proto.common.Address|undefined} value
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
*/
proto.business_endpoint.AddBusinessRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.AddBusinessRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string logo = 4;
 * @return {string}
 */
proto.business_endpoint.AddBusinessRequest.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string phoneNumbers = 5;
 * @return {!Array<string>}
 */
proto.business_endpoint.AddBusinessRequest.prototype.getPhonenumbersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.setPhonenumbersList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.addPhonenumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.clearPhonenumbersList = function() {
  return this.setPhonenumbersList([]);
};


/**
 * repeated URL URLs = 6;
 * @return {!Array<!proto.business_endpoint.URL>}
 */
proto.business_endpoint.AddBusinessRequest.prototype.getUrlsList = function() {
  return /** @type{!Array<!proto.business_endpoint.URL>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.business_endpoint.URL, 6));
};


/**
 * @param {!Array<!proto.business_endpoint.URL>} value
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
*/
proto.business_endpoint.AddBusinessRequest.prototype.setUrlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.business_endpoint.URL=} opt_value
 * @param {number=} opt_index
 * @return {!proto.business_endpoint.URL}
 */
proto.business_endpoint.AddBusinessRequest.prototype.addUrls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.business_endpoint.URL, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.clearUrlsList = function() {
  return this.setUrlsList([]);
};


/**
 * optional bool isActive = 7;
 * @return {boolean}
 */
proto.business_endpoint.AddBusinessRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool isAvailable = 8;
 * @return {boolean}
 */
proto.business_endpoint.AddBusinessRequest.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated double location = 9;
 * @return {!Array<number>}
 */
proto.business_endpoint.AddBusinessRequest.prototype.getLocationList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.setLocationList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.addLocation = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.clearLocationList = function() {
  return this.setLocationList([]);
};


/**
 * optional google.protobuf.Int32Value organization = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.business_endpoint.AddBusinessRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
*/
proto.business_endpoint.AddBusinessRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.AddBusinessRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated common.ExtraField extraFields = 11;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.business_endpoint.AddBusinessRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 11));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
*/
proto.business_endpoint.AddBusinessRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.business_endpoint.AddBusinessRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.AddBusinessRequest} returns this
 */
proto.business_endpoint.AddBusinessRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.business_endpoint.UpdateBusinessRequest.repeatedFields_ = [6,7,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.UpdateBusinessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.UpdateBusinessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateBusinessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address: (f = msg.getAddress()) && Protos_common_pb.Address.toObject(includeInstance, f),
    logo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phonenumbersList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    urlsList: jspb.Message.toObjectList(msg.getUrlsList(),
    proto.business_endpoint.URL.toObject, includeInstance),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    locationList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 9)) == null ? undefined : f,
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.UpdateBusinessRequest}
 */
proto.business_endpoint.UpdateBusinessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.UpdateBusinessRequest;
  return proto.business_endpoint.UpdateBusinessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.UpdateBusinessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.UpdateBusinessRequest}
 */
proto.business_endpoint.UpdateBusinessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new Protos_common_pb.Address;
      reader.readMessage(value,Protos_common_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addPhonenumbers(value);
      break;
    case 7:
      var value = new proto.business_endpoint.URL;
      reader.readMessage(value,proto.business_endpoint.URL.deserializeBinaryFromReader);
      msg.addUrls(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLocation(values[i]);
      }
      break;
    case 10:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.UpdateBusinessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.UpdateBusinessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateBusinessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhonenumbersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.business_endpoint.URL.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getLocationList();
  if (f.length > 0) {
    writer.writePackedDouble(
      9,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.Address address = 4;
 * @return {?proto.common.Address}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.getAddress = function() {
  return /** @type{?proto.common.Address} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.Address, 4));
};


/**
 * @param {?proto.common.Address|undefined} value
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
*/
proto.business_endpoint.UpdateBusinessRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string logo = 5;
 * @return {string}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string phoneNumbers = 6;
 * @return {!Array<string>}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.getPhonenumbersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.setPhonenumbersList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.addPhonenumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.clearPhonenumbersList = function() {
  return this.setPhonenumbersList([]);
};


/**
 * repeated URL URLs = 7;
 * @return {!Array<!proto.business_endpoint.URL>}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.getUrlsList = function() {
  return /** @type{!Array<!proto.business_endpoint.URL>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.business_endpoint.URL, 7));
};


/**
 * @param {!Array<!proto.business_endpoint.URL>} value
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
*/
proto.business_endpoint.UpdateBusinessRequest.prototype.setUrlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.business_endpoint.URL=} opt_value
 * @param {number=} opt_index
 * @return {!proto.business_endpoint.URL}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.addUrls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.business_endpoint.URL, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.clearUrlsList = function() {
  return this.setUrlsList([]);
};


/**
 * optional bool isAvailable = 8;
 * @return {boolean}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated double location = 9;
 * @return {!Array<number>}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.getLocationList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.setLocationList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.addLocation = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.clearLocationList = function() {
  return this.setLocationList([]);
};


/**
 * repeated common.ExtraField extraFields = 10;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 10));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
*/
proto.business_endpoint.UpdateBusinessRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.UpdateBusinessRequest} returns this
 */
proto.business_endpoint.UpdateBusinessRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.UpdateBusinessActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.UpdateBusinessActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.UpdateBusinessActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateBusinessActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.UpdateBusinessActivityRequest}
 */
proto.business_endpoint.UpdateBusinessActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.UpdateBusinessActivityRequest;
  return proto.business_endpoint.UpdateBusinessActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.UpdateBusinessActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.UpdateBusinessActivityRequest}
 */
proto.business_endpoint.UpdateBusinessActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.UpdateBusinessActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.UpdateBusinessActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.UpdateBusinessActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateBusinessActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.business_endpoint.UpdateBusinessActivityRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.UpdateBusinessActivityRequest} returns this
 */
proto.business_endpoint.UpdateBusinessActivityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isActive = 2;
 * @return {boolean}
 */
proto.business_endpoint.UpdateBusinessActivityRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.business_endpoint.UpdateBusinessActivityRequest} returns this
 */
proto.business_endpoint.UpdateBusinessActivityRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.UpdateThirdPartyConnectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.UpdateThirdPartyConnectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.UpdateThirdPartyConnectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateThirdPartyConnectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    thirdpartyid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionRequest}
 */
proto.business_endpoint.UpdateThirdPartyConnectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.UpdateThirdPartyConnectionRequest;
  return proto.business_endpoint.UpdateThirdPartyConnectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.UpdateThirdPartyConnectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionRequest}
 */
proto.business_endpoint.UpdateThirdPartyConnectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setThirdpartyid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.UpdateThirdPartyConnectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.UpdateThirdPartyConnectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.UpdateThirdPartyConnectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateThirdPartyConnectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getThirdpartyid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.business_endpoint.UpdateThirdPartyConnectionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionRequest} returns this
 */
proto.business_endpoint.UpdateThirdPartyConnectionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 thirdPartyId = 2;
 * @return {number}
 */
proto.business_endpoint.UpdateThirdPartyConnectionRequest.prototype.getThirdpartyid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionRequest} returns this
 */
proto.business_endpoint.UpdateThirdPartyConnectionRequest.prototype.setThirdpartyid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.business_endpoint.GetBusinessesRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.GetBusinessesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.GetBusinessesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.GetBusinessesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 4, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    locationList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 8)) == null ? undefined : f,
    maxdistince: (f = msg.getMaxdistince()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
    countryiso: (f = msg.getCountryiso()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    city: (f = msg.getCity()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.GetBusinessesRequest}
 */
proto.business_endpoint.GetBusinessesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.GetBusinessesRequest;
  return proto.business_endpoint.GetBusinessesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.GetBusinessesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.GetBusinessesRequest}
 */
proto.business_endpoint.GetBusinessesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLocation(values[i]);
      }
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.DoubleValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
      msg.setMaxdistince(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCountryiso(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.GetBusinessesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.GetBusinessesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.GetBusinessesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getLocationList();
  if (f.length > 0) {
    writer.writePackedDouble(
      8,
      f
    );
  }
  f = message.getMaxdistince();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getCountryiso();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue search = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
*/
proto.business_endpoint.GetBusinessesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
 */
proto.business_endpoint.GetBusinessesRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool order = 2;
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
 */
proto.business_endpoint.GetBusinessesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
*/
proto.business_endpoint.GetBusinessesRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
 */
proto.business_endpoint.GetBusinessesRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 numOfResults = 4;
 * @return {number}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
 */
proto.business_endpoint.GetBusinessesRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.Int32Value organization = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
*/
proto.business_endpoint.GetBusinessesRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
 */
proto.business_endpoint.GetBusinessesRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
*/
proto.business_endpoint.GetBusinessesRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
 */
proto.business_endpoint.GetBusinessesRequest.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue isAvailable = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 7));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
*/
proto.business_endpoint.GetBusinessesRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
 */
proto.business_endpoint.GetBusinessesRequest.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated double location = 8;
 * @return {!Array<number>}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.getLocationList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
 */
proto.business_endpoint.GetBusinessesRequest.prototype.setLocationList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
 */
proto.business_endpoint.GetBusinessesRequest.prototype.addLocation = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
 */
proto.business_endpoint.GetBusinessesRequest.prototype.clearLocationList = function() {
  return this.setLocationList([]);
};


/**
 * optional google.protobuf.DoubleValue maxDistince = 9;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.getMaxdistince = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 9));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
*/
proto.business_endpoint.GetBusinessesRequest.prototype.setMaxdistince = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
 */
proto.business_endpoint.GetBusinessesRequest.prototype.clearMaxdistince = function() {
  return this.setMaxdistince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.hasMaxdistince = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue countryIso = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.getCountryiso = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
*/
proto.business_endpoint.GetBusinessesRequest.prototype.setCountryiso = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
 */
proto.business_endpoint.GetBusinessesRequest.prototype.clearCountryiso = function() {
  return this.setCountryiso(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.hasCountryiso = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue city = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.getCity = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
*/
proto.business_endpoint.GetBusinessesRequest.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessesRequest} returns this
 */
proto.business_endpoint.GetBusinessesRequest.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessesRequest.prototype.hasCity = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.GetBusinessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.GetBusinessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.GetBusinessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.GetBusinessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.GetBusinessRequest}
 */
proto.business_endpoint.GetBusinessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.GetBusinessRequest;
  return proto.business_endpoint.GetBusinessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.GetBusinessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.GetBusinessRequest}
 */
proto.business_endpoint.GetBusinessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.GetBusinessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.GetBusinessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.GetBusinessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.GetBusinessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.business_endpoint.GetBusinessRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.GetBusinessRequest} returns this
 */
proto.business_endpoint.GetBusinessRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.UploadLogoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.UploadLogoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.UploadLogoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UploadLogoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    image: msg.getImage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.UploadLogoRequest}
 */
proto.business_endpoint.UploadLogoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.UploadLogoRequest;
  return proto.business_endpoint.UploadLogoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.UploadLogoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.UploadLogoRequest}
 */
proto.business_endpoint.UploadLogoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.UploadLogoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.UploadLogoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.UploadLogoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UploadLogoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.business_endpoint.UploadLogoRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.business_endpoint.UploadLogoRequest} returns this
*/
proto.business_endpoint.UploadLogoRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UploadLogoRequest} returns this
 */
proto.business_endpoint.UploadLogoRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UploadLogoRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes image = 2;
 * @return {!(string|Uint8Array)}
 */
proto.business_endpoint.UploadLogoRequest.prototype.getImage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes image = 2;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.business_endpoint.UploadLogoRequest.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.business_endpoint.UploadLogoRequest.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.business_endpoint.UploadLogoRequest} returns this
 */
proto.business_endpoint.UploadLogoRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.AddBusinessReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.AddBusinessReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.AddBusinessReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.AddBusinessReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.AddBusinessReplay}
 */
proto.business_endpoint.AddBusinessReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.AddBusinessReplay;
  return proto.business_endpoint.AddBusinessReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.AddBusinessReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.AddBusinessReplay}
 */
proto.business_endpoint.AddBusinessReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.AddBusinessReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.AddBusinessReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.AddBusinessReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.AddBusinessReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Id = 1;
 * @return {string}
 */
proto.business_endpoint.AddBusinessReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.AddBusinessReplay} returns this
 */
proto.business_endpoint.AddBusinessReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.business_endpoint.AddBusinessReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.business_endpoint.AddBusinessReplay} returns this
*/
proto.business_endpoint.AddBusinessReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.AddBusinessReplay} returns this
 */
proto.business_endpoint.AddBusinessReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.AddBusinessReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.business_endpoint.AddBusinessReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.business_endpoint.AddBusinessReplay} returns this
*/
proto.business_endpoint.AddBusinessReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.AddBusinessReplay} returns this
 */
proto.business_endpoint.AddBusinessReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.AddBusinessReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.UpdateBusinessReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.UpdateBusinessReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.UpdateBusinessReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateBusinessReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.UpdateBusinessReplay}
 */
proto.business_endpoint.UpdateBusinessReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.UpdateBusinessReplay;
  return proto.business_endpoint.UpdateBusinessReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.UpdateBusinessReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.UpdateBusinessReplay}
 */
proto.business_endpoint.UpdateBusinessReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.UpdateBusinessReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.UpdateBusinessReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.UpdateBusinessReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateBusinessReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.business_endpoint.UpdateBusinessReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.business_endpoint.UpdateBusinessReplay} returns this
*/
proto.business_endpoint.UpdateBusinessReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UpdateBusinessReplay} returns this
 */
proto.business_endpoint.UpdateBusinessReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UpdateBusinessReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.business_endpoint.UpdateBusinessReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.business_endpoint.UpdateBusinessReplay} returns this
*/
proto.business_endpoint.UpdateBusinessReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UpdateBusinessReplay} returns this
 */
proto.business_endpoint.UpdateBusinessReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UpdateBusinessReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.UpdateBusinessActivityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.UpdateBusinessActivityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.UpdateBusinessActivityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateBusinessActivityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.UpdateBusinessActivityReplay}
 */
proto.business_endpoint.UpdateBusinessActivityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.UpdateBusinessActivityReplay;
  return proto.business_endpoint.UpdateBusinessActivityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.UpdateBusinessActivityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.UpdateBusinessActivityReplay}
 */
proto.business_endpoint.UpdateBusinessActivityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.UpdateBusinessActivityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.UpdateBusinessActivityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.UpdateBusinessActivityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateBusinessActivityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.business_endpoint.UpdateBusinessActivityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.business_endpoint.UpdateBusinessActivityReplay} returns this
*/
proto.business_endpoint.UpdateBusinessActivityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UpdateBusinessActivityReplay} returns this
 */
proto.business_endpoint.UpdateBusinessActivityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UpdateBusinessActivityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.business_endpoint.UpdateBusinessActivityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.business_endpoint.UpdateBusinessActivityReplay} returns this
*/
proto.business_endpoint.UpdateBusinessActivityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UpdateBusinessActivityReplay} returns this
 */
proto.business_endpoint.UpdateBusinessActivityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UpdateBusinessActivityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.UpdateThirdPartyConnectionReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.UpdateThirdPartyConnectionReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    metadata: (f = msg.getMetadata()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionReplay}
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.UpdateThirdPartyConnectionReplay;
  return proto.business_endpoint.UpdateThirdPartyConnectionReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.UpdateThirdPartyConnectionReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionReplay}
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.UpdateThirdPartyConnectionReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.UpdateThirdPartyConnectionReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionReplay} returns this
*/
proto.business_endpoint.UpdateThirdPartyConnectionReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionReplay} returns this
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionReplay} returns this
*/
proto.business_endpoint.UpdateThirdPartyConnectionReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionReplay} returns this
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue metadata = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.prototype.getMetadata = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionReplay} returns this
*/
proto.business_endpoint.UpdateThirdPartyConnectionReplay.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionReplay} returns this
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UpdateThirdPartyConnectionReplay.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.UploadLogoReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.UploadLogoReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.UploadLogoReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UploadLogoReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    source: (f = msg.getSource()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    path: (f = msg.getPath()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.UploadLogoReplay}
 */
proto.business_endpoint.UploadLogoReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.UploadLogoReplay;
  return proto.business_endpoint.UploadLogoReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.UploadLogoReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.UploadLogoReplay}
 */
proto.business_endpoint.UploadLogoReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.UploadLogoReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.UploadLogoReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.UploadLogoReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UploadLogoReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPath();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Id = 1;
 * @return {string}
 */
proto.business_endpoint.UploadLogoReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.UploadLogoReplay} returns this
 */
proto.business_endpoint.UploadLogoReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.business_endpoint.UploadLogoReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.business_endpoint.UploadLogoReplay} returns this
*/
proto.business_endpoint.UploadLogoReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UploadLogoReplay} returns this
 */
proto.business_endpoint.UploadLogoReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UploadLogoReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.business_endpoint.UploadLogoReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.business_endpoint.UploadLogoReplay} returns this
*/
proto.business_endpoint.UploadLogoReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UploadLogoReplay} returns this
 */
proto.business_endpoint.UploadLogoReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UploadLogoReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue name = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.UploadLogoReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.UploadLogoReplay} returns this
*/
proto.business_endpoint.UploadLogoReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UploadLogoReplay} returns this
 */
proto.business_endpoint.UploadLogoReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UploadLogoReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue source = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.UploadLogoReplay.prototype.getSource = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.UploadLogoReplay} returns this
*/
proto.business_endpoint.UploadLogoReplay.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UploadLogoReplay} returns this
 */
proto.business_endpoint.UploadLogoReplay.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UploadLogoReplay.prototype.hasSource = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue path = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.UploadLogoReplay.prototype.getPath = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.UploadLogoReplay} returns this
*/
proto.business_endpoint.UploadLogoReplay.prototype.setPath = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UploadLogoReplay} returns this
 */
proto.business_endpoint.UploadLogoReplay.prototype.clearPath = function() {
  return this.setPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UploadLogoReplay.prototype.hasPath = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.business_endpoint.GetBusinessesReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.GetBusinessesReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.GetBusinessesReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.GetBusinessesReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.GetBusinessesReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessesList: jspb.Message.toObjectList(msg.getBusinessesList(),
    proto.business_endpoint.BusinessSummaryInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.GetBusinessesReplay}
 */
proto.business_endpoint.GetBusinessesReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.GetBusinessesReplay;
  return proto.business_endpoint.GetBusinessesReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.GetBusinessesReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.GetBusinessesReplay}
 */
proto.business_endpoint.GetBusinessesReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_endpoint.BusinessSummaryInfo;
      reader.readMessage(value,proto.business_endpoint.BusinessSummaryInfo.deserializeBinaryFromReader);
      msg.addBusinesses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.GetBusinessesReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.GetBusinessesReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.GetBusinessesReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.GetBusinessesReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.business_endpoint.BusinessSummaryInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BusinessSummaryInfo businesses = 1;
 * @return {!Array<!proto.business_endpoint.BusinessSummaryInfo>}
 */
proto.business_endpoint.GetBusinessesReplay.prototype.getBusinessesList = function() {
  return /** @type{!Array<!proto.business_endpoint.BusinessSummaryInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.business_endpoint.BusinessSummaryInfo, 1));
};


/**
 * @param {!Array<!proto.business_endpoint.BusinessSummaryInfo>} value
 * @return {!proto.business_endpoint.GetBusinessesReplay} returns this
*/
proto.business_endpoint.GetBusinessesReplay.prototype.setBusinessesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.business_endpoint.BusinessSummaryInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.business_endpoint.BusinessSummaryInfo}
 */
proto.business_endpoint.GetBusinessesReplay.prototype.addBusinesses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.business_endpoint.BusinessSummaryInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.GetBusinessesReplay} returns this
 */
proto.business_endpoint.GetBusinessesReplay.prototype.clearBusinessesList = function() {
  return this.setBusinessesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.BusinessReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.BusinessReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.BusinessReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.BusinessReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.BusinessReplay}
 */
proto.business_endpoint.BusinessReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.BusinessReplay;
  return proto.business_endpoint.BusinessReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.BusinessReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.BusinessReplay}
 */
proto.business_endpoint.BusinessReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.BusinessReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.BusinessReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.BusinessReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.BusinessReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.BusinessReplay.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.BusinessReplay} returns this
*/
proto.business_endpoint.BusinessReplay.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.BusinessReplay} returns this
 */
proto.business_endpoint.BusinessReplay.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.BusinessReplay.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.BusinessReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.BusinessReplay} returns this
*/
proto.business_endpoint.BusinessReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.BusinessReplay} returns this
 */
proto.business_endpoint.BusinessReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.BusinessReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.ThirdPartySystemLinkReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.ThirdPartySystemLinkReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.ThirdPartySystemLinkReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.ThirdPartySystemLinkReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    metadata: (f = msg.getMetadata()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.ThirdPartySystemLinkReplay}
 */
proto.business_endpoint.ThirdPartySystemLinkReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.ThirdPartySystemLinkReplay;
  return proto.business_endpoint.ThirdPartySystemLinkReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.ThirdPartySystemLinkReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.ThirdPartySystemLinkReplay}
 */
proto.business_endpoint.ThirdPartySystemLinkReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.ThirdPartySystemLinkReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.ThirdPartySystemLinkReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.ThirdPartySystemLinkReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.ThirdPartySystemLinkReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.ThirdPartySystemLinkReplay.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.ThirdPartySystemLinkReplay} returns this
*/
proto.business_endpoint.ThirdPartySystemLinkReplay.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.ThirdPartySystemLinkReplay} returns this
 */
proto.business_endpoint.ThirdPartySystemLinkReplay.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.ThirdPartySystemLinkReplay.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue metadata = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.ThirdPartySystemLinkReplay.prototype.getMetadata = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.ThirdPartySystemLinkReplay} returns this
*/
proto.business_endpoint.ThirdPartySystemLinkReplay.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.ThirdPartySystemLinkReplay} returns this
 */
proto.business_endpoint.ThirdPartySystemLinkReplay.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.ThirdPartySystemLinkReplay.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.business_endpoint.BusinessSummaryInfo.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.BusinessSummaryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.BusinessSummaryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.BusinessSummaryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    locationList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 4)) == null ? undefined : f,
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    logo: (f = msg.getLogo()) && Protos_common_pb.LogoResponse.toObject(includeInstance, f),
    connection: (f = msg.getConnection()) && proto.business_endpoint.ThirdPartySystemLinkReplay.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && proto.business_endpoint.AddressReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.BusinessSummaryInfo}
 */
proto.business_endpoint.BusinessSummaryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.BusinessSummaryInfo;
  return proto.business_endpoint.BusinessSummaryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.BusinessSummaryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.BusinessSummaryInfo}
 */
proto.business_endpoint.BusinessSummaryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLocation(values[i]);
      }
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 8:
      var value = new Protos_common_pb.LogoResponse;
      reader.readMessage(value,Protos_common_pb.LogoResponse.deserializeBinaryFromReader);
      msg.setLogo(value);
      break;
    case 9:
      var value = new proto.business_endpoint.ThirdPartySystemLinkReplay;
      reader.readMessage(value,proto.business_endpoint.ThirdPartySystemLinkReplay.deserializeBinaryFromReader);
      msg.setConnection(value);
      break;
    case 10:
      var value = new proto.business_endpoint.AddressReplay;
      reader.readMessage(value,proto.business_endpoint.AddressReplay.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.BusinessSummaryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.BusinessSummaryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.BusinessSummaryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLocationList();
  if (f.length > 0) {
    writer.writePackedDouble(
      4,
      f
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getLogo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Protos_common_pb.LogoResponse.serializeBinaryToWriter
    );
  }
  f = message.getConnection();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.business_endpoint.ThirdPartySystemLinkReplay.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.business_endpoint.AddressReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.ProfileResponse createdBy = 2;
 * @return {?proto.common.ProfileResponse}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 2));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
*/
proto.business_endpoint.BusinessSummaryInfo.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
*/
proto.business_endpoint.BusinessSummaryInfo.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated double location = 4;
 * @return {!Array<number>}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.getLocationList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.setLocationList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.addLocation = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.clearLocationList = function() {
  return this.setLocationList([]);
};


/**
 * optional google.protobuf.BoolValue isActive = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
*/
proto.business_endpoint.BusinessSummaryInfo.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
*/
proto.business_endpoint.BusinessSummaryInfo.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue isAvailable = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 7));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
*/
proto.business_endpoint.BusinessSummaryInfo.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional common.LogoResponse logo = 8;
 * @return {?proto.common.LogoResponse}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.getLogo = function() {
  return /** @type{?proto.common.LogoResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.LogoResponse, 8));
};


/**
 * @param {?proto.common.LogoResponse|undefined} value
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
*/
proto.business_endpoint.BusinessSummaryInfo.prototype.setLogo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.clearLogo = function() {
  return this.setLogo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.hasLogo = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ThirdPartySystemLinkReplay connection = 9;
 * @return {?proto.business_endpoint.ThirdPartySystemLinkReplay}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.getConnection = function() {
  return /** @type{?proto.business_endpoint.ThirdPartySystemLinkReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_endpoint.ThirdPartySystemLinkReplay, 9));
};


/**
 * @param {?proto.business_endpoint.ThirdPartySystemLinkReplay|undefined} value
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
*/
proto.business_endpoint.BusinessSummaryInfo.prototype.setConnection = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.clearConnection = function() {
  return this.setConnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.hasConnection = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional AddressReplay address = 10;
 * @return {?proto.business_endpoint.AddressReplay}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.getAddress = function() {
  return /** @type{?proto.business_endpoint.AddressReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_endpoint.AddressReplay, 10));
};


/**
 * @param {?proto.business_endpoint.AddressReplay|undefined} value
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
*/
proto.business_endpoint.BusinessSummaryInfo.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.BusinessSummaryInfo} returns this
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.BusinessSummaryInfo.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.AddressReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.AddressReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.AddressReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.AddressReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    countryiso: jspb.Message.getFieldWithDefault(msg, 1, ""),
    city: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.AddressReplay}
 */
proto.business_endpoint.AddressReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.AddressReplay;
  return proto.business_endpoint.AddressReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.AddressReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.AddressReplay}
 */
proto.business_endpoint.AddressReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryiso(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.AddressReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.AddressReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.AddressReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.AddressReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountryiso();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string countryIso = 1;
 * @return {string}
 */
proto.business_endpoint.AddressReplay.prototype.getCountryiso = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.AddressReplay} returns this
 */
proto.business_endpoint.AddressReplay.prototype.setCountryiso = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string city = 2;
 * @return {string}
 */
proto.business_endpoint.AddressReplay.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.AddressReplay} returns this
 */
proto.business_endpoint.AddressReplay.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.business_endpoint.GetBusinessReplay.repeatedFields_ = [4,12,13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.GetBusinessReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.GetBusinessReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.GetBusinessReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.GetBusinessReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    locationList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 4)) == null ? undefined : f,
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    logo: (f = msg.getLogo()) && Protos_common_pb.LogoResponse.toObject(includeInstance, f),
    connection: (f = msg.getConnection()) && proto.business_endpoint.ThirdPartySystemLinkReplay.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && Protos_common_pb.Address.toObject(includeInstance, f),
    phonenumbersList: jspb.Message.toObjectList(msg.getPhonenumbersList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    urlsList: jspb.Message.toObjectList(msg.getUrlsList(),
    proto.business_endpoint.URL.toObject, includeInstance),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.GetBusinessReplay}
 */
proto.business_endpoint.GetBusinessReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.GetBusinessReplay;
  return proto.business_endpoint.GetBusinessReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.GetBusinessReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.GetBusinessReplay}
 */
proto.business_endpoint.GetBusinessReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLocation(values[i]);
      }
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 8:
      var value = new Protos_common_pb.LogoResponse;
      reader.readMessage(value,Protos_common_pb.LogoResponse.deserializeBinaryFromReader);
      msg.setLogo(value);
      break;
    case 9:
      var value = new proto.business_endpoint.ThirdPartySystemLinkReplay;
      reader.readMessage(value,proto.business_endpoint.ThirdPartySystemLinkReplay.deserializeBinaryFromReader);
      msg.setConnection(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 11:
      var value = new Protos_common_pb.Address;
      reader.readMessage(value,Protos_common_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addPhonenumbers(value);
      break;
    case 13:
      var value = new proto.business_endpoint.URL;
      reader.readMessage(value,proto.business_endpoint.URL.deserializeBinaryFromReader);
      msg.addUrls(value);
      break;
    case 14:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.GetBusinessReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.GetBusinessReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.GetBusinessReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.GetBusinessReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLocationList();
  if (f.length > 0) {
    writer.writePackedDouble(
      4,
      f
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getLogo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Protos_common_pb.LogoResponse.serializeBinaryToWriter
    );
  }
  f = message.getConnection();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.business_endpoint.ThirdPartySystemLinkReplay.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Protos_common_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getPhonenumbersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.business_endpoint.URL.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.business_endpoint.GetBusinessReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.ProfileResponse createdBy = 2;
 * @return {?proto.common.ProfileResponse}
 */
proto.business_endpoint.GetBusinessReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 2));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
*/
proto.business_endpoint.GetBusinessReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.GetBusinessReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
*/
proto.business_endpoint.GetBusinessReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated double location = 4;
 * @return {!Array<number>}
 */
proto.business_endpoint.GetBusinessReplay.prototype.getLocationList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.setLocationList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.addLocation = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.clearLocationList = function() {
  return this.setLocationList([]);
};


/**
 * optional google.protobuf.BoolValue isActive = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.business_endpoint.GetBusinessReplay.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
*/
proto.business_endpoint.GetBusinessReplay.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessReplay.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.business_endpoint.GetBusinessReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
*/
proto.business_endpoint.GetBusinessReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue isAvailable = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.business_endpoint.GetBusinessReplay.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 7));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
*/
proto.business_endpoint.GetBusinessReplay.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessReplay.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional common.LogoResponse logo = 8;
 * @return {?proto.common.LogoResponse}
 */
proto.business_endpoint.GetBusinessReplay.prototype.getLogo = function() {
  return /** @type{?proto.common.LogoResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.LogoResponse, 8));
};


/**
 * @param {?proto.common.LogoResponse|undefined} value
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
*/
proto.business_endpoint.GetBusinessReplay.prototype.setLogo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.clearLogo = function() {
  return this.setLogo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessReplay.prototype.hasLogo = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ThirdPartySystemLinkReplay connection = 9;
 * @return {?proto.business_endpoint.ThirdPartySystemLinkReplay}
 */
proto.business_endpoint.GetBusinessReplay.prototype.getConnection = function() {
  return /** @type{?proto.business_endpoint.ThirdPartySystemLinkReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_endpoint.ThirdPartySystemLinkReplay, 9));
};


/**
 * @param {?proto.business_endpoint.ThirdPartySystemLinkReplay|undefined} value
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
*/
proto.business_endpoint.GetBusinessReplay.prototype.setConnection = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.clearConnection = function() {
  return this.setConnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessReplay.prototype.hasConnection = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue description = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.business_endpoint.GetBusinessReplay.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
*/
proto.business_endpoint.GetBusinessReplay.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessReplay.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional common.Address address = 11;
 * @return {?proto.common.Address}
 */
proto.business_endpoint.GetBusinessReplay.prototype.getAddress = function() {
  return /** @type{?proto.common.Address} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.Address, 11));
};


/**
 * @param {?proto.common.Address|undefined} value
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
*/
proto.business_endpoint.GetBusinessReplay.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessReplay.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated google.protobuf.StringValue phoneNumbers = 12;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.business_endpoint.GetBusinessReplay.prototype.getPhonenumbersList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
*/
proto.business_endpoint.GetBusinessReplay.prototype.setPhonenumbersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.business_endpoint.GetBusinessReplay.prototype.addPhonenumbers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.clearPhonenumbersList = function() {
  return this.setPhonenumbersList([]);
};


/**
 * repeated URL URLs = 13;
 * @return {!Array<!proto.business_endpoint.URL>}
 */
proto.business_endpoint.GetBusinessReplay.prototype.getUrlsList = function() {
  return /** @type{!Array<!proto.business_endpoint.URL>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.business_endpoint.URL, 13));
};


/**
 * @param {!Array<!proto.business_endpoint.URL>} value
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
*/
proto.business_endpoint.GetBusinessReplay.prototype.setUrlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.business_endpoint.URL=} opt_value
 * @param {number=} opt_index
 * @return {!proto.business_endpoint.URL}
 */
proto.business_endpoint.GetBusinessReplay.prototype.addUrls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.business_endpoint.URL, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.clearUrlsList = function() {
  return this.setUrlsList([]);
};


/**
 * repeated common.ExtraField extraFields = 14;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.business_endpoint.GetBusinessReplay.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 14));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
*/
proto.business_endpoint.GetBusinessReplay.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.business_endpoint.GetBusinessReplay.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.business_endpoint.GetBusinessReplay} returns this
 */
proto.business_endpoint.GetBusinessReplay.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.business_endpoint.AddBusinessResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.business_endpoint.AddBusinessResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.business_endpoint.AddBusinessResponse.ResultCase}
 */
proto.business_endpoint.AddBusinessResponse.prototype.getResultCase = function() {
  return /** @type {proto.business_endpoint.AddBusinessResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.business_endpoint.AddBusinessResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.AddBusinessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.AddBusinessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.AddBusinessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.AddBusinessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.business_endpoint.AddBusinessReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.AddBusinessResponse}
 */
proto.business_endpoint.AddBusinessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.AddBusinessResponse;
  return proto.business_endpoint.AddBusinessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.AddBusinessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.AddBusinessResponse}
 */
proto.business_endpoint.AddBusinessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_endpoint.AddBusinessReplay;
      reader.readMessage(value,proto.business_endpoint.AddBusinessReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.AddBusinessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.AddBusinessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.AddBusinessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.AddBusinessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business_endpoint.AddBusinessReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddBusinessReplay success = 1;
 * @return {?proto.business_endpoint.AddBusinessReplay}
 */
proto.business_endpoint.AddBusinessResponse.prototype.getSuccess = function() {
  return /** @type{?proto.business_endpoint.AddBusinessReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_endpoint.AddBusinessReplay, 1));
};


/**
 * @param {?proto.business_endpoint.AddBusinessReplay|undefined} value
 * @return {!proto.business_endpoint.AddBusinessResponse} returns this
*/
proto.business_endpoint.AddBusinessResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.business_endpoint.AddBusinessResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.AddBusinessResponse} returns this
 */
proto.business_endpoint.AddBusinessResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.AddBusinessResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.business_endpoint.UpdateBusinessResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.business_endpoint.UpdateBusinessResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.business_endpoint.UpdateBusinessResponse.ResultCase}
 */
proto.business_endpoint.UpdateBusinessResponse.prototype.getResultCase = function() {
  return /** @type {proto.business_endpoint.UpdateBusinessResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.business_endpoint.UpdateBusinessResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.UpdateBusinessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.UpdateBusinessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.UpdateBusinessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateBusinessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.business_endpoint.UpdateBusinessReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.UpdateBusinessResponse}
 */
proto.business_endpoint.UpdateBusinessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.UpdateBusinessResponse;
  return proto.business_endpoint.UpdateBusinessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.UpdateBusinessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.UpdateBusinessResponse}
 */
proto.business_endpoint.UpdateBusinessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_endpoint.UpdateBusinessReplay;
      reader.readMessage(value,proto.business_endpoint.UpdateBusinessReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.UpdateBusinessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.UpdateBusinessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.UpdateBusinessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateBusinessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business_endpoint.UpdateBusinessReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateBusinessReplay success = 1;
 * @return {?proto.business_endpoint.UpdateBusinessReplay}
 */
proto.business_endpoint.UpdateBusinessResponse.prototype.getSuccess = function() {
  return /** @type{?proto.business_endpoint.UpdateBusinessReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_endpoint.UpdateBusinessReplay, 1));
};


/**
 * @param {?proto.business_endpoint.UpdateBusinessReplay|undefined} value
 * @return {!proto.business_endpoint.UpdateBusinessResponse} returns this
*/
proto.business_endpoint.UpdateBusinessResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.business_endpoint.UpdateBusinessResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UpdateBusinessResponse} returns this
 */
proto.business_endpoint.UpdateBusinessResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UpdateBusinessResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.business_endpoint.UpdateBusinessActivityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.business_endpoint.UpdateBusinessActivityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.business_endpoint.UpdateBusinessActivityResponse.ResultCase}
 */
proto.business_endpoint.UpdateBusinessActivityResponse.prototype.getResultCase = function() {
  return /** @type {proto.business_endpoint.UpdateBusinessActivityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.business_endpoint.UpdateBusinessActivityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.UpdateBusinessActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.UpdateBusinessActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.UpdateBusinessActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateBusinessActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.business_endpoint.UpdateBusinessActivityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.UpdateBusinessActivityResponse}
 */
proto.business_endpoint.UpdateBusinessActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.UpdateBusinessActivityResponse;
  return proto.business_endpoint.UpdateBusinessActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.UpdateBusinessActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.UpdateBusinessActivityResponse}
 */
proto.business_endpoint.UpdateBusinessActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_endpoint.UpdateBusinessActivityReplay;
      reader.readMessage(value,proto.business_endpoint.UpdateBusinessActivityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.UpdateBusinessActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.UpdateBusinessActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.UpdateBusinessActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateBusinessActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business_endpoint.UpdateBusinessActivityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateBusinessActivityReplay success = 1;
 * @return {?proto.business_endpoint.UpdateBusinessActivityReplay}
 */
proto.business_endpoint.UpdateBusinessActivityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.business_endpoint.UpdateBusinessActivityReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_endpoint.UpdateBusinessActivityReplay, 1));
};


/**
 * @param {?proto.business_endpoint.UpdateBusinessActivityReplay|undefined} value
 * @return {!proto.business_endpoint.UpdateBusinessActivityResponse} returns this
*/
proto.business_endpoint.UpdateBusinessActivityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.business_endpoint.UpdateBusinessActivityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UpdateBusinessActivityResponse} returns this
 */
proto.business_endpoint.UpdateBusinessActivityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UpdateBusinessActivityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.business_endpoint.GetBusinessesResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.business_endpoint.GetBusinessesResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.business_endpoint.GetBusinessesResponse.ResultCase}
 */
proto.business_endpoint.GetBusinessesResponse.prototype.getResultCase = function() {
  return /** @type {proto.business_endpoint.GetBusinessesResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.business_endpoint.GetBusinessesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.GetBusinessesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.GetBusinessesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.GetBusinessesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.GetBusinessesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.business_endpoint.GetBusinessesReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.GetBusinessesResponse}
 */
proto.business_endpoint.GetBusinessesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.GetBusinessesResponse;
  return proto.business_endpoint.GetBusinessesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.GetBusinessesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.GetBusinessesResponse}
 */
proto.business_endpoint.GetBusinessesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_endpoint.GetBusinessesReplay;
      reader.readMessage(value,proto.business_endpoint.GetBusinessesReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.GetBusinessesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.GetBusinessesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.GetBusinessesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.GetBusinessesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business_endpoint.GetBusinessesReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetBusinessesReplay success = 1;
 * @return {?proto.business_endpoint.GetBusinessesReplay}
 */
proto.business_endpoint.GetBusinessesResponse.prototype.getSuccess = function() {
  return /** @type{?proto.business_endpoint.GetBusinessesReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_endpoint.GetBusinessesReplay, 1));
};


/**
 * @param {?proto.business_endpoint.GetBusinessesReplay|undefined} value
 * @return {!proto.business_endpoint.GetBusinessesResponse} returns this
*/
proto.business_endpoint.GetBusinessesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.business_endpoint.GetBusinessesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessesResponse} returns this
 */
proto.business_endpoint.GetBusinessesResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessesResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.business_endpoint.GetBusinessResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.business_endpoint.GetBusinessResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.business_endpoint.GetBusinessResponse.ResultCase}
 */
proto.business_endpoint.GetBusinessResponse.prototype.getResultCase = function() {
  return /** @type {proto.business_endpoint.GetBusinessResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.business_endpoint.GetBusinessResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.GetBusinessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.GetBusinessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.GetBusinessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.GetBusinessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.business_endpoint.GetBusinessReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.GetBusinessResponse}
 */
proto.business_endpoint.GetBusinessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.GetBusinessResponse;
  return proto.business_endpoint.GetBusinessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.GetBusinessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.GetBusinessResponse}
 */
proto.business_endpoint.GetBusinessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_endpoint.GetBusinessReplay;
      reader.readMessage(value,proto.business_endpoint.GetBusinessReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.GetBusinessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.GetBusinessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.GetBusinessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.GetBusinessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business_endpoint.GetBusinessReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetBusinessReplay success = 1;
 * @return {?proto.business_endpoint.GetBusinessReplay}
 */
proto.business_endpoint.GetBusinessResponse.prototype.getSuccess = function() {
  return /** @type{?proto.business_endpoint.GetBusinessReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_endpoint.GetBusinessReplay, 1));
};


/**
 * @param {?proto.business_endpoint.GetBusinessReplay|undefined} value
 * @return {!proto.business_endpoint.GetBusinessResponse} returns this
*/
proto.business_endpoint.GetBusinessResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.business_endpoint.GetBusinessResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.GetBusinessResponse} returns this
 */
proto.business_endpoint.GetBusinessResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.GetBusinessResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.business_endpoint.UpdateThirdPartyConnectionResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.business_endpoint.UpdateThirdPartyConnectionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.business_endpoint.UpdateThirdPartyConnectionResponse.ResultCase}
 */
proto.business_endpoint.UpdateThirdPartyConnectionResponse.prototype.getResultCase = function() {
  return /** @type {proto.business_endpoint.UpdateThirdPartyConnectionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.business_endpoint.UpdateThirdPartyConnectionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.UpdateThirdPartyConnectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.UpdateThirdPartyConnectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.UpdateThirdPartyConnectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateThirdPartyConnectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.business_endpoint.UpdateThirdPartyConnectionReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionResponse}
 */
proto.business_endpoint.UpdateThirdPartyConnectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.UpdateThirdPartyConnectionResponse;
  return proto.business_endpoint.UpdateThirdPartyConnectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.UpdateThirdPartyConnectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionResponse}
 */
proto.business_endpoint.UpdateThirdPartyConnectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_endpoint.UpdateThirdPartyConnectionReplay;
      reader.readMessage(value,proto.business_endpoint.UpdateThirdPartyConnectionReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.UpdateThirdPartyConnectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.UpdateThirdPartyConnectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.UpdateThirdPartyConnectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UpdateThirdPartyConnectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business_endpoint.UpdateThirdPartyConnectionReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateThirdPartyConnectionReplay success = 1;
 * @return {?proto.business_endpoint.UpdateThirdPartyConnectionReplay}
 */
proto.business_endpoint.UpdateThirdPartyConnectionResponse.prototype.getSuccess = function() {
  return /** @type{?proto.business_endpoint.UpdateThirdPartyConnectionReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_endpoint.UpdateThirdPartyConnectionReplay, 1));
};


/**
 * @param {?proto.business_endpoint.UpdateThirdPartyConnectionReplay|undefined} value
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionResponse} returns this
*/
proto.business_endpoint.UpdateThirdPartyConnectionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.business_endpoint.UpdateThirdPartyConnectionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UpdateThirdPartyConnectionResponse} returns this
 */
proto.business_endpoint.UpdateThirdPartyConnectionResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UpdateThirdPartyConnectionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.business_endpoint.UploadLogoResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.business_endpoint.UploadLogoResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.business_endpoint.UploadLogoResponse.ResultCase}
 */
proto.business_endpoint.UploadLogoResponse.prototype.getResultCase = function() {
  return /** @type {proto.business_endpoint.UploadLogoResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.business_endpoint.UploadLogoResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business_endpoint.UploadLogoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.business_endpoint.UploadLogoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business_endpoint.UploadLogoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UploadLogoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.business_endpoint.UploadLogoReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business_endpoint.UploadLogoResponse}
 */
proto.business_endpoint.UploadLogoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business_endpoint.UploadLogoResponse;
  return proto.business_endpoint.UploadLogoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business_endpoint.UploadLogoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business_endpoint.UploadLogoResponse}
 */
proto.business_endpoint.UploadLogoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business_endpoint.UploadLogoReplay;
      reader.readMessage(value,proto.business_endpoint.UploadLogoReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business_endpoint.UploadLogoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business_endpoint.UploadLogoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business_endpoint.UploadLogoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business_endpoint.UploadLogoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business_endpoint.UploadLogoReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UploadLogoReplay success = 1;
 * @return {?proto.business_endpoint.UploadLogoReplay}
 */
proto.business_endpoint.UploadLogoResponse.prototype.getSuccess = function() {
  return /** @type{?proto.business_endpoint.UploadLogoReplay} */ (
    jspb.Message.getWrapperField(this, proto.business_endpoint.UploadLogoReplay, 1));
};


/**
 * @param {?proto.business_endpoint.UploadLogoReplay|undefined} value
 * @return {!proto.business_endpoint.UploadLogoResponse} returns this
*/
proto.business_endpoint.UploadLogoResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.business_endpoint.UploadLogoResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business_endpoint.UploadLogoResponse} returns this
 */
proto.business_endpoint.UploadLogoResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business_endpoint.UploadLogoResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.business_endpoint);
