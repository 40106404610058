/**
 * @fileoverview gRPC-Web generated client stub for business_client_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as businessclient_pb from './businessclient_pb';


export class BusinessClientEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/business_client_endpoint.BusinessClientEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    businessclient_pb.AddBusinessClientRequest,
    businessclient_pb.AddBusinessClientResponse,
    (request: businessclient_pb.AddBusinessClientRequest) => {
      return request.serializeBinary();
    },
    businessclient_pb.AddBusinessClientResponse.deserializeBinary
  );

  add(
    request: businessclient_pb.AddBusinessClientRequest,
    metadata: grpcWeb.Metadata | null): Promise<businessclient_pb.AddBusinessClientResponse>;

  add(
    request: businessclient_pb.AddBusinessClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: businessclient_pb.AddBusinessClientResponse) => void): grpcWeb.ClientReadableStream<businessclient_pb.AddBusinessClientResponse>;

  add(
    request: businessclient_pb.AddBusinessClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: businessclient_pb.AddBusinessClientResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/business_client_endpoint.BusinessClientEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/business_client_endpoint.BusinessClientEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdateActivity = new grpcWeb.MethodDescriptor(
    '/business_client_endpoint.BusinessClientEndpoint/UpdateActivity',
    grpcWeb.MethodType.UNARY,
    businessclient_pb.UpdateBusinessClientStateRequest,
    businessclient_pb.UpdateBusinessClientActivityResponse,
    (request: businessclient_pb.UpdateBusinessClientStateRequest) => {
      return request.serializeBinary();
    },
    businessclient_pb.UpdateBusinessClientActivityResponse.deserializeBinary
  );

  updateActivity(
    request: businessclient_pb.UpdateBusinessClientStateRequest,
    metadata: grpcWeb.Metadata | null): Promise<businessclient_pb.UpdateBusinessClientActivityResponse>;

  updateActivity(
    request: businessclient_pb.UpdateBusinessClientStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: businessclient_pb.UpdateBusinessClientActivityResponse) => void): grpcWeb.ClientReadableStream<businessclient_pb.UpdateBusinessClientActivityResponse>;

  updateActivity(
    request: businessclient_pb.UpdateBusinessClientStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: businessclient_pb.UpdateBusinessClientActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/business_client_endpoint.BusinessClientEndpoint/UpdateActivity',
        request,
        metadata || {},
        this.methodDescriptorUpdateActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/business_client_endpoint.BusinessClientEndpoint/UpdateActivity',
    request,
    metadata || {},
    this.methodDescriptorUpdateActivity);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/business_client_endpoint.BusinessClientEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    businessclient_pb.DeleteBusinessClientRequest,
    businessclient_pb.DeleteBusinessClientResponse,
    (request: businessclient_pb.DeleteBusinessClientRequest) => {
      return request.serializeBinary();
    },
    businessclient_pb.DeleteBusinessClientResponse.deserializeBinary
  );

  delete(
    request: businessclient_pb.DeleteBusinessClientRequest,
    metadata: grpcWeb.Metadata | null): Promise<businessclient_pb.DeleteBusinessClientResponse>;

  delete(
    request: businessclient_pb.DeleteBusinessClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: businessclient_pb.DeleteBusinessClientResponse) => void): grpcWeb.ClientReadableStream<businessclient_pb.DeleteBusinessClientResponse>;

  delete(
    request: businessclient_pb.DeleteBusinessClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: businessclient_pb.DeleteBusinessClientResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/business_client_endpoint.BusinessClientEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/business_client_endpoint.BusinessClientEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetBusinessClients = new grpcWeb.MethodDescriptor(
    '/business_client_endpoint.BusinessClientEndpoint/GetBusinessClients',
    grpcWeb.MethodType.UNARY,
    businessclient_pb.GetBusinessClientsRequest,
    businessclient_pb.GetBusinessClientsResponse,
    (request: businessclient_pb.GetBusinessClientsRequest) => {
      return request.serializeBinary();
    },
    businessclient_pb.GetBusinessClientsResponse.deserializeBinary
  );

  getBusinessClients(
    request: businessclient_pb.GetBusinessClientsRequest,
    metadata: grpcWeb.Metadata | null): Promise<businessclient_pb.GetBusinessClientsResponse>;

  getBusinessClients(
    request: businessclient_pb.GetBusinessClientsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: businessclient_pb.GetBusinessClientsResponse) => void): grpcWeb.ClientReadableStream<businessclient_pb.GetBusinessClientsResponse>;

  getBusinessClients(
    request: businessclient_pb.GetBusinessClientsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: businessclient_pb.GetBusinessClientsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/business_client_endpoint.BusinessClientEndpoint/GetBusinessClients',
        request,
        metadata || {},
        this.methodDescriptorGetBusinessClients,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/business_client_endpoint.BusinessClientEndpoint/GetBusinessClients',
    request,
    metadata || {},
    this.methodDescriptorGetBusinessClients);
  }

  methodDescriptorGetBusinessClient = new grpcWeb.MethodDescriptor(
    '/business_client_endpoint.BusinessClientEndpoint/GetBusinessClient',
    grpcWeb.MethodType.UNARY,
    businessclient_pb.GetBusinessClientRequest,
    businessclient_pb.GetBusinessClientResponse,
    (request: businessclient_pb.GetBusinessClientRequest) => {
      return request.serializeBinary();
    },
    businessclient_pb.GetBusinessClientResponse.deserializeBinary
  );

  getBusinessClient(
    request: businessclient_pb.GetBusinessClientRequest,
    metadata: grpcWeb.Metadata | null): Promise<businessclient_pb.GetBusinessClientResponse>;

  getBusinessClient(
    request: businessclient_pb.GetBusinessClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: businessclient_pb.GetBusinessClientResponse) => void): grpcWeb.ClientReadableStream<businessclient_pb.GetBusinessClientResponse>;

  getBusinessClient(
    request: businessclient_pb.GetBusinessClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: businessclient_pb.GetBusinessClientResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/business_client_endpoint.BusinessClientEndpoint/GetBusinessClient',
        request,
        metadata || {},
        this.methodDescriptorGetBusinessClient,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/business_client_endpoint.BusinessClientEndpoint/GetBusinessClient',
    request,
    metadata || {},
    this.methodDescriptorGetBusinessClient);
  }

  methodDescriptorUpdateThirdPartyConnection = new grpcWeb.MethodDescriptor(
    '/business_client_endpoint.BusinessClientEndpoint/UpdateThirdPartyConnection',
    grpcWeb.MethodType.UNARY,
    businessclient_pb.UpdateThirdPartyConnectionRequest,
    businessclient_pb.UpdateThirdPartyConnectionResponse,
    (request: businessclient_pb.UpdateThirdPartyConnectionRequest) => {
      return request.serializeBinary();
    },
    businessclient_pb.UpdateThirdPartyConnectionResponse.deserializeBinary
  );

  updateThirdPartyConnection(
    request: businessclient_pb.UpdateThirdPartyConnectionRequest,
    metadata: grpcWeb.Metadata | null): Promise<businessclient_pb.UpdateThirdPartyConnectionResponse>;

  updateThirdPartyConnection(
    request: businessclient_pb.UpdateThirdPartyConnectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: businessclient_pb.UpdateThirdPartyConnectionResponse) => void): grpcWeb.ClientReadableStream<businessclient_pb.UpdateThirdPartyConnectionResponse>;

  updateThirdPartyConnection(
    request: businessclient_pb.UpdateThirdPartyConnectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: businessclient_pb.UpdateThirdPartyConnectionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/business_client_endpoint.BusinessClientEndpoint/UpdateThirdPartyConnection',
        request,
        metadata || {},
        this.methodDescriptorUpdateThirdPartyConnection,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/business_client_endpoint.BusinessClientEndpoint/UpdateThirdPartyConnection',
    request,
    metadata || {},
    this.methodDescriptorUpdateThirdPartyConnection);
  }

}

