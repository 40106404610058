import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../../../../repository/UserManagement/Managers/UserServiceClientPb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../app/Helpers';

import { TableState } from '../../../../../common/Table/TableSate';
import { number } from 'yup';
import { RoleEndpointClient } from '../../../../../../repository/UserManagement/Managers/RoleServiceClientPb';
import { DeleteRolePrivilegeRequest, DeleteRolePrivilegeResponse, GetRolePrivilegesRequest, GetRolePrivilegesResponse } from '../../../../../../repository/UserManagement/Managers/roleprivilege_pb';
import { RolePrivilegeEndpointClient } from '../../../../../../repository/UserManagement/Managers/RoleprivilegeServiceClientPb';


export interface RolePrivilegesPageState {
    privileges: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],

}

const initialState: RolePrivilegesPageState = {
    privileges: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: []

}

const client = new RolePrivilegeEndpointClient(API_URL, null, null);


export const getPrivileges = createAsyncThunk<GetRolePrivilegesResponse.AsObject, APIRequest<GetRolePrivilegesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/roleDetails/getPrivileges',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {

                var r = await client.getRolePrivileges(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }


        }
        return await callReq();
    }

)


export const removePrivilege = createAsyncThunk<APIResponse<DeleteRolePrivilegeResponse.AsObject>, APIRequest<DeleteRolePrivilegeRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/roleDetails/remove',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



const getFilters = (state: RolePrivilegesPageState): any[] => {

    return [];

}
export const managersRolePrivilegesDetailsPageSlice = createSlice({
    name: 'pages/management/roleDetails/privileges',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.privileges = initialState.privileges;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
        },
        resetPrivileges: (state) => {
            state.privileges.items = [];
            state.privileges.hasMore = true;
        },

        setSort: (state, action: PayloadAction<boolean>) => {
            state.privileges.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.privileges.numberOfResults = action.payload;
        },
        setSelectedPrivilege: (state, action: PayloadAction<any[]>) => {
            state.privileges.selected = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.privileges.isFilteredSet = action.payload;
        },
        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
        addPrivileges: (state, action: PayloadAction<any>) => {
            if (!state.privileges.isFilteredSet) {
                if (!state.privileges.hasMore && !state.privileges.isDescending) {
                    
                    action.payload?.forEach((e: any) => {
                        state.privileges.items.push(e);
                    })
                    // state.privileges.items.push(action.payload);
                } else if (state.privileges.isDescending) {
                    action.payload?.forEach((e: any) => {
                        state.privileges.items.unshift(e);
                    })

                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPrivileges.fulfilled, (state, { payload }) => {
            state.privileges.isFetching = false
            if (payload) {
                var r = (payload?.success?.roleprivilegesList.map(val => {
                    return {
                        id: val.id,
                        privilegeId: val.privilege?.id,
                        name: val.privilege?.name?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
                        addedById: val.addedby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.privileges.items.length != 0) {
                    l = state.privileges.items.slice(0, state.privileges.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.privileges.numberOfResults) {
                    state.privileges.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.privileges.items = l
                //console.log(state.items)

            } else {
                state.privileges.hasMore = false;
            }
        })
        builder.addCase(getPrivileges.rejected, (state, action) => {
            state.privileges.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getPrivileges.pending, (state, action) => {
            state.privileges.isFetching = true;
            state.message = undefined;
        })



        builder.addCase(removePrivilege.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.privileges.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        state.privileges.selected = []
                    }
                    var u = state.privileges.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.privileges.items.splice(u, 1);
                    }
                }
            }
        })
        builder.addCase(removePrivilege.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(removePrivilege.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })




    }
})

export const { reset, dismissMessage, setSort, setNumberOfResults, setMessage, resetPrivileges, setSelectedPrivilege, setSearchText, setIsFilteredSet, addPrivileges } = managersRolePrivilegesDetailsPageSlice.actions

export default managersRolePrivilegesDetailsPageSlice.reducer


