import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../../../../app/Helpers';
import { DataRestrictionEndpointClient } from '../../../../../../../../../repository/UserManagement/Managers/DatarestrictionServiceClientPb';
import { GetDataRestrictionResponse, GetDataRestrictionRequest, DeleteDataRestrictionResponse, DeleteDataRestrictionRequest } from '../../../../../../../../../repository/UserManagement/Managers/datarestriction_pb';
import { DynamicField } from '../../../../../../../../common/DynamicFields/DynamicFields';


export interface DataRestrictionDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: DataRestrictionDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const client = new  DataRestrictionEndpointClient(API_URL, null, null);


export const getItem = createAsyncThunk<GetDataRestrictionResponse.AsObject, APIRequest<GetDataRestrictionRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/dataRestrictionDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getDataRestriction(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const removeItem = createAsyncThunk<APIResponse<DeleteDataRestrictionResponse.AsObject>, APIRequest<DeleteDataRestrictionRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/dataRestrictionDetails/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const managersDataRestrictionDetailsPageSlice = createSlice({
    name: 'pages/management/dataRestrictionDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(getItem.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                let obj;
                try {
                    obj = JSON.parse(payload.success?.alloweddata?.value ?? "");
                } catch {
                    obj = undefined
                }
                let r = {
                    id: payload.success?.id,
                    groupId: payload.success?.group?.id,
                    groupName: payload.success?.group?.name?.value ?? "",
                    privilegeDataId: payload.success?.privilegedata?.id,
                    privilegeDataName: payload.success?.privilegedata?.name?.value ?? "",
                    privilegeDataType: payload.success?.privilegedata?.datatype?.value ?? false,
                    allowedData: obj ?? "",
                    enum: [] as DynamicField[],
                    blocked: [] as DynamicField[],
                    range: [] as DynamicField[],
                    regex: obj?.regex ?? undefined,
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.addedby?.firstname?.value ?? "") + " " + (payload.success?.addedby?.lastname?.value ?? ""),
                    addedById: payload.success?.addedby?.id?.value,
                }

                if (obj) {
                    let count = 0
                    obj?.enum?.forEach((element: string) => {
                        r.enum.push({ key: count + "", label: undefined, type: 1, value: element })
                        count++
                    });
                    obj?.blocked?.forEach((element: string) => {
                        r.blocked.push({ key: count + "", label: undefined, type: 1, value: element })
                        count++
                    });
                    count = 0;
                    obj?.range?.forEach((element: {from: string, to: string}) => {
                        r.range.push({ key: count + "", label: element.from, type: 1, value: element.to })
                        count++
                    });
                }
             
                state.item = r;

            
            }

        })
        builder.addCase(getItem.rejected, (state, action) => {
            state.isLoading = false;

            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItem.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })



        builder.addCase(removeItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
            }
        })
        builder.addCase(removeItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(removeItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, resetItem, updateItem } = managersDataRestrictionDetailsPageSlice.actions

export default managersDataRestrictionDetailsPageSlice.reducer