import { getTheme, IBasePickerStyleProps, IBasePickerStyles, IButtonProps, Icon, IconButton, IInputProps, IModalStyleProps, IModalStyles, IPeoplePickerProps, IPersonaProps, IPickerItemProps, ISearchBoxProps, IStyle, IStyleFunctionOrObject, ISuggestionItemProps, ISuggestionModel, ISuggestions, ISuggestionsProps, ITag, ITagPickerProps, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, SearchBox, Stack, TagItemSuggestion, TagPicker, Text, ValidationState, values } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { useImperativeHandle, useRef } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/Hooks";
import { getMainTheme, ThemeManager } from "../../../app/Themes";

interface ListProps {

    endButtons?: IButtonProps[],
    isPeoplePicker?: boolean,
    onChange?: (e?: any) => any,
    onEmptyInputFocus?: () => any,
    selectedItems?: any[]
    inputProps?: IInputProps
    disabled?: boolean
    suggestionsHeaderText?: string
    moreSuggestionsAvailable?: boolean
    onSuggestionClick?: (ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined, item?: any, index?: number | undefined) => void
    onGetMoreResults?: () => any
    suggestions?: ISuggestionModel<any>[];
    isLoading?: boolean;
    isSearching?: boolean
    onFilterChanged: (filterText: string, selectedItems?: any[]) => any[]
    styles?: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles>
}

export const List: React.FunctionComponent<ListProps> = (props) => {
    const [isFocused, setFoucsed] = useState(false);
    const [hasText, setHasText] = useState(false);
    const [key, setKey] = useState("def");
    const { t, i18n } = useTranslation();

    const stateTheme: { appTheme: string, isDarkModeEnabled: boolean } = useAppSelector((state) => {

        return { appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled }
    })

    const ref = useRef<ISuggestions<ITag>>(null)

 
    const normalSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: props.suggestionsHeaderText,
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: props.moreSuggestionsAvailable,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion >
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
   
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            if (props.onSuggestionClick)
                props?.onSuggestionClick(ev, item, index);
        },

        onGetMoreResults: () => {
            if (props.onGetMoreResults)
                props?.onGetMoreResults();
        },
    
        componentRef : ref,
        suggestions: props?.suggestions ?? [],
        isLoading: props?.isLoading,
        isSearching: props?.isSearching,

    };
    const peopleSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: props.suggestionsHeaderText,
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: props.moreSuggestionsAvailable,
        onRenderSuggestion: (props: IPersonaProps) => {

            return <Persona {...props}  size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            if (props.onSuggestionClick)
                props?.onSuggestionClick(ev, item, index);

        },

        onGetMoreResults: () => {
            if (props.onGetMoreResults)
                props?.onGetMoreResults();
        },
        suggestions: props?.suggestions ?? [],
        isLoading: props?.isLoading,
        isSearching: props?.isSearching,


    };


    const { endButtons, ...searchBoxProps } = props;
    return (
        <Stack horizontal >
            <Stack.Item grow>
                {props.isPeoplePicker ? <NormalPeoplePicker
                    inputProps={props?.inputProps}
        
                    pickerSuggestionsProps={props?.selectedItems?.length == 0?  peopleSuggestionsProps: undefined}

                    disabled={props.disabled}
                    styles={props.styles}
                    className={'ms-PeoplePicker'}
                    onRenderItem={((props: IPickerItemProps<IPersonaProps>) => {
                        const newProps = {
                            ...props,
                            item: {
                                ...props.item,
                                ValidationState: ValidationState.valid,
                                showSecondaryText: true,
                            },
                        };

                        return <PeoplePickerItem {...newProps} />;
                    })}
                    itemLimit={1}
                    selectedItems={props?.selectedItems ?? []}
                    onChange={(e) => {
                        if (props.onChange)
                            props.onChange(e)

                    }}
                    onEmptyInputFocus={() => {
                        if (props.onEmptyInputFocus)
                            props.onEmptyInputFocus()

                        return []
                    }}
                    onResolveSuggestions={props?.onFilterChanged}

                /> : <TagPicker
                    inputProps={props?.inputProps}
                    pickerSuggestionsProps={props?.selectedItems?.length == 0?  normalSuggestionsProps: undefined}
                    disabled={props.disabled}
                    itemLimit={1}
                    styles={props.styles}
                    selectedItems={props?.selectedItems ?? []}
                    onChange={(e) => {
                        if (props.onChange)
                            props.onChange(e)

                    }}
                    onEmptyResolveSuggestions={() => {
                        if (props.onEmptyInputFocus)
                            props.onEmptyInputFocus()

                        return []
                    }}
                    onResolveSuggestions={props?.onFilterChanged}

                />}
            </Stack.Item>
            {

                endButtons?.map((e, index) => {

                    return <IconButton disabled={props.disabled ?? e.disabled}
                        key={index} {...e}
                    ></IconButton>
                })
            }

        </Stack >

    );

}