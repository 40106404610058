/**
 * @fileoverview gRPC-Web generated client stub for customers_group_membership_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as groupmembership_pb from './groupmembership_pb';


export class GroupMembershipEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/customers_group_membership_endpoint.GroupMembershipEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    groupmembership_pb.AddGroupMembershipRequest,
    groupmembership_pb.AddGroupMembershipResponse,
    (request: groupmembership_pb.AddGroupMembershipRequest) => {
      return request.serializeBinary();
    },
    groupmembership_pb.AddGroupMembershipResponse.deserializeBinary
  );

  add(
    request: groupmembership_pb.AddGroupMembershipRequest,
    metadata: grpcWeb.Metadata | null): Promise<groupmembership_pb.AddGroupMembershipResponse>;

  add(
    request: groupmembership_pb.AddGroupMembershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: groupmembership_pb.AddGroupMembershipResponse) => void): grpcWeb.ClientReadableStream<groupmembership_pb.AddGroupMembershipResponse>;

  add(
    request: groupmembership_pb.AddGroupMembershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: groupmembership_pb.AddGroupMembershipResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/customers_group_membership_endpoint.GroupMembershipEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/customers_group_membership_endpoint.GroupMembershipEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/customers_group_membership_endpoint.GroupMembershipEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    groupmembership_pb.DeleteGroupMembershipRequest,
    groupmembership_pb.DeleteGroupMembershipResponse,
    (request: groupmembership_pb.DeleteGroupMembershipRequest) => {
      return request.serializeBinary();
    },
    groupmembership_pb.DeleteGroupMembershipResponse.deserializeBinary
  );

  delete(
    request: groupmembership_pb.DeleteGroupMembershipRequest,
    metadata: grpcWeb.Metadata | null): Promise<groupmembership_pb.DeleteGroupMembershipResponse>;

  delete(
    request: groupmembership_pb.DeleteGroupMembershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: groupmembership_pb.DeleteGroupMembershipResponse) => void): grpcWeb.ClientReadableStream<groupmembership_pb.DeleteGroupMembershipResponse>;

  delete(
    request: groupmembership_pb.DeleteGroupMembershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: groupmembership_pb.DeleteGroupMembershipResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/customers_group_membership_endpoint.GroupMembershipEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/customers_group_membership_endpoint.GroupMembershipEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetGroupMemberships = new grpcWeb.MethodDescriptor(
    '/customers_group_membership_endpoint.GroupMembershipEndpoint/GetGroupMemberships',
    grpcWeb.MethodType.UNARY,
    groupmembership_pb.GetGroupMembershipsRequest,
    groupmembership_pb.GetGroupMembershipsResponse,
    (request: groupmembership_pb.GetGroupMembershipsRequest) => {
      return request.serializeBinary();
    },
    groupmembership_pb.GetGroupMembershipsResponse.deserializeBinary
  );

  getGroupMemberships(
    request: groupmembership_pb.GetGroupMembershipsRequest,
    metadata: grpcWeb.Metadata | null): Promise<groupmembership_pb.GetGroupMembershipsResponse>;

  getGroupMemberships(
    request: groupmembership_pb.GetGroupMembershipsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: groupmembership_pb.GetGroupMembershipsResponse) => void): grpcWeb.ClientReadableStream<groupmembership_pb.GetGroupMembershipsResponse>;

  getGroupMemberships(
    request: groupmembership_pb.GetGroupMembershipsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: groupmembership_pb.GetGroupMembershipsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/customers_group_membership_endpoint.GroupMembershipEndpoint/GetGroupMemberships',
        request,
        metadata || {},
        this.methodDescriptorGetGroupMemberships,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/customers_group_membership_endpoint.GroupMembershipEndpoint/GetGroupMemberships',
    request,
    metadata || {},
    this.methodDescriptorGetGroupMemberships);
  }

  methodDescriptorGetGroupMembership = new grpcWeb.MethodDescriptor(
    '/customers_group_membership_endpoint.GroupMembershipEndpoint/GetGroupMembership',
    grpcWeb.MethodType.UNARY,
    groupmembership_pb.GetGroupMembershipRequest,
    groupmembership_pb.GetGroupMembershipResponse,
    (request: groupmembership_pb.GetGroupMembershipRequest) => {
      return request.serializeBinary();
    },
    groupmembership_pb.GetGroupMembershipResponse.deserializeBinary
  );

  getGroupMembership(
    request: groupmembership_pb.GetGroupMembershipRequest,
    metadata: grpcWeb.Metadata | null): Promise<groupmembership_pb.GetGroupMembershipResponse>;

  getGroupMembership(
    request: groupmembership_pb.GetGroupMembershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: groupmembership_pb.GetGroupMembershipResponse) => void): grpcWeb.ClientReadableStream<groupmembership_pb.GetGroupMembershipResponse>;

  getGroupMembership(
    request: groupmembership_pb.GetGroupMembershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: groupmembership_pb.GetGroupMembershipResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/customers_group_membership_endpoint.GroupMembershipEndpoint/GetGroupMembership',
        request,
        metadata || {},
        this.methodDescriptorGetGroupMembership,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/customers_group_membership_endpoint.GroupMembershipEndpoint/GetGroupMembership',
    request,
    metadata || {},
    this.methodDescriptorGetGroupMembership);
  }

}

