

import { DocumentCard, DocumentCardType, FocusTrapZone, FontIcon, getTheme, IconButton, ILabel, ILabelProps, ImageIcon, IModalStyleProps, IModalStyles, IStyleFunctionOrObject, ITextField, ITextFieldProps, ITextProps, ITooltipHostStyles, Label, Link, mergeStyles, Modal, PrimaryButton, Stack, Text, TextField, TooltipHost } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { t, use } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../app/Hooks";
import { getMainTheme, ThemeManager } from "../../../app/Themes";
import { ItemView } from "./ItemView";

interface ITextViewProps {
    label: string
    value: string,
    iconName?: string,
    
}

export const TextView: React.FunctionComponent<ITextViewProps & ITextProps> = (props) => {

    const { t, i18n } = useTranslation();

    const isLink = (val: string) => {
        try {
            if (!val) return false;
            var res = val.match(/^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm);
            return (res !== null)
        } catch {
            return false;
        }
    };
    const isEmail = (val: string) => {
        try {
            if (!val) return false;
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regEmail.test(val)) {
                return false;
            }
            return true;
        } catch {
            return false;
        }
    };
    const isPhoneNumber = (val: string) => {
        try {
            if (!val) return false;
            var res = val.match(/^(\+|00|0)[1-9][0-9 \-\(\)\.]{7,32}$/);
            return (res !== null)
        } catch {
            return false;
        }
    }
    // t("copy") onClick={() => { navigator.clipboard.writeText(props.value) }} 
    if ((props.value == undefined || props.value == "")) {
        return (null)
    }
    return (
        <ItemView onClick={() => { navigator.clipboard.writeText(props.value) }}
            label={props.label} iconProps={{ iconName: "Copy" }}  hintIconName={props.iconName} endButtonHint={t("copy")} title={props.label + ": " + props.value}>

            {isEmail(props.value) ? < Link href={"mailto:" + props.value} target="_blank" >
                {props.value}
            </Link > : isLink(props.value) ? <Link href={props.value} target="_blank">
                {props.value}
            </Link> : isPhoneNumber(props.value) ? <Link href={"tel:" + props.value} target="_blank">
                {props.value}
            </Link> : <Text styles={props.styles} variant={'mediumPlus'}>{props.value}</Text>}
        </ItemView >

    );




}

