// source: groupmembership.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('../common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.managers_group_membership_endpoint.AddGroupMembershipReplay', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.AddGroupMembershipRequest', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.AddGroupMembershipResponse', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.AddGroupMembershipResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.GetGroupMembershipReplay', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.GetGroupMembershipRequest', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.GetGroupMembershipResponse', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.GetGroupMembershipResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.GetGroupMembershipsReplay', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.GetGroupMembershipsRequest', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.GetGroupMembershipsResponse', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.GroupInfo', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.ProfileInfo', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse', null, global);
goog.exportSymbol('proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.AddGroupMembershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_group_membership_endpoint.AddGroupMembershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.AddGroupMembershipRequest.displayName = 'proto.managers_group_membership_endpoint.AddGroupMembershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.displayName = 'proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.displayName = 'proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.GetGroupMembershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_group_membership_endpoint.GetGroupMembershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.GetGroupMembershipRequest.displayName = 'proto.managers_group_membership_endpoint.GetGroupMembershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_group_membership_endpoint.GetGroupMembershipsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.displayName = 'proto.managers_group_membership_endpoint.GetGroupMembershipsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_group_membership_endpoint.AddGroupMembershipReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.AddGroupMembershipReplay.displayName = 'proto.managers_group_membership_endpoint.AddGroupMembershipReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.displayName = 'proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.displayName = 'proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_group_membership_endpoint.GetGroupMembershipReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.GetGroupMembershipReplay.displayName = 'proto.managers_group_membership_endpoint.GetGroupMembershipReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.repeatedFields_, null);
};
goog.inherits(proto.managers_group_membership_endpoint.GetGroupMembershipsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.displayName = 'proto.managers_group_membership_endpoint.GetGroupMembershipsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.ProfileInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_group_membership_endpoint.ProfileInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.ProfileInfo.displayName = 'proto.managers_group_membership_endpoint.ProfileInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.GroupInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_group_membership_endpoint.GroupInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.GroupInfo.displayName = 'proto.managers_group_membership_endpoint.GroupInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.displayName = 'proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.AddGroupMembershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_group_membership_endpoint.AddGroupMembershipResponse.oneofGroups_);
};
goog.inherits(proto.managers_group_membership_endpoint.AddGroupMembershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.AddGroupMembershipResponse.displayName = 'proto.managers_group_membership_endpoint.AddGroupMembershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.oneofGroups_);
};
goog.inherits(proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.displayName = 'proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.oneofGroups_);
};
goog.inherits(proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.displayName = 'proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.oneofGroups_);
};
goog.inherits(proto.managers_group_membership_endpoint.GetGroupMembershipsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.displayName = 'proto.managers_group_membership_endpoint.GetGroupMembershipsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_group_membership_endpoint.GetGroupMembershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_group_membership_endpoint.GetGroupMembershipResponse.oneofGroups_);
};
goog.inherits(proto.managers_group_membership_endpoint.GetGroupMembershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_group_membership_endpoint.GetGroupMembershipResponse.displayName = 'proto.managers_group_membership_endpoint.GetGroupMembershipResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.AddGroupMembershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.AddGroupMembershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.AddGroupMembershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    profileid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipRequest}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.AddGroupMembershipRequest;
  return proto.managers_group_membership_endpoint.AddGroupMembershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.AddGroupMembershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipRequest}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProfileid(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.AddGroupMembershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.AddGroupMembershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.AddGroupMembershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProfileid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 groupId = 1;
 * @return {number}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipRequest.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipRequest} returns this
 */
proto.managers_group_membership_endpoint.AddGroupMembershipRequest.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 profileId = 2;
 * @return {number}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipRequest.prototype.getProfileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipRequest} returns this
 */
proto.managers_group_membership_endpoint.AddGroupMembershipRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Int32Value organization = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipRequest} returns this
*/
proto.managers_group_membership_endpoint.AddGroupMembershipRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipRequest} returns this
 */
proto.managers_group_membership_endpoint.AddGroupMembershipRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest;
  return proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest} returns this
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value organization = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest} returns this
*/
proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest} returns this
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest;
  return proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest} returns this
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value organization = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest} returns this
*/
proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest} returns this
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.GetGroupMembershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GetGroupMembershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipRequest}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.GetGroupMembershipRequest;
  return proto.managers_group_membership_endpoint.GetGroupMembershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipRequest}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.GetGroupMembershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GetGroupMembershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipRequest} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value organization = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipRequest} returns this
*/
proto.managers_group_membership_endpoint.GetGroupMembershipRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipRequest} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    nextto: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numofresults: jspb.Message.getFieldWithDefault(msg, 4, 0),
    groupid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    usertype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsRequest}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.GetGroupMembershipsRequest;
  return proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsRequest}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextto(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsertype(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getNextto();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getGroupid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getUsertype();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue search = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsRequest} returns this
*/
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsRequest} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool order = 2;
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsRequest} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int64 nextTo = 3;
 * @return {number}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.getNextto = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsRequest} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.setNextto = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 numOfResults = 4;
 * @return {number}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsRequest} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 groupId = 5;
 * @return {number}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsRequest} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 userType = 6;
 * @return {number}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.getUsertype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsRequest} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.Int32Value organization = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsRequest} returns this
*/
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsRequest} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.AddGroupMembershipReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.AddGroupMembershipReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupmembershipid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipReplay}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.AddGroupMembershipReplay;
  return proto.managers_group_membership_endpoint.AddGroupMembershipReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.AddGroupMembershipReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipReplay}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupmembershipid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.AddGroupMembershipReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.AddGroupMembershipReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupmembershipid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 groupMembershipId = 1;
 * @return {number}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.prototype.getGroupmembershipid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipReplay} returns this
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.prototype.setGroupmembershipid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipReplay} returns this
*/
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipReplay} returns this
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipReplay} returns this
*/
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipReplay} returns this
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay;
  return proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay} returns this
*/
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay} returns this
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay} returns this
*/
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay} returns this
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteddate: (f = msg.getDeleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay;
  return proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleteddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp deletedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.prototype.getDeleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay} returns this
*/
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.prototype.setDeleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay} returns this
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.prototype.clearDeleteddate = function() {
  return this.setDeleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.prototype.hasDeleteddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay} returns this
*/
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay} returns this
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.GetGroupMembershipReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    group: (f = msg.getGroup()) && proto.managers_group_membership_endpoint.GroupInfo.toObject(includeInstance, f),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    profile: (f = msg.getProfile()) && proto.managers_group_membership_endpoint.ProfileInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipReplay}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.GetGroupMembershipReplay;
  return proto.managers_group_membership_endpoint.GetGroupMembershipReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipReplay}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.managers_group_membership_endpoint.GroupInfo;
      reader.readMessage(value,proto.managers_group_membership_endpoint.GroupInfo.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 3:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 5:
      var value = new proto.managers_group_membership_endpoint.ProfileInfo;
      reader.readMessage(value,proto.managers_group_membership_endpoint.ProfileInfo.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.GetGroupMembershipReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.managers_group_membership_endpoint.GroupInfo.serializeBinaryToWriter
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.managers_group_membership_endpoint.ProfileInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipReplay} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional GroupInfo group = 2;
 * @return {?proto.managers_group_membership_endpoint.GroupInfo}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.getGroup = function() {
  return /** @type{?proto.managers_group_membership_endpoint.GroupInfo} */ (
    jspb.Message.getWrapperField(this, proto.managers_group_membership_endpoint.GroupInfo, 2));
};


/**
 * @param {?proto.managers_group_membership_endpoint.GroupInfo|undefined} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipReplay} returns this
*/
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipReplay} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.ProfileResponse addedBy = 3;
 * @return {?proto.common.ProfileResponse}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 3));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipReplay} returns this
*/
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipReplay} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipReplay} returns this
*/
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipReplay} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ProfileInfo profile = 5;
 * @return {?proto.managers_group_membership_endpoint.ProfileInfo}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.getProfile = function() {
  return /** @type{?proto.managers_group_membership_endpoint.ProfileInfo} */ (
    jspb.Message.getWrapperField(this, proto.managers_group_membership_endpoint.ProfileInfo, 5));
};


/**
 * @param {?proto.managers_group_membership_endpoint.ProfileInfo|undefined} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipReplay} returns this
*/
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipReplay} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipReplay.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupmembershipsList: jspb.Message.toObjectList(msg.getGroupmembershipsList(),
    proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsReplay}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.GetGroupMembershipsReplay;
  return proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsReplay}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo;
      reader.readMessage(value,proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.deserializeBinaryFromReader);
      msg.addGroupmemberships(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupmembershipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GroupMembershipSummaryInfo groupMemberships = 1;
 * @return {!Array<!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo>}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.prototype.getGroupmembershipsList = function() {
  return /** @type{!Array<!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo, 1));
};


/**
 * @param {!Array<!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo>} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsReplay} returns this
*/
proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.prototype.setGroupmembershipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.prototype.addGroupmemberships = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsReplay} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.prototype.clearGroupmembershipsList = function() {
  return this.setGroupmembershipsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.ProfileInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.ProfileInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.ProfileInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.ProfileInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    firstname: (f = msg.getFirstname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    middlename: (f = msg.getMiddlename()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastname: (f = msg.getLastname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.ProfileInfo}
 */
proto.managers_group_membership_endpoint.ProfileInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.ProfileInfo;
  return proto.managers_group_membership_endpoint.ProfileInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.ProfileInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.ProfileInfo}
 */
proto.managers_group_membership_endpoint.ProfileInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstname(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMiddlename(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.ProfileInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.ProfileInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.ProfileInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.ProfileInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getFirstname();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMiddlename();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastname();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int64Value id = 1;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.managers_group_membership_endpoint.ProfileInfo.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.managers_group_membership_endpoint.ProfileInfo} returns this
*/
proto.managers_group_membership_endpoint.ProfileInfo.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.ProfileInfo} returns this
 */
proto.managers_group_membership_endpoint.ProfileInfo.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.ProfileInfo.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue firstName = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_group_membership_endpoint.ProfileInfo.prototype.getFirstname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_group_membership_endpoint.ProfileInfo} returns this
*/
proto.managers_group_membership_endpoint.ProfileInfo.prototype.setFirstname = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.ProfileInfo} returns this
 */
proto.managers_group_membership_endpoint.ProfileInfo.prototype.clearFirstname = function() {
  return this.setFirstname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.ProfileInfo.prototype.hasFirstname = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue middleName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_group_membership_endpoint.ProfileInfo.prototype.getMiddlename = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_group_membership_endpoint.ProfileInfo} returns this
*/
proto.managers_group_membership_endpoint.ProfileInfo.prototype.setMiddlename = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.ProfileInfo} returns this
 */
proto.managers_group_membership_endpoint.ProfileInfo.prototype.clearMiddlename = function() {
  return this.setMiddlename(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.ProfileInfo.prototype.hasMiddlename = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue lastName = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_group_membership_endpoint.ProfileInfo.prototype.getLastname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_group_membership_endpoint.ProfileInfo} returns this
*/
proto.managers_group_membership_endpoint.ProfileInfo.prototype.setLastname = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.ProfileInfo} returns this
 */
proto.managers_group_membership_endpoint.ProfileInfo.prototype.clearLastname = function() {
  return this.setLastname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.ProfileInfo.prototype.hasLastname = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.GroupInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.GroupInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.GroupInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GroupInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.GroupInfo}
 */
proto.managers_group_membership_endpoint.GroupInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.GroupInfo;
  return proto.managers_group_membership_endpoint.GroupInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.GroupInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.GroupInfo}
 */
proto.managers_group_membership_endpoint.GroupInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.GroupInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.GroupInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.GroupInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GroupInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.managers_group_membership_endpoint.GroupInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_group_membership_endpoint.GroupInfo} returns this
 */
proto.managers_group_membership_endpoint.GroupInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_group_membership_endpoint.GroupInfo.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_group_membership_endpoint.GroupInfo} returns this
*/
proto.managers_group_membership_endpoint.GroupInfo.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.GroupInfo} returns this
 */
proto.managers_group_membership_endpoint.GroupInfo.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.GroupInfo.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    profile: (f = msg.getProfile()) && proto.managers_group_membership_endpoint.ProfileInfo.toObject(includeInstance, f),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo}
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo;
  return proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo}
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.managers_group_membership_endpoint.ProfileInfo;
      reader.readMessage(value,proto.managers_group_membership_endpoint.ProfileInfo.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 3:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.managers_group_membership_endpoint.ProfileInfo.serializeBinaryToWriter
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo} returns this
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ProfileInfo profile = 2;
 * @return {?proto.managers_group_membership_endpoint.ProfileInfo}
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.getProfile = function() {
  return /** @type{?proto.managers_group_membership_endpoint.ProfileInfo} */ (
    jspb.Message.getWrapperField(this, proto.managers_group_membership_endpoint.ProfileInfo, 2));
};


/**
 * @param {?proto.managers_group_membership_endpoint.ProfileInfo|undefined} value
 * @return {!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo} returns this
*/
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo} returns this
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.ProfileResponse addedBy = 3;
 * @return {?proto.common.ProfileResponse}
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 3));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo} returns this
*/
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo} returns this
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo} returns this
*/
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo} returns this
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.GroupMembershipSummaryInfo.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_group_membership_endpoint.AddGroupMembershipResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_group_membership_endpoint.AddGroupMembershipResponse.ResultCase}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_group_membership_endpoint.AddGroupMembershipResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_group_membership_endpoint.AddGroupMembershipResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.AddGroupMembershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.AddGroupMembershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.AddGroupMembershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_group_membership_endpoint.AddGroupMembershipReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipResponse}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.AddGroupMembershipResponse;
  return proto.managers_group_membership_endpoint.AddGroupMembershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.AddGroupMembershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipResponse}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_group_membership_endpoint.AddGroupMembershipReplay;
      reader.readMessage(value,proto.managers_group_membership_endpoint.AddGroupMembershipReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.AddGroupMembershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.AddGroupMembershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.AddGroupMembershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_group_membership_endpoint.AddGroupMembershipReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddGroupMembershipReplay success = 1;
 * @return {?proto.managers_group_membership_endpoint.AddGroupMembershipReplay}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_group_membership_endpoint.AddGroupMembershipReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_group_membership_endpoint.AddGroupMembershipReplay, 1));
};


/**
 * @param {?proto.managers_group_membership_endpoint.AddGroupMembershipReplay|undefined} value
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipResponse} returns this
*/
proto.managers_group_membership_endpoint.AddGroupMembershipResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_group_membership_endpoint.AddGroupMembershipResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.AddGroupMembershipResponse} returns this
 */
proto.managers_group_membership_endpoint.AddGroupMembershipResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.AddGroupMembershipResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.ResultCase}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse;
  return proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay;
      reader.readMessage(value,proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateGroupMembershipReplay success = 1;
 * @return {?proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay, 1));
};


/**
 * @param {?proto.managers_group_membership_endpoint.UpdateGroupMembershipReplay|undefined} value
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse} returns this
*/
proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse} returns this
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.UpdateGroupMembershipResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.ResultCase}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse;
  return proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay;
      reader.readMessage(value,proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteGroupMembershipReplay success = 1;
 * @return {?proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay, 1));
};


/**
 * @param {?proto.managers_group_membership_endpoint.DeleteGroupMembershipReplay|undefined} value
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse} returns this
*/
proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse} returns this
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.DeleteGroupMembershipResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.ResultCase}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsResponse}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.GetGroupMembershipsResponse;
  return proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsResponse}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_group_membership_endpoint.GetGroupMembershipsReplay;
      reader.readMessage(value,proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_group_membership_endpoint.GetGroupMembershipsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetGroupMembershipsReplay success = 1;
 * @return {?proto.managers_group_membership_endpoint.GetGroupMembershipsReplay}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_group_membership_endpoint.GetGroupMembershipsReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_group_membership_endpoint.GetGroupMembershipsReplay, 1));
};


/**
 * @param {?proto.managers_group_membership_endpoint.GetGroupMembershipsReplay|undefined} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsResponse} returns this
*/
proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipsResponse} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_group_membership_endpoint.GetGroupMembershipResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_group_membership_endpoint.GetGroupMembershipResponse.ResultCase}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_group_membership_endpoint.GetGroupMembershipResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_group_membership_endpoint.GetGroupMembershipResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_group_membership_endpoint.GetGroupMembershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GetGroupMembershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_group_membership_endpoint.GetGroupMembershipReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipResponse}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_group_membership_endpoint.GetGroupMembershipResponse;
  return proto.managers_group_membership_endpoint.GetGroupMembershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipResponse}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_group_membership_endpoint.GetGroupMembershipReplay;
      reader.readMessage(value,proto.managers_group_membership_endpoint.GetGroupMembershipReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_group_membership_endpoint.GetGroupMembershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_group_membership_endpoint.GetGroupMembershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_group_membership_endpoint.GetGroupMembershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_group_membership_endpoint.GetGroupMembershipReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetGroupMembershipReplay success = 1;
 * @return {?proto.managers_group_membership_endpoint.GetGroupMembershipReplay}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_group_membership_endpoint.GetGroupMembershipReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_group_membership_endpoint.GetGroupMembershipReplay, 1));
};


/**
 * @param {?proto.managers_group_membership_endpoint.GetGroupMembershipReplay|undefined} value
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipResponse} returns this
*/
proto.managers_group_membership_endpoint.GetGroupMembershipResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_group_membership_endpoint.GetGroupMembershipResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_group_membership_endpoint.GetGroupMembershipResponse} returns this
 */
proto.managers_group_membership_endpoint.GetGroupMembershipResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_group_membership_endpoint.GetGroupMembershipResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.managers_group_membership_endpoint);
