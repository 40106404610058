import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, DialogFooter, CheckboxVisibility, TooltipHost, Icon, IComboBoxOption } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/Hooks"
import { getPage } from "../../../app/Pages"
import { setPage } from "../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../common/Table/Table"
import { reset, dismissMessage, setSort, setNumberOfResults, setSearchText, setIsFilteredSet, addItem, updateItem, getItems, resetItems, setActivityFilterOption, updateActivity, updateThirdPartyConnection, setSelectedItems, deleteItem, getBusinesses, resetBusinesses, getClinetUsers, setConnectionMetaDataFilterOption, resetClients, setIsFilteredClientsSet, setIsFilteredBusinessesSet, setSelectedBusinessItems, setSelectedClientItems, updateConnection } from "./BusinessClientsPageSlice"
import { ApiMessage, getHeaders } from "../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { SearchField } from "../../common/SearchField/SearchField"
import { currencyFormatter, formatDate, printComponent, normalizeKey } from "../../../app/Helpers"
import { TableState } from "../../common/Table/TableSate"
import { Message } from "../../common/Message/Message"
import { ShareBox } from "../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../common/Popup/Popup"
import { FormType } from "../../forms/FormProps"
import { AccountStatus, LimitationType, Operations } from "../../../app/Enums"
import { GetBusinessResponse, GetBusinessesResponse, GetBusinessRequest, GetBusinessesRequest } from "../../../repository/UserManagement/Dimensions/business_pb"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../PageProps"
import { List } from "../../common/List/List"
import { UsersPage } from "../Customers/Users/UsersPage"
import { BusinessesForm } from "../../forms/Businesses/BusinessesForm"
import { countriesAR, countriesEn } from "../../../app/Content"
import { BusinessThirdPartyForm } from "../../forms/Businesses/BusinessThirdPartyForm"
import { DeleteBusinessClientRequest, GetBusinessClientsRequest, UpdateBusinessClientStateRequest } from "../../../repository/UserManagement/Dimensions/businessclient_pb"
import { GetUsersRequest } from "../../../repository/UserManagement/Customers/user_pb"
import { BusinessesPage } from "../Businesses/BusinessesPage"
import { BusinessClientsForm } from "../../forms/BusinessClients/BusinessClientsForm"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

let req: GetBusinessClientsRequest;
let businessesReq: GetBusinessesRequest;
let clintsReq: GetUsersRequest;

let org: number = -1;

let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;

const key = "businessClients"
export const BusinessClientsPage: React.FunctionComponent<PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        isChangeStateLoading: boolean,
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        searchText: string | undefined,
        filters: any[],
        isActiveFilterOption: { key: number, text: string }
        connectionMetaDataFilterOption: { value: string, text: string }
        clients: TableState,
        businesses: TableState,



    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.businessClientsPage.isChangeStateLoading,
            set: state.businessClientsPage.items,
            message: state.businessClientsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            searchText: state.businessClientsPage.searchText,
            filters: state.businessClientsPage.filters,
            isActiveFilterOption: state.businessClientsPage.isActiveFilterOption,
            connectionMetaDataFilterOption: state.businessClientsPage.connectionMetaDataFilterOption,
            clients: state.businessClientsPage.clients,
            businesses: state.businessClientsPage.businesses,


        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const closeAccountActionConfirmationcalloutTargetId = useId('close-account-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');

    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)

    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true), true)
        }

    });
    const getActionId = (action: number): string => {
        if (action == 1) {
            return activityActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return deleteActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedItems(_selection.getSelection()));
            } else {
                dispatch(setSelectedItems([]));
            }
        },

    }));

    const clearFilter = () => {
        dispatch(setActivityFilterOption({ key: 0, text: "" }))
        req.setIsactive(undefined)
        dispatch(setConnectionMetaDataFilterOption({ value: "", text: "" }))
        req.setConnectionmetadata(undefined)
        dispatch(setSelectedClientItems([]))
        req.setClient(undefined)
        dispatch(setSelectedBusinessItems([]))
        req.setBusiness(undefined)

    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }




        if (state.isActiveFilterOption.key != 0) {
            const wrapper = new BoolValue();
            wrapper.setValue(state.isActiveFilterOption.key == 1);
            req.setIsactive(wrapper)
            isFiltered = true;

        } else {
            req.setIsactive(undefined)
        }
        if (state.businesses.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.businesses.selected.at(0)?.id));
            req.setBusiness(wrapper)
            isFiltered = true;
        } else {
            req.setBusiness(undefined)
        }

        if (state.clients.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.clients.selected.at(0)?.internalId));
            req.setClient(wrapper)
            isFiltered = true;
        } else {
            req.setClient(undefined)
        }



        if (state.connectionMetaDataFilterOption && state.connectionMetaDataFilterOption.value.trim().length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue(state.connectionMetaDataFilterOption.value.trim());
            req.setConnectionmetadata(wrapper)
            isFiltered = true;

        } else {
            req.setConnectionmetadata(undefined)
        }

        req.setNextto(undefined)
        dispatch(resetItems())
        dispatch(setIsFilteredSet(isFiltered))
        getItemsPromise?.abort()
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {
        req = new GetBusinessClientsRequest();
        businessesReq = new GetBusinessesRequest();
        clintsReq = new GetUsersRequest();

        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                businessesReq.setOrganization(wrapper)
                clintsReq.setOrganization(wrapper)

            }
        }
        if (!props.isSelection) {
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
        }

        req.setNextto(undefined)
        req.setNumofresults(state.set.numberOfResults)
        req.setOrder(state.set.isDescending)


        const boolv = new BoolValue();
        boolv.setValue(true);

        businessesReq.setNumofresults(state.set.numberOfResults)
        businessesReq.setOrder(state.set.isDescending)
       // businessesReq.setIsavailable(boolv)

        clintsReq.setNumofresults(state.clients.numberOfResults)
        clintsReq.setOrder(state.clients.isDescending)

        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))


        return () => { //clean up
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])
    /*
    
          public long Id { get; set; }
                public string InternalId { get; set; }
                public string Name { get; set; }
                public string Code { get; set; }
                public decimal MirrorBalance { get; set; }
                public CurrencyResponse Currency { get; set; }
                public int Status { get; set; }
                public AccountTypeResponse AccountType { get; set; }
                public OwnerResponse Owner { get; set; }
                public ProfileResponse CreatedBy { get; set; }
                public DateTime CreatedDate { get; set; }
    */

    const c: IColumn[] = [
        {
            key: 'id',
            name: t("id"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.id != undefined)
                    return <Link onClick={(e) => {
                        if (e) {
                            e.preventDefault();
                            navigate("/business-clients/" + item?.id + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/business-clients/" + item?.id + ((org == -1) ? "" : "?org=" + org)} > {item?.id}</Link >;
            },
        },
        {
            key: 'businessName',
            name: t("business"),
            minWidth: 20,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e) {
                        e.preventDefault();
                        navigate("/businesses/" + item?.businessId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/businesses/" + item?.businessId + ((org == -1) ? "" : "?org=" + org)} ><Persona
                        text={item?.businessName == "" ? item?.businessId : item?.businessName}

                        size={PersonaSize.size40}
                        imageUrl={item?.logoSource && item?.logoPath ? item?.logoSource + "" + item?.logoPath : undefined}

                    /></Link>
            },
        },

        {
            key: 'clientName',
            name: t("client"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e) {
                        e.preventDefault();
                        navigate("/customers/users/" + item?.clientId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/customers/users/" + item?.clientId + ((org == -1) ? "" : "?org=" + org)} ><Persona
                        text={item?.clientName == "" ? item?.clientId : item?.clientName}
                        size={PersonaSize.size40}
                    /></Link>

            },
        },
        {
            key: 'isActive',
            name: t("status"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Text>{item?.isActive ? t("active") : t("inactive")}</Text>;
            },
        },
        {
            key: 'connectionId',
            name: t("thirdPartyConnection"),
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {//
                if (item?.connectionId)
                    return <Link
                        href={"https://cs.sarafa.ly/clients/users/" + item?.connectionId + "?org="+ item?.connectionOrganization } target="_blank" > {item?.connectionMetaData == "" ? item?.connectionId : item?.connectionMetaData + " (" + item?.connectionId + ")"}</Link >;

            },
        },

     

        {
            key: 'createdDate',
            name: t("createdDate"),
            minWidth: 20,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },



    ];

    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'editThirdPartyConnection',
            text: t("editThirdPartyConnection"),
            title: t("editThirdPartyConnection"),
            iconProps: { iconName: 'PlugDisconnected', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(102)
                }
            }
        },

        {
            key: 'status',
            text: (state.set.selected.length > 0 && state.set.selected.at(0)?.isActive) ? t("deactivate") : t("activate"),
            title: (state.set.selected.length > 0 && state.set.selected.at(0)?.isActive) ? t("deactivate") : t("activate"),
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: (state.set.selected.length > 0 && state.set.selected.at(0)?.isActive) ? "Cancel" : "Accept", styles: { root: { fontWeight: (state.set.selected.length > 0 && state.set.selected.at(0)?.isActive) ? undefined : "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'delete',
            text: t("delete"),
            title: t("delete"),

            iconProps: { id: deleteActionConfirmationcalloutTargetId, iconName: "Delete", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.set.selected.at(0)?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },

        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },


    ];

    const mainOptions: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: t('add'),
            title: t("add"),
            iconProps: { iconName: 'Add' },
            onClick: () => {
                //  navigate("add")
                setCurrenctAction(100)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t("refresh"),
            iconProps: { iconName: 'refresh' },
            disabled: state.set.isFetching,
            onClick: () => {
                dispatch(resetItems())
                req.setNextto(undefined)
                //   if (!state.searchText || state.searchText?.trim().length == 0) {
                //     req.setSearch(undefined)
                //}
                getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },
    ];
    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t('sort'),
            iconProps: { iconName: 'sort' },
            disabled: state.set.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t('ascendingDate'),
                    canCheck: true,
                    checked: !state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetItems())
                        req.setOrder(false)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }


                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t('descendingDate'),
                    canCheck: true,
                    checked: state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetItems())
                        req.setOrder(true)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.set.numberOfResults,
            title: t('results') + ': ' + state.set.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];


    const onClientsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetClients())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        clintsReq.setSearch(wrapper)
        clintsReq.setNextto(undefined)
        dispatch(setIsFilteredClientsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
        return [];

    }
    const onBusinessessFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetBusinesses())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        businessesReq.setSearch(wrapper)
        businessesReq.setNextto(undefined)
        dispatch(setIsFilteredBusinessesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getBusinesses({ body: businessesReq, headers: getHeaders() }))
        return [];

    }





    return (
        <Stack >


            <Popup isOpen={(currentAction == 100)} title={t("addBusinessClient")} onDismiss={() => { setCurrenctAction(0) }} >

                <BusinessClientsForm childrenGap={10} maxWidth={380} type={FormType.ADD} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(addItem(e))

                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 102)} title={t("editThirdPartyConnection")} onDismiss={() => { setCurrenctAction(0) }} >

                <BusinessClientsForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={state.set.selected.at(0)} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateConnection(e))
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 1000)} title={t("clients")} onDismiss={() => { setCurrenctAction(0) }} >

                <UsersPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedClientItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 1001)} title={t("businesses")} onDismiss={() => { setCurrenctAction(0) }} >

                <BusinessesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedBusinessItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={state.set.selected.length == 0 ? mainOptions : _selectionItems}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}


            />



            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareBusinessClient")} value={`https://${window.location.host}/business-clients/${state.set.selected.at(0).id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 2 ? t("delete") : currentAction == 1 ? (state.set.selected.at(0)?.isActive) ? t("deactivate") : t("activate") : "")}

                        </Text>
                        <Text block variant="small">
                            {(currentAction == 2 ? t("deleteBusinessClientDesc") : currentAction == 1 ? (state.set.selected.at(0)?.isActive) ? t("deactivateBusinessClientDesc") : t("activateBusinessClientDesc") : "")}

                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.set.selected.length > 0) {
                                        if (currentAction == 1) {
                                            let up = new UpdateBusinessClientStateRequest();
                                            up.setId(state.set.selected.at(0)?.id)
                                            up.setIsactive(!state.set.selected.at(0)?.isActive)
                                            dispatch(updateActivity({ body: up, headers: getHeaders() }))

                                        } else if (currentAction == 2) {
                                            let req = new DeleteBusinessClientRequest();
                                            req.setId(state.set.selected.at(0)?.id)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(deleteItem({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })


                                        }
                                    }
                                }} text={(currentAction == 2 ? t("delete") : currentAction == 1 ? (state.set.selected.at(0)?.isActive) ? t("deactivate") : t("activate") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {isCalloutVisible ? (
                <Callout
                    ariaLabelledBy={labelId}

                    target={`#${calloutTargetId}`}
                    onDismiss={toggleIsCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={true}
                >
                    <Stack tokens={{ padding: 28, childrenGap: 30 }}  >
                        <Text block variant="mediumPlus" >
                            {t("filterDesc")}
                        </Text>

                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label>{t("status")}</Label>
                            <Dropdown
                                selectedKey={state.isActiveFilterOption.key}
                                options={[{ key: 0, text: t("activeAndInactive") }, { key: 1, text: t("active") }, { key: 2, text: t("inactive") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setActivityFilterOption({ key: Number(item?.key), text: t("status") + ":" + item?.text ?? "" }))

                                })}

                            />
                        </Stack>


                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label >{t("business")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                isPeoplePicker={true}
                                suggestionsHeaderText={t('businesses')}
                                isLoading={state.businesses.items.length == 0 ? state.businesses.isFetching : false}
                                isSearching={state.businesses.items.length > 0 ? state.businesses.isFetching : false}
                                moreSuggestionsAvailable={state.businesses.hasMore && !state.businesses.isFetching}
                                suggestions={state.businesses.items.length > 0 ? state.businesses.items.map(e => { return { item: { key: e.id, text: e.name, imageUrl: e?.logoSource && e?.logoPath ? e?.logoSource + "" + e?.logoPath : undefined } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.businesses.items.length > 0 && !state.businesses.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.businesses.items.at(state.businesses.items.length - 1).id);
                                        businessesReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getBusinesses({ body: businessesReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.businesses.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedBusinessItems([state.businesses.items.at(u)]))
                                    }

                                }}
                                inputProps={{ placeholder: t("business") }}

                                selectedItems={state.businesses.selected.length > 0 ? state.businesses.selected.map(e => { return { key: e.id, text: e.name, imageUrl: e?.logoSource && e?.logoPath ? e?.logoSource + "" + e?.logoPath : undefined } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedBusinessItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetBusinesses())
                                    businessesReq.setSearch(undefined)
                                    businessesReq.setNextto(undefined)
                                    dispatch(setIsFilteredBusinessesSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getBusinesses({ body: businessesReq, headers: getHeaders() }))
                                    return []

                                }}
                                onFilterChanged={onBusinessessFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1001)

                                        }
                                    }]}
                            />
                        </Stack>

                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label >{t("client")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                isPeoplePicker={true}
                                suggestionsHeaderText={t('clients')}
                                isLoading={state.clients.items.length == 0 ? state.clients.isFetching : false}
                                isSearching={state.clients.items.length > 0 ? state.clients.isFetching : false}
                                moreSuggestionsAvailable={state.clients.hasMore && !state.clients.isFetching}
                                suggestions={state.clients.items.length > 0 ? state.clients.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.clients.items.length > 0 && !state.clients.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.clients.items.at(state.clients.items.length - 1).internalId);
                                        clintsReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.clients.items.findIndex(e => e.internalId == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedClientItems([state.clients.items.at(u)]))
                                    }

                                }}
                                inputProps={{ placeholder: t("client") }}

                                selectedItems={state.clients.selected.length > 0 ? state.clients.selected.map(e => { return { key: e.internalId, text: e.name, secondaryText: e.username, } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedClientItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetClients())
                                    clintsReq.setSearch(undefined)
                                    clintsReq.setNextto(undefined)
                                    dispatch(setIsFilteredClientsSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                    return []

                                }}
                                onFilterChanged={onClientsFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1000)

                                        }
                                    }]}
                            />
                        </Stack>


                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label>{t("clientThirdPartyName")}</Label>
                            <TextField onChange={(e: any) => {
                                if (e)
                                    dispatch(setConnectionMetaDataFilterOption({ value: e?.target?.value, text: t("clientThirdPartyName") + ":" + e?.target?.value }))

                            }} placeholder="abs@gmail.com" maxLength={50} value={state.connectionMetaDataFilterOption.value} styles={{ root: { width: 300 } }} />

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton text={t("search")} onClick={(e => {
                                search(state.searchText);
                                toggleIsCalloutVisible();
                            })} />
                            <DefaultButton text={t("clearFilter")} onClick={() => {
                                clearFilter();
                            }} />

                        </DialogFooter>
                    </Stack>
                </Callout>
            ) : null}


            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            { id: calloutTargetId, title: t("filter"), iconProps: { iconName: "filter" }, onClick: toggleIsCalloutVisible },
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                    if (isCalloutVisible)
                                        toggleIsCalloutVisible();

                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {

                                if (e.key == 1) {
                                    dispatch(setActivityFilterOption({ key: 0, text: "" }))
                                    req.setIsactive(undefined)
                                }
                                if (e.key == 2) {
                                    dispatch(setSelectedBusinessItems([]))
                                    req.setBusiness(undefined)
                                }
                                if (e.key == 3) {
                                    dispatch(setSelectedClientItems([]))
                                    req.setClient(undefined)
                                }
                                if (e.key == 4) {
                                    dispatch(setConnectionMetaDataFilterOption({ value: "", text: "" }))
                                    req.setConnectionmetadata(undefined)
                                }
                            });
                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                            if (isCalloutVisible)
                                toggleIsCalloutVisible();
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.set.isFilteredSet) {
                                search(undefined);
                            }
                            /*if (req.getSearch() && !state.managers.isFilteredSet) {
                                dispatch(resetRoles())
                                req.setNextto(undefined)
     
                                req.setSearch(undefined)
     
                                getManagersPromise = dispatch(getManagerUsers({ body: req, headers: getHeaders() }))
                            }*/
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="businessClients"
                items={state.set.items}
                columns={c}
                width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 176px)" : "calc(100vh - 224px)"} selection={_selection}
                selectionMode={SelectionMode.single}
                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                isColumnSortingEnabled={state.set.selected.length == 0}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.justified}
                compRef={ref}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.set.isFetching && state.set.hasMore) {
                        const wrapper = new StringValue();
                        wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                        req.setNextto(wrapper)
                        getItemsPromise?.abort();
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }
                }}
                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.set.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}

                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.set.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.set.items.length > 0 && !state.set.isFetching && state.set.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                                    req.setNextto(wrapper)
                                    getItemsPromise?.abort();
                                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />

            {
                props.isSelection ? <DialogFooter>
                    <PrimaryButton disabled={state.set.isFetching || state.set.selected.length == 0} text={state.set.isFetching ? undefined : t("select")} onClick={() => {
                        if (props.onItemSelected && state.set.selected.length > 0) {
                            props.onItemSelected(state.set.selected.at(0))
                        }
                    }} >

                        <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                    </PrimaryButton>
                    <DefaultButton disabled={state.set.isFetching} text={t("discard")} onClick={() => {
                        if (props.onCancel) {
                            props.onCancel()
                        }
                    }} />
                </DialogFooter> : undefined
            }
        </Stack >



    );
}




