/**
 * @fileoverview gRPC-Web generated client stub for log_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as log_pb from './log_pb';


export class LogEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetLog = new grpcWeb.MethodDescriptor(
    '/log_endpoint.LogEndpoint/GetLog',
    grpcWeb.MethodType.UNARY,
    log_pb.GetLogRequest,
    log_pb.GetLogResponse,
    (request: log_pb.GetLogRequest) => {
      return request.serializeBinary();
    },
    log_pb.GetLogResponse.deserializeBinary
  );

  getLog(
    request: log_pb.GetLogRequest,
    metadata: grpcWeb.Metadata | null): Promise<log_pb.GetLogResponse>;

  getLog(
    request: log_pb.GetLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: log_pb.GetLogResponse) => void): grpcWeb.ClientReadableStream<log_pb.GetLogResponse>;

  getLog(
    request: log_pb.GetLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: log_pb.GetLogResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/log_endpoint.LogEndpoint/GetLog',
        request,
        metadata || {},
        this.methodDescriptorGetLog,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/log_endpoint.LogEndpoint/GetLog',
    request,
    metadata || {},
    this.methodDescriptorGetLog);
  }

  methodDescriptorGetLogs = new grpcWeb.MethodDescriptor(
    '/log_endpoint.LogEndpoint/GetLogs',
    grpcWeb.MethodType.UNARY,
    log_pb.GetLogsRequest,
    log_pb.GetLogsResponse,
    (request: log_pb.GetLogsRequest) => {
      return request.serializeBinary();
    },
    log_pb.GetLogsResponse.deserializeBinary
  );

  getLogs(
    request: log_pb.GetLogsRequest,
    metadata: grpcWeb.Metadata | null): Promise<log_pb.GetLogsResponse>;

  getLogs(
    request: log_pb.GetLogsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: log_pb.GetLogsResponse) => void): grpcWeb.ClientReadableStream<log_pb.GetLogsResponse>;

  getLogs(
    request: log_pb.GetLogsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: log_pb.GetLogsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/log_endpoint.LogEndpoint/GetLogs',
        request,
        metadata || {},
        this.methodDescriptorGetLogs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/log_endpoint.LogEndpoint/GetLogs',
    request,
    metadata || {},
    this.methodDescriptorGetLogs);
  }

}

