import { INavStyles, Stack, Text, Persona, createTheme, IStackStyles, IBreadcrumbStyles, IStackItemStyles } from '@fluentui/react';


export const navDrawerStyle: Partial<INavStyles> = {
    root: {


    },
    // these link styles override the default truncation behavior
    link: {
        whiteSpace: 'normal',
        lineHeight: 'inherit',
    },
};

export const pageSectionStyle: Partial<IStackStyles> = {
    root: {
        boxSizing: 'border-box',
        border: '1px solid #eee',
        overflow: "auto", 
        width: 'calc(100vw - 220px)', //220 navigator,

    },
};


export const currentOrganization: Partial<IStackStyles> = {
    root: {
        minHeight: 48,
        maxHeight: 48,
        height: 48,
       

},
};
export const navigatorLayout: Partial<IStackStyles> = {
    root: {

        width: 220,

        boxSizing: 'border-box',
        border: '1px solid #eee',
    },
};

