/**
 * @fileoverview gRPC-Web generated client stub for customers_privilege_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as privilege_pb from './privilege_pb';


export class PrivilegeEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/customers_privilege_endpoint.PrivilegeEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    privilege_pb.AddPrivilegesRequest,
    privilege_pb.AddPrivilegesResponse,
    (request: privilege_pb.AddPrivilegesRequest) => {
      return request.serializeBinary();
    },
    privilege_pb.AddPrivilegesResponse.deserializeBinary
  );

  add(
    request: privilege_pb.AddPrivilegesRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilege_pb.AddPrivilegesResponse>;

  add(
    request: privilege_pb.AddPrivilegesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilege_pb.AddPrivilegesResponse) => void): grpcWeb.ClientReadableStream<privilege_pb.AddPrivilegesResponse>;

  add(
    request: privilege_pb.AddPrivilegesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilege_pb.AddPrivilegesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/customers_privilege_endpoint.PrivilegeEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/customers_privilege_endpoint.PrivilegeEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorGetPrivileges = new grpcWeb.MethodDescriptor(
    '/customers_privilege_endpoint.PrivilegeEndpoint/GetPrivileges',
    grpcWeb.MethodType.UNARY,
    privilege_pb.GetPrivilegesRequest,
    privilege_pb.GetPrivilegesResponse,
    (request: privilege_pb.GetPrivilegesRequest) => {
      return request.serializeBinary();
    },
    privilege_pb.GetPrivilegesResponse.deserializeBinary
  );

  getPrivileges(
    request: privilege_pb.GetPrivilegesRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilege_pb.GetPrivilegesResponse>;

  getPrivileges(
    request: privilege_pb.GetPrivilegesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilege_pb.GetPrivilegesResponse) => void): grpcWeb.ClientReadableStream<privilege_pb.GetPrivilegesResponse>;

  getPrivileges(
    request: privilege_pb.GetPrivilegesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilege_pb.GetPrivilegesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/customers_privilege_endpoint.PrivilegeEndpoint/GetPrivileges',
        request,
        metadata || {},
        this.methodDescriptorGetPrivileges,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/customers_privilege_endpoint.PrivilegeEndpoint/GetPrivileges',
    request,
    metadata || {},
    this.methodDescriptorGetPrivileges);
  }

  methodDescriptorGetPrivilege = new grpcWeb.MethodDescriptor(
    '/customers_privilege_endpoint.PrivilegeEndpoint/GetPrivilege',
    grpcWeb.MethodType.UNARY,
    privilege_pb.GetPrivilegeRequest,
    privilege_pb.GetPrivilegeResponse,
    (request: privilege_pb.GetPrivilegeRequest) => {
      return request.serializeBinary();
    },
    privilege_pb.GetPrivilegeResponse.deserializeBinary
  );

  getPrivilege(
    request: privilege_pb.GetPrivilegeRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilege_pb.GetPrivilegeResponse>;

  getPrivilege(
    request: privilege_pb.GetPrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilege_pb.GetPrivilegeResponse) => void): grpcWeb.ClientReadableStream<privilege_pb.GetPrivilegeResponse>;

  getPrivilege(
    request: privilege_pb.GetPrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilege_pb.GetPrivilegeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/customers_privilege_endpoint.PrivilegeEndpoint/GetPrivilege',
        request,
        metadata || {},
        this.methodDescriptorGetPrivilege,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/customers_privilege_endpoint.PrivilegeEndpoint/GetPrivilege',
    request,
    metadata || {},
    this.methodDescriptorGetPrivilege);
  }

  methodDescriptorRemove = new grpcWeb.MethodDescriptor(
    '/customers_privilege_endpoint.PrivilegeEndpoint/Remove',
    grpcWeb.MethodType.UNARY,
    privilege_pb.RemovePrivilegeRequest,
    privilege_pb.RemovePrivilegeResponse,
    (request: privilege_pb.RemovePrivilegeRequest) => {
      return request.serializeBinary();
    },
    privilege_pb.RemovePrivilegeResponse.deserializeBinary
  );

  remove(
    request: privilege_pb.RemovePrivilegeRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilege_pb.RemovePrivilegeResponse>;

  remove(
    request: privilege_pb.RemovePrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilege_pb.RemovePrivilegeResponse) => void): grpcWeb.ClientReadableStream<privilege_pb.RemovePrivilegeResponse>;

  remove(
    request: privilege_pb.RemovePrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilege_pb.RemovePrivilegeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/customers_privilege_endpoint.PrivilegeEndpoint/Remove',
        request,
        metadata || {},
        this.methodDescriptorRemove,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/customers_privilege_endpoint.PrivilegeEndpoint/Remove',
    request,
    metadata || {},
    this.methodDescriptorRemove);
  }

}

