import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, getSession, refreshSession } from '../../../app/Api';
import { formatDate, timestampToDate } from '../../../app/Helpers';

import { TableState } from '../../common/Table/TableSate';
import { number } from 'yup';
import { BusinessEndpointClient } from '../../../repository/UserManagement/Dimensions/BusinessServiceClientPb';
import {  UpdateBusinessActivityRequest, GetBusinessesRequest, GetBusinessesResponse, UpdateBusinessActivityResponse, UpdateThirdPartyConnectionResponse, UpdateThirdPartyConnectionRequest,  } from '../../../repository/UserManagement/Dimensions/business_pb';


export interface BusinessesPageState {
    items: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[]
    isActiveFilterOption: { key: number, text: string },
    availabilityFilterOption: { key: number, text: string },
    cityFilterOption : { value: string, text: string },
    countryFilterOption: { key: string, text: string },

}

const initialState: BusinessesPageState = {
    items: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: [],
    isActiveFilterOption: { key: 0, text: "" },
    availabilityFilterOption: { key: 0, text: "" },
    countryFilterOption: { key: "", text: "" } ,
    cityFilterOption: { text: "", value: "" }


}

const businessClient = new BusinessEndpointClient(API_URL, null, null);

export const getItems = createAsyncThunk<GetBusinessesResponse.AsObject, APIRequest<GetBusinessesRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/businesses/getBusinesses',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await businessClient.getBusinesses(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const updateActivity = createAsyncThunk<APIResponse<UpdateBusinessActivityResponse.AsObject>, APIRequest<UpdateBusinessActivityRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/businesses/updateActivity',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await businessClient.updateActivity(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), state: req.body.getIsactive() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const updateThirdPartyConnection = createAsyncThunk<APIResponse<UpdateThirdPartyConnectionResponse.AsObject>, APIRequest<UpdateThirdPartyConnectionRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/businesses/updateThirdPartyConnection',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await businessClient.updateThirdPartyConnection(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), connectionId: req.body.getThirdpartyid(), connectionMetadata: req.metadata?.connectionMetadata }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

const getFilters = (state: BusinessesPageState): any[] => {

    const selected = [];
    if (state.isActiveFilterOption.key != 0) {
        selected.push({ key: 1, name: state.isActiveFilterOption.text });
    } 
    if (state.availabilityFilterOption.key != 0) {
        selected.push({ key: 2, name: state.availabilityFilterOption.text });
    }
    if (state.countryFilterOption.key != "") {
        selected.push({ key: 3, name: state.countryFilterOption.text });
    }
    if (state.cityFilterOption.value && state.cityFilterOption.value != "") {
        selected.push({ key: 4, name: state.cityFilterOption.text });
        
    }
  
   
    return selected;

}
export const businessesPageSlice = createSlice({
    name: 'pages/businesses',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.items = initialState.items;
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.isActiveFilterOption = initialState.isActiveFilterOption;
            state.availabilityFilterOption = initialState.availabilityFilterOption;
            state.cityFilterOption = initialState.cityFilterOption;
            state.countryFilterOption = initialState.countryFilterOption;

        },
        resetItems: (state) => {
            state.items.items = [];
            state.items.hasMore = true;
        },

        setSort: (state, action: PayloadAction<boolean>) => {
            state.items.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.items.numberOfResults = action.payload;
        },
        setSelectedItems: (state, action: PayloadAction<any[]>) => {
            state.items.selected = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.items.isFilteredSet = action.payload;
        },
        setAvailabilityFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.availabilityFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setActivityFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.isActiveFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setCityFilterOption: (state, action: PayloadAction<{ value: string, text: string }>) => {
            state.cityFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setCountryFilterOption: (state, action: PayloadAction<{ key: string, text: string }>) => {
            state.countryFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        addItem: (state, action: PayloadAction<any>) => {
            if (!state.items.isFilteredSet) {
                if (!state.items.hasMore && !state.items.isDescending) {
                    state.items.items.push(action.payload);
                } else if (state.items.isDescending) {
                    state.items.items.unshift(action.payload);
                }
            }
        },
        updateItem: (state, action: PayloadAction<any>) => {
            let selected = state.items.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.items.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.items.items[u] = action?.payload
                }
            }

        },
        updateConnection: (state, action: PayloadAction<any>) => {
            let selected = state.items.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.items.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.items.items[u].connectionId = action?.payload?.connectionId
                    state.items.items[u].connectionMetaData = action?.payload?.connectionMetaData

                }
            }

        }
    },
    extraReducers: (builder) => {
        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            state.items.isFetching = false
            if (payload) {
                var r = (payload?.success?.businessesList.map(val => {
                    return {
                        id: val.id,
                        name: (val.name?.value ?? ""),
                        connectionId: val.connection?.id?.value ?? "",
                        connectionMetaData: val.connection?.metadata?.value ?? "",
                        isActive: val.isactive?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        createdBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        createdById: val.createdby?.id?.value,
                        isAvailable: val.isavailable?.value,
                        location: val.locationList,
                        addressCity: val.address?.city,
                        addressCountry: val.address ? { key: val.address?.countryiso, text: '' } : { key: 'LY', text: 'Libya' },
                        logoId: val.logo?.id?.value,
                        logoName: val.logo?.name?.value,
                        logoPath: val.logo?.path?.value,
                        logoSource: val.logo?.source?.value,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.items.items.length != 0) {
                    l = state.items.items.slice(0, state.items.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.items.numberOfResults) {
                    state.items.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.items.items = l
                //console.log(state.items)

            } else {
                state.items.hasMore = false;
            }
        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.items.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.items.isFetching = true;
            state.message = undefined;
        })


        builder.addCase(updateActivity.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.items.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        selected.at(0).isActive = payload?.metadata.state
                    }
                    var u = state.items.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.items.items.at(u).isActive = payload?.metadata.state
                    }

                }
            }
        })
        builder.addCase(updateActivity.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateActivity.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


        builder.addCase(updateThirdPartyConnection.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.items.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        selected.at(0).connectionId = payload?.metadata.connectionId
                        selected.at(0).connectionMetaData = payload?.metadata.connectionMetadata

                    }
                    var u = state.items.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.items.items.at(u).connectionId = payload?.metadata.connectionId
                        state.items.items.at(u).connectionMetaData = payload?.metadata.connectionMetadata

                    }

                }
            }
        })
        builder.addCase(updateThirdPartyConnection.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateThirdPartyConnection.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })




    }
})

export const { reset, dismissMessage,updateConnection, setCityFilterOption, setCountryFilterOption, setSort, setNumberOfResults, resetItems, setSelectedItems, setSearchText, setIsFilteredSet, addItem, setActivityFilterOption, updateItem, setAvailabilityFilterOption } = businessesPageSlice.actions

export default businessesPageSlice.reducer


