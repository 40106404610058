import { FocusTrapZone, getTheme, IconButton, IModalStyleProps, IModalStyles, IStyleFunctionOrObject, ITextField, ITextFieldProps, Modal, PrimaryButton, Stack, Text, TextField } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { t, use } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/Hooks";
import { getMainTheme, ThemeManager } from "../../../app/Themes";
import { contentStyles, iconButtonStyles } from "./ShareBox.styles";

interface ShareBoxProps {
    title: string
    value: string,
    isSecret?: boolean
    onDismiss: () => void
}

export const ShareBox: React.FunctionComponent<ShareBoxProps> = (props) => {
    const titleId = useId('title');
    const ref = useRef<ITextField>(null);
    const { t, i18n } = useTranslation();

    const stateTheme: { appTheme: string, isDarkModeEnabled: boolean } = useAppSelector((state) => {

        return { appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled }
    })

    return (
        <Stack >
            <Stack horizontal horizontalAlign="space-between" className={contentStyles.header} styles={{ root: { borderTopColor: getMainTheme(stateTheme.appTheme, stateTheme.isDarkModeEnabled).palette.themePrimary } }}>
                <Text variant={'xLarge'} id={titleId}>{props.title}</Text>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel={t("closePopup")}
                    onClick={props.onDismiss}
                />

            </Stack>
            <Stack  tokens={{ childrenGap: 4 }}  className={contentStyles.body}>
                <Text variant={'smallPlus'}><b>{t("forUsersOnly")}</b></Text>
                <Stack horizontalAlign="space-between" tokens={{ childrenGap: 4 }} horizontal >

                    <Stack.Item grow>
                        {!props.isSecret ? <TextField componentRef={ref} autoFocus onFocus={(e) => { (e.target as HTMLInputElement).select() }} readOnly value={props.value} />
                            : <TextField componentRef={ref} autoFocus onFocus={(e) => { (e.target as HTMLInputElement).select() }} readOnly value={props.value} type="password" canRevealPassword />
                        }
                    </Stack.Item>
                    <IconButton title={t("copy")} iconProps={{ iconName: "Copy" }} onClick={() => { navigator.clipboard.writeText(props.value); ref.current?.focus() }}></IconButton>
                </Stack>

            </Stack>

        </Stack>

    );

}