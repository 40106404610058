// source: authentication.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.authentication_endpoint.AccessToken', null, global);
goog.exportSymbol('proto.authentication_endpoint.AuthenticateReply', null, global);
goog.exportSymbol('proto.authentication_endpoint.AuthenticateRequest', null, global);
goog.exportSymbol('proto.authentication_endpoint.AuthenticateResponse', null, global);
goog.exportSymbol('proto.authentication_endpoint.AuthenticateResponse.ResultCase', null, global);
goog.exportSymbol('proto.authentication_endpoint.Organization', null, global);
goog.exportSymbol('proto.authentication_endpoint.RefreshTokenRequest', null, global);
goog.exportSymbol('proto.authentication_endpoint.RefreshTokenResponse', null, global);
goog.exportSymbol('proto.authentication_endpoint.RefreshTokenResponse.ResultCase', null, global);
goog.exportSymbol('proto.authentication_endpoint.TwoFactorConfirmationRequest', null, global);
goog.exportSymbol('proto.authentication_endpoint.TwoFactorConfirmationResponse', null, global);
goog.exportSymbol('proto.authentication_endpoint.TwoFactorConfirmationResponse.ResultCase', null, global);
goog.exportSymbol('proto.authentication_endpoint.TwoFactorEnabledReply', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authentication_endpoint.RefreshTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authentication_endpoint.RefreshTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authentication_endpoint.RefreshTokenRequest.displayName = 'proto.authentication_endpoint.RefreshTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authentication_endpoint.AuthenticateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authentication_endpoint.AuthenticateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authentication_endpoint.AuthenticateRequest.displayName = 'proto.authentication_endpoint.AuthenticateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authentication_endpoint.TwoFactorConfirmationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authentication_endpoint.TwoFactorConfirmationRequest.displayName = 'proto.authentication_endpoint.TwoFactorConfirmationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authentication_endpoint.Organization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authentication_endpoint.Organization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authentication_endpoint.Organization.displayName = 'proto.authentication_endpoint.Organization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authentication_endpoint.AccessToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authentication_endpoint.AccessToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authentication_endpoint.AccessToken.displayName = 'proto.authentication_endpoint.AccessToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authentication_endpoint.AuthenticateReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authentication_endpoint.AuthenticateReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authentication_endpoint.AuthenticateReply.displayName = 'proto.authentication_endpoint.AuthenticateReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authentication_endpoint.TwoFactorEnabledReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authentication_endpoint.TwoFactorEnabledReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authentication_endpoint.TwoFactorEnabledReply.displayName = 'proto.authentication_endpoint.TwoFactorEnabledReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authentication_endpoint.AuthenticateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authentication_endpoint.AuthenticateResponse.oneofGroups_);
};
goog.inherits(proto.authentication_endpoint.AuthenticateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authentication_endpoint.AuthenticateResponse.displayName = 'proto.authentication_endpoint.AuthenticateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authentication_endpoint.TwoFactorConfirmationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authentication_endpoint.TwoFactorConfirmationResponse.oneofGroups_);
};
goog.inherits(proto.authentication_endpoint.TwoFactorConfirmationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authentication_endpoint.TwoFactorConfirmationResponse.displayName = 'proto.authentication_endpoint.TwoFactorConfirmationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authentication_endpoint.RefreshTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authentication_endpoint.RefreshTokenResponse.oneofGroups_);
};
goog.inherits(proto.authentication_endpoint.RefreshTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authentication_endpoint.RefreshTokenResponse.displayName = 'proto.authentication_endpoint.RefreshTokenResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authentication_endpoint.RefreshTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authentication_endpoint.RefreshTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authentication_endpoint.RefreshTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.RefreshTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    refreshtoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authentication_endpoint.RefreshTokenRequest}
 */
proto.authentication_endpoint.RefreshTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authentication_endpoint.RefreshTokenRequest;
  return proto.authentication_endpoint.RefreshTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authentication_endpoint.RefreshTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authentication_endpoint.RefreshTokenRequest}
 */
proto.authentication_endpoint.RefreshTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshtoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSessionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authentication_endpoint.RefreshTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authentication_endpoint.RefreshTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authentication_endpoint.RefreshTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.RefreshTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRefreshtoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string refreshToken = 1;
 * @return {string}
 */
proto.authentication_endpoint.RefreshTokenRequest.prototype.getRefreshtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authentication_endpoint.RefreshTokenRequest} returns this
 */
proto.authentication_endpoint.RefreshTokenRequest.prototype.setRefreshtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 sessionId = 2;
 * @return {number}
 */
proto.authentication_endpoint.RefreshTokenRequest.prototype.getSessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.authentication_endpoint.RefreshTokenRequest} returns this
 */
proto.authentication_endpoint.RefreshTokenRequest.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authentication_endpoint.AuthenticateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authentication_endpoint.AuthenticateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authentication_endpoint.AuthenticateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.AuthenticateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    captchatoken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authentication_endpoint.AuthenticateRequest}
 */
proto.authentication_endpoint.AuthenticateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authentication_endpoint.AuthenticateRequest;
  return proto.authentication_endpoint.AuthenticateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authentication_endpoint.AuthenticateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authentication_endpoint.AuthenticateRequest}
 */
proto.authentication_endpoint.AuthenticateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaptchatoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authentication_endpoint.AuthenticateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authentication_endpoint.AuthenticateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authentication_endpoint.AuthenticateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.AuthenticateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCaptchatoken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.authentication_endpoint.AuthenticateRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authentication_endpoint.AuthenticateRequest} returns this
 */
proto.authentication_endpoint.AuthenticateRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.authentication_endpoint.AuthenticateRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authentication_endpoint.AuthenticateRequest} returns this
 */
proto.authentication_endpoint.AuthenticateRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string captchaToken = 3;
 * @return {string}
 */
proto.authentication_endpoint.AuthenticateRequest.prototype.getCaptchatoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.authentication_endpoint.AuthenticateRequest} returns this
 */
proto.authentication_endpoint.AuthenticateRequest.prototype.setCaptchatoken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authentication_endpoint.TwoFactorConfirmationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authentication_endpoint.TwoFactorConfirmationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    codeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    signature: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    refreshtokenexpiration: (f = msg.getRefreshtokenexpiration()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    refreshtokenmetadata: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authentication_endpoint.TwoFactorConfirmationRequest}
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authentication_endpoint.TwoFactorConfirmationRequest;
  return proto.authentication_endpoint.TwoFactorConfirmationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authentication_endpoint.TwoFactorConfirmationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authentication_endpoint.TwoFactorConfirmationRequest}
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCodeid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRefreshtokenexpiration(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshtokenmetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authentication_endpoint.TwoFactorConfirmationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authentication_endpoint.TwoFactorConfirmationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCodeid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRefreshtokenexpiration();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRefreshtokenmetadata();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 codeId = 1;
 * @return {number}
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.prototype.getCodeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.authentication_endpoint.TwoFactorConfirmationRequest} returns this
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.prototype.setCodeid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string signature = 2;
 * @return {string}
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authentication_endpoint.TwoFactorConfirmationRequest} returns this
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.authentication_endpoint.TwoFactorConfirmationRequest} returns this
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp refreshTokenExpiration = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.prototype.getRefreshtokenexpiration = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.authentication_endpoint.TwoFactorConfirmationRequest} returns this
*/
proto.authentication_endpoint.TwoFactorConfirmationRequest.prototype.setRefreshtokenexpiration = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.TwoFactorConfirmationRequest} returns this
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.prototype.clearRefreshtokenexpiration = function() {
  return this.setRefreshtokenexpiration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.prototype.hasRefreshtokenexpiration = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string RefreshTokenMetaData = 5;
 * @return {string}
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.prototype.getRefreshtokenmetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.authentication_endpoint.TwoFactorConfirmationRequest} returns this
 */
proto.authentication_endpoint.TwoFactorConfirmationRequest.prototype.setRefreshtokenmetadata = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authentication_endpoint.Organization.prototype.toObject = function(opt_includeInstance) {
  return proto.authentication_endpoint.Organization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authentication_endpoint.Organization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.Organization.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    domain: (f = msg.getDomain()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    subscription: (f = msg.getSubscription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authentication_endpoint.Organization}
 */
proto.authentication_endpoint.Organization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authentication_endpoint.Organization;
  return proto.authentication_endpoint.Organization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authentication_endpoint.Organization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authentication_endpoint.Organization}
 */
proto.authentication_endpoint.Organization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDomain(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSubscription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authentication_endpoint.Organization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authentication_endpoint.Organization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authentication_endpoint.Organization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.Organization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDomain();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSubscription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.authentication_endpoint.Organization.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.authentication_endpoint.Organization} returns this
 */
proto.authentication_endpoint.Organization.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authentication_endpoint.Organization.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authentication_endpoint.Organization} returns this
*/
proto.authentication_endpoint.Organization.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.Organization} returns this
 */
proto.authentication_endpoint.Organization.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.Organization.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue domain = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authentication_endpoint.Organization.prototype.getDomain = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authentication_endpoint.Organization} returns this
*/
proto.authentication_endpoint.Organization.prototype.setDomain = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.Organization} returns this
 */
proto.authentication_endpoint.Organization.prototype.clearDomain = function() {
  return this.setDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.Organization.prototype.hasDomain = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue subscription = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authentication_endpoint.Organization.prototype.getSubscription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authentication_endpoint.Organization} returns this
*/
proto.authentication_endpoint.Organization.prototype.setSubscription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.Organization} returns this
 */
proto.authentication_endpoint.Organization.prototype.clearSubscription = function() {
  return this.setSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.Organization.prototype.hasSubscription = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authentication_endpoint.AccessToken.prototype.toObject = function(opt_includeInstance) {
  return proto.authentication_endpoint.AccessToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authentication_endpoint.AccessToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.AccessToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    refreshtoken: (f = msg.getRefreshtoken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    token: (f = msg.getToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    expirationdate: (f = msg.getExpirationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authentication_endpoint.AccessToken}
 */
proto.authentication_endpoint.AccessToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authentication_endpoint.AccessToken;
  return proto.authentication_endpoint.AccessToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authentication_endpoint.AccessToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authentication_endpoint.AccessToken}
 */
proto.authentication_endpoint.AccessToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSessionid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRefreshtoken(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authentication_endpoint.AccessToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authentication_endpoint.AccessToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authentication_endpoint.AccessToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.AccessToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRefreshtoken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExpirationdate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 sessionId = 1;
 * @return {number}
 */
proto.authentication_endpoint.AccessToken.prototype.getSessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.authentication_endpoint.AccessToken} returns this
 */
proto.authentication_endpoint.AccessToken.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue refreshToken = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authentication_endpoint.AccessToken.prototype.getRefreshtoken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authentication_endpoint.AccessToken} returns this
*/
proto.authentication_endpoint.AccessToken.prototype.setRefreshtoken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.AccessToken} returns this
 */
proto.authentication_endpoint.AccessToken.prototype.clearRefreshtoken = function() {
  return this.setRefreshtoken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.AccessToken.prototype.hasRefreshtoken = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue token = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authentication_endpoint.AccessToken.prototype.getToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authentication_endpoint.AccessToken} returns this
*/
proto.authentication_endpoint.AccessToken.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.AccessToken} returns this
 */
proto.authentication_endpoint.AccessToken.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.AccessToken.prototype.hasToken = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp expirationDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.authentication_endpoint.AccessToken.prototype.getExpirationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.authentication_endpoint.AccessToken} returns this
*/
proto.authentication_endpoint.AccessToken.prototype.setExpirationdate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.AccessToken} returns this
 */
proto.authentication_endpoint.AccessToken.prototype.clearExpirationdate = function() {
  return this.setExpirationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.AccessToken.prototype.hasExpirationdate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.toObject = function(opt_includeInstance) {
  return proto.authentication_endpoint.AuthenticateReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authentication_endpoint.AuthenticateReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.AuthenticateReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: (f = msg.getUsername()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountphonenumber: (f = msg.getAccountphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountemail: (f = msg.getAccountemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    profile: (f = msg.getProfile()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    accesstoken: (f = msg.getAccesstoken()) && proto.authentication_endpoint.AccessToken.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && proto.authentication_endpoint.Organization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authentication_endpoint.AuthenticateReply}
 */
proto.authentication_endpoint.AuthenticateReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authentication_endpoint.AuthenticateReply;
  return proto.authentication_endpoint.AuthenticateReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authentication_endpoint.AuthenticateReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authentication_endpoint.AuthenticateReply}
 */
proto.authentication_endpoint.AuthenticateReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUsername(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountphonenumber(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountemail(value);
      break;
    case 5:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 6:
      var value = new proto.authentication_endpoint.AccessToken;
      reader.readMessage(value,proto.authentication_endpoint.AccessToken.deserializeBinaryFromReader);
      msg.setAccesstoken(value);
      break;
    case 8:
      var value = new proto.authentication_endpoint.Organization;
      reader.readMessage(value,proto.authentication_endpoint.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authentication_endpoint.AuthenticateReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authentication_endpoint.AuthenticateReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.AuthenticateReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountphonenumber();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountemail();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getAccesstoken();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.authentication_endpoint.AccessToken.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.authentication_endpoint.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 accountId = 1;
 * @return {number}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.getAccountid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.authentication_endpoint.AuthenticateReply} returns this
 */
proto.authentication_endpoint.AuthenticateReply.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue username = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.getUsername = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authentication_endpoint.AuthenticateReply} returns this
*/
proto.authentication_endpoint.AuthenticateReply.prototype.setUsername = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.AuthenticateReply} returns this
 */
proto.authentication_endpoint.AuthenticateReply.prototype.clearUsername = function() {
  return this.setUsername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue accountPhoneNumber = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.getAccountphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authentication_endpoint.AuthenticateReply} returns this
*/
proto.authentication_endpoint.AuthenticateReply.prototype.setAccountphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.AuthenticateReply} returns this
 */
proto.authentication_endpoint.AuthenticateReply.prototype.clearAccountphonenumber = function() {
  return this.setAccountphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.hasAccountphonenumber = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue accountEmail = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.getAccountemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authentication_endpoint.AuthenticateReply} returns this
*/
proto.authentication_endpoint.AuthenticateReply.prototype.setAccountemail = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.AuthenticateReply} returns this
 */
proto.authentication_endpoint.AuthenticateReply.prototype.clearAccountemail = function() {
  return this.setAccountemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.hasAccountemail = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.ProfileResponse profile = 5;
 * @return {?proto.common.ProfileResponse}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.getProfile = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 5));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.authentication_endpoint.AuthenticateReply} returns this
*/
proto.authentication_endpoint.AuthenticateReply.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.AuthenticateReply} returns this
 */
proto.authentication_endpoint.AuthenticateReply.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AccessToken accessToken = 6;
 * @return {?proto.authentication_endpoint.AccessToken}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.getAccesstoken = function() {
  return /** @type{?proto.authentication_endpoint.AccessToken} */ (
    jspb.Message.getWrapperField(this, proto.authentication_endpoint.AccessToken, 6));
};


/**
 * @param {?proto.authentication_endpoint.AccessToken|undefined} value
 * @return {!proto.authentication_endpoint.AuthenticateReply} returns this
*/
proto.authentication_endpoint.AuthenticateReply.prototype.setAccesstoken = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.AuthenticateReply} returns this
 */
proto.authentication_endpoint.AuthenticateReply.prototype.clearAccesstoken = function() {
  return this.setAccesstoken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.hasAccesstoken = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Organization organization = 8;
 * @return {?proto.authentication_endpoint.Organization}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.getOrganization = function() {
  return /** @type{?proto.authentication_endpoint.Organization} */ (
    jspb.Message.getWrapperField(this, proto.authentication_endpoint.Organization, 8));
};


/**
 * @param {?proto.authentication_endpoint.Organization|undefined} value
 * @return {!proto.authentication_endpoint.AuthenticateReply} returns this
*/
proto.authentication_endpoint.AuthenticateReply.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.AuthenticateReply} returns this
 */
proto.authentication_endpoint.AuthenticateReply.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.AuthenticateReply.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.toObject = function(opt_includeInstance) {
  return proto.authentication_endpoint.TwoFactorEnabledReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authentication_endpoint.TwoFactorEnabledReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.TwoFactorEnabledReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    codeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    signature: (f = msg.getSignature()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    maxattemptsnumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    accountphonenumber: (f = msg.getAccountphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountemail: (f = msg.getAccountemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authentication_endpoint.TwoFactorEnabledReply}
 */
proto.authentication_endpoint.TwoFactorEnabledReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authentication_endpoint.TwoFactorEnabledReply;
  return proto.authentication_endpoint.TwoFactorEnabledReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authentication_endpoint.TwoFactorEnabledReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authentication_endpoint.TwoFactorEnabledReply}
 */
proto.authentication_endpoint.TwoFactorEnabledReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCodeid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSignature(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxattemptsnumber(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountphonenumber(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountemail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authentication_endpoint.TwoFactorEnabledReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authentication_endpoint.TwoFactorEnabledReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.TwoFactorEnabledReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCodeid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSignature();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMaxattemptsnumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAccountphonenumber();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountemail();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 codeId = 1;
 * @return {number}
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.getCodeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.authentication_endpoint.TwoFactorEnabledReply} returns this
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.setCodeid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue signature = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.getSignature = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authentication_endpoint.TwoFactorEnabledReply} returns this
*/
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.setSignature = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.TwoFactorEnabledReply} returns this
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.clearSignature = function() {
  return this.setSignature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.hasSignature = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 maxAttemptsNumber = 3;
 * @return {number}
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.getMaxattemptsnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.authentication_endpoint.TwoFactorEnabledReply} returns this
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.setMaxattemptsnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.StringValue accountPhoneNumber = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.getAccountphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authentication_endpoint.TwoFactorEnabledReply} returns this
*/
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.setAccountphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.TwoFactorEnabledReply} returns this
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.clearAccountphonenumber = function() {
  return this.setAccountphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.hasAccountphonenumber = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue accountEmail = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.getAccountemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authentication_endpoint.TwoFactorEnabledReply} returns this
*/
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.setAccountemail = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.TwoFactorEnabledReply} returns this
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.clearAccountemail = function() {
  return this.setAccountemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.TwoFactorEnabledReply.prototype.hasAccountemail = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authentication_endpoint.AuthenticateResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.authentication_endpoint.AuthenticateResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  AUTHENTICATE: 1,
  TWOFACTORENABLED: 2
};

/**
 * @return {proto.authentication_endpoint.AuthenticateResponse.ResultCase}
 */
proto.authentication_endpoint.AuthenticateResponse.prototype.getResultCase = function() {
  return /** @type {proto.authentication_endpoint.AuthenticateResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.authentication_endpoint.AuthenticateResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authentication_endpoint.AuthenticateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authentication_endpoint.AuthenticateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authentication_endpoint.AuthenticateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.AuthenticateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authenticate: (f = msg.getAuthenticate()) && proto.authentication_endpoint.AuthenticateReply.toObject(includeInstance, f),
    twofactorenabled: (f = msg.getTwofactorenabled()) && proto.authentication_endpoint.TwoFactorEnabledReply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authentication_endpoint.AuthenticateResponse}
 */
proto.authentication_endpoint.AuthenticateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authentication_endpoint.AuthenticateResponse;
  return proto.authentication_endpoint.AuthenticateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authentication_endpoint.AuthenticateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authentication_endpoint.AuthenticateResponse}
 */
proto.authentication_endpoint.AuthenticateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authentication_endpoint.AuthenticateReply;
      reader.readMessage(value,proto.authentication_endpoint.AuthenticateReply.deserializeBinaryFromReader);
      msg.setAuthenticate(value);
      break;
    case 2:
      var value = new proto.authentication_endpoint.TwoFactorEnabledReply;
      reader.readMessage(value,proto.authentication_endpoint.TwoFactorEnabledReply.deserializeBinaryFromReader);
      msg.setTwofactorenabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authentication_endpoint.AuthenticateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authentication_endpoint.AuthenticateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authentication_endpoint.AuthenticateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.AuthenticateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthenticate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authentication_endpoint.AuthenticateReply.serializeBinaryToWriter
    );
  }
  f = message.getTwofactorenabled();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.authentication_endpoint.TwoFactorEnabledReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional AuthenticateReply authenticate = 1;
 * @return {?proto.authentication_endpoint.AuthenticateReply}
 */
proto.authentication_endpoint.AuthenticateResponse.prototype.getAuthenticate = function() {
  return /** @type{?proto.authentication_endpoint.AuthenticateReply} */ (
    jspb.Message.getWrapperField(this, proto.authentication_endpoint.AuthenticateReply, 1));
};


/**
 * @param {?proto.authentication_endpoint.AuthenticateReply|undefined} value
 * @return {!proto.authentication_endpoint.AuthenticateResponse} returns this
*/
proto.authentication_endpoint.AuthenticateResponse.prototype.setAuthenticate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.authentication_endpoint.AuthenticateResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.AuthenticateResponse} returns this
 */
proto.authentication_endpoint.AuthenticateResponse.prototype.clearAuthenticate = function() {
  return this.setAuthenticate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.AuthenticateResponse.prototype.hasAuthenticate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TwoFactorEnabledReply twoFactorEnabled = 2;
 * @return {?proto.authentication_endpoint.TwoFactorEnabledReply}
 */
proto.authentication_endpoint.AuthenticateResponse.prototype.getTwofactorenabled = function() {
  return /** @type{?proto.authentication_endpoint.TwoFactorEnabledReply} */ (
    jspb.Message.getWrapperField(this, proto.authentication_endpoint.TwoFactorEnabledReply, 2));
};


/**
 * @param {?proto.authentication_endpoint.TwoFactorEnabledReply|undefined} value
 * @return {!proto.authentication_endpoint.AuthenticateResponse} returns this
*/
proto.authentication_endpoint.AuthenticateResponse.prototype.setTwofactorenabled = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.authentication_endpoint.AuthenticateResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.AuthenticateResponse} returns this
 */
proto.authentication_endpoint.AuthenticateResponse.prototype.clearTwofactorenabled = function() {
  return this.setTwofactorenabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.AuthenticateResponse.prototype.hasTwofactorenabled = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authentication_endpoint.TwoFactorConfirmationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.authentication_endpoint.TwoFactorConfirmationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  AUTHENTICATE: 1
};

/**
 * @return {proto.authentication_endpoint.TwoFactorConfirmationResponse.ResultCase}
 */
proto.authentication_endpoint.TwoFactorConfirmationResponse.prototype.getResultCase = function() {
  return /** @type {proto.authentication_endpoint.TwoFactorConfirmationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.authentication_endpoint.TwoFactorConfirmationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authentication_endpoint.TwoFactorConfirmationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authentication_endpoint.TwoFactorConfirmationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authentication_endpoint.TwoFactorConfirmationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.TwoFactorConfirmationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authenticate: (f = msg.getAuthenticate()) && proto.authentication_endpoint.AuthenticateReply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authentication_endpoint.TwoFactorConfirmationResponse}
 */
proto.authentication_endpoint.TwoFactorConfirmationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authentication_endpoint.TwoFactorConfirmationResponse;
  return proto.authentication_endpoint.TwoFactorConfirmationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authentication_endpoint.TwoFactorConfirmationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authentication_endpoint.TwoFactorConfirmationResponse}
 */
proto.authentication_endpoint.TwoFactorConfirmationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authentication_endpoint.AuthenticateReply;
      reader.readMessage(value,proto.authentication_endpoint.AuthenticateReply.deserializeBinaryFromReader);
      msg.setAuthenticate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authentication_endpoint.TwoFactorConfirmationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authentication_endpoint.TwoFactorConfirmationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authentication_endpoint.TwoFactorConfirmationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.TwoFactorConfirmationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthenticate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authentication_endpoint.AuthenticateReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional AuthenticateReply authenticate = 1;
 * @return {?proto.authentication_endpoint.AuthenticateReply}
 */
proto.authentication_endpoint.TwoFactorConfirmationResponse.prototype.getAuthenticate = function() {
  return /** @type{?proto.authentication_endpoint.AuthenticateReply} */ (
    jspb.Message.getWrapperField(this, proto.authentication_endpoint.AuthenticateReply, 1));
};


/**
 * @param {?proto.authentication_endpoint.AuthenticateReply|undefined} value
 * @return {!proto.authentication_endpoint.TwoFactorConfirmationResponse} returns this
*/
proto.authentication_endpoint.TwoFactorConfirmationResponse.prototype.setAuthenticate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.authentication_endpoint.TwoFactorConfirmationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.TwoFactorConfirmationResponse} returns this
 */
proto.authentication_endpoint.TwoFactorConfirmationResponse.prototype.clearAuthenticate = function() {
  return this.setAuthenticate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.TwoFactorConfirmationResponse.prototype.hasAuthenticate = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authentication_endpoint.RefreshTokenResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.authentication_endpoint.RefreshTokenResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  AUTHENTICATE: 1
};

/**
 * @return {proto.authentication_endpoint.RefreshTokenResponse.ResultCase}
 */
proto.authentication_endpoint.RefreshTokenResponse.prototype.getResultCase = function() {
  return /** @type {proto.authentication_endpoint.RefreshTokenResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.authentication_endpoint.RefreshTokenResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authentication_endpoint.RefreshTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authentication_endpoint.RefreshTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authentication_endpoint.RefreshTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.RefreshTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authenticate: (f = msg.getAuthenticate()) && proto.authentication_endpoint.AuthenticateReply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authentication_endpoint.RefreshTokenResponse}
 */
proto.authentication_endpoint.RefreshTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authentication_endpoint.RefreshTokenResponse;
  return proto.authentication_endpoint.RefreshTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authentication_endpoint.RefreshTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authentication_endpoint.RefreshTokenResponse}
 */
proto.authentication_endpoint.RefreshTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authentication_endpoint.AuthenticateReply;
      reader.readMessage(value,proto.authentication_endpoint.AuthenticateReply.deserializeBinaryFromReader);
      msg.setAuthenticate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authentication_endpoint.RefreshTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authentication_endpoint.RefreshTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authentication_endpoint.RefreshTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authentication_endpoint.RefreshTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthenticate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authentication_endpoint.AuthenticateReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional AuthenticateReply authenticate = 1;
 * @return {?proto.authentication_endpoint.AuthenticateReply}
 */
proto.authentication_endpoint.RefreshTokenResponse.prototype.getAuthenticate = function() {
  return /** @type{?proto.authentication_endpoint.AuthenticateReply} */ (
    jspb.Message.getWrapperField(this, proto.authentication_endpoint.AuthenticateReply, 1));
};


/**
 * @param {?proto.authentication_endpoint.AuthenticateReply|undefined} value
 * @return {!proto.authentication_endpoint.RefreshTokenResponse} returns this
*/
proto.authentication_endpoint.RefreshTokenResponse.prototype.setAuthenticate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.authentication_endpoint.RefreshTokenResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authentication_endpoint.RefreshTokenResponse} returns this
 */
proto.authentication_endpoint.RefreshTokenResponse.prototype.clearAuthenticate = function() {
  return this.setAuthenticate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authentication_endpoint.RefreshTokenResponse.prototype.hasAuthenticate = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.authentication_endpoint);
