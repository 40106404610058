import { configureStore } from '@reduxjs/toolkit'
import navHeaderReducer from '../components/common/NavHeader/NavHeaderSlice'
import masterLayoutReducer from '../components/layouts/Master/MasterLayoutSlice'
import settingReducer from '../components/common/Settings/SettingsSlice'
import loginFormReducer from '../components/forms/Login/LoginFormSlice'
import managersPageReducer from '../components/pages/Management/Users/UsersPageSlice'
import managersRolesPageReducer from '../components/pages/Management/Roles/RolesPageSlice'
import managersUserTypesPageReducer from '../components/pages/Management/UserTypes/UserTypesSlice'
import managersPrivilegesPageReducer from '../components/pages/Management/Privileges/PrivilegesPageSlice'
import managersGroupsPageReducer from '../components/pages/Management/Groups/GroupsPageSlice'
import organizationsPageReducer from '../components/pages/Organizations/OrganizationsPageSlice'
import managersRolesFormReducer from '../components/forms/Management/Roles/RolesFormSlice'
import managersUserTypesFormReducer from '../components/forms/Management/UserTypes/UserTypesFormSlice'
import managersGroupsFormReducer from '../components/forms/Management/Groups/GroupsFormSlice'
import organizationFormReducer from '../components/forms/Organizations/OrganizationsFormSlice'
import managersUsersReducer from '../components/forms/Management/Users/UsersFormSlice'
import managersPrivilegesReducer from '../components/forms/Management/Privileges/PrivilegesFormSlice'
import changePasswordReducer from '../components/forms/ChanagePassword/ChangePasswordFormSlice'
import managersUserTypeDetailsPageReducer from '../components/pages/Management/UserTypes/UserTypeDetailsPage/UserTypeDetailsPageSlice'
import managersUserDetailsPageReducer from '../components/pages/Management/Users/UserDetailsPage/UserDetailsPageSlice'
import managersRoleDetailsPageReducer from '../components/pages/Management/Roles/RoleDetailsPage/RoleDetailsPageSlice'
import managersGroupDetailsPageReducer from '../components/pages/Management/Groups/GroupDetailsPage/GroupDetailsPageSlice'
import managersPrivilegeDetailsPageReducer from '../components/pages/Management/Privileges/PrivilegeDetailsPage/PriviliegeDetailsPageSlice'
import managersRolePrivilegesDetailsPageReducer from '../components/pages/Management/Roles/RoleDetailsPage/RolePrivilegesPage/RolePrivilegesPageSlice'
import managersRolePrivilegesFormReducer from '../components/forms/Management/Roles/RolePrivilegesForm/RolePrivilegesFormSlice'
import organizationDetailsPageReducer from '../components/pages/Organizations/OrganizationDetailsPage/OrganizationDetailsPageSlice'
import managersPrivilegeDataPageReducer from '../components/pages/Management/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataPageSlice'
import managersPrivilegeDataDetailsPageReducer from '../components/pages/Management/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/PrivilegeDataDetailsPageSlice'
import managersDataRestrictionsPageReducer from '../components/pages/Management/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/DataRestrictionsPage/DataRestrictionsPageSlice'
import managersDataRestrictionsFormReducer from '../components/forms/Management/DataRestriactions/DataRestriactionsFormSlice'
import managersGroupMembersPageReducer from '../components/pages/Management/Groups/GroupDetailsPage/GroupMembersPage/GroupMembersPageSlice'
import managersGroupMembersFromReducer from '../components/forms/Management/Groups/GroupMembers/GroupMembersFromSlice'
import profilePageReducer from '../components/pages/Profile/ProfilePageSlice'
import profileFormReducer from '../components/forms/Profile/ProfileFormSlice'
import managersGroupMembershipDetailsPageReducer from '../components/pages/Management/Groups/GroupDetailsPage/GroupMembersPage/GroupMemberDetailsPage/GroupMemberDetailsPageSlice'
import managersRolePrivilegeDetailsPageReducer from '../components/pages/Management/Roles/RoleDetailsPage/RolePrivilegesPage/RolePrivilegeDetailsPage/RolePrivilegeDetailsPageSlice'
import managersDataRestrictionDetailsPageReducer  from '../components/pages/Management/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/DataRestrictionsPage/DataRestrictionDetailsPage/DataRestrictionDetailsPageSlice'



import customersPageReducer from '../components/pages/Customers/Users/UsersPageSlice'
import customersRolesPageReducer from '../components/pages/Customers/Roles/RolesPageSlice'
import customersUserTypesPageReducer from '../components/pages/Customers/UserTypes/UserTypesSlice'
import customersPrivilegesPageReducer from '../components/pages/Customers/Privileges/PrivilegesPageSlice'
import customersGroupsPageReducer from '../components/pages/Customers/Groups/GroupsPageSlice'
import customersRolesFormReducer from '../components/forms/Customers/Roles/RolesFormSlice'
import customersUserTypesFormReducer from '../components/forms/Customers/UserTypes/UserTypesFormSlice'
import customersGroupsFormReducer from '../components/forms/Customers/Groups/GroupsFormSlice'
import customersUsersReducer from '../components/forms/Customers/Users/UsersFormSlice'
import customersPrivilegesReducer from '../components/forms/Customers/Privileges/PrivilegesFormSlice'
import customersUserTypeDetailsPageReducer from '../components/pages/Customers/UserTypes/UserTypeDetailsPage/UserTypeDetailsPageSlice'
import customersUserDetailsPageReducer from '../components/pages/Customers/Users/UserDetailsPage/UserDetailsPageSlice'
import customersRoleDetailsPageReducer from '../components/pages/Customers/Roles/RoleDetailsPage/RoleDetailsPageSlice'
import customersGroupDetailsPageReducer from '../components/pages/Customers/Groups/GroupDetailsPage/GroupDetailsPageSlice'
import customersPrivilegeDetailsPageReducer from '../components/pages/Customers/Privileges/PrivilegeDetailsPage/PriviliegeDetailsPageSlice'
import customersRolePrivilegesDetailsPageReducer from '../components/pages/Customers/Roles/RoleDetailsPage/RolePrivilegesPage/RolePrivilegesPageSlice'
import customersRolePrivilegesFormReducer from '../components/forms/Customers/Roles/RolePrivilegesForm/RolePrivilegesFormSlice'
import customersPrivilegeDataPageReducer from '../components/pages/Customers/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataPageSlice'
import customersPrivilegeDataDetailsPageReducer from '../components/pages/Customers/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/PrivilegeDataDetailsPageSlice'
import customersDataRestrictionsPageReducer from '../components/pages/Customers/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/DataRestrictionsPage/DataRestrictionsPageSlice'
import customersDataRestrictionsFormReducer from '../components/forms/Customers/DataRestriactions/DataRestriactionsFormSlice'
import customersGroupMembersPageReducer from '../components/pages/Customers/Groups/GroupDetailsPage/GroupMembersPage/GroupMembersPageSlice'
import customersGroupMembersFromReducer from '../components/forms/Customers/Groups/GroupMembers/GroupMembersFromSlice'
import customersGroupMembershipDetailsPageReducer from '../components/pages/Customers/Groups/GroupDetailsPage/GroupMembersPage/GroupMemberDetailsPage/GroupMemberDetailsPageSlice'
import customersRolePrivilegeDetailsPageReducer from  '../components/pages/Customers/Roles/RoleDetailsPage/RolePrivilegesPage/RolePrivilegeDetailsPage/RolePrivilegeDetailsPageSlice'
import customersDataRestrictionDetailsPageReducer from  '../components/pages/Customers/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/DataRestrictionsPage/DataRestrictionDetailsPage/DataRestrictionDetailsPageSlice'

import businessesPageReducer from '../components/pages/Businesses/BusinessesPageSlice'
import businessDetailsPageReducer from '../components/pages/Businesses/BusinessDetailsPage/BusinessDetailsPageSlice'
import businessesFormReducer from '../components/forms/Businesses/BusinessesFormSlice'

import businessClientsPageReducer from '../components/pages/BusinessClients/BusinessClientsPageSlice'
import businessClientsFormReducer from '../components/forms/BusinessClients/BusinessClientsFormSlice'
import businessClientDetailsPageReducer from '../components/pages/BusinessClients/businessClientDetailsPage/businessClientDetailsPageSlice'



import logsPageReducer from '../components/pages/Logs/LogsPageSlice'
import logDetailsPageReducer from '../components/pages/Logs/LogDetailsPage/LogDetailsPageSlice'



export const store = configureStore({
  reducer: {
    navHeader: navHeaderReducer,
    masterLayout: masterLayoutReducer,
    settings: settingReducer,
    loginForm: loginFormReducer,
    managersPage: managersPageReducer,
    managersRolesPage: managersRolesPageReducer,
    managersUserTypesPage: managersUserTypesPageReducer,
    managersPrivilegesPage: managersPrivilegesPageReducer,
    managersGroupsPage: managersGroupsPageReducer,
    organizationsPage: organizationsPageReducer,
    managersRolesForm: managersRolesFormReducer,
    managersUserTypesForm: managersUserTypesFormReducer,
    managersGroupsForm: managersGroupsFormReducer,
    managersUsersForm: managersUsersReducer,
    managersPrivilegesForm: managersPrivilegesReducer,
    organizationForm: organizationFormReducer,
    changePasswordForm: changePasswordReducer,
    managersUserTypeDetailsPage: managersUserTypeDetailsPageReducer,
    managersUserDetailsPage: managersUserDetailsPageReducer,
    managersRoleDetailsPage: managersRoleDetailsPageReducer,
    managersGroupDetailsPage: managersGroupDetailsPageReducer,
    managersPrivilegeDetailsPage: managersPrivilegeDetailsPageReducer,
    managersRolePrivilegesDetailsPage: managersRolePrivilegesDetailsPageReducer,
    managersRolePrivilegesForm: managersRolePrivilegesFormReducer,
    organizationDetailsPage: organizationDetailsPageReducer,
    managersPrivilegeDataPage: managersPrivilegeDataPageReducer,
    managersPrivilegeDataDetailsPage: managersPrivilegeDataDetailsPageReducer,
    managersDataRestrictionsPage: managersDataRestrictionsPageReducer,
    managersDataRestrictionsForm: managersDataRestrictionsFormReducer,
    managersGroupMembersPage: managersGroupMembersPageReducer,
    profilePage: profilePageReducer,
    profileForm: profileFormReducer,
    managersGroupMembersFrom: managersGroupMembersFromReducer,
    managersGroupMembershipDetailsPage: managersGroupMembershipDetailsPageReducer,
    managersRolePrivilegeDetailsPage : managersRolePrivilegeDetailsPageReducer,
    managersDataRestrictionDetailsPage : managersDataRestrictionDetailsPageReducer,
 
    customersPage: customersPageReducer,
    customersRolesPage: customersRolesPageReducer,
    customersUserTypesPage: customersUserTypesPageReducer,
    customersPrivilegesPage: customersPrivilegesPageReducer,
    customersRolesForm: customersRolesFormReducer,
    customersUserTypesForm: customersUserTypesFormReducer,
    customersGroupsForm: customersGroupsFormReducer,
    customersUsersForm: customersUsersReducer,
    customersPrivilegesForm: customersPrivilegesReducer,
    customersUserTypeDetailsPage: customersUserTypeDetailsPageReducer,
    customersUserDetailsPage: customersUserDetailsPageReducer,
    customersRoleDetailsPage: customersRoleDetailsPageReducer,
    customersGroupDetailsPage: customersGroupDetailsPageReducer,
    customersPrivilegeDetailsPage: customersPrivilegeDetailsPageReducer,
    customersRolePrivilegesDetailsPage: customersRolePrivilegesDetailsPageReducer,
    customersRolePrivilegesForm: customersRolePrivilegesFormReducer,
    customersPrivilegeDataPage: customersPrivilegeDataPageReducer,
    customersPrivilegeDataDetailsPage: customersPrivilegeDataDetailsPageReducer,
    customersDataRestrictionsPage: customersDataRestrictionsPageReducer,
    customersDataRestrictionsForm: customersDataRestrictionsFormReducer,
    customersGroupMembersPage: customersGroupMembersPageReducer,
    customersGroupMembersFrom: customersGroupMembersFromReducer,
    customersGroupsPage: customersGroupsPageReducer,
    customersGroupMembershipDetailsPage: customersGroupMembershipDetailsPageReducer,
    customersRolePrivilegeDetailsPage :  customersRolePrivilegeDetailsPageReducer,
    customersDataRestrictionDetailsPage : customersDataRestrictionDetailsPageReducer,

    businessesPage : businessesPageReducer,
    businessesForm : businessesFormReducer,
    businessDetailsPage : businessDetailsPageReducer,

    businessClientsPage : businessClientsPageReducer,
    businessClientsForm : businessClientsFormReducer,
    businessClientDetailsPage : businessClientDetailsPageReducer,

    logsPage :logsPageReducer,
    logDetailsPage : logDetailsPageReducer,

  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

