import {  ICalloutContentStyles, IStackStyles } from '@fluentui/react';

export const navHeaderStyle: Partial<IStackStyles> = {
    root: {
        minHeight: 48,
        height: 48,
        boxSizing: 'border-box',
        border: '1px solid #eee',
     
 
    },
};

export const calloutStyle: Partial<ICalloutContentStyles> = {
    root: {
        width: 320,
        height:250,
        //backgroundColor: c.palette.white
    },
};


export const currentOrganization: Partial<IStackStyles> = {
    root: {
        minHeight: 48,
        maxHeight: 48,
        height: 48,
       

},
};