

import { DocumentCard, DocumentCardType, FocusTrapZone, FontIcon, getTheme, IButtonProps, IconButton, ImageIcon, IModalStyleProps, IModalStyles, IStyleFunctionOrObject, ITextField, ITextFieldProps, ITooltipHostStyles, Label, Link, mergeStyles, Modal, PrimaryButton, Stack, Text, TextField, TooltipHost } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { t, use } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../app/Hooks";
import { getMainTheme, ThemeManager } from "../../../app/Themes";


interface IItemViewProps {
    label: string
    hintIconName?: string,
    endButtonHint?: string,
    children: React.ReactNode,
    title?: string,

}

export const ItemView: React.FunctionComponent<IItemViewProps & IButtonProps> = (props) => {
    const titleId = useId('title');
    const ref = useRef<ITextField>(null);
    const { t, i18n } = useTranslation();
    const [style, setStyle] = useState({ visibility: 'hidden', });
    const stateTheme: { appTheme: string, isDarkModeEnabled: boolean } = useAppSelector((state) => {

        return { appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled }
    })
    const iconClass = mergeStyles({
        fontSize: 16,
        paddingRight: 20,

    });
    const labelClass = mergeStyles({
        color: "#605e5c",
        fontSize: 12

    });
    const containerClass = mergeStyles({
        "&:hover": {
            background: !stateTheme.isDarkModeEnabled ? "#efefef" : "#000000",
            cursor: "pointer",
        },
        wordWrap: "anywhere"

    });
    const tooltipId = useId('tooltip');
    const calloutProps = { gapSpace: 0 };
    // The TooltipHost root uses display: inline by default.
    // If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
    return (


        <Stack horizontal verticalAlign="center" className={containerClass} onMouseEnter={e => {
            setStyle({ visibility: 'visible' });
        }}
            onMouseLeave={e => {
                setStyle({ visibility: 'hidden' })
            }} >
            <Stack title={props.title ?? ""} horizontal verticalAlign="center" tokens={{ padding: "10px 12px 10px 12px" }} grow>
                {props.hintIconName ? <FontIcon
                    iconName={props.hintIconName}
                    className={iconClass}
                /> : undefined}


                <Stack >
                    <Label className={labelClass}>{props.label}</Label>
                    {props.children}

                </Stack>

            </Stack>
            <Stack.Item align="start"  >
                <TooltipHost
                    content={props.endButtonHint}
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={tooltipId}
                    calloutProps={calloutProps}
                    styles={hostStyles}
                >
                    <IconButton iconProps={props.iconProps} onClick={props.onClick} aria-describedby={tooltipId} styles={{ root: style, rootHovered: { background: "#c8c6c4" } }}></IconButton>
                </TooltipHost>

            </Stack.Item>
        </Stack>



    );

}

