import { DefaultButton, DialogFooter, IconButton, IStackTokens, MessageBar, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, Text, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, reset, update } from "./GroupsFormSlice";
import * as Yup from 'yup';
import { InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { AddGroupRequest, UpdateGroupRequest, AddGroupResponse, UpdateGroupResponse } from "../../../../repository/UserManagement/Managers/group_pb";


let req: AddGroupRequest;
let updateReq: UpdateGroupRequest;

let promise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;

export const GroupsForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();


    const state: { isLoading: boolean, message: ApiMessage | undefined } = useAppSelector((state) => {

        return { isLoading: state.managersGroupsForm.isLoading, message: state.managersGroupsForm.message }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new AddGroupRequest();
        updateReq = new UpdateGroupRequest()
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                updateReq.setOrganization(wrapper)
            }
        }



        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])



    return (
        <>

            <Formik


                initialValues={{

                    name: (props.renderObject) ? props.renderObject?.name : '',
                    description: (props.renderObject) ? props.renderObject?.description : '',


                }}

                validationSchema={Yup.object({

                    name: inputs.groupName,
                    description: inputs.groupDescription

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {
                        req.setName(values.name.trim());
                        const wrapper = new StringValue();
                        wrapper.setValue(values.description.trim());
                        req.setDescription(wrapper);
                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddGroupResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res.success?.groupid,
                                            name: values.name.trim(),
                                            description: values.description.trim(),
                                            groupCategory: 1,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {
                        updateReq.setName(values.name.trim());
                        const wrapper = new StringValue();
                        wrapper.setValue(values.description.trim());
                        updateReq.setDescription(wrapper);
                        updateReq.setId(props.renderObject?.id);
                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateGroupResponse.AsObject) => {
                                if (res) {

                                    if (props?.onSuccess) {
                                        props?.onSuccess({ ...props.renderObject, name: values.name.trim(), description: values.description.trim() });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }
                            <Field name="name" label={t("groupName")} placeholder={t("groupName")} component={InputField} disabled={state.isLoading} autoFocus maxLength={50} required />
                            <Field name="description" label={t("groupDescription")} placeholder={t("groupDescription")} component={InputField} rows={3} multiline resizable={false} disabled={state.isLoading} maxLength={140} />

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>
                    </Form>
                )}
            </Formik>
        </>
    );

}

