import { MessageBar, MessageBarType, Text } from "@fluentui/react";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/Hooks";
import { ThemeManager } from "../../../app/Themes";



interface MessageProps {
    title: string,
    data: string
    body: string
    type: number
    onDismiss: () => void
}
//dispatch(dismissError()) 

export enum ERROR {
    RESOURCE_NOT_AVAILABLE
}

export const Message: React.FunctionComponent<MessageProps> = (props) => {
    const { t, i18n } = useTranslation();
    const stateTheme: { appTheme: string, isDarkModeEnabled: boolean } = useAppSelector((state) => {

        return { appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled }
    })
    let type = MessageBarType.info
    let body = props.body;
    let title = props.title;
    let data = props.data;

    if (props.type == 1) {
        type = MessageBarType.success;
    } else if (props.type == 2) {
        type = MessageBarType.blocked;
    } else if (props.type == 3) {
        type = MessageBarType.warning;
    } else if (props.type == 4) {
        type = MessageBarType.info;
    } else {
        type = MessageBarType.error;
        body = t("genericErrorBody");
        title = t("genericErrorTitle");
    }
    return (
        <MessageBar

            messageBarType={type}
            isMultiline={true}
            dismissButtonAriaLabel={t("close")}
            onDismiss={props.onDismiss}
        >
            <Text block styles={{ root: { color: (stateTheme.isDarkModeEnabled) ? ThemeManager.getThemeManager().getTheme(stateTheme.appTheme, stateTheme.isDarkModeEnabled).theme.mainTheme.semanticColors.messageText : undefined } }}><b>
            <div
                    dangerouslySetInnerHTML={{ __html: (data?  ("("+ data +")" + " " + title) :  title)}}
                />
                </b></Text>
            <Text block styles={{ root: { color: (stateTheme.isDarkModeEnabled) ? ThemeManager.getThemeManager().getTheme(stateTheme.appTheme, stateTheme.isDarkModeEnabled).theme.mainTheme.semanticColors.messageText : undefined } }}>
                <div
                    dangerouslySetInnerHTML={{ __html: body }}
                /></Text>
        </MessageBar >

    );

}