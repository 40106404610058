// source: roleprivilege.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('../common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.ResultCase', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.ResultCase', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.ResultCase', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.ResultCase', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.ResultCase', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.PrivilegeInfo', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.RoleInfo', null, global);
goog.exportSymbol('proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.repeatedFields_, null);
};
goog.inherits(proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.displayName = 'proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.displayName = 'proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.displayName = 'proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.displayName = 'proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.displayName = 'proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.displayName = 'proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.displayName = 'proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.displayName = 'proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.displayName = 'proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.repeatedFields_, null);
};
goog.inherits(proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.displayName = 'proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.PrivilegeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customers_role_privilege_endpoint.PrivilegeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.PrivilegeInfo.displayName = 'proto.customers_role_privilege_endpoint.PrivilegeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.RoleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customers_role_privilege_endpoint.RoleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.RoleInfo.displayName = 'proto.customers_role_privilege_endpoint.RoleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.displayName = 'proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.oneofGroups_);
};
goog.inherits(proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.displayName = 'proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.oneofGroups_);
};
goog.inherits(proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.displayName = 'proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.oneofGroups_);
};
goog.inherits(proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.displayName = 'proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.oneofGroups_);
};
goog.inherits(proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.displayName = 'proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.oneofGroups_);
};
goog.inherits(proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.displayName = 'proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    privilegeidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    roleid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest;
  return proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPrivilegeids(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoleid(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrivilegeidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getRoleid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int32 privilegeIds = 1;
 * @return {!Array<number>}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.prototype.getPrivilegeidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest} returns this
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.prototype.setPrivilegeidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest} returns this
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.prototype.addPrivilegeids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest} returns this
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.prototype.clearPrivilegeidsList = function() {
  return this.setPrivilegeidsList([]);
};


/**
 * optional int32 roleId = 2;
 * @return {number}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.prototype.getRoleid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest} returns this
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.prototype.setRoleid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Int32Value organization = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest} returns this
*/
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest} returns this
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roleid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    privilegeid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest;
  return proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoleid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrivilegeid(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoleid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPrivilegeid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 roleId = 1;
 * @return {number}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.prototype.getRoleid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest} returns this
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.prototype.setRoleid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 privilegeId = 2;
 * @return {number}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.prototype.getPrivilegeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest} returns this
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.prototype.setPrivilegeid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Int32Value organization = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest} returns this
*/
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest} returns this
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest;
  return proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest} returns this
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value organization = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest} returns this
*/
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest} returns this
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest;
  return proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value organization = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest} returns this
*/
proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    nextto: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numofresults: jspb.Message.getFieldWithDefault(msg, 4, 0),
    roleid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest;
  return proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextto(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoleid(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getNextto();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRoleid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue search = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest} returns this
*/
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool order = 2;
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 nextTo = 3;
 * @return {number}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.getNextto = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.setNextto = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 numOfResults = 4;
 * @return {number}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 roleId = 5;
 * @return {number}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.getRoleid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.setRoleid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Int32Value organization = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest} returns this
*/
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    roleprivilegeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay;
  return proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoleprivilegeid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoleprivilegeid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 rolePrivilegeId = 1;
 * @return {number}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.prototype.getRoleprivilegeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay} returns this
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.prototype.setRoleprivilegeid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay} returns this
*/
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay} returns this
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay} returns this
*/
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay} returns this
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay;
  return proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp createdDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay} returns this
*/
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay} returns this
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay} returns this
*/
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay} returns this
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteddate: (f = msg.getDeleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay;
  return proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleteddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp deletedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.prototype.getDeleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay} returns this
*/
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.prototype.setDeleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay} returns this
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.prototype.clearDeleteddate = function() {
  return this.setDeleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.prototype.hasDeleteddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay} returns this
*/
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay} returns this
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    role: (f = msg.getRole()) && proto.customers_role_privilege_endpoint.RoleInfo.toObject(includeInstance, f),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    privilege: (f = msg.getPrivilege()) && proto.customers_role_privilege_endpoint.PrivilegeInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay;
  return proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.customers_role_privilege_endpoint.RoleInfo;
      reader.readMessage(value,proto.customers_role_privilege_endpoint.RoleInfo.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 3:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 5:
      var value = new proto.customers_role_privilege_endpoint.PrivilegeInfo;
      reader.readMessage(value,proto.customers_role_privilege_endpoint.PrivilegeInfo.deserializeBinaryFromReader);
      msg.setPrivilege(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.customers_role_privilege_endpoint.RoleInfo.serializeBinaryToWriter
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPrivilege();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.customers_role_privilege_endpoint.PrivilegeInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional RoleInfo role = 2;
 * @return {?proto.customers_role_privilege_endpoint.RoleInfo}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.getRole = function() {
  return /** @type{?proto.customers_role_privilege_endpoint.RoleInfo} */ (
    jspb.Message.getWrapperField(this, proto.customers_role_privilege_endpoint.RoleInfo, 2));
};


/**
 * @param {?proto.customers_role_privilege_endpoint.RoleInfo|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay} returns this
*/
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.hasRole = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.ProfileResponse addedBy = 3;
 * @return {?proto.common.ProfileResponse}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 3));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay} returns this
*/
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay} returns this
*/
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PrivilegeInfo privilege = 5;
 * @return {?proto.customers_role_privilege_endpoint.PrivilegeInfo}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.getPrivilege = function() {
  return /** @type{?proto.customers_role_privilege_endpoint.PrivilegeInfo} */ (
    jspb.Message.getWrapperField(this, proto.customers_role_privilege_endpoint.PrivilegeInfo, 5));
};


/**
 * @param {?proto.customers_role_privilege_endpoint.PrivilegeInfo|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay} returns this
*/
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.setPrivilege = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.clearPrivilege = function() {
  return this.setPrivilege(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.prototype.hasPrivilege = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    roleprivilegesList: jspb.Message.toObjectList(msg.getRoleprivilegesList(),
    proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay;
  return proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo;
      reader.readMessage(value,proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.deserializeBinaryFromReader);
      msg.addRoleprivileges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoleprivilegesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RolePrivilegeSummaryInfo rolePrivileges = 1;
 * @return {!Array<!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo>}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.prototype.getRoleprivilegesList = function() {
  return /** @type{!Array<!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo, 1));
};


/**
 * @param {!Array<!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo>} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay} returns this
*/
proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.prototype.setRoleprivilegesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.prototype.addRoleprivileges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.prototype.clearRoleprivilegesList = function() {
  return this.setRoleprivilegesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.PrivilegeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.PrivilegeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.PrivilegeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.PrivilegeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.PrivilegeInfo}
 */
proto.customers_role_privilege_endpoint.PrivilegeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.PrivilegeInfo;
  return proto.customers_role_privilege_endpoint.PrivilegeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.PrivilegeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.PrivilegeInfo}
 */
proto.customers_role_privilege_endpoint.PrivilegeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.PrivilegeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.PrivilegeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.PrivilegeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.PrivilegeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.customers_role_privilege_endpoint.PrivilegeInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customers_role_privilege_endpoint.PrivilegeInfo} returns this
 */
proto.customers_role_privilege_endpoint.PrivilegeInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.customers_role_privilege_endpoint.PrivilegeInfo.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.PrivilegeInfo} returns this
*/
proto.customers_role_privilege_endpoint.PrivilegeInfo.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.PrivilegeInfo} returns this
 */
proto.customers_role_privilege_endpoint.PrivilegeInfo.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.PrivilegeInfo.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.RoleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.RoleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.RoleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.RoleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.RoleInfo}
 */
proto.customers_role_privilege_endpoint.RoleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.RoleInfo;
  return proto.customers_role_privilege_endpoint.RoleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.RoleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.RoleInfo}
 */
proto.customers_role_privilege_endpoint.RoleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.RoleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.RoleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.RoleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.RoleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.customers_role_privilege_endpoint.RoleInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customers_role_privilege_endpoint.RoleInfo} returns this
 */
proto.customers_role_privilege_endpoint.RoleInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.customers_role_privilege_endpoint.RoleInfo.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.RoleInfo} returns this
*/
proto.customers_role_privilege_endpoint.RoleInfo.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.RoleInfo} returns this
 */
proto.customers_role_privilege_endpoint.RoleInfo.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.RoleInfo.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    privilege: (f = msg.getPrivilege()) && proto.customers_role_privilege_endpoint.PrivilegeInfo.toObject(includeInstance, f),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo}
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo;
  return proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo}
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.customers_role_privilege_endpoint.PrivilegeInfo;
      reader.readMessage(value,proto.customers_role_privilege_endpoint.PrivilegeInfo.deserializeBinaryFromReader);
      msg.setPrivilege(value);
      break;
    case 3:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPrivilege();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.customers_role_privilege_endpoint.PrivilegeInfo.serializeBinaryToWriter
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo} returns this
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PrivilegeInfo privilege = 2;
 * @return {?proto.customers_role_privilege_endpoint.PrivilegeInfo}
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.getPrivilege = function() {
  return /** @type{?proto.customers_role_privilege_endpoint.PrivilegeInfo} */ (
    jspb.Message.getWrapperField(this, proto.customers_role_privilege_endpoint.PrivilegeInfo, 2));
};


/**
 * @param {?proto.customers_role_privilege_endpoint.PrivilegeInfo|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo} returns this
*/
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.setPrivilege = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo} returns this
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.clearPrivilege = function() {
  return this.setPrivilege(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.hasPrivilege = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.ProfileResponse addedBy = 3;
 * @return {?proto.common.ProfileResponse}
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 3));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo} returns this
*/
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo} returns this
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo} returns this
*/
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo} returns this
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.RolePrivilegeSummaryInfo.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.ResultCase}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.prototype.getResultCase = function() {
  return /** @type {proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse;
  return proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay;
      reader.readMessage(value,proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddRolePrivilegeReplay success = 1;
 * @return {?proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay} */ (
    jspb.Message.getWrapperField(this, proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay, 1));
};


/**
 * @param {?proto.customers_role_privilege_endpoint.AddRolePrivilegeReplay|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse} returns this
*/
proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse} returns this
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.AddRolePrivilegeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.ResultCase}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.prototype.getResultCase = function() {
  return /** @type {proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse;
  return proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay;
      reader.readMessage(value,proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteRolePrivilegeReplay success = 1;
 * @return {?proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay} */ (
    jspb.Message.getWrapperField(this, proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay, 1));
};


/**
 * @param {?proto.customers_role_privilege_endpoint.DeleteRolePrivilegeReplay|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse} returns this
*/
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse} returns this
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.DeleteRolePrivilegeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.ResultCase}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.prototype.getResultCase = function() {
  return /** @type {proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse;
  return proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay;
      reader.readMessage(value,proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddBatchRolePrivilegeReplay success = 1;
 * @return {?proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay} */ (
    jspb.Message.getWrapperField(this, proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay, 1));
};


/**
 * @param {?proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeReplay|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse} returns this
*/
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse} returns this
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.AddBatchRolePrivilegeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.ResultCase}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.prototype.getResultCase = function() {
  return /** @type {proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse;
  return proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay;
      reader.readMessage(value,proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetRolePrivilegesReplay success = 1;
 * @return {?proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.prototype.getSuccess = function() {
  return /** @type{?proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay} */ (
    jspb.Message.getWrapperField(this, proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay, 1));
};


/**
 * @param {?proto.customers_role_privilege_endpoint.GetRolePrivilegesReplay|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse} returns this
*/
proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegesResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.ResultCase}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.prototype.getResultCase = function() {
  return /** @type {proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse;
  return proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay;
      reader.readMessage(value,proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetRolePrivilegeReplay success = 1;
 * @return {?proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay} */ (
    jspb.Message.getWrapperField(this, proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay, 1));
};


/**
 * @param {?proto.customers_role_privilege_endpoint.GetRolePrivilegeReplay|undefined} value
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse} returns this
*/
proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse} returns this
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customers_role_privilege_endpoint.GetRolePrivilegeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.customers_role_privilege_endpoint);
