import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession } from '../../../../app/Api';
import { DataRestrictionEndpointClient } from '../../../../repository/UserManagement/Customers/DatarestrictionServiceClientPb';
import { AddDataRestrictionRequest, AddDataRestrictionResponse, UpdateDataRestrictionRequest, UpdateDataRestrictionResponse } from '../../../../repository/UserManagement/Customers/datarestriction_pb';
import { TableState } from '../../../common/Table/TableSate';
import { GetUnRestrictedGroupsRequest, GetUnRestrictedGroupsResponse } from '../../../../repository/UserManagement/Customers/group_pb';
import { GroupEndpointClient } from '../../../../repository/UserManagement/Customers/GroupServiceClientPb';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
export interface DataRestrictionsFormState {
  isLoading: boolean
  groups: TableState
  message?: ApiMessage
}

const initialState: DataRestrictionsFormState = {
  isLoading: false,
  groups: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
  message: undefined
}
const groupsClient = new GroupEndpointClient(API_URL, null, null);
const client = new DataRestrictionEndpointClient(API_URL, null, null);
export const add = createAsyncThunk<AddDataRestrictionResponse.AsObject, APIRequest<AddDataRestrictionRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/customers/dataRestrictions/add',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.add(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)


export const update = createAsyncThunk<UpdateDataRestrictionResponse.AsObject, APIRequest<UpdateDataRestrictionRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/customers/dataRestrictions/update',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.update(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)


export const getGroups = createAsyncThunk<GetUnRestrictedGroupsResponse.AsObject, APIRequest<GetUnRestrictedGroupsRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/customers/dataRestrictions/getGroupss',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {

        var r = await groupsClient.getUnRestrictedGroups(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }


)

export const dataRestrictionsFormSlice = createSlice({
  name: 'forms/customers/dataRestrictions',
  initialState,
  reducers: {

    dismissMessage: (state) => {
      state.message = undefined;
    },
    reset: (state) => {
      state.isLoading = false;
      state.message = undefined;
      state.groups = initialState.groups
    },
    resetGroups: (state) => {
      state.groups.items = [];
      state.groups.hasMore = true;
    },

    setSort: (state, action: PayloadAction<boolean>) => {
      state.groups.isDescending = action.payload;
    },
    setNumberOfResults: (state, action: PayloadAction<number>) => {
      state.groups.numberOfResults = action.payload;
    },
    setSelectedGroup: (state, action: PayloadAction<any[]>) => {
      state.groups.selected = action.payload;
    },

    setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
      state.groups.isFilteredSet = action.payload;
    },
    setMessage: (state, action: PayloadAction<ApiMessage>) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(add.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload)
        state.message = toApiMessage(payload.success?.message);

    })
    builder.addCase(add.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(add.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
    builder.addCase(update.fulfilled, (state, { payload }) => {
      if (payload)
        state.message = toApiMessage(payload.success?.message);
      state.isLoading = false;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(update.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })

    builder.addCase(getGroups.fulfilled, (state, { payload }) => {
      state.groups.isFetching = false
      if (payload) {
        var r = (payload?.success?.groupsList.map(val => {
          return {
            id: val.id,
            name: val.name?.value ?? "",
            description: val.description?.value ?? "",
            groupCategory: val.groupcategory?.value,
            createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
            addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
            addedById: val.addedby?.id?.value,
          }
        }
        ) as any[])
        if (r.length < state.groups.numberOfResults) {
          state.groups.hasMore = false;
        }
        state.groups.items = state.groups.items.concat(r);

      } else {
        state.groups.hasMore = false;
      }
      //console.log(state.items)

    })
    builder.addCase(getGroups.rejected, (state, action) => {
      state.groups.isFetching = false;
      if (action.payload) {
        //state.message = action.payload;
      }
    })
    builder.addCase(getGroups.pending, (state, action) => {
      state.groups.isFetching = true;
      //state.message = undefined;
    })
  }
})

export const { setMessage, reset, dismissMessage, resetGroups, setSelectedGroup, setIsFilteredSet } = dataRestrictionsFormSlice.actions

export default dataRestrictionsFormSlice.reducer


