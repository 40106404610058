import moment from "moment";
import { ReactInstance } from "react";
import { getSession } from "./Api";
import { Operations } from "./Enums";
import i18n from "./i18n";

export const getCurrentLanguage = (): string => {

    var lang = i18n.language || localStorage.getItem("lang") || 'ar'

    return lang;

}

export const timestampToDate = (seconds: number | undefined, nanos: number | undefined): Date | undefined => {

    if (seconds) {

        const a = new Date(seconds * 1000);
        if (nanos)
            a.setMilliseconds((nanos) / 1e6)

        return a;
    }

}


export const formatDate = (date: Date | undefined, format = 'YYYY-MM-DD HH:mm:ss'): string => {

    return moment(date).format(format);

}



export const currencyFormatter = (value: number, options: any) => {

    options = {
        ...{
            significantDigits: 2,
            thousandsSeparator: ',',
            decimalSeparator: '.',
            symbol: '$'
        }, ...options
    }


    let v = Number(value).toFixed(options.significantDigits)

    const [currency, decimal] = v.split('.')
    if (isRTL(options.symbol)) {
        return `${currency.replace(
            /\B(?=(\d{3})+(?!\d))/g,

            options.thousandsSeparator
        )}${options.decimalSeparator}${decimal} ${options.symbol}`
    }
    return `${options.symbol}${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        options.thousandsSeparator
    )}${options.decimalSeparator}${decimal}`
}

export const isRTL = (text: string): boolean => {

    let regexp = new RegExp('[\u0600-\u06FF]+')
    return regexp.test(text);

}


export const printComponent = (html?: any, isTable: boolean = false): ReactInstance => {

    const current = getSession();
    if (!current) {
        return html;
    }
    const PrintElem = document.createElement('div');
    PrintElem.className = isTable ? "table_printing_content" : "printing_content"
    const header =
        `<b>${((current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? "")).trim() + " (" + (current.username?.value ?? "") + " - " + (current.organization?.name?.value ?? "") + ")"}</b>` +
        `<br><b>${formatDate(new Date())}</b>` +
        `<br><b>${window.location.href}</b>`
    PrintElem.innerHTML = header;
    PrintElem.appendChild(html);
    return PrintElem
}

/* 0: common, 1: treasury, 2:account, 3: treasury + common, 4: account + common  */
export const getOperations = (isTransactional?: boolean, type?: number, isInternalOrder?: boolean): { key: number, text: string, isTransactional: boolean, isInternalOrder?: boolean, type?: number }[] => {
    let set = [{ key: Operations.QUERY_BALANCE, text: "queryBalance", isTransactional: false, type: 0, isInternalOrder: false },
    { key: Operations.ISSUE_STATEMENT, text: "issueStatement", isTransactional: false, type: 0, isInternalOrder: false },
    { key: Operations.TRANSFER, text: "transfer", isTransactional: true, type: 2, isInternalOrder: false },
    { key: Operations.DEPOSIT, text: "deposit", isTransactional: true, type: 0, isInternalOrder: false },
    { key: Operations.WITHDRAW, text: "withdraw", isTransactional: true, type: 0, isInternalOrder: false }
        , { key: Operations.FEE_COLLECTION, text: "feeCollection", isTransactional: true, type: 2, isInternalOrder: true }
        , { key: Operations.RETURN_RESERVED_BALANCE, text: "returnReversedBalance", isTransactional: false, type: 2, isInternalOrder: true }
        , { key: Operations.TRADING_ORDER_SELL, text: "internalTradingOrderSell", isTransactional: true, type: 2, isInternalOrder: false }
        , { key: Operations.TRADING_ORDER_BUY, text: "internalTradingOrderBuy", isTransactional: true, type: 2, isInternalOrder: false }
        , { key: Operations.EXTERNAL_TRADING_ORDER_SELL_DEPOSIT, text: "externalTradingOrderSellDeposit", isTransactional: true, type: 0, isInternalOrder: false }
        , { key: Operations.EXTERNAL_TRADING_ORDER_SELL_WITHDRAW, text: "externalTradingOrderSellWithdraw", isTransactional: true, type: 0, isInternalOrder: false }
        , { key: Operations.EXTERNAL_TRADING_ORDER_BUY_DEPOSIT, text: "externalTradingOrderBuyDeposit", isTransactional: true, type: 0, isInternalOrder: false }
        , { key: Operations.EXTERNAL_TRADING_ORDER_BUY_WITHDRAW, text: "externalTradingOrderBuyWithdraw", isTransactional: true, type: 0, isInternalOrder: false }

    ];

    if (isTransactional == undefined)
        set = set;
    else if (isTransactional)
        set = set.filter(e => e.isTransactional == true);
    else {
        set = set.filter(e => e.isTransactional == false);
    }

    if (type == undefined)
        set = set;
    else if (type == 3)
        set = set.filter(e => e.type == 1 || e.type == 0);
    else if (type == 4)
        set = set.filter(e => e.type == 2 || e.type == 0);
    else
        set = set.filter(e => e.type == type);

    if (isInternalOrder == undefined)
        set = set;
    else if (isInternalOrder)
        set = set.filter(e => e.isInternalOrder == true);
    else {
        set = set.filter(e => e.isInternalOrder == false);
    }

    return set;
}

export const normalizeKey = (key: string) => key as unknown as TemplateStringsArray;


export const phoneNumberFormatter = (PhoneNumber: string): string | undefined => {
    if (PhoneNumber == undefined)
        return undefined;
    PhoneNumber = PhoneNumber.trim();
    PhoneNumber = PhoneNumber.replace('\u0660', '0')
        .replace('\u0661', '1')
        .replace('\u0662', '2')
        .replace('\u0663', '3')
        .replace('\u0664', '4')
        .replace('\u0665', '5')
        .replace('\u0666', '6')
        .replace('\u0667', '7')
        .replace('\u0668', '8')
        .replace('\u0669', '9'); // ٠٩١٤٢٦٧٣١٥ cases
    if (PhoneNumber.length >= 8) {
        PhoneNumber = PhoneNumber.trim().replace(" ", "").replace("+", "").replace("-", ""); // Remove spaces, +, -
        if (PhoneNumber.length < 4)
            return undefined;
        let isAllDigits = /^\d+$/.test(PhoneNumber);
        if (!isAllDigits)
            return undefined;
        if (PhoneNumber.length > 15)
            return undefined;

        if (PhoneNumber.startsWith("00")) {
            PhoneNumber = PhoneNumber.substring(2); // 00218912129959 => 218912129959
        }
        else if (PhoneNumber.startsWith("09")) // Local case
        {
            PhoneNumber = "218" + PhoneNumber.substring(1); // 0912129599 => 218912129959
        }
        return PhoneNumber;
    }
    return undefined;

}



export const getCurrentOrganization = (): any => {
    const i: string | null = localStorage.getItem("currentOrg")
    if (i) {
        let currentOrg = JSON.parse(i)
        if (currentOrg)
            return i;
        return undefined;
    }
    return undefined;

};

export const setCurrentOrganization = (e: any): any => {
    let currentOrg = JSON.stringify(e)
    if (currentOrg)
        localStorage.setItem('currentOrg', currentOrg)

};


export const base64ToArrayBuffer = (e: string) => {
    var binary_string = window.atob(e);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

export const downloadFile = (base64Data: string, fileName: string) => {
    var data = new Blob([base64ToArrayBuffer(base64Data)]);
    var csvURL = window.URL.createObjectURL(data);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', fileName);
    tempLink.click();

}

export const getFileExtension = (type: number): string => {
    return type == 1 ? ".xlsx" : type == 2 ? ".csv" : type == 3 ? ".pdf" : ".txt";
}

