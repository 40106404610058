import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession, APIResponse } from '../../../app/Api';
import { formatDate, timestampToDate } from '../../../app/Helpers';
import { AddBusinessRequest, UpdateBusinessRequest, UpdateBusinessResponse, AddBusinessResponse, UpdateThirdPartyConnectionRequest, UpdateThirdPartyConnectionResponse, UploadLogoResponse, UploadLogoRequest, GetBusinessResponse, GetBusinessRequest } from '../../../repository/UserManagement/Dimensions/business_pb';
import { BusinessEndpointClient } from '../../../repository/UserManagement/Dimensions/BusinessServiceClientPb';
import { DynamicField } from '../../common/DynamicFields/DynamicFields';
import { TableState } from '../../common/Table/TableSate';

export interface BusinessesFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    stage2: any,

}

const initialState: BusinessesFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    stage2: undefined,
}

const client = new BusinessEndpointClient(API_URL, null, null);

export const add = createAsyncThunk<AddBusinessResponse.AsObject, APIRequest<AddBusinessRequest>, {
    rejectValue: ApiMessage
}>(
    'forms/businesses/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.add(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const update = createAsyncThunk<UpdateBusinessResponse.AsObject, APIRequest<UpdateBusinessRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/businesses/update',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.update(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const updateThirdParty = createAsyncThunk<UpdateThirdPartyConnectionResponse.AsObject, APIRequest<UpdateThirdPartyConnectionRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/businesses/updateThirdPartyConnection',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateThirdPartyConnection(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const uploadLogo = createAsyncThunk<UploadLogoResponse.AsObject, APIRequest<UploadLogoRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/businesses/uploadLogo',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.uploadLogo(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const getBusiness = createAsyncThunk<APIResponse<GetBusinessResponse.AsObject>, APIRequest<GetBusinessRequest>, {
    rejectValue: ApiMessage
}>(
    'forms/businesses/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getBusiness(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const businessesFormSlice = createSlice({
    name: 'forms/businesses',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;

        },
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.stage2 = initialState.stage2;

        },

        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
                id: action.payload?.id,
                name: action.payload?.name ? action.payload?.name.trim() : "",
                description: action.payload?.description ? action.payload?.description?.trim() : "",
                isAvailable: action.payload?.isAvailable,
                createdDate: action.payload?.createdDate,
                addedBy: action.payload?.addedBy,
                addedById: action.payload?.addedById,
                isActive: action.payload?.isActive,
                logoId: action.payload?.logoId,
                logoName: action.payload?.logoName,
                logoPath: action.payload?.logoPath,
                logoSource: action.payload?.logoSource,
                connectionId: action.payload?.connectionId ,
                connectionMetaData: action.payload?.connectionMetaData ,

            }
            state.stage1 = stage1;

        },
        setStage2: (state, action: PayloadAction<any>) => {

            let stage2 = {
                phoneNumbers: action.payload?.phoneNumbers,
                urls: action.payload?.urls,
                extraFields: action.payload?.extraFields,
                long: action.payload?.long,
                lat: action.payload?.lat,
                addressCountry: action.payload?.addressCountry,
                addressCity: action.payload?.addressCity,
                addressFirstLine: action.payload?.addressFirstLine,
                addressSecondLine: action.payload?.addressSecondLine,
                addressPostalCode: action.payload?.addressPostalCode,

            }
            state.stage2 = stage2

        }
    },
    extraReducers: (builder) => {

        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })


        builder.addCase(updateThirdParty.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(updateThirdParty.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })


        builder.addCase(updateThirdParty.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })


        builder.addCase(update.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(update.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })


        builder.addCase(getBusiness.fulfilled, (state, { payload }) => {

            if (payload) {
                let stage1 = {
                    id: payload.response?.success?.id,
                    name: payload.response?.success?.name?.value ?? "",
                    description: payload.response?.success?.description?.value ?? "",
                    isAvailable: payload.response?.success?.isavailable?.value,
                    createdDate: payload.response?.success?.createddate ? formatDate(timestampToDate(payload.response?.success?.createddate?.seconds, payload.response?.success?.createddate?.nanos)) : "",
                    createdby: payload.response?.success?.createdby ? (payload.response?.success?.createdby?.firstname?.value ?? "") + " " + (payload.response?.success?.createdby?.lastname?.value ?? "") : "",
                    createdbyId: payload.response?.success?.createdby?.id?.value,
                    isActive: payload.response?.success?.isactive?.value,
                    logoId: payload.response?.success?.logo?.id?.value,
                    logoName: payload.response?.success?.logo?.name?.value,
                    logoPath: payload.response?.success?.logo?.path?.value,
                    logoSource: payload.response?.success?.logo?.source?.value,
                    connectionId: payload.response?.success?.connection?.id?.value ?? "",
                    connectionMetaData: payload.response?.success?.connection?.metadata?.value ?? "",

                }

                state.stage1 = stage1;

                let stage2 = {
                    phoneNumbers: [] as DynamicField[],
                    urls: [] as DynamicField[],
                    extraFields: [] as DynamicField[],
                    long: payload.response?.success?.locationList?.length == 2 ? payload.response?.success?.locationList[0] : "",
                    lat: payload.response?.success?.locationList?.length == 2 ? payload.response?.success?.locationList[1] : "",
                    addressCountry: payload.response?.success?.address ? { key: payload.response?.success?.address?.countryiso?.value, text: '' } : { key: 'LY', text: 'Libya' },
                    addressCity: payload.response?.success?.address ? payload.response?.success?.address?.city?.value : "",
                    addressFirstLine: payload.response?.success?.address ? payload.response?.success?.address?.firstline?.value : "",
                    addressSecondLine: payload.response?.success?.address ? payload.response?.success?.address?.secondline?.value : "",
                    addressPostalCode: payload.response?.success?.address ? payload.response?.success?.address?.postalcode?.value : "",

                }


                if (payload.response?.success?.phonenumbersList) {
                    let count = 0
                    payload.response?.success?.phonenumbersList?.forEach(element => {
                        stage2.phoneNumbers.push({ key: count + "", label: undefined, type: 1, value: element.value })
                        count++
                    });
                }

                if (payload.response?.success?.urlsList) {
                    let count = 0
                    payload.response?.success?.urlsList?.forEach(element => {
                        stage2.urls.push({ key: count + "", label: element.id + "", type: 2, value: element.url })
                        count++
                    });

                }

                if (payload.response?.success?.extrafieldsList) {
                    let count = 0

                    payload.response?.success?.extrafieldsList?.forEach(element => {
                        stage2.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }
                state.stage2 = stage2





            }

            state.isLoading = false;

        })
        builder.addCase(getBusiness.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getBusiness.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(uploadLogo.fulfilled, (state, { payload }) => {
            state.isLoading = false;

        })
        builder.addCase(uploadLogo.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(uploadLogo.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

    }
})

export const { setStage1, setStage2, reset, dismissMessage, setIsLoading } = businessesFormSlice.actions

export default businessesFormSlice.reducer


