import { ComboBox, DefaultButton, DialogFooter, IComboBoxOption, Icon, IconButton, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, Link, MessageBar, MessageBarType, Modal, Persona, PersonaSize, Pivot, PivotItem, PrimaryButton, Separator, Spinner, SpinnerSize, Stack, StackItem, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, values } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik, getIn } from "formik";
import { FormEventHandler, InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/Hooks";
import { add, dismissMessage, getBusiness, reset, setIsLoading, updateThirdParty, uploadLogo, setStage1, setStage2, update } from "./BusinessesFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../controls/Controls";
import { inputs } from "../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, ORGANIZATION_LOGO_URL, saveSession } from "../../../app/Api";
import { Message } from "../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../FormProps";
import { Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../app/Helpers";
import { AddBusinessRequest, AddBusinessResponse, GetBusinessRequest, UpdateBusinessRequest, UpdateBusinessResponse, UploadLogoRequest, UploadLogoResponse, URL } from "../../../repository/UserManagement/Dimensions/business_pb";

import { Address, ExtraField } from "../../../repository/UserManagement/common_pb";
import { TableState } from "../../common/Table/TableSate";
import { DynamicField } from "../../common/DynamicFields/DynamicFields";
import { countriesAR, countriesEn } from "../../../app/Content";
import { List } from "../../common/List/List";
import { Popup } from "../../common/Popup/Popup";
import { AuthenticateReply } from "../../../repository/UserManagement/authentication_pb";


let req: AddBusinessRequest;
let updateReq: UpdateBusinessRequest;

let getRolesPromise: any;
let getUserTypesPromise: any;

let promise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;

export const BusinessesForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();

    const [selectedKey, setSelectedKey] = useState(0);
    const onNextClick = () => {
        setSelectedKey((selectedKey + 1) % 2);
    };
    const onBackClick = () => {
        setSelectedKey((selectedKey - 1) % 2);
    };

    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any, stage2: any } = useAppSelector((state) => {

        return {
            isLoading: state.businessesForm.isLoading, message: state.businessesForm.message
            , stage1: state.businessesForm.stage1
            , stage2: state.businessesForm.stage2

        }
    })


    useEffect(() => {

        req = new AddBusinessRequest();
        updateReq = new UpdateBusinessRequest();

        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        } else {
            var l = searchParams.get('org');
            if (l) {
                org = Number(l);
                if (Number.isNaN(org)) {
                    org = -1;
                } else {
                    const wrapper = new Int32Value();
                    wrapper.setValue(org);
                    req.setOrganization(wrapper)
                }
            }

            if (props.type == FormType.EDIT) {
                if (props.fetchData) {
                    let r = new GetBusinessRequest()
                    r.setId(props?.renderObject?.id);
                    promise?.abort()
                    promise = dispatch(getBusiness({ body: r, headers: getHeaders() }))
                    promise.unwrap().catch((e: any) => {
                        setSelectedKey(2);
                    })
                } else {
                    dispatch(setStage1(props?.renderObject))
                    dispatch(setStage2(props?.renderObject))
                }
            }
        }


        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])


    return (
        <>
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()); if (selectedKey == 3) props.onCancel() }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            {selectedKey <= 2 ? <Pivot  aria-label={props.type == FormType.ADD ? t("addBusiness") : t("editBusiness")} selectedKey={String(selectedKey)} >
                <PivotItem headerText={t("businessInfo")} itemKey="0" >
                    <BusinessesGeneralInfoForm onSuccess={(e) => {
                        dispatch(setStage1({ ...state.stage1, ...e }))
                        onNextClick();
                    }} onCancel={props.onCancel} type={props.type} childrenGap={props.childrenGap} maxHeight={props.maxHeight}
                        maxWidth={props.maxWidth} padding={props.padding} renderObject={state.stage1} />
                </PivotItem>
                <PivotItem headerText={t("businessDetailsInfo")} itemKey="1">
                    <BusinessesDetailsForm onSuccess={(e) => {


                        if (props.onSuccess) {
                            if (props.type == FormType.ADD)
                                props.onSuccess({ ...state.stage1, ...e })
                            else
                                props.onSuccess({ ...state.stage1, ...e })

                        }
                    }} onCancel={(e) => {
                        dispatch(setStage2({ ...state.stage2, ...e }))
                        onBackClick()
                    }} type={props.type} childrenGap={props.childrenGap} maxHeight={props.maxHeight}
                        maxWidth={props.maxWidth} padding={props.padding} renderObject={state.stage2} />
                </PivotItem>


            </Pivot> : undefined}
        </>
    );

}




export const BusinessesGeneralInfoForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();

    const dispatch = useAppDispatch()


    const state: { isLoading: boolean, stage1: any } = useAppSelector((state) => {

        return {
            isLoading: state.businessesForm.isLoading,
            stage1: state.businessesForm.stage1,
        }
    })


    const [img, setImg] = useState( props?.renderObject?.logoSource && props?.renderObject?.logoPath  ? props?.renderObject?.logoSource + "" + props?.renderObject?.logoPath  : "");
    const inputFile = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (state.stage1) {
            if (props?.renderObject?.logoSource && props?.renderObject?.logoPath)
                setImg(props?.renderObject?.logoSource + "" + props?.renderObject?.logoPath)
        }
    }, [state.stage1])
    return (
        <>
            <Formik

                enableReinitialize
                initialValues={{
                    name: (props.renderObject?.name) ? props.renderObject.name : '',
                    description: (props.renderObject?.description) ? props.renderObject.description : '',
                    isAvailable: (props.renderObject) ? props.renderObject.isAvailable : true,
                    isActive: (props.renderObject) ? props.renderObject.isActive : true,
                    logoId: props?.renderObject?.logoId ?? '',
                    logoPath: props?.renderObject?.logoPath ?? '',
                    logoSource: props?.renderObject?.logoSource ?? '',


                }}




                validationSchema={Yup.object({

                    name: inputs.businessName,
                    description: inputs.businessDescription,



                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {
                        if (props.onSuccess) {
                            props.onSuccess({
                                name: values.name.trim(),
                                description: values.description.trim(),
                                isAvailable: values.isAvailable,
                                isActive: values.isActive,
                                logoId: values.logoId,
                                logoPath: values.logoPath,
                                logoSource: values.logoSource


                            });
                        }
                        actions.setSubmitting(false)
                    } else {
                        if (props.onSuccess) {
                            props.onSuccess({
                                name: values.name.trim(),
                                description: values.description.trim(),
                                isAvailable: values.isAvailable,
                                isActive: values.isActive,
                                logoId: values.logoId,
                                logoPath: values.logoPath,
                                logoSource: values.logoSource


                            });
                        }
                        actions.setSubmitting(false)
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            <Stack horizontalAlign="center">
                                <Persona
                                    size={PersonaSize.size100}
                                    onClick={(e) => {
                                        if (!state.isLoading)
                                            inputFile?.current?.click();
                                    }}
                                    hidePersonaDetails={true}
                                    imageUrl={img}
                                    styles={{ root: { cursor: "pointer" } }}
                                />
                                <Link disabled={state.isLoading} onClick={(e) => {
                                    inputFile?.current?.click();
                                }}>{t("pickLogo")}</Link>
                                <input
                                    ref={inputFile}
                                    type="file"
                                    onChange={(e: any) => {
                                        const [file] = e.target.files;
                                        const reader = new FileReader();
                                        reader.readAsArrayBuffer(file);
                                        let r = new UploadLogoRequest();
                                        reader.onloadend = (evt) => {
                                            if (evt?.target?.readyState === FileReader.DONE) {
                                                const arrayBuffer = evt.target.result;
                                                let array = new Uint8Array(arrayBuffer as ArrayBuffer);
                                                r.setOrganization(req.getOrganization())
                                                r.setImage(array)


                                                promise = dispatch(uploadLogo({ body: r, headers: getHeaders() }))
                                                promise.unwrap().then((e: UploadLogoResponse.AsObject) => {
                                                    formkikProps.setFieldValue('logoId', e.success?.id);
                                                    formkikProps.setFieldValue('logoSource', e.success?.source?.value);
                                                    formkikProps.setFieldValue('logoPath', e.success?.path?.value);

                                                    setImg(e.success?.source?.value + "" + e.success?.path?.value   ); //file.name.split('.').pop().toLowerCase()
                                                })
                                                promise.unwrap().catch((e: any) => {
                                                    formkikProps.setFieldValue('logoId', '');
                                                    setImg("");
                                                })

                                            }
                                        }


                                    }}
                                    style={{ display: "none" }}
                                    accept="image/*"
                                />
                            </Stack>


                            <Field name="name" label={t("businessName")} placeholder={t("businessName")} component={InputField} disabled={state.isLoading} autoFocus maxLength={50} required />

                            <Field name="description" label={t("description")} placeholder={t("description")} component={InputField} rows={3} multiline resizable={false} disabled={state.isLoading} maxLength={350} />

                            {props.type == FormType.ADD ? <Toggle
                                checked={formkikProps.values.isActive}
                                onText={t("active")} offText={t("inactive")}
                                disabled={state.isLoading}
                                label={t("status")}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isActive', checked);
                                }
                                }
                            /> : null}



                            <Toggle

                                onText={t("available")} offText={t("unavailable")}
                                label={t("availability")}
                                disabled={state.isLoading}

                                checked={formkikProps.values.isAvailable}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isAvailable', checked);
                                }
                                }
                            />
                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : t("next")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>




                    </Form>
                )
                }
            </Formik >
        </>
    );

}


export const BusinessesDetailsForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const [currentAction, setCurrenctAction] = useState(0)
    const state: { isLoading: boolean, stage1: any } = useAppSelector((state) => {

        return {
            isLoading: state.businessesForm.isLoading,
            stage1: state.businessesForm.stage1,
        }
    })
    const [countries, setCountries] = useState([] as IComboBoxOption[])
    useEffect(() => {
        if (localStorage.getItem("lang") == "en") {
            setCountries(countriesEn)
        } else {
            setCountries(countriesAR)
        }

    }, [])


    return (
        <>

            <Formik

                initialValues={{

                    phoneNumbers: (props.renderObject?.phoneNumbers) ? props.renderObject.phoneNumbers as DynamicField[] : [] as DynamicField[],
                    urls: (props.renderObject?.urls) ? props.renderObject.urls as DynamicField[] : [] as DynamicField[],
                    extraFields: (props.renderObject?.extraFields) ? props.renderObject.extraFields as DynamicField[] : [] as DynamicField[],
                    addressCountry: (props.renderObject?.addressCountry) ? props.renderObject.addressCountry : { key: 'LY', text: 'Libya' },
                    addressCity: (props.renderObject?.addressCity) ? props.renderObject.addressCity : '',
                    addressFirstLine: (props.renderObject?.addressFirstLine) ? props.renderObject.addressFirstLine : '',
                    addressSecondLine: (props.renderObject?.addressSecondLine) ? props.renderObject.addressSecondLine : '',
                    addressPostalCode: (props.renderObject?.addressPostalCode) ? props.renderObject.addressPostalCode : '',
                    long: (props.renderObject?.long) ? props.renderObject.long : '',
                    lat: (props.renderObject?.lat) ? props.renderObject.lat : '',


                }}

                validationSchema={Yup.object({

                    extraFields: inputs.extraFields,
                    phoneNumbers: inputs.phoneNumbers,
                    urls: inputs.urls,
                    long: inputs.long,
                    lat: inputs.lat,

                })}

                onSubmit={(values, actions) => {


                    if (props.type == FormType.ADD) {


                        req.setName(state.stage1.name.trim());
                        req.setDescription(state.stage1.description.trim());
                        const phoneNumbers: string[] = [];
                        values.phoneNumbers.forEach((e: DynamicField) => {
                            phoneNumbers.push(e.value.trim())
                        })
                        req.setPhonenumbersList(phoneNumbers);

                        const urls: URL[] = [];
                        values.urls.forEach((e: DynamicField) => {
                            const r = new URL();
                            r.setId(Number(e.label));
                            r.setUrl(e.value.trim());
                            urls.push(r)
                        })
                        req.setUrlsList(urls);

                        req.setIsactive(state.stage1.isActive)
                        req.setIsavailable(state.stage1.isAvailable)
                        req.setLogo(state.stage1.logoId ?? '')

                        let a = new Address();
                        let city = new StringValue();
                        city.setValue(values.addressCity?.trim())
                        a.setCity(city);
                        let country = new StringValue();
                        country.setValue(values.addressCountry?.key?.trim())
                        a.setCountryiso(country);
                        let firstLine = new StringValue();
                        firstLine.setValue(values.addressFirstLine?.trim())
                        a.setFirstline(firstLine);
                        let addressSecondLine = new StringValue();
                        addressSecondLine.setValue(values.addressSecondLine?.trim())
                        a.setSecondline(addressSecondLine);
                        let addressPostalCode = new StringValue();
                        addressPostalCode.setValue(values.addressPostalCode?.trim())
                        a.setPostalcode(addressPostalCode);
                        req.setAddress(a)

                        if (values.long && values.lat )
                            req.setLocationList([Number(values.long), Number(values.lat)])

                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        req.setExtrafieldsList(extraField)

                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddBusinessResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res.success?.id,
                                            phoneNumbers: values.phoneNumbers,
                                            addressCountry: values.addressCountry,
                                            addressCity: values.addressCity?.trim(),
                                            addressFirstLine: values.addressFirstLine?.trim(),
                                            addressSecondLine: values.addressSecondLine?.trim(),
                                            addressPostalCode: values.addressPostalCode?.trim(),
                                            long: values.long,
                                            lat: values.lat,
                                            location: [values.long, values.lat],
                                            urls: values.urls,
                                            extraFields: values.extraFields,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            isActive: state.stage1.isActive,
                                            isAvailable: state.stage1.isAvailable,
                    

                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })


                    } else {
                        updateReq.setId(state.stage1.id)
                        updateReq.setName(state.stage1.name.trim());
                        updateReq.setDescription(state.stage1.description.trim());
                        const phoneNumbers: string[] = [];
                        values.phoneNumbers.forEach((e: DynamicField) => {
                            phoneNumbers.push(e.value.trim())
                        })
                        updateReq.setPhonenumbersList(phoneNumbers);

                        const urls: URL[] = [];
                        values.urls.forEach((e: DynamicField) => {
                            const r = new URL();
                            r.setId(Number(e.label));
                            r.setUrl(e.value.trim());
                            urls.push(r)
                        })
                        updateReq.setUrlsList(urls);
                        updateReq.setLogo(state.stage1.logoId ?? '')
                        updateReq.setIsavailable(state.stage1.isAvailable)

                        let a = new Address();
                        let city = new StringValue();
                        city.setValue(values.addressCity?.trim())
                        a.setCity(city);
                        let country = new StringValue();
                        country.setValue(values.addressCountry?.key?.trim())
                        a.setCountryiso(country);
                        let firstLine = new StringValue();
                        firstLine.setValue(values.addressFirstLine?.trim())
                        a.setFirstline(firstLine);
                        let addressSecondLine = new StringValue();
                        addressSecondLine.setValue(values.addressSecondLine?.trim())
                        a.setSecondline(addressSecondLine);
                        let addressPostalCode = new StringValue();
                        addressPostalCode.setValue(values.addressPostalCode?.trim())
                        a.setPostalcode(addressPostalCode);

                        updateReq.setAddress(a)

                        if (values.long && values.lat )
                            updateReq.setLocationList([Number(values.long), Number(values.lat)])

                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        updateReq.setExtrafieldsList(extraField)



                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateBusinessResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            phoneNumbers: values.phoneNumbers,
                                            urls: values.urls,
                                            extraFields: values.extraFields,
                                            addressCountry: values.addressCountry,
                                            addressCity: values.addressCity?.trim(),
                                            addressFirstLine: values.addressFirstLine?.trim(),
                                            addressSecondLine: values.addressSecondLine?.trim(),
                                            addressPostalCode: values.addressPostalCode?.trim(),
                                            long: values.long,
                                            lat: values.lat,
                                            location: [values.long, values.lat],
                                            isAvailable: state.stage1.isAvailable,
                                            connectionId: state.stage1?.connectionId ?? "",
                                            connectionMetaData: state.stage1?.connectionMetaData ?? "",
                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })


                    }
                }}

            >
                {formkikProps => (
                    <Form autoComplete="off">
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >



                            <Stack>
                                <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }}>
                                    <Stack.Item grow>
                                        <ComboBox
                                            label={t("addressCountry")}
                                            allowFreeform={true}
                                            autoComplete={'on'}
                                            disabled={state.isLoading}
                                            options={countries}
                                            selectedKey={formkikProps.values.addressCountry.key}
                                            onChange={(e, o) => {
                                                formkikProps.setFieldValue("addressCountry", { key: o?.key, text: o?.text })
                                            }}
                                        // Force re-creating the component when the toggles change (for demo purposes)
                                        />
                                    </Stack.Item>
                                    <Stack.Item grow>
                                        <Field name="addressCity" label={t("addressCity")} placeholder={t("addressCity")} component={InputField} disabled={state.isLoading} maxLength={50} />
                                    </Stack.Item>
                                </Stack>
                                <Field name="addressFirstLine" label={t("addressFirstLine")} placeholder={t("addressFirstLine")} component={InputField} disabled={state.isLoading}
                                    maxLength={150} />

                                <Field name="addressSecondLine" label={t("addressSecondLine")} placeholder={t("addressSecondLine")} component={InputField} disabled={state.isLoading} maxLength={150} />

                                <Field name="addressPostalCode" label={t("addressPostalCode")} placeholder={t("addressPostalCode")} component={InputField} disabled={state.isLoading} maxLength={50} />
                                <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }}>

                                    <Stack.Item grow>
                                        <Field name="long" label={t("long")} placeholder={t("long")} component={InputField} disabled={state.isLoading} maxLength={25} />
                                    </Stack.Item>
                                    <Stack.Item grow>
                                        <Field name="lat" label={t("lat")} placeholder={t("lat")} component={InputField} disabled={state.isLoading} maxLength={25} />
                                    </Stack.Item>
                                </Stack>

                            </Stack>

                            <Stack.Item grow>
                                <Label disabled={state.isLoading}>{t("contacts")}</Label>
                                <FieldArray
                                    name="urls"
                                >
                                    {(arrayHelpers) => {
                                        let r = [] as React.ReactNode[];
                                        if (formkikProps.values.urls && formkikProps.values.urls.length > 0) {
                                            r = formkikProps.values.urls.map((o, index) => {
                                                if (o.type == 2) {
                                                    return (<Stack horizontalAlign="space-between" horizontal key={index}>
                                                        {<ComboBox
                                                            allowFreeform={true}
                                                            autoComplete={'on'}
                                                            disabled={state.isLoading}
                                                            options={[{ key: "1", text: t("fb") }, { key: "2", text: t("whatsapp") }, { key: "3", text: t("viber") },
                                                            { key: "4", text: t("telegram") }, { key: "5", text: t("web") }, { key: "6", text: t("email") }]}
                                                            selectedKey={o.label}
                                                            onChange={(e, o) => {
                                                                formkikProps.setFieldValue(`urls[${index}].label`, o?.key)
                                                            }}
                                                        // Force re-creating the component when the toggles change (for demo purposes)
                                                        />}
                                                        <DynamicInputField name={`urls[${index}].value`} disabled={state.isLoading}
                                                            maxLength={1024} dir="ltr" autoFocus={(o as any)?.autoFocus}
                                                        />

                                                        <IconButton disabled={state.isLoading}
                                                            iconProps={{ iconName: "remove" }} onClick={() => {
                                                                arrayHelpers.remove(index)
                                                            }} />
                                                    </Stack>)
                                                }
                                            }
                                            )
                                        }
                                        if (formkikProps.values.urls.length <= 10) {
                                            r.push(< IconButton disabled={state.isLoading}
                                                key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                    arrayHelpers.push({ key: (formkikProps.values.urls.length + 1) + "", label: "1", type: 2, value: "", autoFocus: true })
                                                }} />)
                                        }
                                        return r;
                                    }

                                    }

                                </FieldArray>
                            </Stack.Item>
                            <Stack.Item grow>
                                <Label disabled={state.isLoading}>{t("phonenumbers")}</Label>
                                <FieldArray
                                    name="phoneNumbers"
                                >
                                    {arrayHelpers => {
                                        let r = [] as React.ReactNode[];
                                        if (formkikProps.values.phoneNumbers && formkikProps.values.phoneNumbers.length > 0) {
                                            r = formkikProps.values.phoneNumbers.map((o, index) => {
                                                if (o.type == 1) {
                                                    return (<Stack horizontal key={index}>
                                                        {o.label != undefined ? <DynamicInputField name={`phoneNumbers[${index}].label`} placeholder={t("label")} disabled={state.isLoading}
                                                            maxLength={50} /> : null}
                                                        <DynamicInputField name={`phoneNumbers[${index}].value`} placeholder={"+218911111111"} disabled={state.isLoading}
                                                            maxLength={50} autoFocus={(o as any)?.autoFocus} dir="ltr"
                                                        />

                                                        <IconButton disabled={state.isLoading}
                                                            iconProps={{ iconName: "remove" }} onClick={() => {
                                                                arrayHelpers.remove(index)
                                                            }} />
                                                    </Stack>)
                                                }
                                            }
                                            )
                                        }
                                        if (formkikProps.values.phoneNumbers.length <= 4) {
                                            r.push(< IconButton disabled={state.isLoading}
                                                key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                    arrayHelpers.push({ key: (formkikProps.values.phoneNumbers.length + 1) + "", label: undefined, type: 1, value: "", autoFocus: true })
                                                }} />)
                                        }
                                        return r;
                                    }
                                    }

                                </FieldArray>

                            </Stack.Item>




                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("extraFields") + ' '}
                                    <TooltipHost content={t("extraInformationDescription")}>
                                        <Icon iconName="Info" aria-label={t("extraFields")} />
                                    </TooltipHost>
                                </div>
                            }</Label>
                            <FieldArray
                                name="extraFields"
                                validateOnChange={false}

                            >
                                {arrayHelpers => {
                                    let r = [] as React.ReactNode[];
                                    if (formkikProps.values.extraFields && formkikProps.values.extraFields.length > 0) {
                                        r = formkikProps.values.extraFields.map((o, index) => {
                                            if (o.type == 1) {
                                                return (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} key={index}>
                                                    {o.label != undefined ? <Stack.Item grow> <DynamicInputField name={`extraFields[${index}].label`} placeholder={t("extraFieldLabel")} disabled={state.isLoading}
                                                        maxLength={50} autoFocus={(o as any)?.autoFocus} /> </Stack.Item> : null}
                                                    <Stack.Item grow>  <DynamicInputField name={`extraFields[${index}].value`} placeholder={t("extraFieldValue")} disabled={state.isLoading}
                                                        maxLength={50}
                                                    /></Stack.Item>

                                                    <IconButton disabled={state.isLoading} iconProps={{ iconName: "remove" }} onClick={() => {
                                                        arrayHelpers.remove(index)
                                                    }} />
                                                </Stack>)
                                            }
                                        }
                                        )
                                    }
                                    if (formkikProps.values.extraFields.length <= 4) {
                                        r.push(< IconButton disabled={state.isLoading} key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                            arrayHelpers.push({ key: (formkikProps.values.extraFields.length + 1) + "", label: "", type: 1, value: "" })
                                        }} />)
                                    }
                                    return r;
                                }
                                }

                            </FieldArray>


                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={t("back")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    if (props.type == FormType.ADD) {
                                        props.onCancel({
                                            extraFields: formkikProps.values.extraFields,
                                            phoneNumbers: formkikProps.values.phoneNumbers,
                                            urls: formkikProps.values.urls,
                                            addressCountry: formkikProps.values.addressCountry,
                                            addressCity: formkikProps.values.addressCity?.trim(),
                                            addressFirstLine: formkikProps.values.addressFirstLine?.trim(),
                                            addressSecondLine: formkikProps.values.addressSecondLine?.trim(),
                                            addressPostalCode: formkikProps.values.addressPostalCode?.trim(),
                                            long: formkikProps.values.long,
                                            lat: formkikProps.values.lat,

                                        })
                                    } else {
                                        props.onCancel({
                                            extraFields: formkikProps.values.extraFields,
                                            phoneNumbers: formkikProps.values.phoneNumbers,
                                            urls: formkikProps.values.urls,
                                            addressCountry: formkikProps.values.addressCountry,
                                            addressCity: formkikProps.values.addressCity?.trim(),
                                            addressFirstLine: formkikProps.values.addressFirstLine?.trim(),
                                            addressSecondLine: formkikProps.values.addressSecondLine?.trim(),
                                            addressPostalCode: formkikProps.values.addressPostalCode?.trim(),
                                            long: formkikProps.values.long,
                                            lat: formkikProps.values.lat,

                                        })
                                    }
                                }
                            }} />
                        </DialogFooter>


                    </Form>
                )
                }
            </Formik >
        </>
    );

}


