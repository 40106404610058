import { ComboBox, DefaultButton, DialogFooter, IComboBoxOption, Icon, IconButton, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, Pivot, PivotItem, PrimaryButton, Separator, Spinner, SpinnerSize, Stack, StackItem, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, values } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik, getIn } from "formik";
import { FormEventHandler, InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/Hooks";
import { dismissMessage, reset, setStage1, setStage2, update } from "./ProfileFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../controls/Controls";
import { inputs } from "../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../app/Api";
import { Message } from "../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../FormProps";
import { Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, phoneNumberFormatter, timestampToDate } from "../../../app/Helpers";
import { AuthenticateReply } from "../../../repository/UserManagement/authentication_pb";

import { Address, ExtraField } from "../../../repository/UserManagement/common_pb";
import { TableState } from "../../common/Table/TableSate";
import { GetUserTypesRequest } from "../../../repository/UserManagement/Managers/usertype_pb";
import { GetRolesRequest } from "../../../repository/UserManagement/Managers/role_pb";
import { setIsFilteredUserTypesSet } from "../../pages/Management/Users/UsersPageSlice";
import { DynamicField } from "../../common/DynamicFields/DynamicFields";
import { GetOrganizationRequest } from "../../../repository/UserManagement/organization_pb";
import { countriesAR, countriesEn } from "../../../app/Content";
import { UpdateProfileRequest, UpdateProfileResponse } from "../../../repository/UserManagement/Managers/profile_pb";


let updateReq: UpdateProfileRequest;



let promise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;

export const ProfileForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();

    const [selectedKey, setSelectedKey] = useState(0);
    const onNextClick = () => {
        setSelectedKey((selectedKey + 1) % 3);
    };
    const onBackClick = () => {
        setSelectedKey((selectedKey - 1) % 3);
    };

    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any, stage2: any } = useAppSelector((state) => {

        return {
            isLoading: state.profileForm.isLoading, message: state.profileForm.message
            , stage1: state.profileForm.stage1
            , stage2: state.profileForm.stage2

        }
    })


    useEffect(() => {

        updateReq = new UpdateProfileRequest();

        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        } else {


            if (props.type == FormType.EDIT) {
                if (props.fetchData) {

                } else {
                    dispatch(setStage1(props?.renderObject))
                    dispatch(setStage2(props?.renderObject))
                }
            }
        }


        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])



    return (
        <>
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()); if (selectedKey == 3) props.onCancel() }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            {selectedKey <= 2 ? <Pivot aria-label={props.type == FormType.ADD ? t("addManager") : t("editManager")} selectedKey={String(selectedKey)} >
                <PivotItem headerText={t("managerInfo")} itemKey="0" >
                    <UsersGeneralInfoForm onSuccess={(e) => {
                        dispatch(setStage1({ ...state.stage1, ...e }))
                        onNextClick();
                    }} onCancel={props.onCancel} type={props.type} childrenGap={props.childrenGap} maxHeight={props.maxHeight}
                        maxWidth={props.maxWidth} padding={props.padding} renderObject={state.stage1} />
                </PivotItem>
                <PivotItem headerText={t("accountInfo")} itemKey="1">
                    <UsersAccountInfoForm onSuccess={(e) => {
                        if (props.onSuccess) {
                            props.onSuccess({ ...state.stage1, ...state.stage2, ...e })
                        }
                    }} onCancel={(e) => {
                        dispatch(setStage2({ ...state.stage2, ...e }))
                        onBackClick()
                    }} type={props.type} childrenGap={props.childrenGap} maxHeight={props.maxHeight}
                        maxWidth={props.maxWidth} padding={props.padding} renderObject={state.stage2} />
                </PivotItem>

            </Pivot> : undefined}
        </>
    );

}




export const UsersGeneralInfoForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const [countries, setCountries] = useState([] as IComboBoxOption[])
    const state: { isLoading: boolean } = useAppSelector((state) => {

        return {
            isLoading: state.managersUsersForm.isLoading,
        }
    })

    useEffect(() => {
        if (localStorage.getItem("lang") == "en") {
            setCountries(countriesEn)
        } else {
            setCountries(countriesAR)
        }

    }, [])

    return (
        <>
            <Formik

                enableReinitialize
                initialValues={{

                    firstname: (props.renderObject?.firstname) ? props.renderObject.firstname : '',
                    lastname: (props.renderObject?.lastname) ? props.renderObject.lastname : '',
                    phoneNumbers: (props.renderObject?.phoneNumbers) ? props.renderObject.phoneNumbers as DynamicField[] : [] as DynamicField[],
                    emailAddresses: (props.renderObject?.emailAddresses) ? props.renderObject.emailAddresses as DynamicField[] : [] as DynamicField[],

                }}

                validationSchema={Yup.object({

                    firstname: inputs.firstname,
                    lastname: inputs.lastname,
                    phoneNumbers: inputs.phoneNumbers,
                    emailAddresses: inputs.emailAddresses,


                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {
                        if (props.onSuccess) {
                            props.onSuccess({
                                firstname: values.firstname.trim(),
                                lastname: values.lastname.trim(),
                                phoneNumbers: values.phoneNumbers,
                            });
                        }
                        actions.setSubmitting(false)
                    } else {
                        if (props.onSuccess) {
                            props.onSuccess({
                                firstname: values.firstname.trim(),
                                lastname: values.lastname.trim(),
                                phoneNumbers: values.phoneNumbers,
                                emailAddresses: values.emailAddresses,

                            });
                        }
                        actions.setSubmitting(false)
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >


                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }}>
                                <Stack.Item grow>
                                    <Field name="firstname" label={t("firstname")} placeholder={t("firstname")} component={InputField} disabled={state.isLoading} autoFocus maxLength={50} required />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <Field name="lastname" label={t("lastname")} placeholder={t("lastname")} component={InputField} disabled={state.isLoading} maxLength={50} required />
                                </Stack.Item>
                            </Stack>



                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }}>
                                <Stack.Item grow>
                                    <Label disabled={state.isLoading}>{t("emailAddresses")}</Label>
                                    <FieldArray
                                        name="emailAddresses"
                                    >
                                        {(arrayHelpers) => {
                                            let r = [] as React.ReactNode[];
                                            if (formkikProps.values.emailAddresses && formkikProps.values.emailAddresses.length > 0) {
                                                r = formkikProps.values.emailAddresses.map((o, index) => {
                                                    if (o.type == 1) {
                                                        return (<Stack horizontal key={index}>
                                                            {o.label != undefined ? <DynamicInputField name={`emailAddresses[${index}].label`} placeholder={t("label")} disabled={state.isLoading}
                                                                maxLength={50} /> : null}
                                                            <DynamicInputField name={`emailAddresses[${index}].value`} placeholder={"abc@gmail.com"} disabled={state.isLoading}
                                                                maxLength={50} dir="ltr" autoFocus={(o as any)?.autoFoucse} type="email"
                                                            />

                                                            <IconButton disabled={state.isLoading} iconProps={{ iconName: "remove" }} onClick={() => {
                                                                arrayHelpers.remove(index)
                                                            }} />
                                                        </Stack>)
                                                    }
                                                }
                                                )
                                            }
                                            if (formkikProps.values.emailAddresses.length <= 3) {
                                                r.push(< IconButton disabled={state.isLoading} key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                    arrayHelpers.push({ key: (formkikProps.values.emailAddresses.length + 1) + "", label: undefined, type: 1, value: "", autoFoucse: true })
                                                }} />)
                                            }
                                            return r;
                                        }

                                        }

                                    </FieldArray>
                                </Stack.Item>
                                <Stack.Item grow>
                                    <Label disabled={state.isLoading} >{t("phonenumbers")}</Label>
                                    <FieldArray
                                        name="phoneNumbers"
                                    >
                                        {arrayHelpers => {
                                            let r = [] as React.ReactNode[];
                                            if (formkikProps.values.phoneNumbers && formkikProps.values.phoneNumbers.length > 0) {
                                                r = formkikProps.values.phoneNumbers.map((o, index) => {
                                                    if (o.type == 1) {
                                                        return (<Stack horizontal key={index}>
                                                            {o.label != undefined ? <DynamicInputField name={`phoneNumbers[${index}].label`} placeholder={t("label")} disabled={state.isLoading}
                                                                maxLength={50} /> : null}
                                                            <DynamicInputField name={`phoneNumbers[${index}].value`} placeholder={"+218911111111"} disabled={state.isLoading}
                                                                maxLength={50} autoFocus={(o as any)?.autoFoucse} dir="ltr"
                                                            />

                                                            <IconButton disabled={state.isLoading} iconProps={{ iconName: "remove" }} onClick={() => {
                                                                arrayHelpers.remove(index)
                                                            }} />
                                                        </Stack>)
                                                    }
                                                }
                                                )
                                            }
                                            if (formkikProps.values.phoneNumbers.length <= 3) {
                                                r.push(< IconButton disabled={state.isLoading} key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                    arrayHelpers.push({ key: (formkikProps.values.phoneNumbers.length + 1) + "", label: undefined, type: 1, value: "", autoFoucse: true })
                                                }} />)
                                            }
                                            return r;
                                        }
                                        }

                                    </FieldArray>

                                </Stack.Item>
                            </Stack>

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : t("next")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )
                }
            </Formik >
        </>
    );

}


export const UsersAccountInfoForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()

    const state: { isLoading: boolean, stage1: any, stage2: any } = useAppSelector((state) => {

        return {
            isLoading: state.profileForm.isLoading,
            stage1: state.profileForm.stage1,
            stage2: state.profileForm.stage2,

        }
    })


    return (
        <>

            <Formik

                initialValues={{

                    primaryEmail: (props.renderObject?.primaryEmail) ? props.renderObject.primaryEmail : '',
                    primaryPhoneNumber: (props.renderObject?.primaryPhoneNumber) ? "+"+phoneNumberFormatter(props.renderObject.primaryPhoneNumber) : '',
                }}

                validationSchema={props.type == FormType.ADD ? Yup.object({

                    primaryEmail: inputs.primaryEmail,
                    primaryPhoneNumber: inputs.primaryPhoneNumber,

                }) : Yup.object({

                    primaryEmail: inputs.primaryEmail,
                    primaryPhoneNumber: inputs.primaryPhoneNumber,

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {

                    } else {

                        updateReq.setFirstname(state.stage1.firstname.trim());
                        updateReq.setLastname(state.stage1.lastname.trim());

                        const phoneNumbers: StringValue[] = [];
                        state.stage1.phoneNumbers.forEach((e: DynamicField) => {
                            const r = new StringValue();
                            r.setValue(e.value.trim());
                            phoneNumbers.push(r)
                        })
                        updateReq.setPhonenumbersList(phoneNumbers);

                        const emails: StringValue[] = [];
                        state.stage1.emailAddresses.forEach((e: DynamicField) => {
                            const r = new StringValue();
                            r.setValue(e.value.trim());
                            emails.push(r)
                        })
                        updateReq.setEmailaddressesList(emails);

                        if (values.primaryEmail.trim().length > 0) {
                            const r = new StringValue();
                            r.setValue(values.primaryEmail.trim());
                            updateReq.setPrimaryemail(r)
                        }

                        if (values.primaryPhoneNumber.trim().length > 0) {
                            const r = new StringValue();
                            r.setValue(values.primaryPhoneNumber.trim());
                            updateReq.setPrimaryphonenumber(r)
                        }

                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateProfileResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            primaryEmail: values.primaryEmail,
                                            primaryPhoneNumber: values.primaryPhoneNumber,

                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form autoComplete="off">
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >

                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} >
                                <Stack.Item grow>
                                    <Field name="primaryEmail" label={
                                        <div>
                                            {t("email") + ' '}
                                            <TooltipHost content={t("accountEmailDescription")}>
                                                <Icon iconName="Info" aria-label={t("email")} />
                                            </TooltipHost>
                                        </div>
                                    } placeholder={"abc@gmail.com"} dir="ltr" component={InputField} disabled={state.isLoading} type="email"
                                    />
                                </Stack.Item>

                                <Stack.Item grow>
                                    <Field name="primaryPhoneNumber" label={
                                        <div>
                                            {t("phonenumber") + ' '}
                                            <TooltipHost content={t("phoneNumberDescription")}>
                                                <Icon iconName="Info" aria-label={t("phonenumber")} />
                                            </TooltipHost>
                                        </div>
                                    } placeholder={"+218911111111"} dir="ltr" component={InputField} disabled={state.isLoading} />
                                </Stack.Item>
                            </Stack>

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={t("back")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    if (props.type == FormType.ADD) {
                                        props.onCancel({

                                            primaryEmail: formkikProps.values.primaryEmail,
                                            primaryPhoneNumber: formkikProps.values.primaryPhoneNumber,
                                        })
                                    } else {
                                        props.onCancel({
                                            //   username: props.renderObject.username,
                                            primaryEmail: formkikProps.values.primaryEmail,
                                            primaryPhoneNumber: formkikProps.values.primaryPhoneNumber,
                                            // isAccountActive: props.renderObject.isAccountActive,
                                        })
                                    }
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )
                }
            </Formik >
        </>
    );

}


