import { MessageBar, MessageBarType, Stack, Text } from "@fluentui/react";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/Hooks";
import { ThemeManager } from "../../../app/Themes";




export const NotFound: React.FunctionComponent= () => {
    const { t, i18n } = useTranslation();
    const stateTheme: { appTheme: string, isDarkModeEnabled: boolean } = useAppSelector((state) => {

        return { appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled }
    })
 
    return (
        <Stack verticalFill verticalAlign="center" horizontalAlign="center"> 
            <h1>{t("notFound")}</h1>

        </Stack>
       
  

    );

}