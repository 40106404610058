import { DefaultButton, DialogFooter, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/Hooks";
import { add, dismissMessage, getBusinessClient, getBusinesses, getClinetUsers, reset, resetClients, resetBusinesses, setIsFilteredClientsSet, setSelectedBusinessItems, setIsFilteredBusinessesSet, setSelectedClientItems, updateThirdParty, setStage1 } from "./BusinessClientsFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, DynamicNumberInputField, InputField } from "../controls/Controls";
import { inputs } from "../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../app/Api";
import { Message } from "../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../app/Helpers";
import { AuthenticateReply } from "../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../common/DynamicFields/DynamicFields";
import { TableState } from "../../common/Table/TableSate";
import { List } from "../../common/List/List";
import { Popup } from "../../common/Popup/Popup";
import { AddBusinessClientResponse, AddBusinessClientRequest, UpdateThirdPartyConnectionRequest, UpdateThirdPartyConnectionResponse } from "../../../repository/UserManagement/Dimensions/businessclient_pb";
import { GetBusinessesRequest } from "../../../repository/UserManagement/Dimensions/business_pb";
import { GetUsersRequest } from "../../../repository/UserManagement/Customers/user_pb";
import { UsersPage } from "../../pages/Customers/Users/UsersPage";
import { BusinessesPage } from "../../pages/Businesses/BusinessesPage";


let req: AddBusinessClientRequest;
let updateReq: UpdateThirdPartyConnectionRequest;

let businessesReq: GetBusinessesRequest;
let clintsReq: GetUsersRequest;
let promise: any;
let getFilterPromise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;

export const BusinessClientsForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)

    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any, businesses: TableState, clients: TableState } = useAppSelector((state) => {

        return {
            isLoading: state.businessClientsForm.isLoading, message: state.businessClientsForm.message, stage1: state.businessClientsForm.stage1
            , businesses: state.businessClientsForm.businesses,
            clients: state.businessClientsForm.clients,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new AddBusinessClientRequest();
        businessesReq = new GetBusinessesRequest();
        clintsReq = new GetUsersRequest();
        updateReq = new UpdateThirdPartyConnectionRequest();
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                businessesReq.setOrganization(wrapper)
                clintsReq.setOrganization(wrapper)
            }
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        businessesReq.setNumofresults(state.businesses.numberOfResults)
        businessesReq.setOrder(state.businesses.isDescending)
        //businessesReq.setIsavailable(boolv)
        clintsReq.setNumofresults(state.clients.numberOfResults)
        clintsReq.setOrder(state.clients.isDescending)
        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            getFilterPromise?.abort();
            dispatch(reset());
        }
    }, [])


    const onClientsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetClients())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        clintsReq.setSearch(wrapper)
        clintsReq.setNextto(undefined)
        dispatch(setIsFilteredClientsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
        return [];

    }
    const onBusinessessFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetBusinesses())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        businessesReq.setSearch(wrapper)
        businessesReq.setNextto(undefined)
        dispatch(setIsFilteredBusinessesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getBusinesses({ body: businessesReq, headers: getHeaders() }))
        return [];

    }



    return (
        <>
            <Popup isOpen={(currentAction == 1000)} title={t("clients")} onDismiss={() => { setCurrenctAction(0) }} >

                <UsersPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedClientItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 1001)} title={t("businesses")} onDismiss={() => { setCurrenctAction(0) }} >

                <BusinessesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedBusinessItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    business: undefined,
                    client: undefined,
                    thirdPartyUsername: (props.renderObject) ? props.renderObject.connectionMetaData : "",
                    isActive: (props.renderObject) ? props.renderObject.isActive : false,

                }}

                validationSchema={Yup.object({
                    thirdPartyUsername: inputs.thirdPartyUsername

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {
                        let b = state.businesses.selected.at(0);
                        let client = state.clients.selected.at(0);

                        req.setBusiness(b.id);
                        req.setClient(client.internalId);
                        req.setIsactive(values.isActive)
                        if (values.thirdPartyUsername) {
                            req.setThirdpartyclientusername(values.thirdPartyUsername)
                        }

                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddBusinessClientResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res.success?.id,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                            isActive: values.isActive,
                                            businessId: b.id,
                                            businessName: b.name,
                                            connectionId: res.success?.thirdpartyid?.value,
                                            connectionMetaData: values.thirdPartyUsername,
                                            clientId: client.internalId,
                                            clientFirstName: client.firstName,
                                            clientLastName: client.lastName,
                                            clientMiddleName: client.middleName,
                                            clientName: (client.name),
                                            connectionOrganization: res.success?.thirdpartyorganization?.value

                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {
                        updateReq.setId(props.renderObject?.id);
                        updateReq.setThirdpartyclientusername(values.thirdPartyUsername);
                        promise = dispatch(updateThirdParty({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateThirdPartyConnectionResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: props.renderObject?.id,
                                            connectionId: res.success?.thirdpartyid?.value,
                                            connectionMetaData: values.thirdPartyUsername,
                                            connectionOrganization: res.success?.thirdpartyorganization?.value


                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }

                            {props.type == FormType.ADD ? <Stack>

                                <Label required
                                    disabled={state.isLoading}>{t("business")}</Label>
                                <List
                                    isPeoplePicker={true}
                                    suggestionsHeaderText={t('businesses')}
                                    isLoading={state.businesses.items.length == 0 ? state.businesses.isFetching : false}
                                    isSearching={state.businesses.items.length > 0 ? state.businesses.isFetching : false}
                                    moreSuggestionsAvailable={state.businesses.hasMore && !state.businesses.isFetching}
                                    suggestions={state.businesses.items.length > 0 ? state.businesses.items.map(e => { return { item: { key: e.id, text: e.name, imageUrl: e?.logoSource && e?.logoPath ? e?.logoSource + "" + e?.logoPath : undefined } } as ISuggestionModel<IPersonaProps> }) : []}

                                    onGetMoreResults={() => {
                                        if (state.businesses.items.length > 0 && !state.businesses.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.businesses.items.at(state.businesses.items.length - 1).id);
                                            businessesReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getBusinesses({ body: businessesReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.businesses.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedBusinessItems([state.businesses.items.at(u)]))
                                        }

                                    }}
                                    inputProps={{ placeholder: t("business"), required: true }}

                                    disabled={state.isLoading}
                                    selectedItems={state.businesses.selected.length > 0 ? state.businesses.selected.map(e => { return { key: e.id, text: e.name, imageUrl: e?.logoSource && e?.logoPath ? e?.logoSource + "" + e?.logoPath : undefined } as IPersonaProps }) : []}

                                    onChange={(e) => {
                                        dispatch(setSelectedBusinessItems([]))
                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetBusinesses())
                                        businessesReq.setSearch(undefined)
                                        businessesReq.setNextto(undefined)
                                        dispatch(setIsFilteredBusinessesSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getBusinesses({ body: businessesReq, headers: getHeaders() }))
                                        return []

                                    }}
                                    onFilterChanged={onBusinessessFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(1001)

                                            }
                                        }]}
                                />

                            </Stack> : undefined}
                            {props.type == FormType.ADD ? <Stack>

                                <Label required
                                    disabled={state.isLoading}>{t("client")}</Label>
                                <List
                                    isPeoplePicker={true}
                                    suggestionsHeaderText={t('clients')}
                                    isLoading={state.clients.items.length == 0 ? state.clients.isFetching : false}
                                    isSearching={state.clients.items.length > 0 ? state.clients.isFetching : false}
                                    moreSuggestionsAvailable={state.clients.hasMore && !state.clients.isFetching}
                                    suggestions={state.clients.items.length > 0 ? state.clients.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.clients.items.length > 0 && !state.clients.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.clients.items.at(state.clients.items.length - 1).internalId);
                                            clintsReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.clients.items.findIndex(e => e.internalId == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedClientItems([state.clients.items.at(u)]))
                                        }

                                    }}
                                    inputProps={{ placeholder: t("client"), required: true }}

                                    disabled={state.isLoading}
                                    selectedItems={state.clients.selected.length > 0 ? state.clients.selected.map(e => { return { key: e.internalId, text: e.name, secondaryText: e.username } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedClientItems([]))
                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetClients())
                                        clintsReq.setSearch(undefined)
                                        clintsReq.setNextto(undefined)
                                        dispatch(setIsFilteredClientsSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                        return []

                                    }}
                                    onFilterChanged={onClientsFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(1000)

                                            }
                                        }]}
                                />

                            </Stack> : undefined}
                            {props.type == FormType.ADD ? <Toggle

                                onText={t("active")} offText={t("inactive")}
                                label={t("status")}
                                disabled={state.isLoading}

                                checked={formkikProps.values.isActive}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isActive', checked);
                                }
                                }
                            /> : undefined}


                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("clientThirdPartyName") + ' '}
                                    <TooltipHost content={t("thirdPartyUsernameDesc")}>
                                        <Icon iconName="Info" aria-label={t("clientThirdPartyName")} />
                                    </TooltipHost>
                                </div>
                            }</Label>

                            <Field name="thirdPartyUsername" autocomplete="on" placeholder={t("clientThirdPartyName")} component={InputField} disabled={state.isLoading}
                            />

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )}
            </Formik>
        </>
    );

}

