import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession, } from '../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import {  BusinessClientEndpointClient} from '../../../../repository/UserManagement/Dimensions/BusinessclientServiceClientPb';
import { GetBusinessClientRequest, GetBusinessClientResponse, UpdateBusinessClientStateRequest, UpdateBusinessClientActivityResponse, DeleteBusinessClientRequest, DeleteBusinessClientResponse } from '../../../../repository/UserManagement/Dimensions/businessclient_pb';
import { DynamicField } from '../../../common/DynamicFields/DynamicFields';


export interface BusinessClientDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: BusinessClientDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const businessClient = new BusinessClientEndpointClient(API_URL, null, null);

export const get = createAsyncThunk<GetBusinessClientResponse.AsObject, APIRequest<GetBusinessClientRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/businessClients/businessClientDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await businessClient.getBusinessClient(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const updateActivity = createAsyncThunk<APIResponse<UpdateBusinessClientActivityResponse.AsObject>, APIRequest<UpdateBusinessClientStateRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/businessClients/businessClientDetails/updateActivity',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await businessClient.updateActivity(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), state: req.body.getIsactive() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const deleteItem = createAsyncThunk<APIResponse<DeleteBusinessClientResponse.AsObject>, APIRequest<DeleteBusinessClientRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/businessClients/businessClientDetails/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await businessClient.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)



export const businessClientDetailsPageSlice = createSlice({
    name: 'pages/businessClients/businessClientDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        },

        updateConnection: (state, action: PayloadAction<any>) => {
            state.item.connectionId = action?.payload?.connectionId
            state.item.connectionMetaData = action?.payload?.connectionMetaData

        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateActivity.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.isActive = payload.metadata?.state;
            }
        })
        builder.addCase(updateActivity.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateActivity.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(deleteItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
            }
        })
        builder.addCase(deleteItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                let val = payload.success;
                let r = {
                    id: val?.id,
                    businessId: (val?.business?.id?.value),
                    businessName: (val?.business?.name?.value ?? ""),
                    connectionId: val?.connection?.id?.value ?? "",
                    connectionMetaData: val?.connection?.metadata?.value ?? "",
                    connectionOrganization: val?.connection?.organization?.value ?? "",
                    isActive: val?.isactive?.value,
                    createdDate: formatDate(timestampToDate(val?.createddate?.seconds, val?.createddate?.nanos)),
                    createdBy: (val?.createdby?.firstname?.value ?? "") + " " + (val?.createdby?.lastname?.value ?? ""),
                    createdById: val?.createdby?.id?.value,
                    clientId: val?.client?.id?.value,
                    clientFirstName: val?.client?.firstname?.value,
                    clientLastName: val?.client?.lastname?.value,
                    clientMiddleName: val?.client?.middlename?.value,
                    clientName: (val?.client?.firstname?.value ?? "") + " " + (val?.client?.lastname?.value ?? ""),


                }

             

                state.item = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, resetItem, updateConnection, updateItem } = businessClientDetailsPageSlice.actions

export default businessClientDetailsPageSlice.reducer