/**
 * @fileoverview gRPC-Web generated client stub for customers_data_restriction_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as datarestriction_pb from './datarestriction_pb';


export class DataRestrictionEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/customers_data_restriction_endpoint.DataRestrictionEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    datarestriction_pb.AddDataRestrictionRequest,
    datarestriction_pb.AddDataRestrictionResponse,
    (request: datarestriction_pb.AddDataRestrictionRequest) => {
      return request.serializeBinary();
    },
    datarestriction_pb.AddDataRestrictionResponse.deserializeBinary
  );

  add(
    request: datarestriction_pb.AddDataRestrictionRequest,
    metadata: grpcWeb.Metadata | null): Promise<datarestriction_pb.AddDataRestrictionResponse>;

  add(
    request: datarestriction_pb.AddDataRestrictionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: datarestriction_pb.AddDataRestrictionResponse) => void): grpcWeb.ClientReadableStream<datarestriction_pb.AddDataRestrictionResponse>;

  add(
    request: datarestriction_pb.AddDataRestrictionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: datarestriction_pb.AddDataRestrictionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/customers_data_restriction_endpoint.DataRestrictionEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/customers_data_restriction_endpoint.DataRestrictionEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/customers_data_restriction_endpoint.DataRestrictionEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    datarestriction_pb.UpdateDataRestrictionRequest,
    datarestriction_pb.UpdateDataRestrictionResponse,
    (request: datarestriction_pb.UpdateDataRestrictionRequest) => {
      return request.serializeBinary();
    },
    datarestriction_pb.UpdateDataRestrictionResponse.deserializeBinary
  );

  update(
    request: datarestriction_pb.UpdateDataRestrictionRequest,
    metadata: grpcWeb.Metadata | null): Promise<datarestriction_pb.UpdateDataRestrictionResponse>;

  update(
    request: datarestriction_pb.UpdateDataRestrictionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: datarestriction_pb.UpdateDataRestrictionResponse) => void): grpcWeb.ClientReadableStream<datarestriction_pb.UpdateDataRestrictionResponse>;

  update(
    request: datarestriction_pb.UpdateDataRestrictionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: datarestriction_pb.UpdateDataRestrictionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/customers_data_restriction_endpoint.DataRestrictionEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/customers_data_restriction_endpoint.DataRestrictionEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/customers_data_restriction_endpoint.DataRestrictionEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    datarestriction_pb.DeleteDataRestrictionRequest,
    datarestriction_pb.DeleteDataRestrictionResponse,
    (request: datarestriction_pb.DeleteDataRestrictionRequest) => {
      return request.serializeBinary();
    },
    datarestriction_pb.DeleteDataRestrictionResponse.deserializeBinary
  );

  delete(
    request: datarestriction_pb.DeleteDataRestrictionRequest,
    metadata: grpcWeb.Metadata | null): Promise<datarestriction_pb.DeleteDataRestrictionResponse>;

  delete(
    request: datarestriction_pb.DeleteDataRestrictionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: datarestriction_pb.DeleteDataRestrictionResponse) => void): grpcWeb.ClientReadableStream<datarestriction_pb.DeleteDataRestrictionResponse>;

  delete(
    request: datarestriction_pb.DeleteDataRestrictionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: datarestriction_pb.DeleteDataRestrictionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/customers_data_restriction_endpoint.DataRestrictionEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/customers_data_restriction_endpoint.DataRestrictionEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetDataRestrictions = new grpcWeb.MethodDescriptor(
    '/customers_data_restriction_endpoint.DataRestrictionEndpoint/GetDataRestrictions',
    grpcWeb.MethodType.UNARY,
    datarestriction_pb.GetDataRestrictionsRequest,
    datarestriction_pb.GetDataRestrictionsResponse,
    (request: datarestriction_pb.GetDataRestrictionsRequest) => {
      return request.serializeBinary();
    },
    datarestriction_pb.GetDataRestrictionsResponse.deserializeBinary
  );

  getDataRestrictions(
    request: datarestriction_pb.GetDataRestrictionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<datarestriction_pb.GetDataRestrictionsResponse>;

  getDataRestrictions(
    request: datarestriction_pb.GetDataRestrictionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: datarestriction_pb.GetDataRestrictionsResponse) => void): grpcWeb.ClientReadableStream<datarestriction_pb.GetDataRestrictionsResponse>;

  getDataRestrictions(
    request: datarestriction_pb.GetDataRestrictionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: datarestriction_pb.GetDataRestrictionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/customers_data_restriction_endpoint.DataRestrictionEndpoint/GetDataRestrictions',
        request,
        metadata || {},
        this.methodDescriptorGetDataRestrictions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/customers_data_restriction_endpoint.DataRestrictionEndpoint/GetDataRestrictions',
    request,
    metadata || {},
    this.methodDescriptorGetDataRestrictions);
  }

  methodDescriptorGetDataRestriction = new grpcWeb.MethodDescriptor(
    '/customers_data_restriction_endpoint.DataRestrictionEndpoint/GetDataRestriction',
    grpcWeb.MethodType.UNARY,
    datarestriction_pb.GetDataRestrictionRequest,
    datarestriction_pb.GetDataRestrictionResponse,
    (request: datarestriction_pb.GetDataRestrictionRequest) => {
      return request.serializeBinary();
    },
    datarestriction_pb.GetDataRestrictionResponse.deserializeBinary
  );

  getDataRestriction(
    request: datarestriction_pb.GetDataRestrictionRequest,
    metadata: grpcWeb.Metadata | null): Promise<datarestriction_pb.GetDataRestrictionResponse>;

  getDataRestriction(
    request: datarestriction_pb.GetDataRestrictionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: datarestriction_pb.GetDataRestrictionResponse) => void): grpcWeb.ClientReadableStream<datarestriction_pb.GetDataRestrictionResponse>;

  getDataRestriction(
    request: datarestriction_pb.GetDataRestrictionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: datarestriction_pb.GetDataRestrictionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/customers_data_restriction_endpoint.DataRestrictionEndpoint/GetDataRestriction',
        request,
        metadata || {},
        this.methodDescriptorGetDataRestriction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/customers_data_restriction_endpoint.DataRestrictionEndpoint/GetDataRestriction',
    request,
    metadata || {},
    this.methodDescriptorGetDataRestriction);
  }

}

