import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface NavHeaderState {
  title: string
}

const initialState: NavHeaderState = {
  title: "",
}

export const navHeaderSlice = createSlice({
  name: 'navHeader',
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
      document.title = state.title;
    },
    
  }
})

export const { setTitle } = navHeaderSlice.actions

export default navHeaderSlice.reducer


