/**
 * @fileoverview gRPC-Web generated client stub for business_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as business_pb from './business_pb';


export class BusinessEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/business_endpoint.BusinessEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    business_pb.AddBusinessRequest,
    business_pb.AddBusinessResponse,
    (request: business_pb.AddBusinessRequest) => {
      return request.serializeBinary();
    },
    business_pb.AddBusinessResponse.deserializeBinary
  );

  add(
    request: business_pb.AddBusinessRequest,
    metadata: grpcWeb.Metadata | null): Promise<business_pb.AddBusinessResponse>;

  add(
    request: business_pb.AddBusinessRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: business_pb.AddBusinessResponse) => void): grpcWeb.ClientReadableStream<business_pb.AddBusinessResponse>;

  add(
    request: business_pb.AddBusinessRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: business_pb.AddBusinessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/business_endpoint.BusinessEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/business_endpoint.BusinessEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/business_endpoint.BusinessEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    business_pb.UpdateBusinessRequest,
    business_pb.UpdateBusinessResponse,
    (request: business_pb.UpdateBusinessRequest) => {
      return request.serializeBinary();
    },
    business_pb.UpdateBusinessResponse.deserializeBinary
  );

  update(
    request: business_pb.UpdateBusinessRequest,
    metadata: grpcWeb.Metadata | null): Promise<business_pb.UpdateBusinessResponse>;

  update(
    request: business_pb.UpdateBusinessRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: business_pb.UpdateBusinessResponse) => void): grpcWeb.ClientReadableStream<business_pb.UpdateBusinessResponse>;

  update(
    request: business_pb.UpdateBusinessRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: business_pb.UpdateBusinessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/business_endpoint.BusinessEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/business_endpoint.BusinessEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdateActivity = new grpcWeb.MethodDescriptor(
    '/business_endpoint.BusinessEndpoint/UpdateActivity',
    grpcWeb.MethodType.UNARY,
    business_pb.UpdateBusinessActivityRequest,
    business_pb.UpdateBusinessActivityResponse,
    (request: business_pb.UpdateBusinessActivityRequest) => {
      return request.serializeBinary();
    },
    business_pb.UpdateBusinessActivityResponse.deserializeBinary
  );

  updateActivity(
    request: business_pb.UpdateBusinessActivityRequest,
    metadata: grpcWeb.Metadata | null): Promise<business_pb.UpdateBusinessActivityResponse>;

  updateActivity(
    request: business_pb.UpdateBusinessActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: business_pb.UpdateBusinessActivityResponse) => void): grpcWeb.ClientReadableStream<business_pb.UpdateBusinessActivityResponse>;

  updateActivity(
    request: business_pb.UpdateBusinessActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: business_pb.UpdateBusinessActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/business_endpoint.BusinessEndpoint/UpdateActivity',
        request,
        metadata || {},
        this.methodDescriptorUpdateActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/business_endpoint.BusinessEndpoint/UpdateActivity',
    request,
    metadata || {},
    this.methodDescriptorUpdateActivity);
  }

  methodDescriptorGetBusinesses = new grpcWeb.MethodDescriptor(
    '/business_endpoint.BusinessEndpoint/GetBusinesses',
    grpcWeb.MethodType.UNARY,
    business_pb.GetBusinessesRequest,
    business_pb.GetBusinessesResponse,
    (request: business_pb.GetBusinessesRequest) => {
      return request.serializeBinary();
    },
    business_pb.GetBusinessesResponse.deserializeBinary
  );

  getBusinesses(
    request: business_pb.GetBusinessesRequest,
    metadata: grpcWeb.Metadata | null): Promise<business_pb.GetBusinessesResponse>;

  getBusinesses(
    request: business_pb.GetBusinessesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: business_pb.GetBusinessesResponse) => void): grpcWeb.ClientReadableStream<business_pb.GetBusinessesResponse>;

  getBusinesses(
    request: business_pb.GetBusinessesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: business_pb.GetBusinessesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/business_endpoint.BusinessEndpoint/GetBusinesses',
        request,
        metadata || {},
        this.methodDescriptorGetBusinesses,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/business_endpoint.BusinessEndpoint/GetBusinesses',
    request,
    metadata || {},
    this.methodDescriptorGetBusinesses);
  }

  methodDescriptorGetBusiness = new grpcWeb.MethodDescriptor(
    '/business_endpoint.BusinessEndpoint/GetBusiness',
    grpcWeb.MethodType.UNARY,
    business_pb.GetBusinessRequest,
    business_pb.GetBusinessResponse,
    (request: business_pb.GetBusinessRequest) => {
      return request.serializeBinary();
    },
    business_pb.GetBusinessResponse.deserializeBinary
  );

  getBusiness(
    request: business_pb.GetBusinessRequest,
    metadata: grpcWeb.Metadata | null): Promise<business_pb.GetBusinessResponse>;

  getBusiness(
    request: business_pb.GetBusinessRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: business_pb.GetBusinessResponse) => void): grpcWeb.ClientReadableStream<business_pb.GetBusinessResponse>;

  getBusiness(
    request: business_pb.GetBusinessRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: business_pb.GetBusinessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/business_endpoint.BusinessEndpoint/GetBusiness',
        request,
        metadata || {},
        this.methodDescriptorGetBusiness,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/business_endpoint.BusinessEndpoint/GetBusiness',
    request,
    metadata || {},
    this.methodDescriptorGetBusiness);
  }

  methodDescriptorUpdateThirdPartyConnection = new grpcWeb.MethodDescriptor(
    '/business_endpoint.BusinessEndpoint/UpdateThirdPartyConnection',
    grpcWeb.MethodType.UNARY,
    business_pb.UpdateThirdPartyConnectionRequest,
    business_pb.UpdateThirdPartyConnectionResponse,
    (request: business_pb.UpdateThirdPartyConnectionRequest) => {
      return request.serializeBinary();
    },
    business_pb.UpdateThirdPartyConnectionResponse.deserializeBinary
  );

  updateThirdPartyConnection(
    request: business_pb.UpdateThirdPartyConnectionRequest,
    metadata: grpcWeb.Metadata | null): Promise<business_pb.UpdateThirdPartyConnectionResponse>;

  updateThirdPartyConnection(
    request: business_pb.UpdateThirdPartyConnectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: business_pb.UpdateThirdPartyConnectionResponse) => void): grpcWeb.ClientReadableStream<business_pb.UpdateThirdPartyConnectionResponse>;

  updateThirdPartyConnection(
    request: business_pb.UpdateThirdPartyConnectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: business_pb.UpdateThirdPartyConnectionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/business_endpoint.BusinessEndpoint/UpdateThirdPartyConnection',
        request,
        metadata || {},
        this.methodDescriptorUpdateThirdPartyConnection,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/business_endpoint.BusinessEndpoint/UpdateThirdPartyConnection',
    request,
    metadata || {},
    this.methodDescriptorUpdateThirdPartyConnection);
  }

  methodDescriptorUploadLogo = new grpcWeb.MethodDescriptor(
    '/business_endpoint.BusinessEndpoint/UploadLogo',
    grpcWeb.MethodType.UNARY,
    business_pb.UploadLogoRequest,
    business_pb.UploadLogoResponse,
    (request: business_pb.UploadLogoRequest) => {
      return request.serializeBinary();
    },
    business_pb.UploadLogoResponse.deserializeBinary
  );

  uploadLogo(
    request: business_pb.UploadLogoRequest,
    metadata: grpcWeb.Metadata | null): Promise<business_pb.UploadLogoResponse>;

  uploadLogo(
    request: business_pb.UploadLogoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: business_pb.UploadLogoResponse) => void): grpcWeb.ClientReadableStream<business_pb.UploadLogoResponse>;

  uploadLogo(
    request: business_pb.UploadLogoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: business_pb.UploadLogoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/business_endpoint.BusinessEndpoint/UploadLogo',
        request,
        metadata || {},
        this.methodDescriptorUploadLogo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/business_endpoint.BusinessEndpoint/UploadLogo',
    request,
    metadata || {},
    this.methodDescriptorUploadLogo);
  }

}

