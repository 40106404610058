import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession, APIResponse } from '../../../app/Api';
import { OrganizationEndpointClient } from '../../../repository/UserManagement/OrganizationServiceClientPb';
import { AddOrganizationRequest, AddOrganizationResponse, GetOrganizationRequest, GetOrganizationResponse, UpdateOrganizationRequest, UpdateOrganizationResponse, UploadLogoRequest, UploadLogoResponse } from '../../../repository/UserManagement/organization_pb';

export interface OrganizationState {
  id: number,
  name: string,
  domain: string,
  address: string,
  email: string,
  phonenumber: string,
  logo: any
}
export interface OrganizationsFormState {
  isLoading: boolean
  message?: ApiMessage
  currentOrganization?: OrganizationState
}

const initialState: OrganizationsFormState = {
  isLoading: false,
  message: undefined,
  currentOrganization: undefined,
}

const client = new OrganizationEndpointClient(API_URL, null, null);
export const add = createAsyncThunk<AddOrganizationResponse.AsObject, APIRequest<AddOrganizationRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/organizations/add',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.add(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)


export const update = createAsyncThunk<UpdateOrganizationResponse.AsObject, APIRequest<UpdateOrganizationRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/organizations/update',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.update(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)

export const getOrganization = createAsyncThunk<APIResponse<GetOrganizationResponse.AsObject>, APIRequest<GetOrganizationRequest>, {
  rejectValue: ApiMessage
}>(
  'forms/organizations/get',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.getOrganization(req.body, req.headers ?? {});
        return { metadata: { id: req.body.getId() }, response: r.toObject() };
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {
          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)

export const uploadLogo = createAsyncThunk<UploadLogoResponse.AsObject, APIRequest<UploadLogoRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/organizations/uploadLogo',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.uploadLogo(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)




export const organizationsFormSlice = createSlice({
  name: 'forms/organizations',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCurrentOrganization: (state, action: PayloadAction<OrganizationState>) => {
      state.currentOrganization = action.payload;
    },
    dismissMessage: (state) => {
      state.message = undefined;
    },
    reset: (state) => {
      state.isLoading = false;
      state.message = undefined;
      state.currentOrganization = initialState.currentOrganization;

    }
  },
  extraReducers: (builder) => {
    builder.addCase(add.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload)
        state.message = toApiMessage(payload.success?.message);

    })
    builder.addCase(add.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(add.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
    builder.addCase(update.fulfilled, (state, { payload }) => {
      if (payload)
        state.message = toApiMessage(payload.success?.message);
      state.isLoading = false;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(update.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
    builder.addCase(getOrganization.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        var r = {
          id: payload.metadata?.id, domain: payload.response.success?.domain?.value ?? "",
          name: payload.response.success?.name?.value ?? "",

          address: payload.response.success?.address?.value ?? "",
          email: payload.response.success?.email?.value ?? "",
          phonenumber: payload.response.success?.phonenumber?.value ?? "",
          logo: {} as any,
  
        }
        try {
          var logo = JSON.parse(payload.response.success?.logo?.value ?? "")
          r.logo = logo;
        } catch {

        }
        state.currentOrganization = r;
      }
    })
    builder.addCase(getOrganization.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(getOrganization.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
    builder.addCase(uploadLogo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
     
    })
    builder.addCase(uploadLogo.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(uploadLogo.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
  }
})

export const { reset, dismissMessage, setIsLoading, setCurrentOrganization } = organizationsFormSlice.actions

export default organizationsFormSlice.reducer


