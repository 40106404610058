import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, DatePicker, DayOfWeek, defaultDatePickerStrings, DialogFooter, CheckboxVisibility } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/Hooks"
import { getPage } from "../../../app/Pages"
import { setPage } from "../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../common/Table/Table"
import { reset, dismissMessage, setSort, setNumberOfResults, addItem, getItems, getPrivileges, getUsers, resetPrivileges, resetSet, resetUsers, setIsFilteredPrivilegeSet, setIsFilteredSet, setIsFilteredUserSet, setObjectIdFilterOption, setSelectedItems, setSearchText, setSelectedPrivilegeItems, setTypeFilterOption, setSelectedUserItems, updateItem, setFromFilterOption, setToFilterOption } from "./LogsPageSlice"
import { ApiMessage, getHeaders } from "../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../app/Themes"
import { SearchField } from "../../common/SearchField/SearchField"
import { currencyFormatter, formatDate, printComponent, normalizeKey } from "../../../app/Helpers"
import { TableState } from "../../common/Table/TableSate"
import { Message } from "../../common/Message/Message"
import { ShareBox } from "../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../common/Popup/Popup"
import { FormType } from "../../forms/FormProps"
import { AccountStatus, LimitationType, LogType, Operations } from "../../../app/Enums"
import { GetLogsRequest } from "../../../repository/UserManagement/log_pb"
import { GetUsersRequest } from "../../../repository/UserManagement/Managers/user_pb"
import { GetPrivilegesRequest } from "../../../repository/UserManagement/Managers/privilege_pb"
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb"
import moment from "moment"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../PageProps"
import { List } from "../../common/List/List"
import { PrivilegesPage } from "../Management/Privileges/PrivilegesPage"
import { UsersPage } from "../Management/Users/UsersPage"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

let req: GetLogsRequest;
let privilegesReq: GetPrivilegesRequest;
let executersReq: GetUsersRequest;

let org: number = -1;

let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;

const key = "logs"
export const LogsPage: React.FunctionComponent<PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        isChangeStateLoading: boolean,
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        searchText: string | undefined,
        typeFilterOption: { key: number, text: string },
        filters: any[],
        objectIdFilterOption: { value: string, text: string }
        executers: TableState
        privileges: TableState
        toFilterOption: { value: string, text: string }
        fromFilterOption: { value: string, text: string }


    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.logsPage.isChangeStateLoading,
            set: state.logsPage.set,
            message: state.logsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            searchText: state.logsPage.searchText,
            executers: state.logsPage.executers,
            privileges: state.logsPage.privileges,
            objectIdFilterOption: state.logsPage.objectIdFilterOption,
            filters: state.logsPage.filters,
            typeFilterOption: state.logsPage.typeFilterOption,
            toFilterOption: state.logsPage.toFilterOption,
            fromFilterOption: state.logsPage.fromFilterOption,


        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const closeAccountActionConfirmationcalloutTargetId = useId('close-account-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true), true)
        }

    });

    const getActionId = (action: number): string => {
        if (action == 1) {
            return closeAccountActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedItems(_selection.getSelection()));
            } else {
                dispatch(setSelectedItems([]));
            }
        },

    }));

    const clearFilter = () => {
        dispatch(setSelectedPrivilegeItems([]))
        req.setAction(undefined)
        dispatch(setSelectedUserItems([]))
        req.setExecuter(undefined)
        dispatch(setTypeFilterOption({ key: 0, text: "" }))
        req.setLogtype(undefined)
        dispatch(setObjectIdFilterOption({ value: "", text: "" }))
        req.setObjectid(undefined)
        dispatch(setFromFilterOption({ value: "", text: "" }))
        req.setFrom(undefined)
        dispatch(setToFilterOption({ value: "", text: "" }))
        req.setTo(undefined)
    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }


        if (state.executers.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.executers.selected.at(0)?.internalId));
            req.setExecuter(wrapper)
            isFiltered = true;
        } else {
            req.setExecuter(undefined)
        }

        if (state.privileges.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.privileges.selected.at(0)?.id) + "");
            req.setAction(wrapper)
            isFiltered = true;
        } else {
            req.setAction(undefined)
        }


        if (state.typeFilterOption.key != 0) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.typeFilterOption.key);
            req.setLogtype(wrapper)
            isFiltered = true;

        } else {
            req.setLogtype(undefined)
        }

        if (state.objectIdFilterOption && state.objectIdFilterOption.value.trim().length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue(state.objectIdFilterOption.value.trim());
            req.setObjectid(wrapper)
            isFiltered = true;

        } else {
            req.setObjectid(undefined)
        }
        if (state.fromFilterOption && state.fromFilterOption.value.trim().length > 0) {
            const wrapper = new Timestamp();

            wrapper.fromDate(moment(state.fromFilterOption.value, 'YYYY-MM-DD').startOf('day').utc().toDate())
            req.setFrom(wrapper)
            isFiltered = true;

        } else {
            req.setFrom(undefined)
        }

        if (state.toFilterOption && state.toFilterOption.value.trim().length > 0) {
            const wrapper = new Timestamp();
            wrapper.fromDate(moment(state.toFilterOption.value, 'YYYY-MM-DD').endOf('day').utc().toDate())
            req.setTo(wrapper)
            isFiltered = true;

        } else {
            req.setTo(undefined)
        }
        req.setNextto(undefined)
        dispatch(resetSet())
        dispatch(setIsFilteredSet(isFiltered))
        getItemsPromise?.abort()
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {
        req = new GetLogsRequest();
        executersReq = new GetUsersRequest();
        privilegesReq = new GetPrivilegesRequest();

        var l = searchParams.get('org');
        var resourceId = searchParams.get('resourceId');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                executersReq.setOrganization(wrapper)
                privilegesReq.setOrganization(wrapper)


            }
        }
        if (resourceId && resourceId.trim().length > 0 && resourceId.trim().length <= 25) {
            const wrapper = new StringValue();
            wrapper.setValue(resourceId.trim());
            req.setObjectid(wrapper)
            dispatch(setObjectIdFilterOption({ value: resourceId.trim(), text: t("resourceId") + ":" + resourceId.trim() }))
        }

        if (!props.isSelection) {
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
        }

        req.setNextto(undefined)
        req.setNumofresults(state.set.numberOfResults)
        req.setOrder(state.set.isDescending)

        executersReq.setNumofresults(state.executers.numberOfResults)
        executersReq.setOrder(state.executers.isDescending)
        privilegesReq.setNumofresults(state.privileges.numberOfResults)
        privilegesReq.setOrder(state.privileges.isDescending)
        privilegesReq.setIsadded(true);
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

        return () => { //clean up
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])
    /*
    
          public long Id { get; set; }
                public string InternalId { get; set; }
                public string Name { get; set; }
                public string Code { get; set; }
                public decimal MirrorBalance { get; set; }
                public CurrencyResponse Currency { get; set; }
                public int Status { get; set; }
                public AccountTypeResponse AccountType { get; set; }
                public OwnerResponse Owner { get; set; }
                public ProfileResponse CreatedBy { get; set; }
                public DateTime CreatedDate { get; set; }
    */

    const c: IColumn[] = [
        {
            key: 'id',
            name: t("id"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.id != undefined)
                    return <Link onClick={(e) => {
                        if (e) {
                            e.preventDefault();
                            navigate("/logs/" + item?.id + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/logs/" + item?.id + ((org == -1) ? "" : "?org=" + org)} > {item?.id}</Link >;
            },
        },
        {
            key: 'privilegeName',
            name: t("privilege"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.privilegeId != undefined)
                    return <Link onClick={(e) => {
                        if (e) {
                            e.preventDefault();
                            navigate("/management/privileges/" + item?.privilegeId + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/management/privileges/" + item?.privilegeId + ((org == -1) ? "" : "?org=" + org)} > {item?.privilegeName == "" ? item?.privilegeId : item?.privilegeName}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;


            },
        },
        {
            key: 'type',
            name: t("type"),
            minWidth: 50,
            maxWidth: 140,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.type != undefined)
                    return <Text>{item?.type == LogType.SUCCESS_OP ? t("success_op") : item?.type == LogType.FATAL_EXCEPTION ? t("fatel_exception") : item?.type == LogType.APP_EXCEPTION ? t("app_exception") : ""}</Text>;
            },
        },

        {
            key: 'addedBy',
            name: t("executer"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.addedById)
                    return <Link onClick={(e) => {
                        if (e) {
                            e.preventDefault();
                            navigate("/management/users/" + item?.addedById + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/management/users/" + item?.addedById + ((org == -1) ? "" : "?org=" + org)} ><Persona
                            text={item?.addedBy == "" ? item?.addedById : item?.addedBy}
                            size={PersonaSize.size40}
                        /></Link>

            },
        },


        {
            key: 'createdDate',
            name: t("executedDate"),
            minWidth: 20,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },



    ];

    const _selectionItems: ICommandBarItemProps[] = [

        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },

    ];

    const mainOptions: ICommandBarItemProps[] = [

        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.set.isFetching,
            onClick: () => {
                dispatch(resetSet())
                req.setNextto(undefined)
                //   if (!state.searchText || state.searchText?.trim().length == 0) {
                //     req.setSearch(undefined)
                //}
                getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },
    ];
    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t('sort'),
            iconProps: { iconName: 'sort' },
            disabled: state.set.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t('ascendingDate'),
                    canCheck: true,
                    checked: !state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetSet())
                        req.setOrder(false)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }


                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t('descendingDate'),
                    canCheck: true,
                    checked: state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetSet())
                        req.setOrder(true)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.set.numberOfResults,
            title: t('results') + ': ' + state.set.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];


    const privilegesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('privileges'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.privileges.hasMore && !state.privileges.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.privileges.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedPrivilegeItems([state.privileges.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.privileges.items.length > 0 && !state.privileges.isFetching) {

                privilegesReq.setNextto(state.privileges.items.at(state.privileges.items.length - 1).id)
                getFilterPromise = dispatch(getPrivileges({ body: privilegesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.privileges.items.length > 0 ? state.privileges.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.privileges.items.length == 0 ? state.privileges.isFetching : false,
        isSearching: state.privileges.items.length > 0 ? state.privileges.isFetching : false,


    };
    const onPrivilegesFilterChanged = (filterText: string, selectedItems?: ITag[]): any => {

        dispatch(resetPrivileges())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        privilegesReq.setSearch(wrapper)
        privilegesReq.setNextto(0)
        dispatch(setIsFilteredPrivilegeSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getPrivileges({ body: privilegesReq, headers: getHeaders() }))
    }


    const managersSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('users'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.executers.hasMore && !state.executers.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.executers.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedUserItems([state.executers.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.executers.items.length > 0 && !state.executers.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.executers.items.at(state.executers.items.length - 1).internalId);
                executersReq.setNextto(wrapper)
                getFilterPromise = dispatch(getUsers({ body: executersReq, headers: getHeaders() }))
            }
        },
        suggestions: state.executers.items.length > 0 ? state.executers.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.executers.items.length == 0 ? state.executers.isFetching : false,
        isSearching: state.executers.items.length > 0 ? state.executers.isFetching : false,


    };


    const onManagersFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetUsers())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        executersReq.setSearch(wrapper)
        executersReq.setNextto(undefined)
        dispatch(setIsFilteredUserSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getUsers({ body: executersReq, headers: getHeaders() }))
        return [];

    }

    return (
        <Stack >


            <Popup isOpen={(currentAction == 1001)} title={t("privileges")} onDismiss={() => { setCurrenctAction(0) }} >
                <PrivilegesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedPrivilegeItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 1000)} title={t("users")} onDismiss={() => { setCurrenctAction(0) }} >

                <UsersPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedUserItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={state.set.selected.length == 0 ? mainOptions : _selectionItems}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}


            />



            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareLog")} value={`https://${window.location.host}/logs/${state.set.selected.at(0).id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {isCalloutVisible ? (
                <Callout
                    ariaLabelledBy={labelId}

                    target={`#${calloutTargetId}`}
                    onDismiss={toggleIsCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={true}
                >
                    <Stack tokens={{ padding: 28, childrenGap: 30 }}  >
                        <Text block variant="mediumPlus" >
                            {t("filterDesc")}
                        </Text>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label  >{t("privilege")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("privilege"), }}
                                suggestionsHeaderText={t('privileges')}
                                isLoading={state.privileges.items.length == 0 ? state.privileges.isFetching : false}
                                isSearching={state.privileges.items.length > 0 ? state.privileges.isFetching : false}
                                moreSuggestionsAvailable={state.privileges.hasMore && !state.privileges.isFetching}
                                suggestions={state.privileges.items.length > 0 ? state.privileges.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                onGetMoreResults={() => {
                                    if (state.privileges.items.length > 0 && !state.privileges.isFetching) {

                                        privilegesReq.setNextto(state.privileges.items.at(state.privileges.items.length - 1).id)
                                        getFilterPromise = dispatch(getPrivileges({ body: privilegesReq, headers: getHeaders() }))
                                    }

                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.privileges.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedPrivilegeItems([state.privileges.items.at(u)]))
                                    }

                                }}
                                isPeoplePicker={false}
                                selectedItems={state.privileges.selected.length > 0 ? state.privileges.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedPrivilegeItems([]))

                                }}
                                onEmptyInputFocus={async () => {
                                    dispatch(resetPrivileges())
                                    privilegesReq.setSearch(undefined)
                                    privilegesReq.setNextto(0)
                                    dispatch(setIsFilteredPrivilegeSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getPrivileges({ body: privilegesReq, headers: getHeaders() }))


                                }}
                                onFilterChanged={onPrivilegesFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1001)

                                        }
                                    }]}
                            />

                        </Stack>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label >{t("executer")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}

                                isPeoplePicker={true}
                                suggestionsHeaderText={t('users')}
                                isLoading={state.executers.items.length == 0 ? state.executers.isFetching : false}
                                isSearching={state.executers.items.length > 0 ? state.executers.isFetching : false}
                                moreSuggestionsAvailable={state.executers.hasMore && !state.executers.isFetching}
                                suggestions={state.executers.items.length > 0 ? state.executers.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.executers.items.length > 0 && !state.executers.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.executers.items.at(state.executers.items.length - 1).internalId);
                                        executersReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getUsers({ body: executersReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.executers.items.findIndex(e => e.internalId == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedUserItems([state.executers.items.at(u)]))
                                    }

                                }}
                                inputProps={{ placeholder: t("executer") }}

                                selectedItems={state.executers.selected.length > 0 ? state.executers.selected.map(e => { return { key: e.internalId, text: e.name, secondaryText: e.username, } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedUserItems([]))
                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetUsers())
                                    executersReq.setSearch(undefined)
                                    executersReq.setNextto(undefined)
                                    dispatch(setIsFilteredUserSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getUsers({ body: executersReq, headers: getHeaders() }))
                                    return []


                                }}
                                onFilterChanged={onManagersFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1000)

                                        }
                                    }]}
                            />



                        </Stack>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("type")}</Label>
                            <Dropdown
                                selectedKey={state.typeFilterOption.key}
                                options={[{ key: 0, text: t("all") }, { key: LogType.SUCCESS_OP, text: t("success_op") }, { key: LogType.APP_EXCEPTION, text: t("app_exception") }, { key: LogType.FATAL_EXCEPTION, text: t("fatel_exception") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {

                                    dispatch(setTypeFilterOption({ key: Number(item?.key), text: t("type") + ":" + item?.text ?? "" }))

                                })}

                            />
                        </Stack>
                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("resourceId")}</Label>
                            <TextField onChange={(e: any) => {
                                if (e)
                                    dispatch(setObjectIdFilterOption({ value: e?.target?.value, text: t("resourceId") + ":" + e?.target?.value }))

                            }} placeholder="62114299c2193103cae270b0" maxLength={25} value={state.objectIdFilterOption.value} styles={{ root: { width: 300 } }} />

                        </Stack>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("from")}</Label>
                            <DatePicker

                                firstDayOfWeek={DayOfWeek.Sunday}
                                placeholder={t("from")}
                                value={state.fromFilterOption.value != "" ? moment(state.fromFilterOption.value, 'YYYY-MM-DD').toDate() : undefined}
                                styles={{ root: { width: 300 } }}
                                ariaLabel={t("from")}
                                formatDate={(e) => {
                                    return formatDate(e, 'YYYY-MM-DD')
                                }}
                                parseDateFromString={(date) => {
                                    return moment(date, 'YYYY-MM-DD').toDate();
                                }}
                                strings={defaultDatePickerStrings}
                                onSelectDate={(date: Date | null | undefined) => {
                                    if (date)
                                        dispatch(setFromFilterOption({ value: formatDate(date, 'YYYY-MM-DD'), text: t("from") + ":" + formatDate(date, 'YYYY-MM-DD') }))

                                }}

                            />

                        </Stack>
                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("to")}</Label>
                            <DatePicker

                                firstDayOfWeek={DayOfWeek.Sunday}
                                placeholder={t("to")}
                                value={state.toFilterOption.value != "" ? moment(state.toFilterOption.value, 'YYYY-MM-DD').toDate() : undefined}
                                styles={{ root: { width: 300 } }}
                                ariaLabel={t("to")}
                                formatDate={(e) => {
                                    return formatDate(e, 'YYYY-MM-DD')
                                }}
                                parseDateFromString={(date) => {
                                    return moment(date, 'YYYY-MM-DD').toDate();
                                }}
                                strings={defaultDatePickerStrings}
                                onSelectDate={(date: Date | null | undefined) => {
                                    if (date)
                                        dispatch(setToFilterOption({ value: formatDate(date, 'YYYY-MM-DD'), text: t("to") + ":" + formatDate(date, 'YYYY-MM-DD') }))

                                }}

                            />

                        </Stack>

                        <DialogFooter>
                            <PrimaryButton text={t("search")} onClick={(e => {
                                search(state.searchText);
                                toggleIsCalloutVisible();
                            })} />
                            <DefaultButton text={t("clearFilter")} onClick={() => {
                                clearFilter();
                            }} />

                        </DialogFooter>
                    </Stack>
                </Callout>
            ) : null}

            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            { id: calloutTargetId, title: t("filter"), iconProps: { iconName: "filter" }, onClick: toggleIsCalloutVisible },
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                    if (isCalloutVisible)
                                        toggleIsCalloutVisible();

                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {

                                if (e.key == 1) {
                                    dispatch(setSelectedUserItems([]))
                                    req.setExecuter(undefined)
                                }
                                if (e.key == 2) {
                                    dispatch(setSelectedPrivilegeItems([]))
                                    req.setAction(undefined)
                                }
                                if (e.key == 3) {
                                    dispatch(setObjectIdFilterOption({ value: "", text: "" }))
                                    req.setObjectid(undefined)
                                }
                                if (e.key == 4) {
                                    dispatch(setTypeFilterOption({ key: 0, text: "" }))
                                    req.setLogtype(undefined)
                                }

                                if (e.key == 5) {
                                    dispatch(setFromFilterOption({ value: "", text: "" }))
                                    req.setFrom(undefined)
                                }
                                if (e.key == 6) {
                                    dispatch(setToFilterOption({ value: "", text: "" }))
                                    req.setTo(undefined)
                                }

                            });
                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                            if (isCalloutVisible)
                                toggleIsCalloutVisible();
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.set.isFilteredSet) {
                                search(undefined);
                            }
                            /*if (req.getSearch() && !state.managers.isFilteredSet) {
                                dispatch(resetRoles())
                                req.setNextto(undefined)
     
                                req.setSearch(undefined)
     
                                getManagersPromise = dispatch(getManagerUsers({ body: req, headers: getHeaders() }))
                            }*/
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="logs"
                items={state.set.items}
                columns={c}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                selection={_selection}
                width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 176px)" : "calc(100vh - 224px)"}
                selectionMode={SelectionMode.single}
                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                isColumnSortingEnabled={state.set.selected.length == 0}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.justified}
                compRef={ref}
                onScroll={(e) => {

                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.set.isFetching && state.set.hasMore) {
                        const wrapper = new StringValue();
                        wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                        req.setNextto(wrapper)
                        getItemsPromise?.abort();
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }
                }}
                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.set.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.set.items.length > 0 && !state.set.isFetching && state.set.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                                    req.setNextto(wrapper)
                                    getItemsPromise?.abort();
                                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.set.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}
            />

            {props.isSelection ? <DialogFooter>
                <PrimaryButton disabled={state.set.isFetching || state.set.selected.length == 0} text={state.set.isFetching ? undefined : t("select")} onClick={() => {
                    if (props.onItemSelected && state.set.selected.length > 0) {
                        props.onItemSelected(state.set.selected.at(0))
                    }
                }} >

                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                </PrimaryButton>
                <DefaultButton disabled={state.set.isFetching} text={t("discard")} onClick={() => {
                    if (props.onCancel) {
                        props.onCancel()
                    }
                }} />
            </DialogFooter> : undefined}
        </Stack>



    );
}




