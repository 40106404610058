
export interface Page {
    key: string,
    title: string,
    path: string,
    parentKey?: string,
    nav: string
}

const appPages: Page[] = [
    {
        key: "dashboard",
        title: "dashboard",
        path: "",
        nav: "dashboard"
        //parentKey: "users"
    },
    {
        key: "organizations",
        title: "organizations",
        path: "organizations",
        nav: "organizations"
        //parentKey: "users"

    },

    
 
    {
        key: "login",
        title: "dimensions",
        path: "login",
        nav: ""
        //parentKey: "users"
    },
    {
        key: "logs",
        title: "logs",
        path: "logs",
        nav: "logs"
        //parentKey: "users"
    },
    {
        key: "logs/details",
        title: "logDetails",
        path: "logs/:id",
        parentKey: "logs",
        nav: "logs"
    },

    {
        key: "profile",
        title: "myProfilePage",
        path: "profile",
        nav: ""
        //parentKey: "users"
    },

    {
        key: "organizations/details",
        title: "organizationDetails",
        path: "organizations/:id",
        parentKey: "organizations",
        nav: "organizations"
    },


    {
        key: "management",
        title: "management",
        path: "management/users",
        nav: "management"

    },
    {
        key: "management/users",
        title: "users",
        path: "management/users",
        parentKey: "management",
        nav: "management/users"

    },
    {
        key: "management/users/add",
        title: "addManager",
        path: "management/users/add",
        parentKey: "management/users",
        nav: "management/users"

    },
    {
        key: "management/users/details",
        title: "managerDetails",
        path: "management/users/:id",
        parentKey: "management/users",
        nav: "management/users"
    },
    {
        key: "management/usersTypes",
        title: "usersTypes",
        path: "management/user-types",
        parentKey: "management",
        nav: "management/usersTypes"


    },
    {
        key: "management/usersTypes/details",
        title: "userTypeDetails",
        path: "management/user-types/:id",
        parentKey: "management/usersTypes",
        nav: "management/usersTypes"
    },

    {
        key: "management/privileges",
        title: "privileges",
        path: "management/privileges",
        parentKey: "management",
        nav: "management/privileges"


    },
    {
        key: "management/privileges/details",
        title: "privilegeDetails",
        path: "management/privileges/:id",
        parentKey: "management/privileges",
        nav: "management/privileges"
    },
    {
        key: "management/privilege-data/details",
        title: "privilegeDataDetails",
        path: "management/privilege-data/:id",
        nav: "management/privileges",
        parentKey: "management",

    },
    {
        key: "management/roles",
        title: "roles",
        path: "management/roles",
        parentKey: "management",
        nav: "management/roles"


    },
    {
        key: "management/roles/details",
        title: "roleDetails",
        path: "management/roles/:id",
        parentKey: "management/roles",
        nav: "management/roles"
    },
    {
        key: "management/groups",
        title: "groups",
        path: "management/groups",
        parentKey: "management",
        nav: "management/groups"


    },
    {
        key: "management/groups/details",
        title: "groupDetails",
        path: "management/groups/:id",
        parentKey: "management/groups",
        nav: "management/groups"
    },

    {
        key: "management/groupMembership/details",
        title: "groupMembershipDetails",
        path: "management/group-memberships/:id",
        nav: "management/groups"
    },
    {
        key: "customers/groupMembership/details",
        title: "groupMembershipDetails",
        path: "customers/group-memberships/:id",
        nav: "customers/groups"
    },


    {
        key: "customers/dataRestrection/details",
        title: "dataRestrectionDetails",
        path: "customers/data-restrictions/:id",
        nav: "customers/privileges"
    },


    {
        key: "management/dataRestrection/details",
        title: "dataRestrectionDetails",
        path: "management/data-restrictions/:id",
        nav: "management/privileges"
    },


    {
        key: "customers/rolePrivilege/details",
        title: "subscriptionServiceDetails",
        path: "customers/role-privileges/:id",
        nav: "customers/roles"
    },


    {
        key: "management/rolePrivilege/details",
        title: "rolePrivilegeDetails",
        path: "management/role-privileges/:id",
        nav: "management/roles"
    },

    {
        key: "customers",
        title: "customers",
        path: "customers/users",
        nav: "customers"

    },
    {
        key: "customers/users",
        title: "profiles",
        path: "customers/users",
        parentKey: "customers",
        nav: "customers/users"

    },
    {
        key: "customers/users/add",
        title: "addCustomer",
        path: "customers/users/add",
        parentKey: "customers/users",
        nav: "customers/users"

    },
    {
        key: "customers/users/details",
        title: "customerDetails",
        path: "customers/users/:id",
        parentKey: "customers/users",
        nav: "customers/users"
    },
    {
        key: "customers/usersTypes",
        title: "clientTypes",
        path: "customers/user-types",
        parentKey: "customers",
        nav: "customers/usersTypes"


    },
    {
        key: "customers/usersTypes/details",
        title: "clientTypeDetails",
        path: "customers/user-types/:id",
        parentKey: "customers/usersTypes",
        nav: "customers/usersTypes"
    },

    {
        key: "customers/privileges",
        title: "services",
        path: "customers/privileges",
        parentKey: "customers",
        nav: "customers/privileges"


    },
    {
        key: "customers/privileges/details",
        title: "serviceDetails",
        path: "customers/privileges/:id",
        parentKey: "customers/privileges",
        nav: "customers/privileges"
    },
    {
        key: "customers/privilege-data/details",
        title: "serviceDataDetails",
        path: "customers/privilege-data/:id",
        nav: "customers/privileges",
        parentKey: "customers",
    },
    {
        key: "customers/roles",
        title: "subscriptions",
        path: "customers/roles",
        parentKey: "customers",
        nav: "customers/roles"


    },
    {
        key: "customers/roles/details",
        title: "subscriptionDetails",
        path: "customers/roles/:id",
        parentKey: "customers/roles",
        nav: "customers/roles"
    },
    {
        key: "customers/groups",
        title: "groups",
        path: "customers/groups",
        parentKey: "customers",
        nav: "customers/groups"


    },
    {
        key: "customers/groups/details",
        title: "groupDetails",
        path: "customers/groups/:id",
        parentKey: "customers/groups",
        nav: "customers/groups"
    },

    {
        key: "customers/groupMembership/details",
        title: "groupMembershipDetails",
        path: "customers/group-memberships/:id",
        nav: "customers/groups"
    },

    {
        key: "businesses",
        title: "businesses",
        path: "businesses",
        nav: "businesses"
        //parentKey: "users"
    },
    {
        key: "businesses/details",
        title: "businessDetails",
        path: "businesses/:id",
        parentKey: "businesses",
        nav: "businesses"
    },

    {
        key: "businessClients",
        title: "businessClients",
        path: "business-clients",
        nav: "businessClients"
        //parentKey: "users"
    },
    {
        key: "businessClients/details",
        title: "businessClientDetails",
        path: "business-clients/:id",
        parentKey: "businessClients",
        nav: "businessClients"
    },

    
];


export const getPage = (key: string) => appPages.filter(e => e.key == key)[0];

